import _, {isArray, isNumber, isObject, split, toNumber} from "lodash";
import dayjs from "dayjs";


/**
 * const for local-storage-keys
 */
export const LSK_REFRESH_JWT_OTHER = "auth-refresh-jwt-other"
export const LSK_ACCESS_JWT_OTHER = "auth-access-jwt-other"
export const LSK_REFRESH_JWT = "auth-refresh-jwt"
export const LSK_ACCESS_JWT = "auth-access-jwt"


export function isDevelopment(): boolean {
    //@ts-ignore
    return !!window.webpackHotUpdate || !!window.webpackHotUpdateliftbase
}


export enum DeploymentStage {
    dev,
    testing,
    production
}

/**
 * Returns "dev", "testing", "production" based on the url eg. "app-dev.liftbase.de" vs "app-test.liftbase.de"
 * Returns null if environment could not be detemindes
 */
export function getDeploymentStage(): DeploymentStage | null {
    const host = window?.location?.host
    if (!host) {
        return null
    }

    if (host.indexOf("localhost") >= 0 || host.indexOf("app-dev") >= 0) {
        return DeploymentStage.dev
    } else if (host.indexOf("app-test") >= 0 || host.indexOf("app-demo") >= 0) {
        return DeploymentStage.testing
    } else if (host.indexOf("app.") >= 0) {
        return DeploymentStage.production
    }

    return null
}

export function isDev(): boolean {
    const host = window?.location?.host
    return host?.indexOf("localhost") >= 0 || host?.indexOf("app-dev") >= 0
}

export function isLocalhost(): boolean {
    const host = window?.location?.host
    return host?.indexOf("localhost") >= 0
}

/**
 * Return true if:
 * - url is a relative url or
 * - url is a "liftbase.de" or subdomain
 */
export function isLiftbaseUrl(url: string) {
    try {
        const externalUrl = new URL(url)
        return externalUrl.host.indexOf("liftbase.de") >= 0;
    } catch (e) {
        // its a relative URL
        return true
    }
}

export function formatCurrencyGerman(unformatted: any): string | undefined {
    if (!unformatted) {
        return undefined
    }

    let cleared: string;
    if (typeof unformatted == "string") {
        cleared = "" + parseFormattedCurrency(unformatted)
    } else if (typeof unformatted == "number") {
        cleared = "" + unformatted
    } else {
        throw Error(unformatted + "is of unsupported type (" + typeof unformatted + "), only string | number is supported")
    }

    const valueAsNumber = Number.parseFloat(cleared)
    return valueAsNumber.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
}

export function parseFormattedCurrency(formatted: any): number | null {
    if (!formatted) {
        return null
    }

    if (typeof formatted == "number") {
        return formatted
    }

    return parseFloat(
        formatted
            .replace(/\./g, "")
            .replace(/,/g, ".")
    )
}

export function deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj))
}

export function deepFind(obj: any, find: (o: any) => boolean) {
    let foundObj
    try {
        JSON.stringify(obj, (key, value: any) => {
            if (find(value)) {
                foundObj = value
                throw "END"
            }
            return value
        })
    } catch (e) {
    }

    return foundObj
}

export function debounce(this: any, func: any, timeout = 300) {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function data(_: any) {
    return {
        data: _
    }
}

export function filterTable(dataList: [], search: string | RegExp, currentLanguage: string = 'de') {
    return dataList?.filter((data: any) => {
            return !search
                ||
                match(getTranslation(data, currentLanguage), search)
                ||
                Object.values(data)?.filter((prop: any) => {
                    if (isObject(prop)) prop = Object.values(_.omit(prop, ["__typename"]));
                    return prop && match(prop, search)
                }).length > 0
        }
    )
}

function match(text: string, search: string | RegExp) {
    if (search instanceof RegExp)
        return search.test(text)

    return text.toString().toLowerCase().includes(search.toLowerCase())
}

export function getFuzzySearchRegExp(search: string) {
    const fuzzySearch = getFuzzySearch(search)
    return new RegExp(`^${fuzzySearch}.*$`, 'i')
}

export function getFuzzySearch(search: string) {
    return search.split(" ").map((term: string) => `(?=.*${escapeRegExp(term)})`).join("")
}


function getTranslation(item: any, currentLanguage: string, listName: string = 'translations', fieldName: string = 'translation') {
    if (!item) return ''
    return item[listName]?.length > 0 ?
        (item[listName].find((item: any) => item.language == currentLanguage) ?? item[listName][0])[fieldName] :
        item[fieldName] ?? item?.name
}


export function getValidationErrorsFromDataTable(dataTable: any, columns: any) {
    let validationErrors = []
    const notEmptyRow = dataTable.filter((item: any) => !isEmptyRow(item))
    validationErrors = notEmptyRow.map((row: any, index: number) => {
        let errors = columns.filter((column: any) => column.required && !row[column.name] && row[column.name] != 0)
        return errors.length > 0 ? {row: index, columns: errors, type: 'required'} : null

    }).filter((errorRow: any) => !!errorRow) || []

    const uniqueColumns = columns.filter((column: any) => column.unique)
    uniqueColumns?.forEach((column: any) => {
        let rowIndexErrors = notEmptyRow?.map((rowA: any, indexA: number) => {
            const duplicated = notEmptyRow.filter((rowB: any, indexB: number) => {
                return !!rowA[column.name] && rowA[column.name] == rowB[column.name] && indexA != indexB
            })
            const violateUniqueKey = duplicated.length > 0
            return violateUniqueKey ? indexA : null
        })?.filter((indexError: any) => isNumber(indexError))
        rowIndexErrors?.forEach((rowIndexError: number) => {
            validationErrors.push({row: rowIndexError, columns: [column], type: 'unique'})
        })
    })

    const customValidateColumns = columns.filter((column: any) => !!column.validate && typeof column.validate == "function")
    customValidateColumns?.forEach((column: any) => {
        let rowIndexErrors = notEmptyRow?.map((rowA: any, indexA: number) => {
            return !column.validate(rowA[column.name]) ? indexA : null
        })?.filter((indexError: any) => isNumber(indexError))
        rowIndexErrors?.forEach((rowIndexError: number) => {
            validationErrors.push({row: rowIndexError, columns: [column], type: 'invalid'})
        })
    })

    return validationErrors
}

export function formatDataTableBeforeSave(dataTable: any, columns: any, table: string = '', language: string = 'de') {
    return dataTable.filter((item: any) => !isEmptyRow(item)).map((item: any) => {
        columns.map((column: any) => {
            if (column.type == 'numeric' && !!column.mask) {
                if (column.required === false) {
                    const value = item[column.name]?.toString().trim().replaceAll('.', '').replace(',', '.').replace(/[^0-9\.]+/g, "")
                    item[column.name] = (!value && value !== '0') || value == '' ? null : toNumber(value)
                } else {
                    let valueOnlyNumber = item[column.name]?.toString().replace(/[^0-9\.,]+/g, "")
                    const value = valueOnlyNumber.indexOf(',') > 0 ?
                        valueOnlyNumber.replaceAll('.', '').replace(',', '.') :
                        ((valueOnlyNumber.split('.')[1]?.length < 3) ? valueOnlyNumber : valueOnlyNumber.replaceAll('.', ''))
                    item[column.name] = toNumber(value)
                }
            }
            if (['numeric', 'dropdown', 'autocomplete'].includes(column.type) &&
                !column.multiple && !column.mask &&
                column.name != 'currency' && column.subtype != 'text') {
                item[column.name] = !!item[column.name] ? toNumber(item[column.name]) : null
            }
            if ((column.type == 'dropdown' || column.type == 'autocomplete') && item[column.name] == 0) item[column.name] = null
            if (column.name == 'id' && !item[column.name]) delete item[column.name]
            if (column.type == 'calendar') {
                if (!!item[column.name]) item[column.name] = split(item[column.name], ' ')[0]
                else delete item[column.name]
            }
            if (column.i18n) {
                if (!item.id || !isNumber(item.id)) {
                    item.translations = {}
                    item.translations.data = {table, language, field: column.name, translation: item[column.name]}
                }
            }
            if (column.multiple) {
                if (!item.id || !isNumber(item.id)) {
                    item[column.relation] = {}
                    item[column.relation].data = item[column.name]?.split(';')?.filter((item: any) => item != '')
                        ?.map((item: any) => {
                            let obj = {} as any
                            obj[column.name] = isNaN(toNumber(item)) ? item : toNumber(item)
                            return obj
                        }) ?? []
                    delete item[column.name]
                }
            }
            if (!!column.fnValue && typeof column.fnValue == "function") item[column.name] = column.fnValue(item)
            else if (column.type == "hidden" && (!item[column.name] || !isNumber(item[column.name]))) delete item[column.name]
            if (column.type == 'email') item[column.name] = item[column.name]?.toLowerCase()?.replaceAll(/\s/g, "")
            if (!!column.default && !item[column.name]) item[column.name] = column.default
        })
        return item
    })
}

export function getRelationsToSave(dataTable: any, columns: any, table: string = '', language: string = 'de') {
    let relations = {} as any
    dataTable.filter((item: any) => !!item.id)?.forEach((row: any) => {
        columns.filter((column: any) => column.multiple)?.map((column: any) => {
            if (!isArray(relations[column.relation])) relations[column.relation] = []
            _.uniq(row[column.name]?.split(';')?.filter((item: any) => item != ''))
                ?.map((item: any) => {
                    let obj = {} as any
                    obj[column.name] = isNaN(toNumber(item)) ? item : toNumber(item)
                    obj[column.foreign_key] = toNumber(row.id)
                    relations[column.relation].push(obj)
                })

            delete row[column.name]
        })
        columns.filter((column: any) => column.i18n)?.map((column: any) => {
            if (!isArray(relations.translations)) relations.translations = []
            relations.translations.push({
                foreign_key: row.id,
                table,
                language,
                field: column.name,
                translation: row[column.name]
            })
            delete row[column.name]
        })
    })
    return relations
}

export function isEmptyRow(item: any) {
    return !Object.keys(item).find((key: string) => !!item[key] && item[key] != '0')
}

export function getRemovedIdsFromDataTable(dataTableBefore: any, dataTableAfter: any) {
    let idsBefore = dataTableBefore.filter((item: any) => !item.ms365_imported)?.map((item: any) => {
        return item.id
    })
    let idsAfter = dataTableAfter.filter((item: any) => !!item.id).map((item: any) => {
        return item.id
    }) ?? []

    return idsBefore.filter((id: number) => !idsAfter.includes(id));
}


/**
 * Returns the amount of hours between to dates
 */
export function hoursBetween(date1: Date, date2: Date) {
    // thank you https://stackoverflow.com/a/19225540/808723

    //@ts-ignore
    return Math.floor((date1 - date2) / 36e5);
}

export function convertParamsToObject(url: string) {
    let queryParams = new URLSearchParams(url).entries()
    const result = {} as any;
    for (const [key, value] of queryParams) {
        result[key] = value;
    }
    return result;
}

export function slugify(text: string) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\u0100-\uFFFF\w\-]/g, '-') // Remove all non-word chars ( fix for UTF-8 chars )
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export function isValidIBANNumber(input: any) {
    const CODE_LENGTHS: any = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
        return (letter.charCodeAt(0) - 55).toString();
    });
    // final check
    return mod97(digits);
}

function mod97(string: any) {
    let checksum = string.slice(0, 2), fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function checkIfStringIsValidISODate(str: any) {
    return (typeof str == "string" && /^\d{4}-\d{2}-\d{2}$/.test(str.substring(0, 10)) && dayjs(str).isValid())
}


export function compareTwoStrings(first: any, second: any) {
    first = first?.replace(/\s+/g, '')
    second = second?.replace(/\s+/g, '')

    if (!first || !second) return 0
    if (first === second) return 1; // identical or empty
    if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
        const bigram = first.substring(i, i + 2);
        const count = firstBigrams.has(bigram)
            ? firstBigrams.get(bigram) + 1
            : 1;

        firstBigrams.set(bigram, count);
    }


    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
        const bigram = second.substring(i, i + 2);
        const count = firstBigrams.has(bigram)
            ? firstBigrams.get(bigram)
            : 0;

        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

export function findBestMatch(mainString: any, targetStrings: any) {
    if (!areArgsValid(mainString, targetStrings)) throw new Error('Bad arguments: First argument should be a string, second should be an array of strings');

    const ratings = [];
    let bestMatchIndex = 0;

    for (let i = 0; i < targetStrings.length; i++) {
        const currentTargetString = targetStrings[i];
        const currentRating = compareTwoStrings(mainString, currentTargetString)
        ratings.push({target: currentTargetString, rating: currentRating})
        if (currentRating > ratings[bestMatchIndex].rating) {
            bestMatchIndex = i
        }
    }


    const bestMatch = ratings[bestMatchIndex]

    return {ratings: ratings, bestMatch: bestMatch, bestMatchIndex: bestMatchIndex};
}

function areArgsValid(mainString: any, targetStrings: any) {
    if (typeof mainString !== 'string') return false;
    if (!Array.isArray(targetStrings)) return false;
    if (!targetStrings.length) return false;
    if (targetStrings.find(function (s) {
        return typeof s !== 'string'
    })) return false;
    return true;
}

export function sortDictionaryOnKeys(dict: any, nullFirst: boolean = true) {

    let sorted = []

    for (let key in dict) sorted[sorted.length] = key
    sorted.sort()

    let tempDict = {} as any
    if (nullFirst && !!dict.null) tempDict.null = dict.null

    for (let i = 0; i < sorted.length; i++) {
        if (nullFirst && sorted[i] === 'null') continue
        tempDict[sorted[i]] = dict[sorted[i]]
    }

    return tempDict
}

export function trimObjectProperties(obj: any) {
    let trimmedObject: any = {};

    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (typeof obj[prop] === 'string') {
                trimmedObject[prop] = obj[prop].trim()
            } else {
                trimmedObject[prop] = obj[prop]
            }
        }
    }

    return trimmedObject
}


export function escapeRegExp(text?: string) {
    return text?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') ?? ''
}

export function omitWarning(action: any = {}) {
    // Store the original console methods
    const originalWarn = console.warn
    try {
        // Temporarily replace console methods to suppress warnings and errors
        console.warn = () => {
        }
        // Execute the action and return its result
        return action()
    } catch (e) {
        // Return null if an exception occurs
        return null
    } finally {
        // Restore the original console methods
        console.warn = originalWarn
    }
}
