export default {
    "administration": "Správa",
    "admin_page": {
        "no_permission": "Nemáte oprávnění pro přístup do administrační oblasti.",
        "confirm_before_delete_msg": "Opravdu chcete odstranit tento záznam? (ID #{id})",
        "confirm_before_delete_from_db_msg": "Opravdu chcete smazat tento záznam? (ID #{id})",
        "confirm_before_delete_from_db_title": "Trvale smazat",
        "confirm_before_delete_from_db_btn": "Vymazat",
        "block_user_msg": "Opravdu chcete zablokovat uživatele {name}? Osoba se pak nebude moci přihlásit.",
        "block_user_title": "Přihlašovací blok",
        "block_user_btn": "Blok",
        "unblock_user_msg": "Opravdu chcete odemknout {name}? Daná osoba se pak bude moci znovu přihlásit.",
        "unblock_user_title": "Odemknout přihlášení",
        "unblock_user_btn": "Odemknout",
        "tour": {
            "menu_title": "Sekce",
            "menu": "Zde vidíte sekce administrační oblasti, kde můžete spravovat a upravovat kmenová data a nastavení.",
            "client_menu_title": "Správa společností",
            "client_menu": "Zde můžete přidat, zobrazit nebo upravit všechny společnosti.",
            "client_list": "Zde si můžete prohlédnout všechny společnosti.",
            "new_client": "Kliknutím sem založíte novou společnost.",
            "view_mode": "<p>Zde můžete přepnout ze zobrazit seznam do zobrazení tabulky a nastavit více položek najednou nebo získat lepší přehled.</p> Zobrazení tabulky lze také použít pro export nebo import dat. Po úpravě nezapomeňte tabulku uložit."
        }
    },
    "version": "Verze",
    "yes": "Ano",
    "no": "Ne",
    "never": "Nikdy",
    "add": "Přidat",
    "edit": "Upravit",
    "delete": "Vymazat",
    "save": "Uložit",
    "cancel": "Zrušit",
    "next": "další",
    "back": "Zadní",
    "finish": "Dokončit",
    "ok": "OK",
    "open": "Otevřené",
    "dashboard": "Ukazatele",
    "overview": "Přehled",
    "operator": "Operátor",
    "profile": "Profil",
    "help": "Pomoc",
    "logout": "Odhlásit se",
    "list_view": "Zobrazit seznam",
    "table_view": "Zobrazit tabulku",
    "archive": "Archiv",
    "active": "Aktivní",
    "email": "E-mail",
    "name": "Název",
    "tax": "daň (%)",
    "value": "Množství",
    "number": "Číslo",
    "currency": "Měna",
    "actions": "Akce",
    "system": "Systém",
    "from": "Z",
    "of": "Z",
    "until": "až do",
    "up_to": "až do",
    "cost_center_id": "Nákladové středisko",
    "cost_centers": "Nákladová střediska",
    "budget_id": "Rozpočet",
    "budgets": "Rozpočty",
    "branch_id": "Pobočka",
    "branches": "Pobočky",
    "employee_id": "Zaměstnanec",
    "employees": "Zaměstnanec",
    "d365_vehicle_id": "Motorové vozidlo",
    "vehicles": "Motorová vozidla",
    "financial_dimension_id": "Finanční dimenze",
    "financial_dimensions": "Finanční dimenze",
    "tax_group_id": "Skupina daně z prodeje",
    "tax_groups": "Skupiny daně z prodeje",
    "tax_item_group_id": "Skupina položek daně z prodeje",
    "tax_item_groups": "Skupiny položek daně z prodeje",
    "profit_center_id": "Ziskové centrum",
    "profit_centers": "Zisková centra",
    "client_id": "Společnost",
    "clients": "Společnosti",
    "department_id": "Oddělení",
    "departments": "Oddělení",
    "d365_department_id": "D365 Oddělení",
    "d365_departments": "D365 Oddělení",
    "location_id": "Umístění",
    "consignee_id": "Příjem zboží",
    "address_id": "Doručovací adresa",
    "lb_user_id": "Osoba",
    "users": "Osoby",
    "task_group_id": "Skupina úkolů",
    "task_groups": "Skupiny úkolů",
    "user_task_group": "Uživatel/Skupina úkolů",
    "user_task_groups": "Uživatelé/Skupiny úkolů",
    "products": "Produktový katalog",
    "product_group_id": "Skupina produktů",
    "product_groups": "Skupiny produktů",
    "supplier_id": "Dodavatel",
    "suppliers": "Dodavatelé",
    "procurement_id": "Nákupní objednávka",
    "procurements": "Nákupní objednávky",
    "user_select_product_id": "Osoba 'Výběr produktu'",
    "user_send_order_id": "Osoba 'Objednání'",
    "user_clearing_id": "Osoba 'Objasněno'",
    "user_consignee_id": "Osoba 'Příjem zboží'",
    "user_invoice_manual_check_id": "Osoba „Ruční kontrola (faktura)“",
    "user_invoice_clearing_id": "Osoba 'Objasnění účetnictví'",
    "user_accounting_id": "Uživatel „Účetnictví“",
    "ledger_account_id": "Účet G/L",
    "ledger_accounts": "Účetní záznamy",
    "posting_key_id": "Klíč zaúčtování",
    "posting_keys": "Účtovací klíče",
    "permission_id": "Skupina oprávnění",
    "permissions": "Skupiny oprávnění",
    "invoices": "Faktury",
    "invoice_id": "Faktura",
    "configurations": "Nastavení",
    "marketplace_id": "Tržiště",
    "provider_id": "Poskytovatel tržiště",
    "notes": "Poznámka",
    "search": "Hledat",
    "no_data": "Nebyly nalezeny žádné záznamy",
    "months": "měsíce",
    "select_valid_option": "Vyberte platný záznam.",
    "procurement_line": {
        "description": "Produkt",
        "item_number": "Číslo položky",
        "is_cost_center_fixed": "Pevná předvolba",
        "image_url": "Adresa URL obrázku",
        "webshop_url": "Adresa URL obchodu",
        "offer_number": "Číslo nabídky",
        "offer_date": "Datum nabídky",
        "include_tax": "vč. DPH {tax}",
        "offer_msg": "Podle nabídky {offer_number}",
        "offer_date_msg": "dne {offer_date}",
        "total_price_brutto": "Celková hrubá cena",
        "total_price_netto": "Celková čistá cena",
        "type_total_price_brutto": "currency",
        "type_price_brutto": "currency",
        "type_total_price_netto": "currency",
        "type_price_netto": "currency"
    },
    "product_catalog": {
        "description": "Položka",
        "item_number": "Číslo položky",
        "is_cost_center_fixed": "Pevné zadání",
        "image_url": "Adresa URL obrázku",
        "webshop_url": "Adresa URL obchodu",
        "offer_number": "Číslo nabídky",
        "offer_date": "Datum nabídky",
        "type_price_netto": "plovák",
        "template_price_netto": "$ 1 €",
        "type_price_brutto": "plovák",
        "template_price_brutto": "$ 1 €",
        "form_sequence": "name,item_number,product_group_id,supplier_id,location_id,cost_center_id,is_cost_center_fixed,tax,price_netto,price_brutto,unit,image_url,webshop_url,offer_number,offer_date",
        "page": {
            "new": "Nový výrobek",
            "supplier_tooltip": "Vyberte dodavatele z kmenových dat nebo přidejte nového zadáním nového názvu.",
            "supplier_new_tooltip": "Tento dodavatel bude uložen ve vašich kmenových datech při přidávání produktu.",
            "should_save": "Uložit do Katalogu produktů",
            "validation": {
                "name_required": "Zadejte jméno.",
                "supplier_required": "Vyberte dodavatele nebo přidejte nového.",
                "image_url_invalid": "Zadejte prosím platnou adresu URL.",
                "webshop_url_invalid": "Zadejte prosím platnou adresu URL.",
                "tax_invalid": "Zadejte platnou sazbu daně."
            },
            "tour": {
                "name": "Uveďte významný název produktu.",
                "number": "Zde můžete zadat číslo položky produktu.",
                "product_group": "Vyberte skupinu produktů, do které produkt patří.",
                "supplier": "Vyberte dodavatele produktu, u kterého chcete produkt objednat.",
                "price": "Zadejte sazbu daně a čistou nebo hrubou cenu. Ostatní cena bude vypočtena pomocí daňové sazby.",
                "unit": "Uveďte jednotku, ve které se výrobek měří, např. „položky“, „kg“ nebo „litry“.",
                "url": "Zde můžete přidat adresu URL obrázku k produktu. Navíc, pokud je k dispozici, můžete přidat odkaz na stránku produktu v obchodě.",
                "offer": "Pokud existuje nabídka na produkt, můžete zde zadat číslo nabídky a datum, aby se zobrazily v objednávkovém PDF.",
                "btn_save": "Jakmile přidáte všechny informace, uložte produkt do katalogu produktů."
            }
        }
    },
    "product_group": {
        "form_sequence": "name,description,user_select_product_id",
        "page": {
            "new": "Nová skupina produktů",
            "no_product_group": "Bez skupiny položek",
            "validation": {
                "name_required": "Zadejte jméno."
            },
            "tour": {
                "name": "Zadejte smysluplný název skupiny produktů.",
                "btn_save": "Jakmile zadáte všechny informace, přidejte skupinu produktů."
            }
        }
    },
    "procurement": {
        "description": "Nákupní objednávka",
        "order_description": "Objednávka",
        "name": "Jméno",
        "number": "Číslo objednávky",
        "order_number_formatted": "Číslo objednávky",
        "creator_id": "Vytvořeno",
        "created_at": "Datum vytvoření",
        "delivery_date": "Datum dodání",
        "delivery_address_id": "Doručovací adresa",
        "billing_address_text": "fakturační adresa",
        "status": "Stav",
        "total_brutto": "Celkový hrubý",
        "total_netto": "Celková čistá",
        "total_netto_abbr": "-Síť",
        "payment_in_advance": "Platba předem",
        "type": "Typ objednávky",
        "type_options": {
            "single_invoice": "eKuez s jednou fakturou",
            "recurring_invoice": "dKuez s jednou fakturou",
            "recurring_contract": "dKuez s opakovanou fakturou"
        },
        "statuses": {
            "open": "Výběr produktu",
            "approved": "Objednáno",
            "ordered": "Čekání na doručení",
            "declined": "Odmítnuto",
            "completed": "Dokončeno",
            "approval": "Odsouhlaseno",
            "clearing": "Objasněno",
            "draft": "Návrh"
        },
        "page": {
            "no_delivery_address": "Žádná dodací adresa",
            "no_budget": "Žádný rozpočet",
            "new": "Nová objednávka",
            "create_btn": "Zahájit objednávku",
            "copy_btn_tooltip": "Kopírovat objednávku",
            "delivery_date_tooltip": "Termín dodání bude vytištěn na objednávkovém PDF a poté může být zaslán dodavateli.",
            "order_sender": "Umístění objednávky",
            "product_selector": "Výběr produktu",
            "product_search": "Hledat produkty",
            "before_leave_msg": "Vaše nákupní objednávka nebyla uložena. Opravdu chcete stránku opustit?",
            "confirm_without_approval_limit_msg": "Žádná z vybraných osob nemá potřebný schvalovací limit pro hodnotu této objednávky. Další schválení lze přidat později. Opravdu chcete pokračovat?",
            "confirm_without_approval_limit_title": "Limit schválení",
            "confirm_product_selection_without_product_msg": "Nevybrali jste žádný produkt. Opravdu chcete pokračovat?",
            "confirm_product_selection_without_product_title": "Není vybrán žádný produkt",
            "no_approval_limit_msg": "Váš limit schválení není pro tuto objednávku dostatečný. Přidejte ke schválení další osobu s odpovídajícím limitem schválení.",
            "no_approval_limit_title": "Limit schválení",
            "product_group_missing_msg": "Všechny produkty musí být přiřazeny do skupiny produktů. Přejděte na kartu „Skupiny produktů“ nad seznamem produktů a přiřaďte každému produktu skupinu produktů.",
            "product_group_missing_title": "Přiřadit skupinu produktů",
            "product_group_missing_btn": "Rozuměl",
            "min_approval_one_person": "1 osoba",
            "min_approval_qty": "{qty} různých osob",
            "min_approval_error_msg": "Schválení pro tuto objednávku nestačí. Přidejte další osobu ke schválení. Tato nákupní objednávka musí být schválena <b>nejméně {min_approval_msg}</b>.",
            "min_approval_error_title": "Nedostatečné schválení",
            "order_not_completed_msg": "Nezadali jste objednávky na všechny produkty ani jste je nepotvrdili. Ujistěte se, že jste objednali všechny produkty v seznamu produktů a v případě potřeby je potvrďte.",
            "order_not_completed_title": "Nejprve dokončete objednávky",
            "order_not_completed_btn": "Rozuměl",
            "confirm_delivery_not_completed_msg": "Nepotvrdili jste všechny příjemky zboží. Opravdu chcete dokončit nákupní objednávku? <br> <i>Tuto akci nelze vrátit zpět</i>.",
            "confirm_delivery_not_completed_title": "Nevyřízené příjmy zboží",
            "confirm_delivery_not_completed_btn": "Kompletní",
            "default_confirm_btn": "Pokračovat",
            "budget_exceeded_msg": "Rozpočet překročen",
            "approval_limit_exceeded_msg": "Překročen limit schválení",
            "external_order_number_msg": "Zadejte externí číslo objednávky.",
            "external_order_number_title": "Zadejte externí číslo objednávky",
            "no_edit_permission": "Chybí vám oprávnění upravovat data. Odešlete objednávku k vymazání prostřednictvím akcí, abyste provedli změny v datech.",
            "delivery_dialog": {
                "title": "Potvrďte příjem zboží",
                "confirm_btn": "Potvrdit",
                "message": "Zadejte nedávno přijaté množství.<br> Chybí {missing_qty} z {quantity} {unit}.",
                "qty_exceeded_msg": "Chybějící množství nesmí být překročeno.",
                "qty_must_be_greater_msg": "Množství musí být větší než 0.",
                "invalid_format": "Použijte platný číselný formát."
            },
            "order_sender_dialog": {
                "title": "Výchozí role pro umístění objednávky",
                "info": "Osoba v kroku 'Umístění objednávky' je určena na základě výchozích rolí pro oddělení, společnost, výchozích globálních rolí a osoby, která vytvořila nákupní objednávku. Osoba v horní části seznamu je pro úkol předem vybrána."
            },
            "consignee_dialog": {
                "title": "Výchozí role pro příjem zboží",
                "info": "Osoba v kroku 'Příjem zboží' je určena na základě výchozích rolí pro oddělení, společnost, výchozích globálních rolí a osoby, která vytvořila nákupní objednávku. Osoba v horní části seznamu je pro úkol předem vybrána."
            },
            "select_product_dialog": {
                "title": "Výchozí role pro výběr produktu",
                "info": "Osoba v kroku 'Výběr produktu' je určena na základě výchozích rolí pro oddělení, společnost, výchozích globálních rolí a osoby, která vytvořila nákupní objednávku. Osoba v horní části seznamu je pro úkol předem vybrána."
            },
            "marketplace_change_dialog": {
                "title": "Objednávka bude zrušena",
                "info": "Byla zjištěna změna společnosti. Na tržišti {marketplace_name} však existuje objednávka, která není spojena s novou společností {client_name}. Pokud budete pokračovat, tato objednávka bude zrušena.",
                "btn_confirm": "Zrušit objednávku"
            },
            "dialog_assignment": {
                "department_form": "Oddělení ve formě",
                "department_creator": "Oddělení objednatele",
                "client_form": "Společnost ve formě",
                "client_creator": "Společnost objednané osoby",
                "no_selection": "Dosud nevybráno",
                "no_action": "Nelze použít",
                "configuration": "Výchozí globální role"
            },
            "draft": {
                "title": "Návrh",
                "save": "Uložit koncept",
                "delete": "Smazat koncept",
                "confirm_before_delete_msg": "Opravdu chcete tento koncept trvale smazat?",
                "confirm_before_delete_title": "Smazat koncept",
                "confirm_before_delete_btn": "Vymazat"
            },
            "punchout": {
                "pending_msg": "Existují nevyřízené produkty od Punchout.",
                "error_title": "Chyba při vyražení",
                "error_msg": "Vyskytla se chyba. Zkontrolujte prosím nastavení Punch-out nebo kontaktujte osobu s administrátorskými právy.",
                "error_start_url_msg": "Nebyla zadána žádná počáteční adresa URL, nelze zahájit zvýraznění.",
                "no_config": "Pro použití Punch-out nejprve nastavte konfiguraci.",
                "pending_dialog": {
                    "title": "Nevyřízené produkty od Punchout",
                    "add_products": "Přidat do objednávky",
                    "add_all_products": "Přidat vše do objednávky",
                    "delete_products": "Odebrat produkty"
                },
                "log_dialog": {
                    "title": "{supplier_name} Žádost",
                    "request": "Žádost",
                    "response": "Odpověď",
                    "no_log": "Žádný záznam",
                    "url": "Url",
                    "header": "Header",
                    "method": "Method",
                    "body": "Body"
                },
                "delete_dialog": {
                    "title": "Odebrat produkty od {supplier} #{index}",
                    "info1": "Opravdu chcete odstranit všechny následující produkty z {supplier} #{index}?",
                    "info2": "Tuto akci nelze vrátit zpět.",
                    "delete_btn": "Odstranit"
                },
                "loading": "V otevřeném vyskakovacím okně dokončete výběr produktu. Pokud se nezobrazí žádné vyskakovací okno, musíte jej nejprve povolit.",
                "can_not_edit": "Vyražené produkty nelze upravovat. Proveďte prosím Punch-out znovu nebo smažte celý nákupní košík.",
                "no_provider_found": "Vybraný poskytovatel Punch-out bohužel zatím není podporován."
            },
            "download_pdf": {
                "title": "Stáhnout PDF",
                "confirm_btn": "Potvrdit",
                "title_confirm_save_changes": "Uložit neuložená data",
                "text_confirm_save_changes": "Chcete uložit neuložená data a aplikovat je na objednávkový dokument?",
                "invalid_form": "Prosím, vyplňte všechna povinná pole ve formuláři před pokračováním v akci."
            },
            "lines": {
                "no_line": "Nejsou vybrány žádné produkty, přidejte produkty.",
                "show_all_info": "Pokročilé zobrazení",
                "invoice": {
                    "title": "Faktura",
                    "created_at": "Zaznamenáno",
                    "date": "Datum faktury",
                    "created_by": "Vytvořil"
                },
                "automatic_order": "Odeslat elektronicky",
                "automatic_ordered": "Elektronicky odesláno",
                "confirm_order": "Potvrďte objednávku",
                "finish_order": "Dokončit objednávku",
                "order_finished": "Objednávka dokončena",
                "confirm_order_tooltip": "Vezměte prosím na vědomí, že je třeba <b>odeslat objednávku dodavateli e-mail</b>, objednávka nebude <b>přenášena elektronicky.</b>",
                "ordered_at": "Odesláno",
                "ordered_by": "Podle",
                "confirm_receipt": "Potvrdit převzetí zboží",
                "no_cost_center": "Žádné nákladové středisko",
                "include_taxes": "vč. káď",
                "budget": "Rozpočet",
                "no_budget": "Žádný rozpočet",
                "no_product_group": "Žádná skupina produktů",
                "no_ledger_account": "Žádný účet v hlavní knize",
                "select_product": "Výběr produktu",
                "quantity_to_show": "přijato: <b>{quantity_received} z {quantity}</b>",
                "from": "z",
                "item_number": "Předmět číslo. #",
                "delete_msg": "Opravdu chcete tento produkt odstranit?",
                "delete_title": "Odebrat produkt",
                "delete_btn": "Odstranit",
                "automatic_order_dialog": {
                    "confirm_order": "Odeslat objednávku",
                    "confirm_order_btn": "Poslat",
                    "finish_order": "Dokončit objednávku",
                    "finish_order_btn": "Dokončit",
                    "info1": "Chcete tuto objednávku <span style=\"font-weight: bold\">teď</span> <span style=\"font-weight: bold\">elektronicky odeslat</span> dodavateli?",
                    "info2": "Tuto akci nelze vrátit zpět."
                },
                "order_dialog": {
                    "confirm_order": "Potvrďte objednávku",
                    "confirm_order_btn": "Potvrdit",
                    "finish_order": "Dokončit objednávku",
                    "finish_order_btn": "Dokončit",
                    "info1": "Předali jste objednávku dodavateli? (např. nebo faxem)",
                    "info2": "Tuto akci nelze vrátit zpět."
                },
                "more_actions": {
                    "delete_products": "Odebrat produkty",
                    "add_external_id": "Přidejte externí číslo objednávky",
                    "download_pdf": "Objednávka (PDF)",
                    "download_pdf_with_attachments": "Objednávka včetně přílohy PDF",
                    "write_email": "Napsat e-mail",
                    "email_with_pdf": "E-mail s PDF"
                },
                "file": {
                    "open_btn": "Otevřete PDF",
                    "add_btn": "Přidat PDF",
                    "upload_info1": "Přetáhněte sem <br>nebo <br> <em>vyberte soubor</em>.",
                    "upload_info2": "Soubory PDF do velikosti 10 MB.",
                    "delete_msg": "Opravdu chcete tento dokument odstranit?",
                    "delete_title": "Odebrat dokument",
                    "delete_btn": "Odstranit",
                    "delete_error_msg": "Dokument se nepodařilo odstranit."
                }
            },
            "validation": {
                "delivery_date_format": "Zadejte prosím datum doručení.",
                "client_id_required": "Vyberte prosím společnost.",
                "department_id_required": "Vyberte prosím oddělení.",
                "department_id_required_client_missing": "Nejprve vyberte společnost.",
                "cost_center_id_required": "Vyberte prosím nákladové středisko.",
                "cost_center_id_required_filters_missing": "Nejprve vyberte společnost a oddělení.",
                "cost_center_id_required_department_missing": "Nejprve vyberte oddělení.",
                "cost_center_id_required_client_missing": "Nejprve vyberte společnost.",
                "ledger_account_id_required": "Vyberte prosím účetní účet.",
                "consignee_id_required": "Vyberte prosím odpovědnou osobu.",
                "delivery_address_id_required": "Vyberte prosím doručovací adresu.",
                "billing_address_text_required": "Ve firemních údajích chybí fakturační adresa."
            },
            "tour": {
                "main": {
                    "description": "V prvních polích zadejte název požadavku na objednávku, svou společnost a oddělení, pro které má být objednávka provedena. Oddělení je filtrováno podle společnosti a nákladové středisko podle oddělení.",
                    "organization": "Zadejte společnost, oddělení a nákladové středisko pro nákupní objednávku. Seznam oddělení je filtrován podle vybrané společnosti a seznam nákladových středisek je filtrován podle vybraného oddělení.",
                    "address": "Doručovací adresa bude sdělena dodavateli. Můžete si vybrat z adres společnosti, osoby odpovědné za příjem zboží a vlastních adres.",
                    "total": "Zde vidíte celkovou hodnotu vaší objednávky, která je součtem vybraných produktů.",
                    "delivery_date": "Termín dodání je možné zaznamenat po převzetí zboží pro účely dokumentace. Nebude odesláno dodavateli.",
                    "product_selector": "To ukazuje, kdo je zodpovědný za výběr produktu v dalším kroku. Záleží na různých výchozích nastaveních rolí, pro více podrobností klikněte na ikonu info.",
                    "order_sender": "To ukazuje, kdo je zodpovědný za zadání objednávky, což závisí na různých výchozích nastaveních rolí. Pro více podrobností klikněte na ikonu info.",
                    "consignee": "Vyberte osobu, která zboží obdrží, a v posledním kroku objednávky potvrďte příjem pro dokončení procesu.",
                    "approvers": "Zde vidíte, zda bylo pro vybrané nákladové středisko nastaveno schválení. Můžete také přidat lidi ke schválení.",
                    "product_search": "Zde můžete vyhledat produkt v interním katalogu produktů a přidat jej do objednávky.",
                    "punchout": "Zde si můžete vybrat produkty z integrovaných tržišť, jako je Amazon nebo Mercateo. Výběr produktu může být po schválení předán elektronicky na tržiště.",
                    "new_product": "Pokud požadovaný produkt není v katalogu produktů, můžete jej přidat do objednávky zde.",
                    "save_btn": "Po zadání všech požadovaných informací můžete zahájit proces objednávky a přejít k dalšímu kroku."
                },
                "product": {
                    "pdf": "Zde můžete přidat dokumenty k produktu.",
                    "remove": "Kliknutím sem můžete také odebrat produkty z výběru.",
                    "more_actions": "Číslo externí objednávky zde můžete doložit po obdržení.",
                    "tabs": "Zde můžete seskupit pohled na své produkty podle dodavatele, nákladového střediska nebo skupiny produktů.",
                    "expand_view": "Zde můžete zobrazit nebo skrýt rozšířená nastavení pro produkty a vybrat nákladové středisko, dodací adresu nebo skupinu produktů."
                },
                "new_product": {
                    "name": "Zadejte smysluplný název produktu.",
                    "number": "Můžete také zadat číslo položky pro produkt.",
                    "supplier": "Vyberte dodavatele, u kterého je produkt nabízen nebo u kterého má být objednán.",
                    "price": "Zadejte sazbu daně a čistou nebo hrubou cenu. Ostatní cena bude vypočtena pomocí daňové sazby.",
                    "unit": "Zadejte měrnou jednotku produktu, například „položky“, „kg“ nebo „litry“.",
                    "url": "Můžete přidat adresu URL obrázku produktu a odkaz na stránku produktu v obchodě, pokud je k dispozici.",
                    "offer": "Pokud existuje nabídka na produkt, můžete zde zadat číslo nabídky a datum, aby se zobrazily v objednávkovém PDF.",
                    "should_save": "Zde zvolíte přidání vytvořeného produktu do katalogu produktů. Tím je zajištěno, že tento konkrétní produkt bude dostupný pro budoucí objednávky.",
                    "save_btn": "Po zadání všech informací přidejte produkt do objednávky."
                },
                "cost_center_view": "Nákladová střediska lze také přiřadit k jednotlivým produktům v objednávce. Poté lze pro produkt vybrat dodací adresy spojené s nákladovým střediskem.",
                "product_group_view": "Každý produkt lze přiřadit do skupiny produktů. Osoba odpovědná za skupinu produktů obdrží na časové ose úkol upravit nebo potvrdit výběr produktu.",
                "send_order": {
                    "send_btn": "V kroku potvrzení objednávky můžete elektronicky odeslat objednávku nebo potvrdit objednávku provedenou prostřednictvím emailu u dodavatele.",
                    "more_actions": "V možnostech pro vybraného dodavatele můžete vygenerovat objednávkové PDF nebo e-mail s nebo bez objednávkového PDF pro dodavatele. Tímto způsobem můžete zadat objednávku společnosti nebo zadat dotaz prostřednictvím e-mailu."
                },
                "delivery_btn": "Pokud jste obdrželi část dodávky nebo celou objednávku, můžete to uvést zde. Potvrzené převzetí zboží bude rovněž uvedeno v časové ose."
            }
        }
    },
    "quantity": "Množství",
    "price_netto": "Čistá cena",
    "price_brutto": "Hrubá cena",
    "unit": "Jednotka",
    "default_unit": "Položka",
    "tenant": {
        "tab_general": "Obecné",
        "description": "Nájemce",
        "main_language": "Jazyk náhrady",
        "monthly_document_limit": "Dokumenty za měsíc",
        "base_price": "Základní cena",
        "price_extra_document": "Cena každého dalšího dokumentu",
        "monthly_procurement_limit": "Objednávky za měsíc v základní ceně",
        "monthly_invoice_limit": "Faktury za měsíc v základní ceně",
        "base_price_procurement": "Základní cena nákupních objednávek",
        "base_price_invoice": "Základní cena faktur",
        "price_extra_procurement": "Cena za každou další objednávku",
        "price_extra_invoice": "Cena za každou další fakturu",
        "invoice_recognition_unit_price": "Cena za rozpoznání faktury na dokument",
        "easybill_id": "Easybill ID",
        "bank_account": "Číslo účtu",
        "bank_account_owner": "Majitel účtu",
        "bank_bic": "BIC",
        "bank_code": "Bankovní kód",
        "bank_iban": "IBAN",
        "bank_name": "Jméno banky",
        "address": "fakturační adresa",
        "sepa_agreement": "Smlouva o SEPA inkasu",
        "sepa_agreement_date": "SEPA – datum vstupu mandátu",
        "sepa_mandate_reference": "Odkaz na mandát",
        "automatic_billing": "Automaticky generovat fakturaci",
        "automatic_billing_mail": "Automaticky odeslat fakturaci e-mail",
        "bank": "Bankovní spojení",
        "datev": "DATEV",
        "billing_address_name": "Společnost",
        "billing_address_consignee_name": "Jméno příjemce",
        "billing_address_street": "Ulice a č.",
        "billing_address_zipcode": "poštovní směrovací číslo",
        "billing_address_city": "Město",
        "billing_address_state": "Stát",
        "billing_address_country": "Země",
        "billing_address_phone": "Telefonní číslo",
        "contact_email": "Kontaktní e-mail",
        "billing_email": "Fakturační email",
        "contact": "Kontaktní informace",
        "sepa": "SEPA inkaso",
        "add_sepa": "Přidejte SEPA inkaso",
        "remove_sepa": "Zrušit SEPA inkaso",
        "import_invoice_email": "Automaticky importovat faktury prostřednictvím e-mailu",
        "tab_mailbox": "Poštovní schránky",
        "debit_credit_reverse": "Vyměnit účet/protiúčet"
    },
    "datev_access": {
        "description": "Integrace DATEV",
        "label_log": "Společnost DATEV (datev_client_id)",
        "label_log_fields": "datev_client_id",
        "connected_info": "Připojení k DATEV bylo navázáno.",
        "disconnected_info": "Připojení k DATEV bylo odstraněno.",
        "connected_client_info": "Připojení ke společnosti DATEV {datev_client_id} bylo navázáno.",
        "disconnected_client_info": "Připojení ke společnosti DATEV {datev_client_id} bylo deaktivováno."
    },
    "ms365_access": {
        "description": "Integrace D365",
        "connected_info": "Připojení k D365 bylo navázáno.",
        "disconnected_info": "Připojení k D365 bylo odstraněno.",
        "data_loaded_info": "Data z D365 byla ručně načtena.",
        "journal_created_info": "D365 deník byl přidán.<br>Název deníku: {journal_name} <br>URL organizace: {org_url}.",
        "journal_edited_info": "Název D365 deníku byl upraven: {journal_name}."
    },
    "client": {
        "description": "Společnost",
        "min_approval": "Minimální počet schvalovatelů",
        "branch_required": "Pobočka je vyžadována pro formulář faktury.",
        "department_required": "Oddělení je vyžadováno pro formulář faktury.",
        "cost_center_required": "Nákladové středisko je povinné pro objednávkový formulář.",
        "client_number": "Číslo",
        "consultant_number": "Číslo poradce",
        "ledger_account_length": "Délka účetního účtu",
        "vat_number": "DIČ",
        "address_name": "Jméno",
        "consignee": "Jméno příjemce – fakturační adresa",
        "dropoff_location": "Místo převzetí – fakturační adresa",
        "address": "Ulice a č. - Fakturační adresa",
        "address_complement": "Ulice 2 – Fakturační adresa",
        "country": "Země – fakturační adresa",
        "zipcode": "PSČ – fakturační adresa",
        "city": "Město – fakturační adresa",
        "phone_country_code": "Telefonní kód země – fakturační adresa",
        "phone_number": "Telefonní číslo – fakturační adresa",
        "can_delete_document_directly": "Dokumenty lze smazat bez vymazání.",
        "default_product_tax": "Standardní daňová sazba (%)",
        "default_product_currency": "Výchozí měna",
        "default_product_unit": "Výchozí jednotka",
        "form_sequence": "name,email,client_number,currency,min_approval,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id,user_invoice_manual_check_id,user_invoice_clearing_id",
        "page": {
            "tab_general": "Obecné",
            "tab_billing_address": "fakturační adresa",
            "tab_shipping_address": "Dodací adresy",
            "tab_default_roles": "Výchozí role",
            "tab_permissions": "Oprávnění",
            "tab_departments": "Oddělení",
            "tab_cost_centers": "Nákladová místa",
            "tab_ledger_accounts": "Účetní záznamy",
            "tab_required_fields": "Povinná pole",
            "tab_datev_csv": "DATEV CSV Export",
            "new": "Nová společnost",
            "select_logo": "Vyberte Logo",
            "select_logo_tooltip_content": "Přidejte logo, které bude vytištěno na objednávkových dokumentech vašich schválených objednávek. Nejlepší je použít soubor obrázku s průhledným pozadím.",
            "select_letterhead": "Vyberte hlavičkový papír",
            "select_letterhead_tooltip_content": "Přidejte hlavičkový papír, který bude vytištěn na objednávkový dokument vašich schválených objednávek.",
            "billing_address": "fakturační adresa",
            "roles": "Výchozí nastavení obecné role",
            "user_clearing_id_tooltip_content": "Pro objasnění procesů objednávání.",
            "user_invoice_clearing_id_tooltip_content": "Pro upřesnění při vyplňování faktur.",
            "no_selection": "Nebyla vybrána žádná konkrétní společnost, proto je sdílena mezi všemi.",
            "validation": {
                "name_required": "Zadejte jméno.",
                "name_max_length": "Nesmí přesáhnout 50 znaků.",
                "email_invalid": "Prosím zadejte platnou emailovou adresu.",
                "client_number_invalid": "Prosím zadejte platné číslo.",
                "min_approval_invalid": "Prosím zadejte platné číslo.",
                "address_incomplete": "Vyplňte prosím povinné údaje o adrese.",
                "delivery_address_missing": "Přidejte alespoň jednu doručovací adresu.",
                "error_address_incomplete": "Prosím, doplňte povinné údaje pro dodací adresu."
            },
            "tour": {
                "name": "Začněte zadáním názvu vaší společnosti.",
                "email": "Zadejte email Vaší společnosti, na který má být zaslána např. zásilka faktury.",
                "timeline_assignment": "Volitelně můžete předem přiřadit odpovědné osoby pro různé kroky nákupních objednávek pro společnost. Například pokud vždy existuje osoba odpovědná za schvalování objednávek.",
                "billing_address": "Fakturační adresa je vyžadována pro objednávku a může být zaslána dodavateli s objednávkou.",
                "shipping_address": "Zde můžete zadat jednu nebo více dodacích adres, které lze vybrat v objednávce.",
                "btn_save": "Po zadání všech požadovaných informací přidejte firmu."
            }
        }
    },
    "client_form_config": {
        "field": "Pole",
        "form": "Formulář",
        "required": "Povinné"
    },
    "department": {
        "description": "Oddělení",
        "code": "Číslo",
        "form_sequence": "name,code,client_id,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id",
        "page": {
            "new": "Nové oddělení",
            "tab_general": "Obecné",
            "tab_clients": "Klienti",
            "tab_cost_centers": "Nákladová místa",
            "tab_approvers": "Schvalovatelé",
            "tab_permissions": "Skupiny oprávnění",
            "tab_employees": "Zaměstnanci",
            "tab_assignment": "Přiřazení rolí",
            "validation": {
                "name_required": "Zadejte jméno.",
                "email_required": "Prosím zadejte platnou emailovou adresu."
            },
            "tour": {
                "name": "Začněte zadáním názvu oddělení.",
                "client": "Pokud oddělení přiřadíte společnosti, lze oddělení vybrat pouze v rámci této společnosti při vytváření objednávky.",
                "timeline_assignment": "Můžete určit odpovědné osoby pro různé kroky nákupních objednávek pro oddělení. Například pokud za příjem zboží za každé oddělení odpovídá vždy jedna konkrétní osoba.",
                "cost_centers": "Zde můžete k oddělení přiřadit existující nákladová střediska. Pokud nepřiřadíte žádná nákladová střediska, lze při vytváření nákupních objednávek pro oddělení vybrat pouze nákladová střediska, která nejsou přiřazena žádnému oddělení.",
                "employees": "Můžete přidat různé osoby, které jsou součástí tohoto oddělení. Když jednotlivci z tohoto oddělení vytvoří nákupní objednávky, oddělení bude předvyplněno.",
                "btn_save": "Jakmile poskytnete všechny informace, přidejte oddělení."
            }
        }
    },
    "permission": {
        "description": "Skupina oprávnění",
        "name": "Jméno",
        "page": {
            "new": "Nová skupina oprávnění",
            "validation": {
                "name_required": "Zadejte jméno."
            },
            "tour": {
                "name": "Zde můžete vytvořit skupinu oprávnění. Tuto skupinu pak můžete přiřadit k různým entitám (oddělení, produkty z katalogu produktů, skupiny produktů atd.). Jednotlivcům můžete také přiřadit jednu nebo více skupin oprávnění. Pokud by entita neměla mít žádná omezení oprávnění, nepřiřazujte skupinu."
            },
            "no_selection": "Není vybráno žádné oprávnění, proto je viditelné pro všechny."
        }
    },
    "task_group": {
        "description": "Skupina úkolů",
        "name": "Název",
        "page": {
            "new": "Nová skupina úkolů",
            "validation": {
                "name_required": "Prosím, zadejte název."
            },
            "tour": {
                "name": "Zde můžete vytvořit skupinu úkolů. Poté můžete úkoly přiřadit do této skupiny."
            },
            "no_selection": "Není vybrána žádná skupina úkolů"
        }
    },
    "budget": {
        "description": "Rozpočty",
        "label_log": "value€ (start - end)",
        "label_log_fields": "value,start,end",
        "value": "Množství",
        "template_value": "$ 1 €",
        "type_value": "plovák",
        "start": "Platnost od",
        "end": "v platnosti, dokud",
        "add_foreach_ledger_account": "Vložit všechny účty v hlavní knize",
        "component": {
            "validation": {
                "end_date_before_start_date": "Datum ukončení nemůže být před datem zahájení.",
                "end_date_required": "Datum ukončení zde nemůže být prázdné.",
                "time_overlap": "Rozpočty se nemohou časově překrývat.",
                "field_missing": "Zadejte prosím všechny informace."
            }
        }
    },
    "address": {
        "description": "Doručovací adresa",
        "description_plural": "Dodací adresy",
        "address_name": "Jméno",
        "consignee": "Jméno příjemce",
        "dropoff_location": "Místo převzetí",
        "address": "Ulice a č.",
        "address_complement": "Ulice č. 2",
        "complement": "Ulice č. 2",
        "country": "Země",
        "zipcode": "PSČ",
        "city": "Město",
        "phone_country_code": "Telefonní předvolba země",
        "phone_number": "Telefonní číslo",
        "component": {
            "new": "Nová dodací adresa",
            "copy_btn": "Zkopírujte fakturační adresu",
            "required_field": "Vyplňte prosím povinná pole adresy.",
            "phone_required": "Zadejte prosím obě informace.",
            "no_address": "Žádná dodací adresa"
        }
    },
    "cost_center": {
        "name": "Jméno",
        "number": "Číslo",
        "free_budget": "Dostupný rozpočet",
        "description": "Nákladové středisko",
        "label_log": "cost_center_name",
        "budgets": "Rozpočty",
        "form_sequence": "name,number",
        "page": {
            "new": "Nové nákladové středisko",
            "department_tooltip_content": "Která oddělení smějí používat tohoto dodavatele.",
            "tab_general": "Obecné",
            "tab_budgets": "Rozpočty",
            "tab_shipping_address": "Dodací adresy",
            "tab_approvers": "Schvalovatelé",
            "tab_permissions": "Skupiny oprávnění",
            "tab_departments": "Oddělení",
            "tab_clients": "Klienti",
            "validation": {
                "name_required": "Zadejte jméno.",
                "number_required": "Zadejte číslo.",
                "number_length": "Musí mít maximálně 36 znaků a může mít pouze mezery uprostřed.",
                "number_exists": "Číslo již existuje."
            },
            "tour": {
                "name_number": "Zadejte název a jedinečné číslo nákladového střediska.",
                "budget": "Ke svému nákladovému středisku můžete přiřadit různé rozpočty a také rozpočty přiřadit k účtu hlavní knihy.",
                "approver": "Zde definujte, kdo by měl schvalovat nákupní objednávky s tímto nákladovým střediskem. Při vytváření nákupních objednávek lze přidat další schvalovatele.",
                "department": "Zde můžete definovat, ve kterých odděleních lze nákladové středisko používat. Pokud zde žádná oddělení nepřidáte, lze nákladové středisko vybrat ve všech odděleních.",
                "btn_save": "Jakmile zadáte všechny potřebné informace, přidejte nákladové středisko do databáze."
            }
        }
    },
    "supplier": {
        "address": "Adresa",
        "external_id": "DATEV ID",
        "supplier_number": "Číslo dodavatele",
        "client_number": "Číslo zákazníka",
        "delivery_in_days": "Dodací lhůta v pracovních dnech",
        "delivery_terms": "Dodací podmínky",
        "payment_terms": "Platební podmínky",
        "vat_number": "DIČ",
        "city": "Město",
        "iban": "IBAN",
        "bic": "BIC",
        "form_sequence": "name,email,city,address,external_id,delivery_terms,payment_terms,iban,bic,vat_number",
        "payment_method": "Způsob platby",
        "payment_method_info": "Faktury od dodavatelských společností se způsobem platby SEPA inkaso nebo kreditní kartou jsou označeny jako zaplacené přímo",
        "page": {
            "new": "Nový dodavatel",
            "department_tooltip_content": "Která oddělení mohou používat toto nákladové středisko.",
            "delivery_in_days_tooltip_content": "Předpokládaná dodací lhůta slouží k přijetí úkolového emailu zboží.",
            "notes_tooltip_content": "Tato poznámka se zobrazuje pouze interně v liftbase a nevytiskne se na PDF objednávky.",
            "external_id_tooltip_content": "ID dodavatele ze systému třetí strany, který jste integrovali.",
            "client_number_tooltip_content": "Číslo, které vám tento dodavatel přiřadil (volitelné).",
            "supplier_number_tooltip_content": "Číslo, které jste přiřadili tomuto dodavateli.",
            "delivery_terms_tooltip_content": "Platební a dodací podmínky dohodnuté s dodavatelem budou zobrazeny v objednávce ve formátu PDF.",
            "payment_terms_tooltip_content": "Platební a dodací podmínky dohodnuté s dodavatelem budou zobrazeny v objednávce ve formátu PDF.",
            "tab_general": "Obecné",
            "tab_delivery_info": "Informace o dodávce",
            "tab_payment_info": "Informace o platbě",
            "tab_permissions": "Skupiny oprávnění",
            "tab_departments": "Oddělení",
            "tab_approvers": "Schvalovatelé",
            "tab_clients": "Podnikové specifické ID",
            "default_clients_ids": "Pokud společnost není uvedena v této tabulce s konkrétními ID, <br>budou použita následující výchozí ID: <br>ID DATEV: {external_id} <br>Číslo klienta: {client_number}.",
            "no_default_id": "Žádné výchozí ID není k dispozici.",
            "validation": {
                "name_required": "Zadejte jméno.",
                "name_length": "Musí mít maximálně 50 znaků.",
                "email_invalid": "Prosím zadejte platnou emailovou adresu.",
                "supplier_number_invalid": "Prosím zadejte platné číslo.",
                "vat_number_invalid": "Může obsahovat čísla, velká a malá písmena, tečky, mezery a podtržítka. Musí mít maximálně 15 znaků.",
                "iban_invalid": "Musí mít alespoň 15 a maximálně 34 znaků. Nesmí obsahovat mezery. Začíná dvěma velkými písmeny, následovanými 2 čísly a poté 11 až 30 alfanumerickými znaky.",
                "bic_invalid": "Musí mít alespoň 8 a maximálně 11 znaků a obsahovat pouze velká písmena a čísla. Prvních šest znaků musí být písmena.",
                "external_id_length": "ID DATEV musí obsahovat pouze celá čísla a nesmí být menší než 10000."
            },
            "tour": {
                "name": "Zadejte jméno dodavatele.",
                "email": "Emailová adresa dodavatele slouží např. pro přípravu emailových zpráv pro zadání objednávky.",
                "address": "Zde můžete uvést adresu dodavatele.",
                "external_id": "Externí ID je číslo dodavatele uložené v externím systému. Toto číslo se používá v API.",
                "terms": "Zadejte s dodavatelem dohodnuté platební a dodací podmínky. Tyto informace se zobrazí v PDF nákupní objednávky.",
                "btn_save": "Jakmile zadáte všechny informace, uložte dodavatele zde."
            }
        }
    },
    "user": {
        "name": "Jméno",
        "client_id": "Hlavní společnost",
        "approval_limit": "Limit schválení",
        "roles": {
            "user-admin": "Správce",
            "user-price": "Může zobrazit ceny",
            "user-dashboard": "Může zobrazit řídicí panel",
            "user-change-order": "Může upravit objednávku po schválení",
            "user-see-all-orders": "Může vidět všechny objednávky",
            "user-see-all-invoices": "Všechny společnosti",
            "user-see-invoices": "Zobrazit faktury:",
            "user-see-all-invoices-belonging-to-own-company": "Vlastní společnost",
            "user-see-own-invoices-and-involved": "Vlastní a zúčastněné",
            "user-can-change-invoices": "Upravit fakturu později",
            "user-see-client-addresses": "Může vidět adresy všech společností",
            "user-export-invoice": "Může exportovat faktury do {export_name}",
            "user-can-delete-document-directly": "Může zrušit/odmítnout dokumenty bez objasnění",
            "user-can-insert-product": "Uložit do katalogu produktů"
        },
        "blocked": "Přihlášení zablokováno",
        "token_issued_at": "Token API vydán dne",
        "form_sequence": "name,email,client_id,department_id,cost_center_id,location_id,approval_limit,roles,blocked",
        "page": {
            "tab_general": "Obecné",
            "tab_roles": "Oprávnění",
            "tab_roles_general": "Obecná oprávnění",
            "tab_roles_procurement": "Požadavky na nákup",
            "tab_roles_invoice": "Faktury",
            "tab_roles_block": "Bloky",
            "tab_permissions": "Skupiny oprávnění",
            "tab_addresses": "Dodací adresy",
            "tab_task_groups": "Skupiny úkolů",
            "tab_passkeys": "Přístupové klíče",
            "new": "Nový uživatel",
            "block_btn": "Zablokovat uživatele",
            "unblock_btn": "Odblokovat uživatele",
            "login_as_btn": "Přihlásit se jako",
            "no_permission": "Není vybrána žádná skupina oprávnění.",
            "task_group_permissions": "Oprávnění z mých skupin úkolů:",
            "approval_limit_tooltip_content": "Zde definujte konečný limit schválení pro tuto osobu. <br> Vyšší částky mohou být schváleny, ale musí je také definitivně schválit jiná osoba s vyšším limitem schválení.",
            "approval_limit_placeholder": "Neomezený",
            "upload_signature_btn": "Vyberte Podpis",
            "upload_signature_tooltip_content": "Přidejte podpis, který bude vytištěn na objednávkovém dokumentu vašich schválených nákupních objednávek. <br/> Doporučujeme použít obrázkový soubor s průhledným pozadím.",
            "api_token_btn": "Vygenerovat token API",
            "api_token_dialog_title": "Vygenerovat token API",
            "api_token_dialog_content": "Tokeny API jsou vyžadovány pro integraci aplikací třetích stran a umožňují přístup k datům jménem příslušného uživatele.",
            "api_token_dialog_date_placeholder": "Datum vypršení platnosti tokenu",
            "api_token_dialog_generate_btn": "generovat",
            "api_token_dialog_copy_btn": "kopírovat",
            "api_token_dialog_copy_info": "Zkopírujte a vložte následující token rozhraní API do aplikace třetí strany:",
            "api_token_dialog_copied": "Zkopírováno!",
            "language": "Jazyk",
            "role_dashboard_tooltip": "Lze zkontrolovat pouze v případě, že uživatel může zobrazit ceny.",
            "passkey": "Přístupový klíč",
            "passkeys": "Přístupové klíče",
            "passkey_name": "Jméno",
            "passkey_last_login": "Poslední přihlášení",
            "passkey_dialog": {
                "title": "Přidat přístupový klíč",
                "info1": "<b>Co jsou přístupové klíče?</b><ul><li>Přihlášení bez čekání na e-mail</li><li>Otiskem prstu, obličejem nebo kódem PIN</li><li>Bezpečné a pohodlné</li> li></ul>",
                "info2": "<b>Nastavte nyní ve 3 krocích:</b><ol><li>Zadejte jméno, např. \"Počítač domácí kanceláře\" nebo \"Poznámkový blok\"</li><li>Klikněte na tlačítko \"Další\"</li><li>Postupujte podle pokynů z prohlížeče / operačního systému</li></ol>",
                "info3": "Podrobné pokyny <a href=\"https://liftbase.de/handbuch/anmeldung/passkeys/\" target=\"_blank\">najdete v příručce</a>",
                "name_input_label": "Název přístupového klíče",
                "name_input_placeholder": "např. 'Home Office PC' nebo 'Notebook'",
                "added_msg": "Byl přidán přístupový klíč.",
                "deleted_msg": "Přístupový klíč byl smazán.",
                "exist_msg": "V tomto zařízení již máte přístupový klíč, zkuste to s jiným zařízením."
            },
            "validation": {
                "name_required": "Zadejte jméno.",
                "name_exists": "Název již existuje.",
                "email_required": "Prosím zadejte emailovou adresu.",
                "email_invalid": "Prosím zadejte platnou emailovou adresu.",
                "email_exists": "E-mailová adresa již existuje.",
                "approval_limit_length": "Limit schválení musí být buď větší než 0, nebo prázdný."
            },
            "tour": {
                "name": "Zadejte jméno uživatele, nejlépe jméno a příjmení.",
                "email": "Zadejte e-mailovou adresu, přes kterou se uživatel může přihlásit.",
                "organization": "Přiřaďte uživatele společnosti, oddělení a nákladovému středisku. Tyto údaje budou předvyplněny v objednávkovém formuláři.",
                "approval_limit": "Zde můžete pro uživatele definovat limit schválení. Pokud má objednávka vyšší celkovou částku, musí ji také definitivně schválit osoba s vyšším schvalovacím limitem. Pokud není definován žádný limit, uživatel může nakonec schválit všechny částky.",
                "signature": "Zde můžete přidat podpis uživatele. Když uživatel schválí nákupní objednávku, jeho podpis bude přidán do PDF nákupní objednávky.",
                "roles": "Zde můžete nastavit oprávnění pro osobu, například zda a které procesy nebo informace mohou být zobrazeny nebo upraveny. Je zde také možnost osobu zablokovat, pokud například opustila společnost.",
                "roles_price": "Určete, zda uživatel může zobrazit ceny produktů nebo řídicí panel se statistikami nákupních objednávek.",
                "role_change_order": "Důvěryhodnému uživateli můžete udělit oprávnění k úpravě nákupních objednávek po schválení, aniž byste je posílali zpět k vyúčtování, a tím se vyhli opětovnému schválení. To může být užitečné například pro dodatečnou úpravu dodacích adres nebo cen produktů.",
                "admin": "Zvolte, zda má mít tato osoba přístup do administrační oblasti, například pro přidání dalších uživatelů, kmenových dat nebo nastavení.",
                "block": "Tuto možnost vyberte, pokud chcete zabránit uživateli v přihlášení do liftbase, např. když dočasně opustil společnost.",
                "api_token": "Tokeny API jsou vyžadovány pro integraci aplikací třetích stran a umožňují přístup k datům jménem příslušného uživatele.",
                "addresses": "Uživateli můžete přidat osobní dodací adresy, například pokud pracuje z domova a chce, aby mu tam byly doručovány produkty.",
                "btn_save": "Uložte uživatele a související informace.",
                "profile_signature": "Zde můžete nahrát svůj podpis. Když schválíte nákupní objednávky, váš podpis bude přidán do PDF nákupní objednávky.",
                "profile_addresses": "Uživateli můžete přidat osobní dodací adresy, například pokud pracuje z domova a chce, aby mu tam byly doručovány produkty.",
                "profile_btn_save": "Uložte související informace."
            }
        }
    },
    "lb_user": {
        "description": "Uživatel"
    },
    "posting_key": {
        "name": "Jméno",
        "tax": "Sazba daně (%)",
        "number": "Klíč",
        "form_sequence": "name,number,tax",
        "page": {
            "new": "Nový klíč pro zasílání",
            "validation": {
                "name_required": "Zadejte jméno.",
                "number_required": "Prosím zadejte platné číslo.",
                "number_length": "Může obsahovat až 9místná čísla začínající od 1."
            },
            "tour": {
                "name": "Zadejte klíč a související daňovou sazbu."
            }
        }
    },
    "ledger_account": {
        "name": "Jméno",
        "description": "Popis",
        "form_sequence": "name,number,description",
        "page": {
            "new": "Nový účet hlavní knihy",
            "validation": {
                "name_required": "Zadejte název.",
                "number_required": "Prosím zadejte platné číslo.",
                "number_length": "Může obsahovat pouze čísla a nemůže začínat 0.",
                "tax_required": "Zadejte platnou sazbu daně.",
                "tax_length": "Procentuální hodnota může mít pouze dvě číslice s čárkou a nejvýše dvě desetinná místa."
            },
            "tour": {
                "name": "Zadejte číslo a název hlavního účtu."
            }
        }
    },
    "profit_center": {
        "name": "Název",
        "form_sequence": "name,number",
        "page": {
            "new": "Nové ziskové centrum",
            "validation": {
                "name_required": "Zadejte název.",
                "number_required": "Zadejte platné číslo.",
                "number_length": "Smí obsahovat pouze čísla a při více číslicích nesmí začínat nulou.",
            },
            "tour": {
                "name": "Uveďte číslo a název ziskového centra."
            }
        }
    },
    "tax_group": {
        "code": "ID",
        "name": "Název",
        "form_sequence": "name,code,client_id",
        "page": {
            "new": "Nová skupina daně z prodeje",
            "validation": {
                "name_required": "Zadejte název.",
                "code_required": "Zadejte ID.",
                "client_required": "Vyberte prosím klienta.",
            },
            "tour": {
                "name": "Uveďte ID a název skupiny daně z prodeje."
            }
        }
    },
    "financial_dimension": {
        "code": "ID",
        "name": "Název",
        "form_sequence": "name,code",
        "page": {
            "new": "Nový zaměstnanec",
            "validation": {
                "name_required": "Prosím, zadejte jméno.",
                "code_required": "Prosím, zadejte ID.",
                "client_required": "Prosím, vyberte společnost."
            },
            "tour": {
                "name": "Zadejte ID a jméno zaměstnance."
            }
        }
    },
    "tax_item_group": {
        "code": "ID",
        "tax_code": "Daňové ID",
        "name": "Název",
        "form_sequence": "name,code,tax_code,client_id",
        "page": {
            "new": "Nová skupina položek daní",
            "validation": {
                "name_required": "Prosím, zadejte název.",
                "code_required": "Prosím, zadejte ID.",
                "tax_code_required": "Prosím, zadejte Daňové ID.",
                "client_required": "Prosím, vyberte firmu.",
            },
            "tour": {
                "name": "Zadejte ID a název skupiny daně z přidané hodnoty."
            }
        }
    },
    "approver": {
        "description": "Odsouhlasení",
        "label_log": "lb_user_name",
        "table_log": "lb_user",
        "approver_required": "Vyberte prosím schvalovatele.",
        "is_required": "Povinný",
        "is_required_info": "Je třeba schválit, i když předchozí schvalovatelé již měli potřebný limit schválení."
    },
    "invoice": {
        "description": "Faktura",
        "name": "Jméno",
        "creator_id": "Nahráno uživatelem",
        "created_at": "Nahráno dne",
        "number": "Číslo faktury",
        "invoice_number_formatted": "Interní RE číslo",
        "due_date": "Datum splatnosti",
        "delivery_date": "Datum dodání",
        "invoice_date": "Datum faktury",
        "tax": "Sazba daně %",
        "total_brutto": "Celkový hrubý",
        "total_netto": "Celková čistá",
        "total_netto_abbr": "-Síť",
        "fix_recognition": "Nespokojeni s rozpoznáváním dat",
        "fix_recognition_checkbox": "Jsem nespokojen s rozpoznáváním dat.",
        "paid": "Již zaplaceno",
        "review_status": "Výsledek kontroly",
        "review_status_options": {
            "content_rejection": "Faktura odmítnuta z věcných důvodů",
            "insolvency_rejection": "Faktura odmítnuta z důvodu insolvenčního práva",
            "further_review_required": "Faktura vyžaduje další kontrolu",
            "approved": "Faktura schválena"
        },
        "currency": "Měna",
        "notes": "Poznámky",
        "order_notes": "Poznámky k objednávce",
        "discount_percentage": "Sleva 1 (%)",
        "discount_amount": "Sleva 1 – Částka",
        "discount_days": "Sleva 1 - Dny",
        "discount_payment_date": "Sleva 1 – Datum",
        "discount_percentage2": "Sleva 2 (%)",
        "discount_amount2": "Sleva 2 – Částka",
        "discount_days2": "Sleva 2 - Dny",
        "discount_payment_date2": "Sleva 2 – Datum",
        "type_discount_amount": "currency",
        "type_discount_amount2": "currency",
        "export_individual_items": "Exportovat jednotlivé položky",
        "export_individual_items_tooltip": "Pokud je toto políčko zaškrtnuto, přenášejí se pozice místo údajů v záhlaví.",
        "status": "Stav",
        "statuses": {
            "open": "Rozpoznávání faktur",
            "export": "Připraveno k exportu",
            "declined": "Vyřazeno",
            "completed": "Dokončeno",
            "approval": "Ruční kontrola",
            "financial-approval": "Schválení",
            "clearing": "Objasnění účetnictví",
            "accounting": "Účetnictví"
        },
        "page": {
            "upload": {
                "title": "Nahrajte nové faktury",
                "info": "Přetažením nebo kliknutím sem nahrajte jednu nebo více faktur (*.pdf až 10 MB).",
                "info_attachments": "Přetáhněte soubory nebo klikněte zde pro nahrání jednoho či více příloh (až 10 MB na soubor).",
                "create_btn": "Spusťte rozpoznávání faktur",
                "file_invalid": "Nahrávejte pouze soubory PDF or XML do velikosti 10 MB.",
                "no_preview_available": "Pro tento typ souboru není k dispozici žádný náhled. <br>Stáhněte si soubor a otevřete jej na svém počítači.",
                "tour": {
                    "before_attach": "Zde můžete nahrát jednu nebo více faktur přetažením nebo kliknutím v této oblasti.",
                    "after_attach_title": "Spusťte rozpoznávání faktur",
                    "after_attach": "Zde můžete spustit automatické rozpoznání všech nahraných faktur. Poté budete přesměrováni na přehled první faktury. Ostatní faktury budou k dispozici v seznamu úkolů."
                }
            },
            "new": "Nová faktura",
            "payment_conditions": "Platební podmínky",
            "discount1": "Sleva 1",
            "discount2": "Sleva 2",
            "discount_percentage": "Procento",
            "discount_amount": "Množství",
            "discount_days": "Dny",
            "discount_payment_date": "datum",
            "recognition_data_title": "Rozpoznávání nezpracovaných dat",
            "no_recognition_data": "Nebyla rozpoznána žádná data.",
            "repeat_recognition_btn": "Zopakovat rozpoznání dokumentu",
            "repeat_recognition_confirm_btn": "Potvrdit",
            "repeat_recognition_confirm_msg": "Existující data ve formuláři budou přepsána. Opravdu chcete pokračovat?",
            "repeat_recognition_confirm_title": "Zopakovat rozpoznání dokumentu",
            "repeat_recognition_feedback_msg": "Rozpoznávání dokumentu probíhá na pozadí a může to trvat několik sekund.",
            "select_columns_btn": "Vybrat sloupce",
            "export_to_datev_btn": "Odeslat do DATEV",
            "export_to_datev_csv_btn": "Exportovat DATEV CSV",
            "export_to_ms365_btn": "Odeslat do D365",
            "export_btn_incomplete_form_tooltip": "Prosím, doplňte údaje faktury.",
            "export_btn_send_to_clearing_tooltip": "Chcete-li znovu přenést do D365, pošlete prosím znovu na účetnictví prostřednictvím kroku 'Vyjasnění'.",
            "export_error": "<b>Faktura nemohla být odeslána</b>.<br>",
            "no_export_config_datev_tooltip": "Nebyla nastavena žádná připojení k <b>{export_name}</b>. <br>V konfiguraci nebyl přiřazen žádný klient DATEV pro vybranou společnost <b>{client_name}</b>. <br>V případě potřeby kontaktujte osobu s administrátorskými právy <br>pro nastavení připojení v administrační oblasti.",
            "no_export_config_tooltip": "Nebylo nastaveno žádné připojení k {export_name}.<br>Obraťte se na osobu s administrátorskými právy, aby nastavila připojení v administrativní oblasti.",
            "confirm_before_leave_msg": "Vaše faktura nebyla uložena. Opravdu chcete stránku opustit?",
            "can_not_edit": "Nemáte oprávnění upravovat data. Chcete-li tak učinit, zašlete fakturu do zúčtování pomocí Akce.",
            "recognized_client_id": "Společnost byla úspěšně identifikována na základě <b>DIČ</b> a <b>názvu</b>.<br> <b>Společnost:</b> {client_name}",
            "user_client_id": "Společnost byla přiřazena na základě <b>uživatelského profilu</b> nahrávajícího.<br><b>Společnost:</b> {client_name}",
            "no_client_recognized": "Žádná společnost nebyla identifikována na základě <b>DIČ</b> nebo <b>názvu</b>.<br> Zkontrolujte prosím, zda údaje společnosti v liftbase odpovídají údajům na faktuře.",
            "recognized_supplier_id": "Dodavatelská společnost byla úspěšně identifikována na základě <b>IBAN</b>, <b>DIČ</b> a <b>názvu</b>.<br> <b>Dodavatel:</b> {supplier_name}",
            "no_supplier_recognized": "Žádný dodavatel nebyl identifikován na základě <b>DIČ</b> nebo <b>názvu</b>.<br> Zkontrolujte prosím, zda údaje dodavatele v liftbase odpovídají údajům na faktuře.",
            "total_brutto_missing": "Zadejte celkovou hrubou částku.",
            "total_brutto_zero": "Nesmí mít částku 0 €.",
            "total_brutto_does_not_match_with_positions": "Celková částka musí odpovídat součtu pozic.",
            "combination_invalid": "Kombinace účtu, nákladového místa a profitového centra není v D365 povolena.",
            "possible_combinations": "Možné kombinace",
            "current_combination": "Aktuální kombinace",
            "combination": "Kombinace",
            "min_approval_one_person": "1 osoba",
            "min_approval_qty": "{qty} různé osoby",
            "min_approval_error_msg": "Schválení této faktury není dostatečné. Přidejte prosím další osobu ke schválení. Tato faktura musí být schválena <b>alespoň {min_approval_msg}</b>.",
            "min_approval_error_title": "Nedostatečné schválení",
            "no_approval_limit_msg": "Váš schvalovací limit není pro tuto fakturu dostatečný. Přidejte prosím další osobu ke schválení, která má potřebný schvalovací limit.",
            "no_approval_limit_title": "Schvalovací limit",
            "confirm_without_approval_limit_msg": "Žádná z vybraných osob nemá potřebný schvalovací limit pro částku této faktury. Potřebná schválení lze přidat později. Opravdu chcete pokračovat?",
            "confirm_without_approval_limit_title": "Schvalovací limit",
            "lines": {
                "add_tooltip": "Přidat řádek faktury",
                "get_from_recognition": "Převzít z rozpoznávání dokladů",
                "legend": {
                    "description": "Legenda",
                    "no_order": "Položka není součástí objednávky.",
                    "variance": "Rozptyl ve vyznačených sloupcích.",
                    "not_invoiced": "Objednané zboží nebylo na faktuře nalezeno.",
                    "matched": "Zboží v objednávce a na faktuře je totožné."
                },
                "form": {
                    "item": "Položka",
                    "new": "Nová fakturační linka",
                    "validation": {
                        "description_required": "Zadejte jméno.",
                        "item_number_required": "Zadejte číslo položky.",
                        "tax_percentage_required": "Vyberte prosím platnou daň.",
                        "unit_net_price_required": "Prosím zadejte platné číslo.",
                        "quantity_required": "Prosím zadejte platné číslo."
                    },
                    "tour": {
                        "name": "Zadejte smysluplný název produktu.",
                        "number": "Zde můžete zadat číslo položky produktu.",
                        "price": "Zadejte daňovou sazbu a jednotkovou čistou cenu. Hrubá cena bude vypočtena pomocí daňové sazby."
                    }
                },
                "table": {
                    "source": "Zdroj",
                    "item_number": "Předmět číslo.",
                    "name": "Jméno",
                    "quantity": "množství",
                    "tax": "daň (%)",
                    "netto_price": "Jednotka netto (€)",
                    "total_netto_price": "Celková čistá (€)",
                    "tax_amount": "Celková daň (€)",
                    "total_brutto_price": "Celkový hrubý (€)",
                    "cost_center": "Nákladové středisko",
                    "profit_center": "Profit center",
                    "ledger_account": "Sekundární účet",
                    "tax_group": "Daňová skupina",
                    "tax_item_group": "Daňová skupina položek",
                    "open_bcase": "Otevřít dokument",
                    "no_order_position": "Nebyly nalezeny žádné pozice objednávky. K faktuře prosím přiřaďte <b>nákupní objednávku (PO)</b> a <b>dodavatele</b>.",
                    "copy_from_procurement": "Vložte všechny položky z objednávky",
                    "no_order_tooltip": "Zatím není propojena žádná objednávka.",
                    "no_supplier_tooltip": "Zatím není připojen žádný dodavatel.",
                    "validation_error": "Opravte prosím chyby v tabulce."
                }
            },
            "export_dialog": {
                "title": {
                    "D365": "Vytvořit deník",
                    "DATEV": "Odeslat do DATEV",
                    "DATEV CSV": "Export DATEV CSV"
                },
                "description": "Následující doklady budou odeslány na {export_name}.",
                "description_csv": "Následující doklady budou exportovány pro {export_name}.",
                "btn_confirm": "Odeslat",
                "btn_confirm_csv": "Exportovat",
                "btn_finish": "Zavřít",
                "export_feedback_csv": "Exportováno",
                "export_feedback": "Odesláno",
                "exported_csv": "Exportováno",
                "exported": "Odesláno",
                "continue_background": "Pokračovat na pozadí",
                "exported_at": "Naposledy exportováno",
                "is_loading": "Odesílání probíhá",
                "is_loading_csv": "Exportování probíhá"
            },
            "select_columns_dialog": {
                "title": "Vybrat sloupce",
                "select_fields": "Následující sloupce budou zobrazeny pod <i>Moje úkoly</i> a <i>Všechny dokumenty</i>.",
                "btn_confirm": "Použít",
                "btn_finish": "Zavřít"
            },
            "copy_procurement_dialog": {
                "title": "Zkopírujte data z objednávky",
                "message": "Přejete si zkopírovat některá data z objednávky do této faktury?",
                "overwrite_checkbox": "Přepsat existující data ve formuláři.",
                "select_supplier_msg": "Vyberte dodavatele, od kterého chcete data zkopírovat:",
                "select_supplier_placeholder": "Vyberte dodavatele",
                "confirm_btn": "Potvrdit"
            },
            "validation": {
                "invoice_date_required": "Zadejte prosím datum faktury.",
                "due_date_required": "Zadejte prosím datum splatnosti.",
                "due_date_invalid1": "Datum splatnosti musí být větší než datum dokumentu.",
                "delivery_date_required": "Zadejte prosím datum doručení.",
                "ledger_account_required": "Vyberte účet hlavní knihy.",
                "branch_required": "Vyberte prosím pobočku.",
                "posting_key_required": "Vyberte klíč pro zaúčtování.",
                "supplier_required": "Vyberte dodavatele.",
                "cost_center_required": "Vyberte prosím nákladové středisko.",
                "profit_center_required": "Prosím, vyberte středisko zisku.",
                "employee_required": "Prosím, vyberte zaměstnance.",
                "d365_department_required": "Prosím, vyberte oddělení D365.",
                "financial_dimension_required": "Vyberte prosím finanční dimenzi.",
                "client_required": "Vyberte prosím společnost.",
                "number_required": "Zadejte prosím číslo faktury.",
                "number_invalid_d365": "Může obsahovat maximálně 20 znaků.",
                "number_invalid": "Musí obsahovat až 36 alfanumerických znaků, žádné přehlásky a znaky $ % & * + - /.",
                "notes_length": "Nesmí přesáhnout 120 znaků.",
                "discount_percentage_invalid1": "Procento slevy musí být větší než 0.",
                "discount_percentage_invalid2": "Sleva 1 musí být větší než sleva 2.",
                "discount_amount_invalid1": "Pro kladné fakturované částky jsou přijatelné pouze kladné částky.",
                "discount_amount_invalid2": "Pro záporné fakturované částky jsou přijatelné pouze záporné částky.",
                "discount_amount_invalid3": "Sleva 1 musí být větší než sleva 2.",
                "discount_amount_invalid4": "Sleva musí být nižší než celková částka.",
                "discount_amount_invalid5": "Součet částek slevy musí být menší než částka.",
                "discount_date_invalid1": "Data slevy 1 a 2 musí být mezi fakturou a datem splatnosti.",
                "discount_date_invalid2": "Datum slevy 1 musí být před datem slevy 2.",
                "missing_discount_field": "Je nutné vyplnit všechna pole pro slevy."
            }
        }
    },
    "invoice_line": {
        "item_number": "Číslo položky",
        "quantity": "Množství",
        "unit_net_price": "Jednotková čistá cena",
        "tax_percentage": "Daň"
    },
    "location": {
        "name": "Jméno",
        "number": "Číslo",
        "address": "Adresa",
        "form_sequence": "name,number,address"
    },
    "spreadsheet": {
        "add_row": "Nový řádek",
        "download": "Stáhnout tabulku",
        "save": "Uložit tabulku"
    },
    "audit_log": {
        "description": "Úpravy",
        "history": "Úpravy",
        "table": "Stůl",
        "lb_user_id": "Uživatel",
        "data_before": "Před",
        "data_after": "Po",
        "created_at": "datum",
        "type": {
            "insert": "Přidal",
            "update": "Upraveno",
            "delete": "Odebráno"
        },
        "msg": {
            "deleted": "odstraněno",
            "inserted": "přidal",
            "was_deleted": "Byl odstraněn.",
            "was_inserted": "Byl přidán."
        },
        "none": "žádný",
        "empty": "Nejsou k dispozici žádné úpravy."
    },
    "txt_before_leave_page": "Vaše změny nemusí být uloženy. Opravdu chcete pokračovat?",
    "easybill_invoice": {
        "from_tenant": "Od nájemce",
        "no_invoice": "Dosud nebyla vytvořena žádná faktura.",
        "number": "Číslo",
        "service_date": "Doba",
        "from": "Z",
        "until": "na",
        "total": "Celkový",
        "due_date": "Datum splatnosti",
        "status": "Stav",
        "paid_at": "Zaplaceno dne",
        "payment_missing": "čekající",
        "sepa": "SEPA",
        "tour": {
            "invoice_table": "Zde naleznete podrobný přehled všech liftbase faktur včetně jejich stavu. Faktury lze stáhnout pomocí tlačítka."
        }
    },
    "configuration": {
        "description": "Nastavení",
        "sepa_number": "Identifikační číslo věřitele",
        "sepa_mandate_reference": "Odkaz na mandát",
        "remove_sepa_msg": "Opravdu chcete zrušit SEPA inkaso?",
        "remove_sepa_btn": "Zrušit",
        "system-modules-procurement": "Modul - Žádosti o nákup",
        "system-modules-invoice": "Modul - Faktury",
        "system-modules-permissions": "Modul - Skupiny oprávnění",
        "system-modules-multilingualism": "Modul - Vícejazyčná hlavní data",
        "system-modules-invoice-export": "Modul - Export faktur do",
        "purchaseorder-defaults-selectproduct": "Předvolby role - Osoba „Výběr produktu“",
        "purchaseorder-defaults-sendorder": "Předvolby role - Osoba „Odeslání objednávky“",
        "purchaseorder-defaults-clearing": "Předvolby role - Osoba „Vyrovnání nákupu“",
        "purchaseorder-defaults-consignee": "Předvolby role - Osoba „Příjem zboží“",
        "invoice-defaults-manual_check": "Předvolby role - Osoba „Manuální kontrola (faktura)“",
        "invoice-defaults-clearing": "Předvolby role - Osoba „Účetní vyrovnání“",
        "purchaseorder-defaults-init_seq": "Žádosti o nákup - Číslování objednávek od",
        "purchaseorder-product-require_product_group": "Žádosti o nákup - Všechny produkty musí být přiřazeny ke skupině produktů-",
        "purchaseorder-product-require_product_selection": "Žádosti o nákup - Proces žádosti o nákup",
        "email-send-order": "Objednávky - E-mailová objednávka",
        "purchaseorder-pdf-free-text": "Objednávky - PDF objednávky",
        "type_email-send-order": "html-i18n",
        "type_purchaseorder-pdf-free-text": "html-i18n",
        "invoice-recognition-lines": "Faktury - Automaticky vytvářet fakturační položky na základě rozpoznání dokumentu.",
        "invoice-form-procurement-required": "Faktury - Je požadován odkaz na žádost o nákup.",
        "notifications-mail-interval": "Upozornění - Interval e-mailů v minutách",
        "punchout-amazon-identity": "Integrace - Punch-out Amazon - Identita",
        "punchout-amazon-secret": "Integrace - Punch-out Amazon - Tajný klíč",
        "punchout-amazon-url": "Integrace - Punch-out Amazon - Punch-out URL",
        "punchout-amazon-order-url": "Integrace - Punch-out Amazon - URL objednávky",
        "punchout-amazon-email": "Integrace - Punch-out Amazon - E-mail",
        "punchout-amazon.supplier": "Integrace - Punch-out Amazon - Výchozí dodavatel",
        "punchout-mercateo-identity": "Integrace - Punch-out Mercateo - Identita",
        "punchout-mercateo-secret": "Integrace - Punch-out Mercateo - Tajný klíč",
        "punchout-mercateo-url": "Integrace - Punch-out Mercateo - Punch-out URL",
        "punchout-mercateo-order-url": "Integrace - Punch-out Mercateo - URL objednávky",
        "punchout-mercateo-email": "Integrace - Punch-out Mercateo - E-mail",
        "punchout-mercateo-supplier": "Integrace - Punch-out Mercateo - Výchozí dodavatel",
        "punchout-rajapack-identity": "Integrace - Punch-out RAJA - Identita",
        "punchout-rajapack-secret": "Integrace - Punch-out RAJA - Tajný klíč",
        "punchout-rajapack-url": "Integrace - Punch-out RAJA - Punch-out URL",
        "punchout-rajapack-order-url": "Integrace - Punch-out RAJA - URL objednávky",
        "punchout-rajapack-email": "Integrace - Punch-out RAJA - E-mail",
        "punchout-rajapack-supplier": "Integrace - Punch-out RAJA - Výchozí dodavatel",
        "punchout-conrad-identity": "Integrace - Punch-out Conrad - Identita",
        "punchout-conrad-secret": "Integrace - Punch-out Conrad - Tajný klíč",
        "punchout-conrad-url": "Integrace - Punch-out Conrad - Punch-out URL",
        "punchout-conrad-order-url": "Integrace - Punch-out Conrad - URL objednávky",
        "punchout-conrad-email": "Integrace - Punch-out Conrad - E-mail",
        "punchout-conrad-supplier": "Integrace - Punch-out Conrad - Výchozí dodavatel",
        "punchout-thgeyer-identity": "Integrace - Punch-out TH.GEYER - Identita",
        "punchout-thgeyer-secret": "Integrace - Punch-out TH.GEYER - Tajný klíč",
        "punchout-thgeyer-url": "Integrace - Punch-out TH.GEYER - Punch-out URL",
        "punchout-thgeyer-supplier": "Integrace - Punch-out TH.GEYER - Výchozí dodavatel",
        "punchout-reichelt-identity": "Integrace - Punch-out Reichelt - Identita",
        "punchout-reichelt-secret": "Integrace - Punch-out Reichelt - Tajný klíč",
        "punchout-reichelt-url": "Integrace - Punch-out Reichelt - Punch-out URL",
        "punchout-reichelt-supplier": "Integrace - Punch-out Reichelt - Výchozí dodavatel",
        "validation": {
            "bic_invalid": "Zadejte prosím platný BIC.",
            "iban_invalid": "Zadejte prosím platné číslo IBAN."
        },
        "datev": {
            "description": "DATEV",
            "connection": "Spojení s DATEV",
            "status_active": "Aktivní",
            "status_inactive": "Neaktivní",
            "from": "Z",
            "until": "Až do",
            "load_clients": "Načtěte klienty DATEV",
            "remove_connection": "Odebrat připojení",
            "deactivate_connection": "Deaktivovat připojení",
            "add_connection": "Nastavení připojení",
            "connected_by": "Spojení navázalo {name}.",
            "apps_manager": "Zde můžete spravovat <a href=\"https://apps.datev.de/tokrevui\" target=\"_blank\">aplikace spojené s DATEV</a>.",
            "msg_no_clients": "Nebyly nalezeny žádné záznamy nebo nemáte potřebná oprávnění nebo je nutné připojení znovu nastavit.",
            "fiscal_year_start": "Začátek fiskálního roku",
            "account_length": "Délka účtu",
            "is_accounts_payable_ledger_available": "Kniha závazků",
            "is_accounts_receivable_ledger_available": "Kniha pohledávek",
            "client_number": "Číslo klienta",
            "consultant_number": "Číslo konzultanta",
            "client_name": "Klient",
            "liftbase_client": "Společnost liftbase",
            "save_client": "Uložit společnost",
            "change_client": "Změnit společnost"
        },
        "ms365": {
            "description": "D365",
            "connection": "Připojení k D365",
            "status_active": "aktivní",
            "status_inactive": "neaktivní",
            "connect_and_load_data": "Vytvořte spojení a načtěte data",
            "connect": "Vytvořte spojení",
            "load_data": "Načítají se všechna data",
            "remove_connection": "Odstranit připojení",
            "add_connection": "Nastavit připojení",
            "no_client_found": "Nebyli nalezeni žádní klienti D365.",
            "msg_no_clients": "Nebyly nalezeny žádné záznamy nebo vám chybí potřebná oprávnění, nebo je nutné připojení nastavit znovu.",
            "client_number": "Číslo firmy D365",
            "client_name": "Firma D365",
            "liftbase_client": "Firma liftbase",
            "save_client": "Uložit firmu",
            "change_client": "Změnit firmu",
            "sync_running": "Data se načítá. To může trvat několik minut.",
            "last_sync_at": "Naposledy načteno dne {last_sync_at}",
            "journal_name": "Journal Name",
            "org_url": "Org URL",
        },
        "datev-csv": {
            "description": "DATEV CSV",
        },
        "template": {
            "module-procurement": {
                "group": "Moduly",
                "label": "Nákupní objednávka",
                "tour": [
                    {
                        "target": ".krokové moduly",
                        "content": "<p>Vyberte moduly výtahové základny, které chcete aktivovat. Informace o nákladech najdete na <a href=\"liftbase.de/preise\" target=\"_blank\">liftbase.de/preise</a>.</p>"
                    }
                ]
            },
            "module-invoice": {
                "group": "Moduly",
                "label": "Faktura"
            },
            "module-permissions": {
                "group": "Moduly",
                "label": "Skupina oprávnění"
            },
            "module-multilingualism": {
                "group": "Moduly",
                "label": "Vícejazyčná základní data"
            },
            "insolvency-law-fields": {
                "group": "Moduly",
                "label": "Zobrazit pole insolvenčního práva"
            },
            "module-invoice-export": {
                "group": "Moduly",
                "label": "Export faktury na",
                "options": [
                    {
                        "value": "datev",
                        "content": "DATEV"
                    },
                    {
                        "value": "ms365",
                        "content": "D365"
                    },
                    {
                        "value": "datev-csv",
                        "content": "DATEV CSV"
                    }
                ]
            },
            "procurement-select-product": {
                "group": "Výchozí role",
                "label": "Osoba \"Výběr produktu\"",
                "tour": [
                    {
                        "target": ".krok-přiřazení",
                        "content": "<p>Zde můžete nastavit výchozí globální role. Pokud pro společnost nebo oddělení nejsou definovány žádné výchozí hodnoty rolí, budou zde zadané osoby přiřazeny k příslušným krokům v pracovním postupu nákupních objednávek a faktur.</p>"
                    }
                ]
            },
            "procurement-send-order": {
                "group": "Výchozí role",
                "label": "Osoba \"Zadání objednávky\""
            },
            "procurement-clearing": {
                "group": "Výchozí role",
                "label": "Osoba \"Objasněno\"",
                "hint": "Pro objasnění procesů objednávání."
            },
            "procurement-consignee": {
                "group": "Výchozí role",
                "label": "Osoba \"Převzetí zboží\""
            },
            "invoice-manual-check": {
                "group": "Výchozí role",
                "label": "Osoba \"Ruční kontrola (faktura)\""
            },
            "invoice-clearing": {
                "group": "Výchozí role",
                "label": "Osoba \"Objasnění účetnictví\"",
                "hint": "Pro upřesnění při vyplňování faktur."
            },
            "procurement-init-seq": {
                "group": "Nákupní objednávky",
                "label": "Počáteční čísla objednávek",
                "hint": "Zadejte číslo, od kterého mají čísla objednávky začínat.",
                "validation": {
                    "interval": "Musí být mezi 0 a 999999999.",
                    "type": "Měl by obsahovat pouze celá čísla."
                },
                "tour": [
                    {
                        "target": ".krokové zadávání veřejných zakázek",
                        "content": "Zde najdete nastavení související s nákupními objednávkami. Nakonfigurujte počáteční číslo pro nákupní objednávky a zda musí být všechny produkty v nákupních objednávkách přiřazeny ke skupině produktů."
                    }
                ]
            },
            "procurement-require-product-group": {
                "group": "Nákupní objednávky",
                "label": "Všechny produkty musí patřit do skupiny produktů."
            },
            "procurement-require-product-selection": {
                "group": "Nákupní objednávky",
                "label": "Nákupní objednávky",
                "options": [
                    {
                        "value": "skutečný",
                        "content": "s výběrem produktu<br><ul><li>Vytvořit objednávku</li><li>Výběr produktu</li><li>Schválení</li><li>Objednávka</li><li>Příjem zboží </li></ul>"
                    },
                    {
                        "value": "Nepravdivé",
                        "content": "bez výběru produktu<br><ul><li>Vytvořit objednávku</li><li>Schválení</li><li>Objednávka</li><li>Příjem zboží</li></ul>"
                    }
                ]
            },
            "email-send-order": {
                "group": "Objednávky",
                "label": "Objednávka e-mail",
                "hint": "E-mailová zpráva vygenerovaná během kroku zadávání objednávky, včetně PDF objednávky, a odeslaná dodavateli.",
                "default_value": "Vážený pane/paní,<br>Posílám Vám naši objednávku jako přílohu PDF. Zašlete nám prosím potvrzení objednávky.",
                "tour": [
                    {
                        "target": ".step-e-mail-order-free-text",
                        "content": "Zde napište text, který bude zahrnut do e-mailové zprávy vygenerované během kroku zadání objednávky, včetně PDF objednávky, a odeslán dodavateli."
                    },
                    {
                        "target": ".step-pdf-free-text",
                        "content": "Zde vytvořte text, který se v kroku zadání objednávky zobrazí na vygenerovaném objednávkovém PDF, které bude následně odesláno dodavateli."
                    }
                ]
            },
            "procurement-pdf-free-text": {
                "group": "Objednávky",
                "label": "Objednávka PDF",
                "hint": "Zpráva zobrazená na vygenerovaném PDF nákupní objednávky během kroku zadání objednávky, která bude následně odeslána dodavateli.",
                "default_value": ""
            },
            "invoice-recognition-lines": {
                "group": "Faktury",
                "label": "Automaticky převzít položky, které rozpoznávání dokladů identifikovalo."
            },
            "invoice-procurement-required": {
                "group": "Faktury",
                "label": "Vyžadováno propojení požadavku na nákup."
            },
            "invoice-approval": {
                "group": "Faktury",
                "label": "Kdy by měla být faktura schválena?",
                "options": [
                    {
                        "value": "always",
                        "content": "<b>Vždy</b><br>Faktury musí být vždy schváleny."
                    },
                    {
                        "value": "if-no-order",
                        "content": "<b>Pouze pokud není přiřazena objednávka</b><br>Schválení je vyžadováno pouze tehdy, pokud neexistuje objednávka (PO)."
                    },
                    {
                        "value": "never",
                        "content": "<b>Nikdy</b><br>Faktury nevyžadují samostatné schválení."
                    }
                ]
            },
            "notifications-mail-interval": {
                "group": "Upozornění",
                "label": "Interval e-mailu v minutách",
                "tour": [
                    {
                        "target": ".step-notification",
                        "content": "Nastavte globální frekvenci pro e-mailová upozornění na nové úkoly. E-mailová upozornění jsou odesílána pouze tehdy, když osoba obdrží nový úkol."
                    }
                ]
            },
            "product-default-tax": {
                "group": "Produkt",
                "label": "Standardní daňová sazba (%)"
            },
            "product-default-currency": {
                "group": "Produkt",
                "label": "Výchozí měna"
            },
            "product-default-unit": {
                "group": "Produkt",
                "label": "Výchozí jednotka"
            },
            "punchout-amazon-identity": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Amazon",
                "label": "Přihlašovací jméno",
                "tour": [
                    {
                        "target": ".step-punchout-amazon",
                        "content": "Zde si můžete nastavit svůj přístup k integrovaným trhům, jako je Amazon a Mercateo. Pokyny naleznete <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">v naší příručce</a>."
                    }
                ]
            },
            "punchout-amazon-secret": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Amazon",
                "label": "Heslo"
            },
            "punchout-amazon-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Amazon",
                "label": "URL integrovaného systému"
            },
            "punchout-amazon-order-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Amazon",
                "label": "Adresa URL objednávky"
            },
            "punchout-amazon-email": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Amazon",
                "label": "E-mail",
                "hint": "E-mailová adresa hlavního účtu Amazon. <br>Chcete-li odeslat e-mailovou adresu přihlášené osoby, ponechte prázdné. <br>Může vyžadovat účet Amazon na osobu."
            },
            "punchout-amazon-supplier": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Amazon",
                "label": "Dodavatel",
                "hint": "Kterému dodavateli by měly být přiřazeny objednávky od tohoto poskytovatele Punch-out (např. ve zprávách)?"
            },
            "punchout-mercateo-identity": {
                "group": "Integrované systémy",
                "subgroup": "Punch-out Mercateo",
                "label": "Přihlašovací jméno"
            },
            "punchout-mercateo-secret": {
                "group": "Integrované systémy",
                "subgroup": "Punch-out Mercateo",
                "label": "Heslo"
            },
            "punchout-mercateo-url": {
                "group": "Integrované systémy",
                "subgroup": "Punch-out Mercateo",
                "label": "URL integrovaného systému"
            },
            "punchout-mercateo-order-url": {
                "group": "Integrované systémy",
                "subgroup": "Punch-out Mercateo",
                "label": "Adresa URL objednávky"
            },
            "punchout-mercateo-email": {
                "group": "Integrované systémy",
                "subgroup": "Punch-out Mercateo",
                "label": "E-mail",
                "hint": "E-mailová adresa hlavního účtu Mercateo. <br>Chcete-li odeslat e-mailovou adresu přihlášené osoby, ponechte prázdné. <br>Může vyžadovat účet Mercateo na osobu."
            },
            "punchout-mercateo-supplier": {
                "group": "Integrované systémy",
                "subgroup": "Punch-out Mercateo",
                "label": "Dodavatel",
                "hint": "Kterému dodavateli by měly být přiřazeny objednávky od tohoto poskytovatele Punch-out (např. ve zprávách)?"
            },
            "punchout-rajapack-identity": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážecí RAJA",
                "label": "Přihlašovací jméno"
            },
            "punchout-rajapack-secret": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážecí RAJA",
                "label": "Heslo"
            },
            "punchout-rajapack-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážecí RAJA",
                "label": "URL integrovaného systému"
            },
            "punchout-rajapack-order-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážecí RAJA",
                "label": "Adresa URL objednávky"
            },
            "punchout-rajapack-email": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážecí RAJA",
                "label": "E-mail"
            },
            "punchout-rajapack-supplier": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážecí RAJA",
                "label": "Dodavatel",
                "hint": "Kterému dodavateli by měly být přiřazeny objednávky od tohoto poskytovatele Punch-out (např. ve zprávách)?"
            },
            "punchout-conrad-identity": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Conrad",
                "label": "Přihlašovací jméno"
            },
            "punchout-conrad-secret": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Conrad",
                "label": "Heslo"
            },
            "punchout-conrad-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Conrad",
                "label": "URL integrovaného systému"
            },
            "punchout-conrad-order-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Conrad",
                "label": "Adresa URL objednávky"
            },
            "punchout-conrad-email": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Conrad",
                "label": "E-mail"
            },
            "punchout-conrad-supplier": {
                "group": "Integrované systémy",
                "subgroup": "Vyražený Conrad",
                "label": "Dodavatel",
                "hint": "Kterému dodavateli by měly být přiřazeny objednávky od tohoto poskytovatele Punch-out (např. ve zprávách)?"
            },
            "punchout-thgeyer-identity": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka TH.GEYER",
                "label": "Přihlašovací jméno"
            },
            "punchout-thgeyer-secret": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka TH.GEYER",
                "label": "Heslo"
            },
            "punchout-thgeyer-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka TH.GEYER",
                "label": "URL integrovaného systému"
            },
            "punchout-thgeyer-supplier": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka TH.GEYER",
                "label": "Výchozí dodavatelská společnost",
                "hint": "Kterému dodavateli by měly být přiřazeny objednávky od tohoto poskytovatele Punch-out (např. ve zprávách)?"
            },
            "punchout-reichelt-identity": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka Reichelt",
                "label": "Přihlašovací jméno"
            },
            "punchout-reichelt-secret": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka Reichelt",
                "label": "Heslo"
            },
            "punchout-reichelt-url": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka Reichelt",
                "label": "URL integrovaného systému"
            },
            "punchout-reichelt-supplier": {
                "group": "Integrované systémy",
                "subgroup": "Vyrážečka Reichelt",
                "label": "Výchozí dodavatelská společnost",
                "hint": "Kterému dodavateli by měly být přiřazeny objednávky od tohoto poskytovatele Punch-out (např. ve zprávách)?"
            },
            "tour": [
                {
                    "target": "",
                    "content": ""
                }
            ]
        },
        "tour": {
            "tab_general": "Zde můžete zadat název vaší společnosti pro prostředí výtahové základny.",
            "tab_contact": "Zde zadejte kontaktní údaje vaší společnosti. Tato data budou použita pro zasílání a doručování faktur za použití liftbase.",
            "tab_bank": "Zde můžete nastavit SEPA inkaso pro vyúčtování liftbase nebo jej zrušit.",
            "tab_datev": "Zde můžete nastavit integraci s DATEV. Nejprve se musíte přihlásit do DATEV, abyste načetli všechny dostupné klienty. Poté můžete tyto klienty propojit se společnostmi registrovanými zde v liftbase. Dále musíte každého klienta jednotlivě připojit k DATEV, abyste pro něj mohli přenášet exporty. Toto připojení má dobu platnosti 2 roky a prodlužuje se pokaždé, když je použito pro export.",
            "tab_marketplaces": "Zde můžete nastavit svůj přístup k integrovaným tržištím, jako jsou Amazon a Mercateo. Návod najdete <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">v našem manuálu</a>."
        }
    },
    "error_title": "Chyba",
    "request_error": "Chyba při provádění požadavku. Zkontrolujte prosím své internetové připojení nebo to zkuste znovu později.",
    "saved_success": "Dokument byl úspěšně uložen.",
    "save_error": "Při ukládání dokumentu došlo k chybě.",
    "help_page": {
        "title": "Pomoc",
        "popup_feedback_msg": "Váš prohlížeč je správně nastaven. Nyní můžete toto okno zavřít.",
        "punchout_error_msg": "Vyskytla se chyba. Zkontrolujte prosím nastavení Punch-out nebo kontaktujte osobu s administrátorskými právy.",
        "popup_did_not_open": "Pokud se neotevře žádné nové okno, vyskakovací okna jsou ve vašem prohlížeči zablokována. Během používání se proto mohou objevit omezení.",
        "test_email_msg": "Měl by se otevřít e-mailový program nebo seznam e-mailových programů. V případě potřeby nastavte e-mailový program ze seznamu.",
        "tour_restart": "Prohlídka byla znovu zahájena.",
        "manual_title": "Manuál",
        "manual_msg": "<p>V příručce najdete různé články a návody o použití a nastavení výtahové základny.</p>",
        "manual_open_btn": "Otevřete manuál",
        "function_title": "Testovací funkce",
        "popup_msg": "<p>Zde si můžete vyzkoušet, zda jsou ve vašem prohlížeči povolena vyskakovací okna, která jsou vyžadována pro váš výběr produktů na externích tržištích, jako je Amazon a Mercateo.</p>",
        "popup_test_btn": "Testování blokování vyskakovacích oken",
        "email_msg": "<p>Zde si můžete vyzkoušet, zda můžete otevřít e-maily pomocí e-mailového programu. Funkce e-mailu se používá například pro odesílání objednávek doručovacím společnostem.</p>",
        "email_test_btn": "Test funkce e-mailu",
        "tour_title": "Prohlídka",
        "tour_msg": "<p>Chcete-li znovu představit výtahovou základnu, můžete prohlídku restartovat zde.</p>",
        "tour_btn": "Restartovat prohlídku"
    },
    "dashboard_page": {
        "title": "Ukazatele",
        "no_option": "Žádná",
        "date_picker": {
            "start_placeholder": "Datum zahájení",
            "end_placeholder": "Datum zahájení",
            "separator": "na"
        },
        "ordered_by": "Objednal",
        "count_by_status": "Počet podle stavu",
        "diagrams": {
            "title": "Grafy",
            "count_by_status": "Počet podle stavu",
            "value_by_status": "Hodnoty podle stavu (netto v EUR)",
            "count_by_department": "Počet za oddělení",
            "value_by_department": "Hodnoty na oddělení (netto v EUR)",
            "count_by_cost_center": "Počítat na nákladové středisko",
            "value_by_cost_center": "Hodnoty na nákladové středisko (netto v EUR)",
            "count_by_ledger_account": "Počet za hlavní účet",
            "value_by_ledger_account": "Částky podle hlavního účtu (netto v EUR)"
        },
        "budgets": {
            "title": "Rozpočty a výdaje",
            "approved_status": "Schváleno",
            "in_approval_status": "Schvaluje se",
            "open_status": "Otevřené",
            "no_budget": "Bez rozpočtu",
            "from": "Z",
            "no_name": "Bez jména"
        },
        "products": {
            "title": "produkty",
            "approved_status": "Schváleno",
            "in_approval_status": "Schvaluje se"
        },
        "no_data": "Nebyly nalezeny žádné nákupní objednávky.",
        "no_permission": "Nemáte oprávnění pro řídicí panel.",
        "from_budget": "z rozpočtu",
        "data": "Výdaje",
        "shortcuts": {
            "this_week": "Tento týden",
            "last_week": "Minulý týden",
            "this_month": "Tento měsíc",
            "last_month": "Minulý měsíc",
            "last_3_months": "Poslední 3 měsíce",
            "last_6_months": "Posledních 6 měsíců",
            "this_year": "Tento rok",
            "last_year": "Minulý rok"
        },
        "tour": {
            "title": "Řídicí panel vám nabízí statistiky a funkce hodnocení pro vaše nákupní objednávky.",
            "filters_title": "Filtr",
            "filters": "Nejprve vyberte požadované období a poté filtrujte nákupní objednávky podle společnosti, objednávky, nákladového střediska nebo oddělení.",
            "count_by_status_title": "Počet podle stavu",
            "count_by_status": "Ukazuje, kolik z předfiltrovaných nákupních objednávek je aktuálně v příslušných stavech.",
            "graphic_title": "Grafy",
            "graphic": "Toto je grafické znázornění počtu nebo množství nákupních objednávek, seskupených podle stavu, oddělení nebo nákladových středisek.",
            "budget_title": "Rozpočty a výdaje",
            "budget": "Tato oblast vám poskytuje přehled o rozpočtech a výdajích vašich nákladových středisek. Barevné pruhy ukazují využití rozpočtů a kolik výdajů je ještě ve schválení.",
            "progress_bar_cost_center_title": "Ukazatele pokroku podle nákladového střediska",
            "progress_bar_cost_center": "Zde můžete rozvinout podrobnější přehled různých rozpočtů nákladového střediska. U jednotlivých rozpočtů lze také uvést zadané objednávky. Výdaje bez rozpočtu jsou uvedeny pod samostatnou lištou."
        }
    },
    "overview_page": {
        "title": "Přehled",
        "procurement_abbr": "PO",
        "invoice_abbr": "IN",
        "draft_abbr": "DR",
        "delivery_note_abbr": "DN",
        "all_docs": "Všechny dokumenty",
        "my_tasks": "Moje úkoly",
        "procurements": "Nákupní objednávky",
        "invoices": "Faktury",
        "drafts": "Pracovní verze",
        "no_selection": "Není vybrán žádný dokument.",
        "comment_box_placeholder": "Tvůj komentář...",
        "comment_box_btn": "Komentář",
        "edit_filename_title": "Upravit název souboru",
        "edit_filename_msg": "Zadejte nový název souboru. <br> Starý název: {filename}",
        "duplicate_invoice": {
            "message": "Duplicita - Byla nalezena nejméně jedna faktura se stejným číslem faktury:",
            "invoice_number": "Číslo faktury",
            "invoice_date": "Datum"
        },
        "task_list": {
            "name": "Jméno",
            "status": "Stav",
            "date": "datum",
            "draft": "Návrh"
        },
        "searchbar": {
            "search": "Hledat",
            "filter_by": "Filtrovat podle",
            "created_at": "Datum vzniku",
            "timeline": "Timeline",
            "last_used": "Naposledy použitý",
            "no_search": "Žádný",
            "timeline_props": {
                "editor_name": "Editor",
                "entry_type": "Krok časové osy",
                "current_editor_name": "Aktuální editor",
                "current_entry_type": "Aktuální krok"
            },
            "procurement_props": {
                "order_number_formatted": "Číslo objednávky",
                "name": "Jméno",
                "creator_name": "Vytvořeno",
                "client_name": "Společnost",
                "department_name": "Oddělení",
                "cost_center_name": "Název nákladového střediska",
                "cost_center_number": "Číslo nákladového střediska",
                "consignee_name": "Příjem zboží",
                "status": "Stav",
                "product_names": "Produkt",
                "product_numbers": "Číslo produktu",
                "supplier_names": "Dodavatel"
            },
            "invoice_props": {
                "invoice_number": "Číslo faktury",
                "invoice_number_formatted": "Interní LB číslo",
                "procurement_number": "Číslo objednávky",
                "creator_name": "Nahráno uživatelem",
                "client_name": "Společnost",
                "cost_center_name": "Název nákladového střediska",
                "cost_center_number": "Číslo nákladového střediska",
                "supplier_name": "Dodavatel",
                "ledger_account_name": "Výdajový účet",
                "status": "Stav",
                "product_names": "Produkt",
                "product_numbers": "Číslo produktu",
                "due_date": "Splatnost"
            },
            "time_ranges": {
                "today": "dnes",
                "yesterday": "včera",
                "past_7_days": "posledních 7 dní",
                "past_30_days": "posledních 30 dnů",
                "this_week": "tento týden",
                "last_week": "minulý týden",
                "this_month": "tento měsíc",
                "last_month": "minulý měsíc",
                "this_year": "tento rok",
                "last_year": "minulý rok"
            }
        },
        "export": {
            "all_information": "Všechny informace",
            "export_procurement": "Export nákupních objednávek",
            "export_documents": "Exportovat dokumenty",
            "registers": "záznamy",
            "export": "Vývozní",
            "to_export": "Exportovat",
            "export_with_products": "Export s produkty",
            "custom_export": "Nakonfigurovaný export",
            "select_fields": "Vyberte pole, která chcete exportovat.",
            "select_all": "Vybrat vše",
            "deselect_all": "Odebrat všechny",
            "general": "Obecné",
            "products": "produkty",
            "include_product": "Vč. produktů",
            "filter": "filtr",
            "no_filter": "žádný filtr",
            "labels": {
                "procurement": {
                    "order_number_formatted": "LB-číslo",
                    "name": "Jméno",
                    "creator_name": "Vytvořeno",
                    "status": "Stav",
                    "total_price_brutto": "Celkem-brutto",
                    "total_price_netto": "Total-Net",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Datum vzniku",
                    "client_number": "Číslo firmy",
                    "client_name": "Jméno společnosti",
                    "department_name": "Oddělení",
                    "cost_center_number": "Číslo nákladového střediska",
                    "cost_center_name": "Název nákladového střediska",
                    "consignee_name": "Příjem zboží",
                    "shipping_date": "Datum dodání",
                    "shipping_name": "Název dodací adresy",
                    "shipping_consignee": "Doručovací adresa jméno přijímající osoby",
                    "shipping_dropoff_location": "Název dodací adresy místa výdeje",
                    "shipping_address": "Dodací adresa ulice a č.p.",
                    "shipping_complement": "Dodací adresa ulice 2",
                    "shipping_country": "Země dodací adresy",
                    "shipping_zipcode": "Doručovací adresa PSČ",
                    "shipping_city": "Dodací adresa město",
                    "shipping_phone_country_code": "Tel. předvolba země dodací adresy",
                    "shipping_phone_number": "Telefonní číslo dodací adresy",
                    "billing_name": "Název fakturační adresy",
                    "billing_consignee": "Fakturační adresa jméno příjemce",
                    "billing_dropoff_location": "Název fakturační adresy místa výdeje",
                    "billing_address": "Fakturační adresa ulice a č.p.",
                    "billing_complement": "Ulice fakturační adresy 2",
                    "billing_country": "Země fakturační adresy",
                    "billing_zipcode": "Poštovní směrovací číslo fakturační adresy",
                    "billing_city": "Fakturační adresa město",
                    "billing_phone_country_code": "Tel. předvolba země fakturační adresy",
                    "billing_phone_number": "Telefonní číslo fakturační adresy"
                },
                "product": {
                    "product_name": "Jméno výrobku",
                    "product_number": "Číslo položky",
                    "product_supplier_name": "Jméno dodavatele",
                    "product_supplier_email": "Email dodavatele",
                    "product_cost_center_name": "Název nákladového střediska (produkt)",
                    "product_cost_center_number": "Číslo nákladového střediska (produkt)",
                    "product_tax": "Daň",
                    "product_quantity": "množství (produkt)",
                    "product_total_netto": "Celková čistá (produkt)",
                    "product_total_brutto": "Celkem brutto (produkt)",
                    "product_netto": "Jednotná cena netto",
                    "product_brutto": "Jednotná cena brutto",
                    "product_unit": "Jednotka",
                    "product_img_url": "Adresa URL obrázku",
                    "product_shop_url": "Adresa URL obchodu",
                    "product_shipping_name": "Název dodací adresy (produkt)",
                    "product_shipping_consignee": "Doručovací adresa jméno přijímající osoby (produkt)",
                    "product_shipping_dropoff_location": "Název dodací adresy místa odevzdání (pProdukt)",
                    "product_shipping_address": "Dodací adresa ulice a č.p. (produkt)",
                    "product_shipping_complement": "Dodací adresa ulice 2 (produkt)",
                    "product_shipping_country": "Země dodací adresy (produkt)",
                    "product_shipping_zipcode": "Dodací adresa PSČ (produkt)",
                    "product_shipping_city": "Dodací adresa město (produkt)",
                    "product_shipping_phone_country_code": "Tel. předvolba země dodací adresy (produkt)",
                    "product_shipping_phone_number": "Telefonní číslo dodací adresy (produkt)",
                    "product_received_at": "Poslední příjem zboží (datum)",
                    "product_received_by": "Poslední příjem zboží (osoba)"
                },
                "invoice": {
                    "invoice_number_formatted": "Číslo interní faktury",
                    "number": "Číslo faktury",
                    "creator_name": "Nahráno uživatelem",
                    "status": "Stav",
                    "procurement_name": "Nákupní objednávka",
                    "procurement_number": "Číslo objednávky",
                    "cost_center_name": "Název nákladového střediska",
                    "cost_center_number": "Číslo nákladového střediska",
                    "total_price_netto": "Celkem netto",
                    "total_price_brutto": "Celkem brutto",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Nahráno dne",
                    "client_name": "Společnost",
                    "client_number": "Číslo firmy",
                    "invoice_date": "Datum faktury",
                    "due_date": "Datum splatnosti",
                    "delivery_date": "Datum dodání",
                    "currency": "Měna",
                    "ledger_account_name": "Název účtu hlavní knihy",
                    "ledger_account_number": "Číslo účtu",
                    "posting_key_number": "Číslo účtovacího klíče",
                    "posting_key_tax": "Daň účtovacího klíče",
                    "supplier_name": "Dodavatel"
                }
            }
        },
        "actions": {
            "tour": {
                "procurement_reassignment_title": "Přiřadit/vymazat objednávku",
                "procurement_reassignment_msg": "Tento úkol je momentálně s jinou osobou. Toto tlačítko použijte k odeslání úkolu k vymazání nebo k přidělení tohoto úkolu třetí osobě nebo sobě. Při zúčtování lze také zrušit nákupní objednávky.",
                "invoice_reassignment_title": "Přiřadit/vymazat fakturu",
                "invoice_reassignment_msg": "Tento úkol je momentálně s jinou osobou. Toto tlačítko použijte k odeslání úkolu k vymazání nebo k přidělení tohoto úkolu třetí osobě nebo sobě. Při zúčtování lze zúčtovat i faktury."
            },
            "skip_confirm_dialog_title": "Zrušit rozpoznávání dokumentů",
            "skip_confirm_dialog_msg": "Chcete-li zkontrolovat, zda již existuje výsledek rozpoznání dokladu, obnovte stránku ve svém prohlížeči. <br>Opravdu chcete zrušit rozpoznávání údajů faktury a zadat údaje ručně?",
            "button_hint": {
                "to_approval": "Ke schválení",
                "to_send_order": "Objednat",
                "to_accounting": "Do účetnictví",
                "approve": "Schvalovat",
                "self_assign": "Přijmout",
                "finish_order": "Dokončete objednávku",
                "finish_delivery": "Dokončit",
                "skip_recognition": "Zrušit rozpoznávání dokumentů",
                "to_export": "Exportovat",
                "default": "Pokračovat",
                "cancel_or_assign_procurement": "Přiřadit/zrušit",
                "cancel_or_assign_invoice": "Přiřadit/zrušit",
                "clearing_or_assign": "Přiřadit/Objasněte"
            },
            "assignment_tooltip": "Objasnění nebo přiřazení",
            "assignment_dialog": {
                "title_procurement_assign_or_cancel": "Přiřazení nebo zrušení",
                "title_invoice_assign_or_cancel": "Přiřazení nebo zrušení",
                "title_assign": "Úkol",
                "title_clearing_or_assign": "Objasnění nebo přiřazení",
                "assign_title": "Přidělte aktuální úkol jiné osobě",
                "assign_msg": "Aktuálně přidělená osoba není k dispozici nebo není za tento úkol odpovědná, takže by tento úkol měla převzít jiná osoba.",
                "clearing_title": "Přiřazení pro objasnění",
                "clearing_msg": "Aktuálně přidělená osoba je k dispozici a odpovídá za tento úkol, ale proces nemůže pokračovat z jiných důvodů. Rádi byste proto zahájili objasňovací proces.",
                "cancel_procurement_title": "Zrušení",
                "cancel_procurement_msg": "Objednávka je nenávratně stornována a nelze ji dále zpracovávat. Zrušené objednávky naleznete v přehledu pod statusem 'Odmítnuto'.",
                "cancel_invoice_title": "Probíhá zrušení",
                "cancel_invoice_msg": "Faktura je nenávratně smazána a nelze ji dále zpracovávat. Vyúčtované faktury naleznete v přehledu všech dokladů pod stavem 'Pročištěno'.",
                "assign_to": "Přiřadit",
                "reason": "Důvod",
                "clearing_footer_info": "Aktuální úkol je zrušen. Vybraná osoba dostane nový úkol k objasnění.",
                "reassignment_footer_info": "Změní se pouze přiřazená osoba aktuálního úkolu.",
                "cancel_invoice_btn": "Zrušit fakturu",
                "cancel_procurement_btn": "Zrušit objednávku",
                "assign_btn": "Přiřadit",
                "clearing_dialog": {
                    "title": "Předběžné přidělení objasnění",
                    "procurement_message": "Osoba v kroku „objasnění“ vyplývá z různých výchozích hodnot odpovědných osob podle oddělení, společnosti, výchozí globální role a osoby, která objednávku vytvořila. Osoba, která je na začátku seznamu, je předem přiřazena k úkolu.",
                    "invoice_message": "Osoba v kroku „objasnění“ vyplývá z různých výchozích hodnot odpovědných osob za oddělení, společnost, výchozí globální role a osoby, která nahrála fakturu. Osoba, která je na začátku seznamu, je předem přiřazena k úkolu.",
                    "department_form": "Oddělení ve formě",
                    "department_creator": "Oddělení objednané osoby",
                    "client_form": "Společnost ve formě",
                    "client_creator": "Společnost objednané osoby",
                    "no_selection": "ještě nevybráno",
                    "no_action": "nelze použít"
                },
                "cancellation_dialog": {
                    "title_procurement": "Zrušení",
                    "btn_procurement": "Zrušit objednávku",
                    "message_procurement": "Opravdu chcete zrušit tuto objednávku?",
                    "title_invoice": "Probíhá zrušení",
                    "btn_invoice": "Zrušit fakturu",
                    "message_invoice": "Opravdu chcete zrušit tuto fakturu?"
                }
            }
        },
        "tour": {
            "overview": {
                "search_box": "Pomocí vyhledávání můžete filtrovat nákupní objednávky pod \"Moje úkoly\" a \"Všechny dokumenty\" a vyhledávat různé informace pro rychlejší nalezení konkrétních objednávek.",
                "tab_tasks": "Zde můžete vidět nákupní objednávky, za které aktuálně odpovídáte. V pravém sloupci vidíte stav svých úkolů.",
                "tab_all_procurements": "V této stromové struktuře naleznete všechny objednávky seřazené podle aktuálního stavu.",
                "refresh_btn": "Zde si můžete aktualizovat přehled objednávek.",
                "type_filter": "Zde můžete filtrovat podle typu úkolu."
            },
            "procurement": {
                "form_title": "Formulář nákupní objednávky",
                "form": "Zde můžete vidět informace o objednávce.",
                "approve_btn_title": "Přepošlete objednávku",
                "approve_btn": "Když dokončíte úkol a chcete objednávku předat další odpovědné osobě nebo v dalším kroku, stiskněte modré zaškrtnutí.",
                "reassign_btn": "Pokud nechcete úkol dokončit, protože např. stále přetrvávají nejasnosti nebo by ve zpracování úkolu měla pokračovat jiná osoba, můžete stisknout červené tlačítko. Úkol pak můžete buď předat, nebo jej někomu přidělit k vymazání. Během procesu zúčtování lze také zrušit nákupní objednávky.",
                "add_comment": "Prostřednictvím pole komentáře můžete k objednávce přidat komentář. To se pak zobrazí na časové ose.",
                "timeline_title": "Timeline",
                "timeline": "Časová osa zobrazuje minulý a budoucí průběh objednávky a také aktuální stav (modrá) a komentáře k objednávce. Pořadí řazení lze obrátit pomocí ikony ozubeného kola.",
                "timeline_preferences": "Prostřednictvím tohoto tlačítka lze provést nastavení zobrazení na časové ose. Lze například nastavit, zda se mají události řadit vzestupně nebo sestupně a zda mají být změny v objednávce skryty nebo zobrazeny."
            },
            "invoice": {
                "form_title": "Formulář faktury",
                "form": "Zde můžete zadat nebo upravit informace pro fakturu.",
                "add_line": "Zde můžete přidat řádkovou položku faktury.",
                "edit_lines": "Zde můžete upravit informace o řádkových položkách faktury.",
                "approve_btn_title": "Přeposlat fakturu",
                "approve_btn": "Jakmile dokončíte úkol a chcete přeposlat fakturu další odpovědné osobě nebo dalšímu kroku, můžete stisknout modré zaškrtnutí.",
                "reassign_btn": "Pokud nechcete úkol dokončit, například proto, že existuje problém k vyřešení nebo pokud by na úkolu měla pokračovat jiná osoba, můžete stisknout červené tlačítko. Poté můžete úkol buď přidělit někomu jinému, nebo jej přidělit někomu k vymazání. Během procesu zúčtování lze také upravovat faktury.",
                "add_comment": "Přes pole komentáře můžete k faktuře přidat komentář. To se pak zobrazí na časové ose.",
                "timeline_title": "Timeline",
                "timeline": "Časová osa zobrazuje minulý a budoucí průběh faktury a také aktuální stav (modrá) a komentáře k faktuře. Pořadí řazení lze obrátit pomocí ikony ozubeného kola.",
                "timeline_preferences": "Prostřednictvím tohoto tlačítka lze provést nastavení zobrazení na časové ose. Zde lze nastavit, zda se mají události řadit vzestupně nebo sestupně a zda se mají úpravy faktury skrýt nebo zobrazit."
            },
            "welcome": {
                "logo_title": "Vítejte na liftbase!",
                "logo": "<p>Abychom vám usnadnili začátek, vysvětlíme vám nejdůležitější věci na prohlídce základny výtahu.</p> <p>Procházku procházejte pomocí tlačítek níže nebo kláves se šipkami.</p>",
                "menu": "V pravém horním rohu je hlavní menu, přes které najdete všechny důležité oblasti výtahové základny.",
                "menu_help": "Jednotlivé části prohlídky můžete kdykoli ukončit a později v nabídce nápovědy znovu spustit.",
                "menu_admin": "Zde najdete další část prohlídky."
            }
        }
    },
    "operator_area": {
        "title": "Operátor",
        "tenant_menu": "Nájemníci",
        "procurement_menu": "Nákupní objednávky",
        "invoice_menu": "Faktury",
        "no_permission": "K zobrazení této oblasti nemáte oprávnění.",
        "tenant": {
            "description": "Nájemce",
            "new": "Nový nájemník",
            "edit": "Upravit",
            "archive": "Archiv",
            "delete": "Vymazat",
            "activate": "Obnovit",
            "procurement_count": "OB",
            "user_count": "Uživatel",
            "automatic_billing": "Automatické vyúčtování",
            "tabs": {
                "general": "Obecné",
                "invoice_config": "Fakturace"
            },
            "standard_package": "Standardní balíček",
            "invoice": {
                "items": {
                    "label": "Další položky",
                    "description": "Popis",
                    "price": "Čistá částka",
                    "start_date": "Datum zahájení",
                    "end_date": "Datum ukončení"
                },
                "tiered_price": {
                    "label": "Stupňovité ceny",
                    "unit_price": "Jednotková cena",
                    "base_price": "Základní cena",
                    "doc_amount": "Množství"
                }
            },
            "contact_email_tooltip": "Nájemce se může zpočátku přihlásit pomocí tohoto e-mailu.",
            "no_bank_info": "Data zatím nejsou k dispozici a může je vytvořit pouze nájemce.",
            "no_connection_with_easybill": "Tento nájemce ještě není spojen s Easybill. Zaregistrujte si ID nájemce v systému Easybill.",
            "sync_with_easybill": "Získejte všechna data z Easybill",
            "update_from_easybill_btn": "Načíst z Easybill",
            "update_from_easybill_tooltip": "Získejte nájemce z Easybill",
            "send_to_easybill_btn": "Vytvořte v Easybill",
            "send_to_easybill_tooltip": "Vytvořte tenanta v Easybill",
            "create_and_send_invoice_tooltip": "Vytvořte fakturu v Easybill a odešlete ji zákazníkovi",
            "create_and_send_invoice_btn": "Vytvořit/odeslat fakturu",
            "open_invoices": "Zobrazit faktury",
            "delete_procurements": "Smazat objednávku",
            "delete_invoices": "Smazat faktury",
            "confirm_before_delete_msg": "<p>Opravdu chcete tohoto tenanta a všechna jeho data trvale smazat z databáze?</p><p> <i>Tuto akci nelze vrátit zpět.</i></p><p><b >Zadejte jméno nájemce:</b></p>",
            "confirm_before_delete_error_msg": "Tenant nebyl odstraněn, protože jméno bylo zadáno nesprávně.",
            "deleted_msg": "Nájemník byl trvale smazán.",
            "updated_msg": "Byla aktualizována.",
            "created_msg": "Bylo vytvořeno.",
            "sync_msg_error": "Tento nájemce ještě není spojen s Easybill. Zaregistrujte si ID nájemce v systému Easybill.",
            "create_invoice_dialog": {
                "title": "Vytvořit fakturaci",
                "message": "Vyberte fakturační období.",
                "date_placeholder": "Vyberte měsíc.",
                "create_btn": "Vytvořit"
            },
            "validation": {
                "name_required": "Zadejte jméno.",
                "name_exists": "Název již existuje.",
                "email_required": "Zadejte prosím kontaktní e-mail.",
                "email_invalid": "Prosím zadejte platný email."
            }
        },
        "procurement": {
            "title": "Nákupní objednávky od nájemce",
            "delete_title": "Smazat objednávku",
            "result_found": "Hity",
            "delete_result_found": "Smazat hity",
            "start_date": "Datum zahájení",
            "end_date": "Datum ukončení",
            "range_separator": "na",
            "confirm_delete_msg": "Opravdu chcete trvale odstranit vybrané nákupní objednávky z databáze?",
            "confirm_delete_title": "Smazat objednávku",
            "confirm_delete_btn": "Vymazat"
        },
        "invoice": {
            "title": "Faktury od nájemce",
            "delete_title": "Smazat faktury",
            "result_found": "Hity",
            "delete_result_found": "Smazat hity",
            "start_date": "Datum zahájení",
            "end_date": "Datum ukončení",
            "range_separator": "na",
            "confirm_delete_msg": "Opravdu chcete trvale smazat vybrané faktury z databáze?",
            "confirm_delete_title": "Smazat fakturu",
            "confirm_delete_btn": "Vymazat"
        }
    },
    "timeline": {
        "title": "Timeline",
        "preferences": {
            "order_asc": "Nejdříve nejstarší",
            "order_desc": "Nejdříve nejnovější"
        },
        "text": {
            "description_assignment": "Úkol byl přijat.",
            "cancellation": {
                "name": "{name}",
                "description_past_successful_invoice": "Faktura byla vyřazena a archivována.",
                "description_past_successful_procurement": "Objednávka byla zrušena a archivována.",
                "name_procurement": "Zrušení",
                "name_invoice": "Vyřazení"
            },
            "start": {
                "name": "Nová objednávka",
                "description_past_successful": "Byla vytvořena nová nákupní objednávka."
            },
            "select-product": {
                "is_searchable": true,
                "simple_name": "Výběr produktu",
                "name": "Výběr produktu (fáze {index})",
                "description_past_unsuccessful": "Výběr produktu byl zrušen.",
                "description_past_successful": "Produkty pro tuto objednávku byly vybrány.",
                "description_present": "Vyberte prosím produkty pro tuto objednávku.",
                "description_future": "Je třeba vybrat produkty pro tuto objednávku."
            },
            "approval": {
                "is_searchable": true,
                "simple_name": "Schválení",
                "name": "Schválení (etapa {index})",
                "description_past_unsuccessful": "Schválení objednávky bylo zrušeno.",
                "description_past_successful": "Objednávka byla zkontrolována a schválena.",
                "description_present": "Zkontrolujte a schvalte objednávku.",
                "description_future": "Objednávku je třeba zkontrolovat a schválit."
            },
            "send-order": {
                "is_searchable": true,
                "name": "Umístění objednávky",
                "description_past_unsuccessful": "Zadání objednávky bylo zrušeno.",
                "description_past_successful": "Objednávka byla podána u dodavatele.",
                "description_present": "Objednávejte prosím u dodavatele.",
                "description_future": "Objednávku je nutné zadat u dodavatele."
            },
            "delivery": {
                "is_searchable": true,
                "name": "Potvrzení o doručení",
                "description_past_unsuccessful": "Potvrzení o převzetí zboží bylo zrušeno.",
                "description_past_successful": "Zboží bylo doručeno a převzetí potvrzeno.",
                "description_present": "Prosím o potvrzení převzetí zboží.",
                "description_future": "Převzetí zboží je nutné potvrdit."
            },
            "reassignment": {
                "name": "Úkol",
                "description_past_unsuccessful": "",
                "description_past_successful": "přidělil úkol uživateli {name}.",
                "description_past_successful_from_group": "přijal úkol."
            },
            "clearing": {
                "is_searchable": true,
                "name": "Objasnění",
                "description_past_unsuccessful": "Objasnění bylo zrušeno.",
                "description_past_successful": "Nejistoty byly odstraněny.",
                "description_present": "Prosím o vyjasnění nejasností ohledně objednávky.",
                "description_finished_unsuccessful": "Objednávka byla zrušena a archivována."
            },
            "order-sent-internal": {
                "name": "Systémová zpráva",
                "description_past_successful": "Objednávka <b>({first_product_name})</b> u dodavatele <b>{supplier}</b> byla zadána a potvrzena."
            },
            "order-sent-punchout": {
                "name": "Systémová zpráva",
                "description_past_successful": "Objednávka <b>({first_product_name})</b> byla odeslána elektronicky dodavateli <b>{supplier}</b>."
            },
            "product-received": {
                "name": "Systémová zpráva",
                "description_past_successful": "<b>{quantity} {unit}</b> přijato:<p>{product}</p><span style=\"color:#9a9898\">{supplier}</span>"
            },
            "invoice-upload": {
                "name": "Nová faktura",
                "description_past_successful": "Faktura byla nahrána."
            },
            "invoice-recognition": {
                "is_searchable": true,
                "name": "Rozpoznávání faktur",
                "description_past_unsuccessful": "Uznávání fakturačních údajů bylo zrušeno.",
                "description_past_successful": "Rozpoznávání fakturačních údajů bylo dokončeno.",
                "description_present": "Údaje o faktuře se načítají. Tento proces může trvat až minutu. Obnovte stránku po této době, abyste viděli výsledky."
            },
            "invoice-manual-check": {
                "is_searchable": true,
                "name": "Ruční kontrola",
                "description_past_unsuccessful": "Ruční kontrola nebyla dokončena.",
                "description_past_successful": "Faktura byla ručně zkontrolována.",
                "description_present": "Zkontrolujte prosím údaje na faktuře.",
                "description_future": "Faktura musí být ručně zkontrolována."
            },
            "invoice-financial-approval": {
                "is_searchable": true,
                "simple_name": "Schválení faktury",
                "name": "Schválení faktury (Fáze {index})",
                "description_past_unsuccessful": "Schválení faktury bylo zrušeno.",
                "description_past_successful": "Faktura byla zkontrolována a schválena.",
                "description_present": "Prosím, zkontrolujte a schvalte fakturu.",
                "description_future": "Faktura musí být zkontrolována a schválena."
            },
            "invoice-clearing": {
                "is_searchable": true,
                "name": "Objasnění účetnictví",
                "description_past_unsuccessful": "Objasnění bylo zrušeno.",
                "description_past_successful": "Nejistoty faktur byly odstraněny.",
                "description_present": "Vyjasněte prosím nejasnosti týkající se faktury.",
                "description_finished_unsuccessful": "Faktura byla stornována a archivována."
            },
            "invoice-export": {
                "is_searchable": true,
                "name": "Vývozní",
                "description_past_unsuccessful": "Faktura nebyla exportována.",
                "description_past_successful": "Faktura byla odeslána společnosti {export_name}.",
                "description_present": "Faktura je připravena k exportu.",
                "description_future": "Faktura je připravena k exportu."
            },
            "invoice-accounting": {
                "is_searchable": true,
                "name": "Účetnictví",
                "description_past_unsuccessful": "Účetnictví bylo zastaveno.",
                "description_past_successful": "Účetnictví bylo ověřeno.",
                "description_present": "Prosím zkontrolujte účetnictví.",
                "description_future": "Účetnictví musí být ověřeno."
            },
            "skip": {
                "name": "Zrušení",
                "description_past_successful": "zrušil krok."
            }
        }
    },
    "jexcel": {
        "noRecordsFound": "Nebyly nalezeny žádné záznamy.",
        "showingPage": "Stránka {0} z {1}",
        "show": "Ukázat",
        "entries": "záznamy",
        "insertANewColumnBefore": "Před vložením sloupce",
        "insertANewColumnAfter": "Vložte sloupec za",
        "deleteSelectedColumns": "Smazat vybrané sloupce",
        "renameThisColumn": "Přejmenujte tento sloupec",
        "orderAscending": "Seřadit vzestupně",
        "orderDescending": "Seřadit sestupně",
        "insertANewRowBefore": "Vložit řádek před",
        "insertANewRowAfter": "Vložte řádek za",
        "deleteSelectedRows": "Smazat vybrané řádky",
        "copy": "kopírovat",
        "paste": "Vložit",
        "saveAs": "Uložit jako",
        "about": "O",
        "areYouSureToDeleteTheSelectedRows": "Opravdu chcete smazat vybrané řádky?",
        "areYouSureToDeleteTheSelectedColumns": "Opravdu chcete smazat vybrané sloupce?",
        "thisActionWillDestroyAnyExistingMergedCellsAreYouSure": "Tato akce zničí všechny existující sloučené buňky. Jsi si jistá?",
        "thisActionWillClearYourSearchResultsAreYouSure": "Tato akce vymaže výsledky vyhledávání. Jsi si jistá?",
        "thereIsAConflictWithAnotherMergedCell": "Došlo ke konfliktu s jinou sloučenou buňkou",
        "invalidMergeProperties": "Neplatné sloučené vlastnosti",
        "cellAlreadyMerged": "Buňka již byla sloučena",
        "noCellsSelected": "Není vybrána žádná buňka",
        "save_warning": "Kopírování dat mezi Excelem a webem může vést k chybnému pořadí a ztrátě ID vztahů. <br>Prosím, používejte pouze online úpravy nebo přidávejte data z Excelu pouze jako doplněk."
    },
    "system_comment": {
        "name": {
            "allocation": "Přidělení",
            "datev_export": "Export DATEV",
            "export_error": "Chyba exportu",
            "export_detail": "Export protokolu",
            "repeat_recognition": "Zopakovat rozpoznání faktury"
        },
        "recognition_was_repeated": "{user_name} zopakoval(a) rozpoznání dokumentu.",
        "attach_new_invoice_to_procurement": "Faktura <b>{link_to_invoice}</b> od <b>{supplier_name}</b> byla přiřazena k této objednávce uživatelem <b>{user_name}</b>.",
        "attach_new_invoice_to_procurement_without_supplier": "Faktura <b>{link_to_invoice}</b> byla přiřazena k této objednávce uživatelem <b>{user_name}</b>.",
        "change_procurement_of_invoice": "Přidělení faktury <b>{link_to_invoice}</b> od <b>{supplier_name}</b> bylo odstraněno. <br>Faktura byla přiřazena k objednávce {link_to_procurement}.",
        "change_procurement_of_invoice_without_supplier": "Přidělení faktury <b>{link_to_invoice}</b> bylo odstraněno. <br>Faktura byla přiřazena k objednávce {link_to_procurement}.",
        "detach_procurement_of_invoice": "Přidělení faktury <b>{link_to_invoice}</b> od <b>{supplier_name}</b> bylo odstraněno.",
        "detach_procurement_of_invoice_without_supplier": "Přidělení faktury <b>{link_to_invoice}</b> bylo odstraněno.",
        "attach_external_invoice_to_procurement": "Faktura {invoice_number} od <b>{supplier_name}</b> byla přiřazena k této objednávce uživatelem <b>{invoice_by}</b>.",
        "insert_datev_note": "Faktura <b>{link_to_invoice}</b> od <b>{supplier_name}</b> byla odeslána do DATEV.",
        "insert_datev_note_without_supplier": "Faktura <b>{link_to_invoice}</b> byla odeslána DATEV.",
        "on_ms365_export_error_generic": "Chyba při exportu faktury do D365. Kontaktujte prosím podporu.",
        "on_ms365_export_validation_error": "Chyba při exportu faktury do D365. Kontaktujte prosím podporu. Chyba: {error}",
        "on_export_error": "Fakturu nebylo možné odeslat do DATEV. {error}",
        "on_export_error_generic": "Chyba při exportu faktury do DATEV. Kontaktujte prosím podporu.",
        "on_export_error_validation": "{supplier_external_id_export_validation}{supplier_name_export_validation} Obraťte se prosím na někoho s administrátorskými právy, aby zkontroloval nastavení.",
        "on_export_error_datev_access_is_not_active": "Musí být vytvořeno spojení s DATEV. Kontaktujte někoho s právy správce.",
        "on_export_error_client_has_no_datev_access": "Společnost {client_name} neuložila žádná přístupová data DATEV. Kontaktujte někoho s právy správce.",
        "on_export_error_client_has_no_datev_permission": "Při přenosu do DATEV došlo k chybě. Založený účet DATEV nemá oprávnění pro společnost {client_name}. Obraťte se na někoho s administrátorskými právy, aby zkontroloval přístup, firemní údaje nebo oprávnění v rámci DATEV.",
        "on_export_error_no_fiscal_year": "Při přenosu do DATEV došlo k chybě. Datum faktury není ve fiskálním roce DATEV. Chcete-li zkontrolovat fiskální rok v DATEV, kontaktujte někoho s právy správce.",
        "on_export_error_datev_has_protocol_entries": "Chyba při exportu faktury do DATEV. Zkontrolujte exportní protokol nebo kontaktujte podporu.",
        "on_export_error_invoice_not_active_for_fiscal_year": "Při přenosu do DATEV došlo k chybě. Fiskální rok DATEV není uvolněn pro přenos došlých faktur. Chcete-li zkontrolovat fiskální rok v DATEV, kontaktujte někoho s právy správce.",
        "insert_datev_protocol_note": "<b>Stav</b>: {status} <br><br> <b>Položky protokolu</b>: <br> {protocol}",
        "labels": {
            "invoice_number_label": "Číslo faktury",
            "procurement_number_label": "Číslo objednávky",
            "invoice_date_label": "Datum faktury",
            "invoice_due_date_label": "Datum splatnosti",
            "supplier_label": "Dodavatel",
            "client_label": "Společnost",
            "total_netto_label": "Celkem netto",
            "total_brutto_label": "Celkem brutto",
            "tax_label": "Sazba daně",
            "positions_count_label": "pozice",
            "no_data_read_label": "Nebyla rozpoznána žádná data.",
            "supplier_external_id_export_validation": "Externí ID dodavatele musí být uvedeno v oblasti administrace.",
            "supplier_name_export_validation": "Jméno dodavatele musí být uvedeno v oblasti administrace.",
            "recognition_was_repeated": "{user_name} zopakoval(a) rozpoznání dokumentu.",
        }
    },
    "login": {
        "email": {
            "title": "Přihlaste se pomocí e-mailu",
            "instructions": "Prosím zadejte svou e-mailovou adresu. Poté klikněte na přihlašovací odkaz v e-mailu.",
            "punchin_instructions": "Pro dokončení procesu je třeba se přihlásit a zahájit proces schvalování objednávky.",
            "tenant": "Nájemce",
            "place_holder": "Emailová adresa",
            "last_used_emails": "Nedávno použité e-mailové adresy",
            "delete_last_used_emails": "Smazat \"Nedávno použité\"",
            "has_no_account": "Ještě nemáte účet?",
            "login_link_via_email": "Obdržet přihlašovací odkaz e-mail",
            "finish_login_title": "Dokončete přihlášení",
            "finish_login_instructions": "Nyní obdržíte e-mail s odkazem pro přihlášení. Dokončete přihlášení kliknutím na odkaz. <br><br>Přihlašovací e-mail byl odeslán na adresu <br><b>{email}</b><br> a měl by brzy dorazit. V případě potřeby zkontrolujte složku se spamem.",
            "forgot_passwort": {
                "title": "zapomenuté heslo",
                "login_via_email": "Přihlášení přes email",
                "instructions": "V liftbase nejsou žádná hesla. Pošleme vám e-mail s odkazem na přihlášení. Nebo můžete použít přístupové klíče."
            },
            "no_email": "Žádný e-mail",
            "no_email_validation": "Pro přihlášení zadejte svou e-mailovou adresu.",
            "check_email_title": "Zkontrolovat email",
            "check_email_message": "Zkontrolujte si nyní svou e-mailovou schránku a klikněte na odkaz pro přihlášení."
        },
        "or": "nebo",
        "passkey": {
            "title": "Přihlaste se pomocí Passkey",
            "instructions": "Klikněte prosím na tlačítko pro přihlášení pomocí přístupového klíče.",
            "login": "Přihlaste se pomocí přístupového klíče",
            "what_is": {
                "title": "Co jsou přístupové klíče?",
                "first": "Přihlaste se bez čekání na e-mail",
                "second": "Pomocí otisku prstu, obličeje nebo kódu PIN",
                "third": "Nastavte nyní v několika krocích ve svém profilu"
            },
            "error": "Chyba při přihlašování pomocí přístupového klíče. Prosím zkuste to znovu."
        }
    },
    "languages": {
        "de": "Němec",
        "en": "Angličtina",
        "it": "italština",
        "cz": "čeština",
        "sk": "Slovák"
    },
    "translation-component": {
        "repeated": "Nemůžete vybrat stejný jazyk vícekrát.",
        "language_empty": "Musíte vybrat jazyk.",
        "translation_empty": "Musíte zadat text pro tuto vybranou jazykovou verzi.",
        "both_empty": "Musíte zadat jak jazyk, tak text.",
        "language": "Jazyk",
        "no_translation": "Pro tento jazyk není registrován žádný text.",
        "at_least_one_required": "Musíte uvést minimálně jedno jméno.",
        "text_without_translation": "Bez překladu"
    },
    "mailbox": {
        "description": "Poštovní schránka",
        "description_plural": "Poštovní schránky",
        "name": "Jméno",
        "username": "Uživatelské jméno",
        "password": "Heslo",
        "host": "Hostitel",
        "protocol": "Protokol",
        "folder": "Složka",
        "mailbox": "Poštovní schránka",
        "period_in_days": "Období ve dnech",
        "period_in_days_column": "Období (dny)",
        "period_in_days_tooltip": "Stáří e-mailů ve dnech",
        "remove_mailbox_msg": "Opravdu chcete odstranit tuto poštovní schránku?",
        "remove_mailbox_btn": "Odstranit",
        "import_invoices_automatically": "Import faktur je aktivní",
        "import_mode": "Režim importu",
        "import_mode_per_attachment": "Vytvořit jednu fakturu na každou přílohu e-mailu",
        "import_mode_per_email": "Vytvořit jednu fakturu na každý e-mail",
        "check_connection_btn": "Zkontrolujte připojení",
        "connectivity_success_msg": "Připojení bylo úspěšně navázáno.",
        "connectivity_error_msg": "Spojení se nepodařilo navázat. Zkontrolujte prosím své přihlašovací údaje.",
        "save_and_connect_btn": "Uložit a připojit",
        "disconnect_btn": "Odpojit",
        "disconnect_success_msg": "Připojení bylo úspěšně odpojeno."
    },
    "marketplace": {
        "description": "Tržiště",
        "description_plural": "Tržiště",
        "provider": "Poskytovatel",
        "country_code": "Země",
        "url": "URL",
        "email": "E-mail",
        "order_url": "URL objednávky",
        "identity": "Identita",
        "secret": "Tajný klíč",
        "new": "Nový trh"
    },
    "payment_options": {
        "label": "Platební metoda dodavatelské společnosti",
        "AUTOMATIC_DEBIT": "Prostřednictvím SEPA inkasa",
        "CREDIT_CARD": "Prostřednictvím kreditní karty",
        "BANK_TRANSFER": "Prostřednictvím bankovního převodu"
    }

}