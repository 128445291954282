export default {
    "administration": "Administration",
    "admin_page": {
        "no_permission": "You do not have permission to access the administration area.",
        "confirm_before_delete_msg": "Do you really want to remove this entry? (ID #{id})",
        "confirm_before_delete_from_db_msg": "Do you really want to delete this entry? (ID #{id})",
        "confirm_before_delete_from_db_title": "Delete Permanently",
        "confirm_before_delete_from_db_btn": "Delete",
        "block_user_msg": "Do you really want to block {name}? The person will then be unable to log in.",
        "block_user_title": "Login Block",
        "block_user_btn": "Block",
        "unblock_user_msg": "Do you really want to unlock {name}? The person will then be able to log in again.",
        "unblock_user_title": "Unlock Login",
        "unblock_user_btn": "Unlock",
        "tour": {
            "menu_title": "Sections",
            "menu": "Here you can see the sections of the administration area where you can manage and edit master data and settings.",
            "client_menu_title": "Manage Companies",
            "client_menu": "Here you can add, view, or edit all companies.",
            "client_list": "Here you can see all companies.",
            "new_client": "Click here to set up a new company.",
            "view_mode": "<p>Here you can switch from list view to table view to set up multiple entries at once or to have a better overview.</p> Table view can also be used for data export or import. Don't forget to save the table after editing."
        }
    },
    "version": "Version",
    "yes": "Yes",
    "no": "No",
    "never": "Never",
    "add": "Add",
    "edit": "Edit",
    "delete": "Delete",
    "save": "Save",
    "cancel": "Cancel",
    "next": "Next",
    "back": "Back",
    "finish": "Finish",
    "ok": "Ok",
    "open": "Open",
    "dashboard": "Dashboard",
    "overview": "Overview",
    "operator": "Operator",
    "profile": "Profile",
    "help": "Help",
    "logout": "Logout",
    "list_view": "List View",
    "table_view": "Table View",
    "archive": "Archive",
    "active": "Active",
    "email": "Email",
    "name": "Name",
    "tax": "Tax (%)",
    "value": "Amount",
    "number": "Number",
    "currency": "Currency",
    "actions": "Actions",
    "system": "System",
    "from": "From",
    "of": "Of",
    "until": "until",
    "up_to": "up to",
    "cost_center_id": "Cost Center",
    "cost_centers": "Cost Centers",
    "budget_id": "Budget",
    "budgets": "Budgets",
    "branch_id": "Branch",
    "branches": "Branches",
    "employee_id": "Employee",
    "employees": "Employees",
    "d365_vehicle_id": "Vehicle",
    "vehicles": "Vehicles",
    "financial_dimension_id": "Financial Dimension",
    "financial_dimensions": "Financial Dimensions",
    "tax_group_id": "Sales Tax Group",
    "tax_groups": "Sales Tax Groups",
    "tax_item_group_id": "Sales Tax Item Group",
    "tax_item_groups": "Sales Tax Item Groups",
    "profit_center_id": "Profit Center",
    "profit_centers": "Profit Centers",
    "client_id": "Company",
    "clients": "Companies",
    "department_id": "Department",
    "departments": "Departments",
    "d365_department_id": "D365 Department",
    "d365_departments": "D365 Departments",
    "location_id": "Location",
    "consignee_id": "Receipt of Goods",
    "address_id": "Delivery Address",
    "lb_user_id": "Person",
    "users": "People",
    "task_group_id": "Task Group",
    "task_groups": "Task Groups",
    "user_task_group": "User/Task Group",
    "user_task_groups": "Users/Task Groups",
    "product": "Product",
    "products": "Product Catalog",
    "product_group_id": "Product Group",
    "product_groups": "Product Groups",
    "supplier_id": "Supplier",
    "suppliers": "Suppliers",
    "procurement_id": "Purchase Order",
    "procurements": "Purchase Orders",
    "user_select_product_id": "Person 'Product Selection'",
    "user_send_order_id": "Person 'Order Placement'",
    "user_clearing_id": "Person 'Procurement Clearing'",
    "user_consignee_id": "Person 'Receipt of Goods'",
    "user_invoice_manual_check_id": "Person 'Manual Check (Invoice)'",
    "user_invoice_clearing_id": "Person 'Accounting Clearing'",
    "user_accounting_id": "User 'Accounting'",
    "ledger_account_id": "Ledger Account",
    "ledger_accounts": "Ledger Accounts",
    "posting_key_id": "Posting Key",
    "posting_keys": "Posting Keys",
    "permission_id": "Permission Group",
    "permissions": "Permission Groups",
    "invoices": "Invoices",
    "invoice_id": "Invoice",
    "configurations": "Settings",
    "marketplace_id": "Marketplace",
    "provider_id": "Marketplace Provider",
    "notes": "Note",
    "search": "Search",
    "no_data": "No entries found",
    "months": "Months",
    "select_valid_option": "Select a valid entry.",
    "procurement_line": {
        "description": "Product",
        "item_number": "Item Number",
        "is_cost_center_fixed": "Fixed Preset",
        "image_url": "Image URL",
        "webshop_url": "Shop URL",
        "offer_number": "Offer Number",
        "offer_date": "Offer Date",
        "include_tax": "incl. {tax} VAT",
        "offer_msg": "According to offer {offer_number}",
        "offer_date_msg": "on {offer_date}",
        "total_price_brutto": "Total gross",
        "total_price_netto": "Total net",
        "type_total_price_brutto": "currency",
        "type_price_brutto": "currency",
        "type_total_price_netto": "currency",
        "type_price_netto": "currency"
    },
    "product_catalog": {
        "description": "Item",
        "add_product_btn": "Add Product",
        "item_number": "Item Number",
        "is_cost_center_fixed": "Fixed Assignment",
        "image_url": "Image URL",
        "webshop_url": "Shop URL",
        "offer_number": "Offer Number",
        "offer_date": "Offer Date",
        "type_price_netto": "float",
        "template_price_netto": "$1 €",
        "type_price_brutto": "float",
        "template_price_brutto": "$1 €",
        "form_sequence": "name,item_number,product_group_id,supplier_id,location_id,cost_center_id,is_cost_center_fixed,tax,price_netto,price_brutto,unit,image_url,webshop_url,offer_number,offer_date",
        "page": {
            "new": "New Product",
            "supplier_tooltip": "Select a supplier from your master data or add a new one by typing a new name.",
            "supplier_new_tooltip": "This supplier will be saved in your master data when adding the product.",
            "should_save": "Save in Product Catalog",
            "validation": {
                "name_required": "Please enter a name.",
                "supplier_required": "Please select a supplier or add a new one.",
                "image_url_invalid": "Please enter a valid URL.",
                "webshop_url_invalid": "Please enter a valid URL.",
                "tax_invalid": "Please enter a valid tax rate."
            },
            "tour": {
                "name": "Provide a significant name for the product.",
                "number": "Here, you can enter the product's item number.",
                "product_group": "Choose the product group to which the product belongs.",
                "supplier": "Choose the product's supplier from which it should be ordered.",
                "price": "Specify a tax rate and a net or gross price. The other price will be calculated using the tax rate.",
                "unit": "Indicate the unit in which the product is measured, e.g. \"items\", \"kg\", or \"liters\".",
                "url": "You can add an image URL to the product here. Additionally, if available, you can add a link to the product's page on the shop.",
                "offer": "If there is an offer for the product, you can enter the offer number and date here to display them on the order PDF.",
                "btn_save": "Save the product in the product catalog once you've added all the information."
            }
        }
    },
    "product_group": {
        "form_sequence": "name,description,user_select_product_id",
        "page": {
            "new": "New Product Group",
            "no_product_group": "Without item group",
            "validation": {
                "name_required": "Please enter a name."
            },
            "tour": {
                "name": "Provide a meaningful name for the product group.",
                "btn_save": "Once you've provided all the information, add the product group."
            }
        }
    },
    "procurement": {
        "description": "Purchase Order",
        "order_description": "Order",
        "name": "Name",
        "number": "Bestellnummer",
        "order_number_formatted": "Bestellnummer",
        "creator_id": "Created by",
        "created_at": "Created Date",
        "delivery_date": "Delivery Date",
        "delivery_address_id": "Delivery Address",
        "billing_address_text": "Billing Address",
        "status": "Status",
        "total_brutto": "Total Gross",
        "total_netto": "Total Net",
        "total_netto_abbr": "-Net",
        "payment_in_advance": "Payment in advance",
        "type": "Order Type",
        "type_options": {
            "single_invoice": "eKuez with single invoice",
            "recurring_invoice": "dKuez with single invoice",
            "recurring_contract": "dKuez with recurring invoice"
        },
        "statuses": {
            "open": "Product Selection",
            "approved": "Order Placement",
            "ordered": "Waiting for Delivery",
            "declined": "Declined",
            "completed": "Completed",
            "approval": "Approval",
            "clearing": "Procurement Clearing",
            "draft": "Draft"
        },
        "page": {
            "no_delivery_address": "No Delivery Address",
            "no_budget": "No budget",
            "new": "New Purchase Order",
            "create_btn": "Start Purchase Order",
            "copy_btn_tooltip": "Copy Purchase Order",
            "delivery_date_tooltip": "The delivery date will be printed on the order PDF and can then be sent to the supplier.",
            "order_sender": "Order Placement",
            "product_selector": "Product Selection",
            "product_search": "Search Products",
            "before_leave_msg": "Your purchase order has not been saved. Are you sure you want to leave the page?",
            "confirm_without_approval_limit_msg": "None of the selected individuals have the necessary approval limit for the value of this order. Additional approvals can be added later. Are you sure you want to proceed?",
            "confirm_without_approval_limit_title": "Approval Limit",
            "confirm_product_selection_without_product_msg": "You haven't selected any product. Are you sure you want to proceed?",
            "confirm_product_selection_without_product_title": "No Product Selected",
            "no_approval_limit_msg": "Your approval limit is not sufficient for this order. Please add another person with an adequate approval limit for approval.",
            "no_approval_limit_title": "Approval Limit",
            "product_group_missing_msg": "All products must be assigned to a product group. Go to the 'Product Groups' tab above the product list and assign a product group to each product.",
            "product_group_missing_title": "Assign Product Group",
            "product_group_missing_btn": "Understood",
            "min_approval_one_person": "1 Person",
            "min_approval_qty": "{qty} different persons",
            "min_approval_error_msg": "The approval is not sufficient for this purchase order. Please add another person for approval. This purchase order must be approved by <b>at least {min_approval_msg}</b>.",
            "min_approval_error_title": "Insufficient Approval",
            "order_not_completed_msg": "You haven't placed orders for all products or confirmed them. Make sure you have ordered all products in the product list and confirm them if necessary to proceed.",
            "order_not_completed_title": "Complete Orders First",
            "order_not_completed_btn": "Understood",
            "confirm_delivery_not_completed_msg": "You haven't confirmed all receipts of goods. Are you sure you want to complete the purchase order? <br> <i>This action cannot be undone</i>.",
            "confirm_delivery_not_completed_title": "Outstanding Receipts of Goods",
            "confirm_delivery_not_completed_btn": "Complete",
            "default_confirm_btn": "Continue",
            "budget_exceeded_msg": "Budget Exceeded",
            "approval_limit_exceeded_msg": "Approval Limit Exceeded",
            "external_order_number_msg": "Enter the external order number.",
            "external_order_number_title": "Enter External Order Number",
            "no_edit_permission": "You lack the permission to edit the data. Please submit the purchase order for clearing via actions to make changes to the data.",
            "delivery_dialog": {
                "title": "Confirm Receipt of Goods",
                "confirm_btn": "Confirm",
                "message": "Enter the quantity received recently.<br> {missing_qty} of {quantity} {unit} are missing.",
                "qty_exceeded_msg": "The missing quantity must not be exceeded.",
                "qty_must_be_greater_msg": "The quantity must be greater than 0.",
                "invalid_format": "Use a valid numeric format."
            },
            "order_sender_dialog": {
                "title": "Role Defaults for Order Placement",
                "info": "The person in the 'Order Placement' step is determined based on the role defaults for the department, the company, global role defaults, and the person who created the purchase order. The person at the top of the list is preselected for the task."
            },
            "consignee_dialog": {
                "title": "Role Defaults for Receipt of Goods",
                "info": "The person in the 'Receipt of Goods' step is determined based on the role defaults for the department, the company, global role defaults, and the person who created the purchase order. The person at the top of the list is preselected for the task."
            },
            "select_product_dialog": {
                "title": "Role Defaults for Product Selection",
                "info": "The person in the 'Product Selection' step is determined based on the role defaults for the department, the company, global role defaults, and the person who created the purchase order. The person at the top of the list is preselected for the task."
            },
            "marketplace_change_dialog": {
                "title": "Order will be canceled",
                "info": "A change in the company has been detected. However, there is an order in the marketplace {marketplace_name} that is not linked to the new company {client_name}. If you proceed, this order will be canceled.",
                "btn_confirm": "Cancel order"
            },
            "dialog_assignment": {
                "department_form": "Department in Form",
                "department_creator": "Department of Ordered-by Person",
                "client_form": "Company in Form",
                "client_creator": "Company of Ordered-by Person",
                "no_selection": "Not Yet Selected",
                "no_action": "Not Applicable",
                "configuration": "Global Role Default"
            },
            "draft": {
                "title": "Draft",
                "save": "Save Draft",
                "delete": "Delete Draft",
                "confirm_before_delete_msg": "Are you sure you want to delete this draft permanently?",
                "confirm_before_delete_title": "Delete Draft",
                "confirm_before_delete_btn": "Delete"
            },
            "punchout": {
                "pending_msg": "There are pending products from Punchout.",
                "error_title": "Punch-out Error",
                "error_msg": "An error occurred. Please check the Punch-out settings or contact a person with administrator rights.",
                "error_start_url_msg": "No start URL provided, Punch-out cannot be initiated.",
                "no_config": "Please set up the configuration first to use Punch-out.",
                "pending_dialog": {
                    "title": "Pending Products from Punchout",
                    "add_products": "Add to Order",
                    "add_all_products": "Add All to Order",
                    "delete_products": "Remove Products"
                },
                "log_dialog": {
                    "title": "{supplier_name} Request",
                    "request": "Request",
                    "response": "Response",
                    "no_log": "No Log",
                    "url": "Url",
                    "header": "Header",
                    "method": "Method",
                    "body": "Body"
                },
                "delete_dialog": {
                    "title": "Remove Products from {supplier} #{index}",
                    "info1": "Do you really want to remove all the following products from {supplier} #{index}?",
                    "info2": "This action cannot be undone.",
                    "delete_btn": "Remove"
                },
                "loading": "Please complete your product selection in the opened pop-up. If no pop-up appears, you need to allow it first.",
                "can_not_edit": "Punch-out products cannot be edited. Please perform Punch-out again or delete the entire shopping cart.",
                "no_provider_found": "Unfortunately, the selected Punch-out provider is not yet supported."
            },
            "download_pdf": {
                "title": "Download PDF",
                "confirm_btn": "Confirm",
                "title_confirm_save_changes": "Save Unsaved Data",
                "text_confirm_save_changes": "Do you want to save the unsaved data and apply it to the purchase document?",
                "invalid_form": "Please fill in all required fields in the form before proceeding with the action."
            },
            "lines": {
                "no_line": "No products selected, please add products.",
                "show_all_info": "Advanced View",
                "invoice": {
                    "title": "Invoice",
                    "created_at": "Recorded",
                    "date": "Invoice Date",
                    "created_by": "Created By"
                },
                "automatic_order": "Send Electronically",
                "automatic_ordered": "Electronically Sent",
                "confirm_order": "Confirm Order",
                "finish_order": "Finish Order",
                "order_finished": "Order Completed",
                "confirm_order_tooltip": "Please note that you need to <b>send the order to the supplier via email</b>, the order will <b>not be transmitted electronically.</b>",
                "ordered_at": "Transmitted",
                "ordered_by": "By",
                "confirm_receipt": "Confirm receipt of goods",
                "no_cost_center": "No Cost Center",
                "include_taxes": "incl. VAT",
                "budget": "Budget",
                "no_budget": "No Budget",
                "no_product_group": "No Product Group",
                "no_ledger_account": "No ledger account",
                "select_product": "Product Selection",
                "quantity_to_show": "received: <b>{quantity_received} of {quantity}</b>",
                "from": "from",
                "item_number": "Item No. #",
                "delete_msg": "Are you sure you want to remove this product?",
                "delete_title": "Remove Product",
                "delete_btn": "Remove",
                "automatic_order_dialog": {
                    "confirm_order": "Send Order",
                    "confirm_order_btn": "Send",
                    "finish_order": "Finish Order",
                    "finish_order_btn": "Finish",
                    "info1": "Do you want to <span style=\"font-weight: bold\">electronically submit</span> this order to the supplier <span style=\"font-weight: bold\">now</span>?",
                    "info2": "This action cannot be undone."
                },
                "order_dialog": {
                    "confirm_order": "Confirm Order",
                    "confirm_order_btn": "Confirm",
                    "finish_order": "Finish Order",
                    "finish_order_btn": "Finish",
                    "info1": "Have you transmitted the order to the supplier? (e.g., via email or fax)",
                    "info2": "This action cannot be undone."
                },
                "more_actions": {
                    "delete_products": "Remove Products",
                    "add_external_id": "Add External Order Number",
                    "download_pdf": "Order (PDF)",
                    "download_pdf_with_attachments": "Order including PDF attachment",
                    "write_email": "Compose Email",
                    "email_with_pdf": "E-Mail with PDF"
                },
                "file": {
                    "open_btn": "Open PDF",
                    "add_btn": "Add PDF",
                    "upload_info1": "Drag and drop here <br>or <br> <em>select file</em>.",
                    "upload_info2": "PDF files up to 10MB.",
                    "delete_msg": "Do you really want to remove this document?",
                    "delete_title": "Remove Document",
                    "delete_btn": "Remove",
                    "delete_error_msg": "The document could not be removed."
                }
            },
            "validation": {
                "delivery_date_format": "Please enter a delivery date.",
                "client_id_required": "Please select a company.",
                "department_id_required": "Please select a department.",
                "department_id_required_client_missing": "First select company.",
                "cost_center_id_required": "Please select a cost center.",
                "cost_center_id_required_filters_missing": "First select company and department.",
                "cost_center_id_required_department_missing": "First select department.",
                "cost_center_id_required_client_missing": "First select company.",
                "ledger_account_id_required": "Please select a ledger account.",
                "consignee_id_required": "Please select a responsible person.",
                "delivery_address_id_required": "Please select a delivery address.",
                "billing_address_text_required": "The billing address is missing in the company data."
            },
            "tour": {
                "main": {
                    "description": "In the first fields, specify a name for the purchase request, as well as your company and the department for which the order is to be made. The department is filtered based on the company, and the cost center is filtered based on the department.",
                    "organization": "Specify the company, department, and cost center for the purchase order. The list of departments is filtered by the selected company and the list of cost centers is filtered by the selected department.",
                    "address": "The delivery address will be provided to the supplier. You can choose from the company's addresses, the person in charge of receiving the goods, and your own addresses.",
                    "total": "Here, you can see the total value of your purchase order, which is the sum of the selected products.",
                    "delivery_date": "The delivery date can be recorded after the receipt of goods for documentation purposes. It will not be sent to the supplier.",
                    "product_selector": "This shows who is responsible for product selection in the next step. It depends on various role defaults, for more detail click the info icon.",
                    "order_sender": "This shows who is responsible for order placement, which depends on various role defaults. For more detail click the info icon.",
                    "consignee": "Select the person who will receive the goods and confirm the receipt in the final step of the purchase order to complete the process.",
                    "approvers": "Here, you see if approval has been set up for the selected cost center. You can also add people for approval.",
                    "product_search": "Here, you can search for a product in the internal product catalog and add it to the purchase order.",
                    "punchout": "Here, you can select products from integrated marketplaces like Amazon or Mercateo. The product selection can be transmitted electronically to the marketplace after the approval.",
                    "new_product": "If your desired product is not in the product catalog, you can add it to the purchase order here.",
                    "save_btn": "When you have entered all the required information, you can start the purchase order process and proceed to the next step."
                },
                "product": {
                    "pdf": "Here you can add documents to the product.",
                    "remove": "You can also remove products from the selection by clicking here.",
                    "more_actions": "You can document the external order number here upon receipt.",
                    "tabs": "Here, you can group the view of your products by supplier, cost center, or product group.",
                    "expand_view": "Here, you can show or hide advanced settings for the products and select a cost center, delivery address, or product group."
                },
                "new_product": {
                    "name": "Provide a meaningful name for the product.",
                    "number": "You can also enter an item number for the product.",
                    "supplier": "Select the supplier from which the product is offered or from which it should be ordered.",
                    "price": "Specify a tax rate and a net or gross price. The other price will be calculated using the tax rate.",
                    "unit": "Specify the unit of measurement for the product, such as \"items\", \"kg\", or \"liters\".",
                    "url": "You can add an image URL for the product and a link to the product's page on the shop, if available.",
                    "offer": "If there is an offer for the product, you can enter the offer number and date here to display them on the order PDF.",
                    "should_save": "Here, you choose to add the created product to the product catalog. This ensures that this specific product will be available for future orders.",
                    "save_btn": "Add the product to the purchase order when you have entered all the information."
                },
                "cost_center_view": "Cost centers can also be assigned to individual products in a purchase order. The delivery addresses associated with the cost center can then be selected for the product.",
                "product_group_view": "Each product can be assigned to a product group. The person responsible for the product group will receive a task in the timeline to edit or confirm the product selection.",
                "send_order": {
                    "send_btn": "In the order confirmation step, you can electronically send the order or confirm the order made via email with the supplier.",
                    "more_actions": "In the options for the selected supplier, you can generate an order PDF or an email with or without an order PDF for the supplier. This way, you can place the order with the company or make a query via email."
                },
                "delivery_btn": "If you have received a partial delivery or the entire order, you can indicate it here. The confirmed receipt of goods will also be noted in the timeline."
            }

        }
    },
    "quantity": "Quantity",
    "price_netto": "Net Price",
    "price_brutto": "Gross Price",
    "unit": "Unit",
    "default_unit": "Item",
    "tenant": {
        "tab_general": "General",
        "description": "Tenant",
        "main_language": "Fallback Language",
        "monthly_document_limit": "Documents per month",
        "base_price": "Base price",
        "price_extra_document": "Price of each additional document",
        "monthly_procurement_limit": "Purchase Orders per Month in Basic Price",
        "monthly_invoice_limit": "Invoices per Month in Basic Price",
        "base_price_procurement": "Purchase Orders Base Price",
        "base_price_invoice": "Invoices Base Price",
        "price_extra_procurement": "Price for Each Additional Purchase Order",
        "price_extra_invoice": "Price for Each Additional Invoice",
        "invoice_recognition_unit_price": "Invoice recognition price per document",
        "easybill_id": "Easybill ID",
        "bank_account": "Account Number",
        "bank_account_owner": "Account Holder",
        "bank_bic": "BIC",
        "bank_code": "Bank Code",
        "bank_iban": "IBAN",
        "bank_name": "Bank Name",
        "address": "Billing Address",
        "sepa_agreement": "SEPA Direct Debit Agreement",
        "sepa_agreement_date": "SEPA - Mandate Entry Date",
        "sepa_mandate_reference": "Mandate Reference",
        "automatic_billing": "Automatically Generate Billing",
        "automatic_billing_mail": "Automatically Send Billing via Email",
        "bank": "Bank Connection",
        "datev": "DATEV",
        "billing_address_name": "Company",
        "billing_address_consignee_name": "Name of Recipient",
        "billing_address_street": "Street and No.",
        "billing_address_zipcode": "Postal Code",
        "billing_address_city": "City",
        "billing_address_state": "State",
        "billing_address_country": "Country",
        "billing_address_phone": "Phone Number",
        "contact_email": "Contact Email",
        "billing_email": "Billing Email",
        "contact": "Contact Information",
        "sepa": "SEPA Direct Debit",
        "add_sepa": "Add SEPA Direct Debit",
        "remove_sepa": "Revoke SEPA Direct Debit",
        "import_invoice_email": "Automatically import invoices via email",
        "tab_mailbox": "Mailboxes",
        "debit_credit_reverse": "Swap account/counter-account"
    },
    "datev_access": {
        "description": "DATEV Integration",
        "label_log": "DATEV Company (datev_client_id)",
        "label_log_fields": "datev_client_id",
        "connected_info": "Connection with DATEV has been established.",
        "disconnected_info": "Connection with DATEV has been removed.",
        "connected_client_info": "Connection with DATEV company {datev_client_id} has been established.",
        "disconnected_client_info": "Connection with DATEV company {datev_client_id} has been deactivated."
    },
    "ms365_access": {
        "description": "D365 Integration",
        "connected_info": "Connection to D365 established.",
        "disconnected_info": "Connection to D365 removed.",
        "data_loaded_info": "Data from D365 manually loaded.",
        "journal_created_info": "The D365 journal has been added.<br>Journal Name: {journal_name} <br>Org URL: {org_url}.",
        "journal_edited_info": "The D365 journal name has been edited: {journal_name}."
    },
    "client": {
        "description": "Company",
        "min_approval": "Minimum Number of Approvers",
        "branch_required": "The branch is required for the invoice form.",
        "department_required": "The department is required for the invoice form.",
        "cost_center_required": "The cost center is required for the order form.",
        "client_number": "Number",
        "consultant_number": "Consultant Number",
        "ledger_account_length": "Ledger Account Length",
        "vat_number": "VAT ID",
        "address_name": "Name",
        "consignee": "Name of Recipient - Billing Address",
        "dropoff_location": "Name of Drop-off Location - Billing Address",
        "address": "Street and No. - Billing Address",
        "address_complement": "Street 2 - Billing Address",
        "country": "Country - Billing Address",
        "zipcode": "Postal Code - Billing Address",
        "city": "City - Billing Address",
        "phone_country_code": "Phone Country Code - Billing Address",
        "phone_number": "Phone Number - Billing Address",
        "can_delete_document_directly": "Documents can be deleted without clearing.",
        "default_product_tax": "Standard tax rate (%)",
        "default_product_currency": "Default currency",
        "default_product_unit": "Default unit",
        "form_sequence": "name,email,client_number,currency,min_approval,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id,user_invoice_manual_check_id,user_invoice_clearing_id",
        "page": {
            "tab_general": "General",
            "tab_billing_address": "Billing Address",
            "tab_shipping_address": "Shipping Addresses",
            "tab_default_roles": "Default Roles",
            "tab_permissions": "Permissions",
            "tab_departments": "Departments",
            "tab_cost_centers": "Cost Centers",
            "tab_ledger_accounts": "Ledger Accounts",
            "tab_required_fields": "Mandatory Fields",
            "tab_datev_csv": "DATEV CSV Export",
            "new": "New Company",
            "select_logo": "Select Logo",
            "select_logo_tooltip_content": "Add a logo that will be printed on the order documents of your approved orders. It's best to use an image file with transparent background.",
            "select_letterhead": "Select letterhead",
            "select_letterhead_tooltip_content": "Add a letterhead that will be printed on the order document of your approved orders.",
            "billing_address": "Billing Address",
            "roles": "General Role Defaults",
            "user_clearing_id_tooltip_content": "For clearing in order processes.",
            "user_invoice_clearing_id_tooltip_content": "For clearing during invoicing.",
            "no_selection": "No specific company has been chosen, hence it is shared among all.",
            "validation": {
                "name_required": "Please enter a name.",
                "name_max_length": "Must not exceed 50 characters.",
                "email_invalid": "Please enter a valid email address.",
                "client_number_invalid": "Please enter a valid number.",
                "min_approval_invalid": "Please enter a valid number.",
                "address_incomplete": "Please complete the mandatory address data.",
                "delivery_address_missing": "Add at least one delivery address.",
                "error_address_incomplete": "Please complete the required data for the delivery address."
            },
            "tour": {
                "name": "Start by entering the name of your company.",
                "email": "Enter the email of your company to which, for example, the invoice delivery should be sent.",
                "timeline_assignment": "You can optionally pre-assign responsible individuals for the various steps of purchase orders for the company. For example, if there is always a person responsible for approving orders.",
                "billing_address": "The billing address is required for the order and may be sent to the supplier with the order.",
                "shipping_address": "Here you can enter one or more delivery addresses which can be selected in a purchase order.",
                "btn_save": "After entering all required information, add the company."
            }
        }
    },
    "client_form_config": {
        "field": "Field",
        "form": "Form",
        "required": "Mandatory"
    },
    "department": {
        "description": "Department",
        "code": "Number",
        "form_sequence": "name,code,client_id,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id",
        "page": {
            "new": "New Department",
            "tab_general": "General",
            "tab_clients": "Clients",
            "tab_cost_centers": "Cost Centers",
            "tab_approvers": "Approvers",
            "tab_permissions": "Permission Groups",
            "tab_employees": "Employees",
            "tab_assignment": "Role Assignments",
            "validation": {
                "name_required": "Please enter a name.",
                "email_required": "Please enter a valid email address."
            },
            "tour": {
                "name": "Start by entering the name of the department.",
                "client": "If you assign the department to a company, the department can only be selected within that company when creating a purchase order.",
                "timeline_assignment": "You can designate responsible individuals for the various steps of purchase orders for the department. For example, if one specific person is always responsible for receiving goods for each department.",
                "cost_centers": "Here, you can assign existing cost centers to the department. If you don't assign any cost centers, when creating purchase orders for the department, only cost centers that are not assigned to any department can be selected.",
                "employees": "You can add different individuals who are part of this department. When individuals from this department create purchase orders, the department will be pre-filled.",
                "btn_save": "Once you've provided all the information, add the department."
            }
        }
    },
    "permission": {
        "description": "Permission Group",
        "name": "Name",
        "page": {
            "new": "New Permission Group",
            "validation": {
                "name_required": "Please enter a name."
            },
            "tour": {
                "name": "Here you can create a permission group. You can then assign this group to various entities (departments, products from the product catalog, product groups etc.). You can also assign one or more permission groups to individuals. If an entity should not have any permission restrictions, do not assign a group."
            },
            "no_selection": "No permission selected, therefore visible to everyone."
        }
    },
    "task_group": {
        "description": "Task Group",
        "name": "Name",
        "page": {
            "new": "New Task Group",
            "validation": {
                "name_required": "Please enter a name."
            },
            "tour": {
                "name": "Here you can create a task group. You can then assign tasks to this group."
            },
            "no_selection": "No Task Group Selected"
        }
    },
    "budget": {
        "description": "Budgets",
        "label_log": "value€ (start - end)",
        "label_log_fields": "value,start,end",
        "value": "Amount",
        "template_value": "$1 €",
        "type_value": "float",
        "start": "Valid from",
        "end": "Valid until",
        "add_foreach_ledger_account": "Insert all ledger accounts",
        "component": {
            "validation": {
                "end_date_before_start_date": "The end date cannot be before the start date.",
                "end_date_required": "The end date cannot be empty here.",
                "time_overlap": "Budgets cannot overlap in time.",
                "field_missing": "Please enter all the information."
            }
        }
    },
    "address": {
        "description": "Delivery Address",
        "description_plural": "Delivery Addresses",
        "address_name": "Name",
        "consignee": "Consignee Name",
        "dropoff_location": "Drop-off Location Name",
        "address": "Street and No.",
        "address_complement": "Street 2",
        "complement": "Street 2",
        "country": "Country",
        "zipcode": "ZIP Code",
        "city": "City",
        "phone_country_code": "Phone Country Code",
        "phone_number": "Phone Number",
        "component": {
            "new": "New Delivery Address",
            "copy_btn": "Copy Billing Address",
            "required_field": "Please complete the required fields of the address.",
            "phone_required": "Please enter both pieces of information.",
            "no_address": "No Delivery Address"
        }
    },
    "cost_center": {
        "name": "Name",
        "number": "Number",
        "free_budget": "Available Budget",
        "description": "Cost Center",
        "label_log": "cost_center_name",
        "budgets": "Budgets",
        "form_sequence": "name,number",
        "page": {
            "new": "New Cost Center",
            "department_tooltip_content": "Which departments are allowed to use this cost center.",
            "tab_general": "General",
            "tab_budgets": "Budgets",
            "tab_shipping_address": "Shipping Addresses",
            "tab_approvers": "Approvers",
            "tab_permissions": "Permissions",
            "tab_departments": "Departments",
            "tab_clients": "Clients",
            "validation": {
                "name_required": "Please enter a name.",
                "number_required": "Please enter a number.",
                "number_length": "Must be at most 36 characters long and can only have spaces in the middle.",
                "number_exists": "The number already exists."
            },
            "tour": {
                "name_number": "Provide a name and a unique number for the cost center.",
                "budget": "You can assign various budgets to your cost center and also allocate the budgets to a ledger account.",
                "approver": "Define here who should approve purchase orders with this cost center. Additional approvers can be added when creating purchase orders.",
                "department": "Here, you can define in which departments the cost center can be used. If you don't add any departments here, the cost center can be selected in all departments.",
                "btn_save": "Once you've provided all necessary information, add the cost center to the database."
            }
        }
    },
    "supplier": {
        "address": "Address",
        "external_id": "DATEV ID",
        "supplier_number": "Supplier Number",
        "client_number": "Customer number",
        "delivery_in_days": "Delivery time in working days",
        "delivery_terms": "Delivery Terms",
        "payment_terms": "Payment Terms",
        "vat_number": "VAT ID",
        "city": "City",
        "iban": "IBAN",
        "bic": "BIC",
        "form_sequence": "name,email,city,address,external_id,delivery_terms,payment_terms,iban,bic,vat_number",
        "payment_method": "Payment method",
        "payment_method_info": "Invoices from supplier companies with the payment method SEPA direct debit or credit card are marked as paid directly",
        "page": {
            "new": "New Supplier",
            "department_tooltip_content": "Which departments are allowed to use this supplier.",
            "delivery_in_days_tooltip_content": "The estimated delivery time serves for the receipt of goods task email.",
            "notes_tooltip_content": "This note is displayed internally on liftbase only and is not printed on the order PDF.",
            "external_id_tooltip_content": "The ID of the supplier from a third-party system you've integrated.",
            "client_number_tooltip_content": "Number assigned to you by this supplier (optional).",
            "supplier_number_tooltip_content": "Number you have assigned to this supplier.",
            "delivery_terms_tooltip_content": "The agreed-upon payment and delivery terms with the supplier will be displayed on the purchase order PDF.",
            "payment_terms_tooltip_content": "The agreed-upon payment and delivery terms with the supplier will be displayed on the purchase order PDF.",
            "vat_number_tooltip_content": "The value-added tax (VAT) ID is relevant for assigning an imported invoice to the delivering company.",
            "name_tooltip_content": "To assign an imported invoice, specify the name of the delivering company exactly as it appears on the invoice.",
            "tab_general": "General",
            "tab_delivery_info": "Delivery Information",
            "tab_payment_info": "Payment Information",
            "tab_permissions": "Permission Groups",
            "tab_departments": "Departments",
            "tab_approvers": "Approvers",
            "tab_clients": "Company-specific IDs",
            "default_clients_ids": "If a company is not listed in this table with specific IDs, <br>the following default IDs will be used: <br>DATEV ID: {external_id} <br>Client number: {client_number}.",
            "no_default_id": "No default ID available.",
            "validation": {
                "name_required": "Please enter a name.",
                "name_length": "Must be at most 50 characters long.",
                "email_invalid": "Please enter a valid email address.",
                "supplier_number_invalid": "Please enter a valid number.",
                "vat_number_invalid": "Can contain numbers, uppercase and lowercase letters, dots, spaces, and underscores. Must be at most 15 characters long.",
                "iban_invalid": "Must be at least 15 and at most 34 characters long. Cannot contain spaces. Begins with two uppercase letters, followed by 2 numbers, and then 11 to 30 alphanumeric characters.",
                "bic_invalid": "Must be at least 8 and at most 11 characters long, and only contain uppercase letters and numbers. The first six characters must be letters.",
                "external_id_length": "The DATEV ID must contain only whole numbers and cannot be less than 10000."
            },
            "tour": {
                "name": "Enter the name of the supplier.",
                "email": "The email address of the supplier is used, for example, for preparing email messages for order placement.",
                "address": "Here, you can provide the address of the supplier.",
                "external_id": "The external ID is the supplier's number as stored in an external system. This number is used in the API.",
                "terms": "Enter the agreed-upon payment and delivery terms with the supplier. This information will be displayed on the purchase order PDF.",
                "btn_save": "Once you've entered all the information, save the supplier here."
            }
        }
    },
    "user": {
        "name": "Name",
        "client_id": "Main Company",
        "approval_limit": "Approval Limit",
        "roles": {
            "user-admin": "Administrator",
            "user-price": "View prices",
            "user-dashboard": "View dashboard",
            "user-change-order": "Edit order after approval",
            "user-see-orders": "See orders:",
            "user-see-all-orders": "All companies",
            "user-see-all-orders-belonging-to-own-company": "Own company",
            "user-see-all-orders-belonging-to-own-department": "Own department",
            "user-see-own-orders-and-involved": "Own and with involvement",
            "user-see-all-invoices": "All Companies",
            "user-see-invoices": "View Invoices:",
            "user-see-all-invoices-belonging-to-own-company": "Own Company",
            "user-see-own-invoices-and-involved": "Own and Involved",
            "user-can-change-invoices": "Edit Invoice Afterwards",
            "user-see-client-addresses": "See all company addresses",
            "user-export-invoice": "Export invoices to {export_name}",
            "user-can-delete-document-directly": "Cancel/decline documents without clearing",
            "user-can-insert-product": "Save in product catalog"
        },
        "blocked": "Login Blocked",
        "token_issued_at": "API token issued at",
        "form_sequence": "name,email,client_id,department_id,cost_center_id,location_id,approval_limit,roles,blocked",
        "page": {
            "tab_general": "General",
            "tab_roles": "Permissions",
            "tab_roles_general": "General Permissions",
            "tab_roles_procurement": "Purchase Requests",
            "tab_roles_invoice": "Invoices",
            "tab_roles_block": "Blocks",
            "tab_permissions": "Permission Groups",
            "tab_addresses": "Delivery Addresses",
            "tab_task_groups": "Task Groups",
            "tab_passkeys": "Passkeys",
            "new": "New User",
            "block_btn": "Block User",
            "unblock_btn": "Unblock User",
            "login_as_btn": "Login As",
            "no_permission": "No permission group selected.",
            "task_group_permissions": "Permissions from My Task Groups:",
            "approval_limit_tooltip_content": "Define here the final approval limit for this person. <br> Higher amounts can be approved, but they must also be finally approved by another person with a higher approval limit.",
            "approval_limit_placeholder": "Unlimited",
            "upload_signature_btn": "Select Signature",
            "upload_signature_tooltip_content": "Add a signature that will be printed on the order document of your approved purchase orders. <br/> We recommend using an image file with a transparent background.",
            "api_token_btn": "Generate API Token",
            "api_token_dialog_title": "Generate API Token",
            "api_token_dialog_content": "API tokens are required for integrating third-party applications and allow access to data on behalf of the respective user.",
            "api_token_dialog_date_placeholder": "Token Expiry Date",
            "api_token_dialog_generate_btn": "Generate",
            "api_token_dialog_copy_btn": "Copy",
            "api_token_dialog_copy_info": "Please copy and paste the following API token into the third-party application:",
            "api_token_dialog_copied": "Copied!",
            "language": "Language",
            "role_dashboard_tooltip": "Can only be checked if user can view prices.",
            "passkey": "Passkey",
            "passkeys": "Passkeys",
            "passkey_name": "Name",
            "passkey_last_login": "Last Login",
            "passkey_dialog": {
                "title": "Add Passkey",
                "info1": "<b>What are Passkeys?</b><ul><li>Login without waiting for an email</li><li>With fingerprint, your face, or PIN</li><li>Secure and convenient</li></ul>",
                "info2": "<b>Set up in 3 steps now:</b><ol><li>Enter name e.g. \"Home Office PC\" or \"Notebook\"</li><li>Click \"Next\"</li><li>Follow instructions from your browser / operating system</li></ol>",
                "info3": "Step-by-step instructions <a href=\"https://liftbase.de/handbuch/anmeldung/passkeys/\" target=\"_blank\">can be found in the manual</a>",
                "name_input_label": "Passkey Name",
                "name_input_placeholder": "e.g. 'Home Office PC' or 'Notebook'",
                "added_msg": "Passkey has been added.",
                "deleted_msg": "Passkey has been deleted.",
                "exist_msg": "You already have a passkey on this device, try with a different device."
            },
            "validation": {
                "name_required": "Please enter a name.",
                "name_exists": "The name already exists.",
                "email_required": "Please enter an email address.",
                "email_invalid": "Please enter a valid email address.",
                "email_exists": "The email address already exists.",
                "approval_limit_length": "The approval limit must be either greater than 0 or empty."
            },
            "tour": {
                "name": "Enter the name of the user, preferably first and last name.",
                "email": "Enter the email address through which the user can log in.",
                "organization": "Assign the user to a company, department, and cost center. This information will be pre-filled in the purchase order form.",
                "approval_limit": "You can define an approval limit for the user here. If a purchase order has a higher total amount, it must also be finally approved by a person with a higher approval limit. If no limit is defined, the user can finally approve all amounts.",
                "signature": "You can add the user's signature here. When the user approves a purchase order, their signature will be added to the purchase order PDF.",
                "roles": "Permissions for the person can be set here, for example, whether and which processes or information can be viewed or edited. There is also the option to block the person if they have, for example, left the company.",
                "roles_price": "Specify whether the user can view product prices or the dashboard with purchase order insights.",
                "role_change_order": "You can grant a trusted user permission to edit purchase orders after approval, without sending them back for clearing and thereby avoiding re-approval. This can be useful, for example, to adjust delivery addresses or product prices afterwards.",
                "admin": "Choose whether this person should have access to the administration area, for example, to add more users, master data, or settings.",
                "block": "Select this option if you want to prevent a user from logging into liftbase, e.g., if they have temporarily left the company.",
                "api_token": "API tokens are required for integrating third-party applications and allow access to data on behalf of the respective user.",
                "addresses": "You can add personal delivery addresses for the user, for example, if they work from home and want products to be delivered there.",
                "btn_save": "Save the user and associated information.",
                "profile_signature": "You can upload your signature here. When you approve purchase orders, your signature will be added to the purchase order PDF.",
                "profile_addresses": "You can add personal delivery addresses for the user, for example, if they work from home and want products to be delivered there.",
                "profile_btn_save": "Save the associated information."
            }
        }
    },
    "lb_user": {
        "description": "User"
    },
    "posting_key": {
        "name": "Name",
        "tax": "Tax Rate (%)",
        "number": "Key",
        "form_sequence": "name,number,tax",
        "page": {
            "new": "New Posting Key",
            "validation": {
                "name_required": "Please enter a name.",
                "number_required": "Please enter a valid number.",
                "number_length": "Can contain up to 9-digit numbers starting from 1."
            },
            "tour": {
                "name": "Enter the key and its associated tax rate."
            }
        }
    },
    "ledger_account": {
        "name": "Name",
        "description": "Description",
        "form_sequence": "name,number,description",
        "page": {
            "new": "New Ledger Account",
            "validation": {
                "name_required": "Please enter a name.",
                "number_required": "Please enter a valid number.",
                "number_length": "Can only contain numbers and cannot start with 0.",
                "tax_required": "Please enter a valid tax rate.",
                "tax_length": "Percentage value can only have two digits with a comma and at most two decimal places."
            },
            "tour": {
                "name": "Enter the number and name of the ledger account."
            }
        }
    },
    "profit_center": {
        "name": "Name",
        "form_sequence": "name,number",
        "page": {
            "new": "New Profit Center",
            "validation": {
                "name_required": "Please enter a name.",
                "number_required": "Please provide a valid number.",
                "number_length": "Should only contain numbers and not start with 0 for multiple digits.",
            },
            "tour": {
                "name": "Specify the number and name of the profit center."
            }
        }
    },
    "tax_group": {
        "code": "ID",
        "name": "Name",
        "form_sequence": "name,code,client_id",
        "page": {
            "new": "New Sales Tax Group",
            "validation": {
                "name_required": "Please enter a name.",
                "code_required": "Please enter an ID.",
                "client_required": "Please select a client.",
            },
            "tour": {
                "name": "Specify the ID and name of the sales tax group."
            }
        }
    },
    "financial_dimension": {
        "code": "ID",
        "name": "Name",
        "form_sequence": "name,code",
        "page": {
            "new": "New Employee",
            "validation": {
                "name_required": "Please enter a name.",
                "code_required": "Please enter an ID.",
                "client_required": "Please select a company."
            },
            "tour": {
                "name": "Enter the ID and name of the employee."
            }
        }
    },
    "tax_item_group": {
        "code": "ID",
        "tax_code": "Tax ID",
        "name": "Description",
        "form_sequence": "name,code,tax_code,client_id",
        "page": {
            "new": "New Tax Item Group",
            "validation": {
                "name_required": "Please enter a name.",
                "code_required": "Please enter an ID.",
                "tax_code_required": "Please enter an Tax ID.",
                "client_required": "Please select a company.",
            },
            "tour": {
                "name": "Enter the ID and description of the value-added tax group."
            }
        }
    },
    "approver": {
        "description": "Approval",
        "label_log": "lb_user_name",
        "table_log": "lb_user",
        "approver_required": "Please select an approver.",
        "is_required": "Required",
        "is_required_info": "Must also approve, even though previous approvers already had the necessary approval limit."
    },
    "invoice": {
        "description": "Invoice",
        "name": "Name",
        "creator_id": "Uploaded by",
        "created_at": "Uploaded on",
        "number": "Invoice Number",
        "invoice_number_formatted": "Internal RE Number",
        "due_date": "Due Date",
        "delivery_date": "Delivery Date",
        "invoice_date": "Invoice Date",
        "tax": "Tax Rate %",
        "total_brutto": "Total Gross",
        "total_netto": "Total Net",
        "total_netto_abbr": "-Net",
        "fix_recognition": "Dissatisfied with Data Recognition",
        "fix_recognition_checkbox": "I am dissatisfied with the data recognition.",
        "paid": "Invoice is already paid",
        "review_status": "Review Result",
        "review_status_options": {
            "content_rejection": "Invoice rejected for content-related reasons",
            "insolvency_rejection": "Invoice rejected due to insolvency law",
            "further_review_required": "Invoice requires further review",
            "approved": "Invoice approved"
        },
        "currency": "Currency",
        "notes": "Notes",
        "order_notes": "Order Notes",
        "discount_percentage": "Discount 1 (%)",
        "discount_amount": "Discount 1 - Amount",
        "discount_days": "Discount 1 - Days",
        "discount_payment_date": "Discount 1 - Date",
        "discount_percentage2": "Discount 2 (%)",
        "discount_amount2": "Discount 2 - Amount",
        "discount_days2": "Discount 2 - Days",
        "discount_payment_date2": "Discount 2 - Date",
        "type_discount_amount": "currency",
        "type_discount_amount2": "currency",
        "export_individual_items": "Export individual items",
        "export_individual_items_tooltip": "If this box is ticked, the positions are transmitted instead of the header data.",
        "status": "Status",
        "statuses": {
            "open": "Invoice Recognition",
            "export": "Ready for Export",
            "declined": "Discarded",
            "completed": "Completed",
            "approval": "Manual Check",
            "financial-approval": "Approval",
            "clearing": "Accounting Clearing",
            "accounting": "Accounting"
        },
        "page": {
            "upload": {
                "title": "Upload New Invoices",
                "info": "Drag and drop or click here to upload one or more invoices (*.pdf up to 10MB).",
                "info_attachments": "Drag-and-drop or click here to upload one or more attachments (up to 10MB per file).",
                "create_btn": "Start Invoice Recognition",
                "file_invalid": "Please upload only PDF or XML files up to 10MB.",
                "no_preview_available": "No preview is available for this file type. <br>Please download the file and open it on your computer.",
                "tour": {
                    "before_attach": "Here, you can upload one or multiple invoices by dragging and dropping or clicking within this area.",
                    "after_attach_title": "Start Invoice Recognition",
                    "after_attach": "Here, you can initiate automatic recognition for all uploaded invoices. You will then be redirected to the overview of the first invoice. The other invoices will be available in the task list."
                }
            },
            "new": "New Invoice",
            "payment_conditions": "Payment Conditions",
            "discount1": "Discount 1",
            "discount2": "Discount 2",
            "discount_percentage": "Percentage",
            "discount_amount": "Amount",
            "discount_days": "Days",
            "discount_payment_date": "Date",
            "recognition_data_title": "Recognition Raw Data",
            "no_recognition_data": "No data recognized.",
            "repeat_recognition_btn": "Repeat Document Recognition",
            "repeat_recognition_confirm_btn": "Confirm",
            "repeat_recognition_confirm_msg": "The existing data in the form will be overwritten. Are you sure you want to proceed?",
            "repeat_recognition_confirm_title": "Repeat Document Recognition",
            "repeat_recognition_feedback_msg": "Document recognition is being processed in the background and may take a few seconds.",
            "select_columns_btn": "Select columns",
            "export_to_datev_btn": "Submit to DATEV",
            "export_to_datev_csv_btn": "Export DATEV CSV",
            "export_to_ms365_btn": "Submit to D365",
            "export_btn_incomplete_form_tooltip": "Please complete the invoice data.",
            "export_btn_send_to_clearing_tooltip": "To retransmit to D365, please resend to accounting via the 'Clearing' step.",
            "export_error": "<b>The invoice could not be submitted</b>.<br>",
            "no_export_config_datev_tooltip": "No connection to <b>{export_name}</b> has been set up. <br>No DATEV client has been assigned in the configuration for the selected company <b>{client_name}</b>. <br>If necessary, contact a person with administrative rights <br>to set up the connection in the administration area.",
            "no_export_config_tooltip": "No connection to {export_name} has been set up.<br>Please contact a person with administrative rights to set up the connection in the administrative area.",
            "confirm_before_leave_msg": "Your invoice has not been saved. Are you sure you want to leave the page?",
            "can_not_edit": "You don't have permission to edit the data. To do so please send the invoice to clearing using Actions.",
            "recognized_client_id": "The company was successfully identified based on the <b>VAT ID</b> and <b>name</b>.<br> <b>Company:</b> {client_name}",
            "user_client_id": "The company was assigned based on the <b>user profile</b> of the uploader.<br><b>Company:</b> {client_name}",
            "no_client_recognized": "No company could be identified based on the <b>VAT ID</b> or <b>name</b>.<br> Please check if the company data in liftbase matches the data on the invoice.",
            "recognized_supplier_id": "The supplier was successfully identified based on the <b>IBAN</b>, <b>VAT ID</b> and <b>name</b>.<br> <b>Supplier:</b> {supplier_name}",
            "no_supplier_recognized": "No supplier could be identified based on the <b>VAT ID</b> or <b>name</b>.<br> Please check if the supplier data in liftbase matches the data on the invoice.",
            "total_brutto_missing": "Please enter the Total Gross.",
            "total_brutto_zero": "Must not have an amount of €0.",
            "total_brutto_does_not_match_with_positions": "Total amount must match the sum of the positions.",
            "combination_invalid": "The combination of ledger account, cost center, and profit center is not allowed in D365.",
            "possible_combinations": "The following combinations are allowed",
            "current_combination": "Current combination",
            "combination": "Combination",
            "min_approval_one_person": "1 person",
            "min_approval_qty": "{qty} different people",
            "min_approval_error_msg": "The approval is not sufficient for this invoice. Please add another person for approval. This invoice must be approved by <b>at least {min_approval_msg}</b>.",
            "min_approval_error_title": "Approval not sufficient",
            "no_approval_limit_msg": "Your approval limit is not sufficient for this invoice. Please add another person for approval who has the necessary approval limit.",
            "no_approval_limit_title": "Approval limit",
            "confirm_without_approval_limit_msg": "None of the selected persons have the necessary approval limit for this invoice amount. The necessary approvals can be added later. Are you sure you want to proceed?",
            "confirm_without_approval_limit_title": "Approval limit",
            "lines": {
                "add_tooltip": "Add Invoice Line",
                "get_from_recognition": "Take from document recognition",
                "legend": {
                    "description": "Legend",
                    "no_order": "Item not included in the order.",
                    "variance": "Variance in marked columns.",
                    "not_invoiced": "Ordered item not found on the invoice.",
                    "matched": "Item in order and on invoice is identical."
                },
                "form": {
                    "item": "Item",
                    "new": "New Invoice Line",
                    "validation": {
                        "description_required": "Please enter a name.",
                        "item_number_required": "Please enter an item number.",
                        "tax_percentage_required": "Please select a valid tax.",
                        "unit_net_price_required": "Please enter a valid number.",
                        "quantity_required": "Please enter a valid number."
                    },
                    "tour": {
                        "name": "Provide a meaningful name for the product.",
                        "number": "Here, you can enter the item number of the product.",
                        "price": "Enter a tax rate and a unit net price. The gross price will be calculated using the tax rate."
                    }
                },
                "table": {
                    "source": "Source",
                    "item_number": "Item No.",
                    "name": "Name",
                    "quantity": "Qty",
                    "tax": "Tax (%)",
                    "netto_price": "Unit Net (€)",
                    "total_netto_price": "Total Net (€)",
                    "tax_amount": "Total Tax (€)",
                    "total_brutto_price": "Total Gross (€)",
                    "cost_center": "Cost Center",
                    "profit_center": "Profit Center",
                    "ledger_account": "Ledger Account",
                    "tax_group": "Tax Group",
                    "tax_item_group": "Tax Item Group",
                    "open_bcase": "Open Document",
                    "no_order_position": "No order positions found. Please assign a <b>Purchase Order (PO)</b> and a <b>Supplier</b> to the invoice.",
                    "copy_from_procurement": "Insert All Items from Purchase Order",
                    "no_order_tooltip": "No purchase order linked yet.",
                    "no_supplier_tooltip": "No supplier linked yet.",
                    "validation_error": "Please correct the errors in the table."
                }
            },
            "export_dialog": {
                "title": {
                    "D365": "Create Journal",
                    "DATEV": "Submit to DATEV",
                    "DATEV CSV": "DATEV CSV Export"
                },
                "description": "The following documents will be submitted to {export_name}.","description_csv": "The following documents will be exported for {export_name}.",
                "btn_confirm": "Submit",
                "btn_confirm_csv": "Export",
                "btn_finish": "Close",
                "export_feedback_csv": "Exported",
                "export_feedback": "Submitted",
                "exported_csv": "Exported",
                "exported": "Submitted",
                "continue_background": "Continue in background",
                "exported_at": "Last exported",
                "is_loading": "Submission in progress",
                "is_loading_csv": "Export in progress"
            },
            "select_columns_dialog": {
                "title": "Select columns",
                "select_fields": "The following columns will be displayed under <i>My Tasks</i> and <i>All Documents</i>.",
                "btn_confirm": "Apply",
                "btn_finish": "Close"
            },
            "copy_procurement_dialog": {
                "title": "Copy Data from Purchase Order",
                "message": "Would you like to copy some data from the purchase order to this invoice?",
                "overwrite_checkbox": "Overwrite existing data in the form.",
                "select_supplier_msg": "Select the supplier from which you want to copy the data:",
                "select_supplier_placeholder": "Select Supplier",
                "confirm_btn": "Confirm"
            },
            "validation": {
                "invoice_date_required": "Please enter an invoice date.",
                "due_date_required": "Please enter a due date.",
                "due_date_invalid1": "Due date must be greater than the document date.",
                "delivery_date_required": "Please enter a delivery date.",
                "ledger_account_required": "Please select a ledger account.",
                "branch_required": "Please select a branch.",
                "posting_key_required": "Please select a posting key.",
                "supplier_required": "Please select a supplier.",
                "cost_center_required": "Please select a cost center.",
                "profit_center_required": "Please select a profit center.",
                "employee_required": "Please select an employee.",
                "d365_department_required": "Please select a D365 department.",
                "financial_dimension_required": "Please select a financial dimension.",
                "client_required": "Please select a company.",
                "number_required": "Please enter the invoice number.",
                "number_invalid_d365": "Must contain no more than 20 characters.",
                "number_invalid": "Must contain up to 36 alphanumeric characters, no umlauts, and characters $ % & * + - /.",
                "notes_length": "Must not exceed 120 characters.",
                "discount_percentage_invalid1": "Discount percentage must be greater than 0.",
                "discount_percentage_invalid2": "Discount 1 must be greater than discount 2.",
                "discount_amount_invalid1": "Only positive amounts are acceptable for positive invoice amounts.",
                "discount_amount_invalid2": "Only negative amounts are acceptable for negative invoice amounts.",
                "discount_amount_invalid3": "Discount 1 must be greater than discount 2.",
                "discount_amount_invalid4": "Discount must be less than the total amount.",
                "discount_amount_invalid5": "The sum of the discount amounts must be less than the amount.",
                "discount_date_invalid1": "Discount dates 1 and 2 must be between invoice and due date.",
                "discount_date_invalid2": "Discount date 1 must be before discount date 2.",
                "missing_discount_field": "All discount fields must be filled in."
            }
        }

    },
    "invoice_line": {
        "item_number": "Item Number",
        "quantity": "Quantity",
        "unit_net_price": "Unit Net Price",
        "tax_percentage": "Tax"
    },
    "location": {
        "name": "Name",
        "number": "Number",
        "address": "Address",
        "form_sequence": "name,number,address"
    },
    "spreadsheet": {
        "add_row": "New Row",
        "download": "Download Table",
        "save": "Save Table"
    },
    "audit_log": {
        "description": "Edits",
        "history": "Edits",
        "table": "Table",
        "lb_user_id": "User",
        "data_before": "Before",
        "data_after": "After",
        "created_at": "Date",
        "type": {
            "insert": "Added",
            "update": "Edited",
            "delete": "Removed"
        },
        "msg": {
            "deleted": "removed",
            "inserted": "added",
            "was_deleted": "Was removed.",
            "was_inserted": "Was added."
        },
        "none": "none",
        "empty": "No edits available."
    },
    "txt_before_leave_page": "Your changes may not be saved. Are you sure you want to proceed?",
    "easybill_invoice": {
        "from_tenant": "From Tenant",
        "no_invoice": "No invoice created yet.",
        "number": "Number",
        "service_date": "Period",
        "from": "From",
        "until": "to",
        "total": "Total",
        "due_date": "Due Date",
        "status": "Status",
        "paid_at": "Paid On",
        "payment_missing": "Pending",
        "sepa": "SEPA",
        "tour": {
            "invoice_table": "Here you can find a detailed overview of all liftbase invoices, including their status. The invoices can be downloaded using a button."
        }
    },
    "configuration": {
        "description": "Settings",
        "sepa_number": "Creditor Identification Number",
        "sepa_mandate_reference": "Mandate Reference",
        "remove_sepa_msg": "Are you sure you want to revoke the SEPA direct debit?",
        "remove_sepa_btn": "Revoke",
        "system-modules-procurement": "Module - Purchase Requests",
        "system-modules-invoice": "Module - Invoices",
        "system-modules-permissions": "Module - Permission Groups",
        "system-modules-multilingualism": "Module - Multilingual Master Data",
        "system-modules-invoice-export": "Module - Invoice Export to",
        "purchaseorder-defaults-selectproduct": "Role Presets - Person “Product Selection”",
        "purchaseorder-defaults-sendorder": "Role Presets - Person “Order Placement”",
        "purchaseorder-defaults-clearing": "Role Presets - Person “Purchase Clearing”",
        "purchaseorder-defaults-consignee": "Role Presets - Person “Goods Receipt”",
        "invoice-defaults-manual_check": "Role Presets - Person “Manual Check (Invoice)”",
        "invoice-defaults-clearing": "Role Presets - Person “Accounting Clearing”",
        "purchaseorder-defaults-init_seq": "Purchase Requests - Order Numbers From",
        "purchaseorder-product-require_product_group": "Purchase Requests - All Products Must Be Assigned to a Product Group.",
        "purchaseorder-product-require_product_selection": "Purchase Requests - Purchase Request Process",
        "email-send-order": "Orders - E-Mail Order",
        "purchaseorder-pdf-free-text": "Orders - Order PDF",
        "type_email-send-order": "html-i18n",
        "type_purchaseorder-pdf-free-text": "html-i18n",
        "invoice-recognition-lines": "Invoices - Automatically Create Invoice Lines from Document Recognition.",
        "invoice-form-procurement-required": "Invoices - Purchase Request Link Required.",
        "notifications-mail-interval": "Notifications - E-Mail Interval in Minutes",
        "punchout-amazon-identity": "Integrations - Punch-out Amazon - Identity",
        "punchout-amazon-secret": "Integrations - Punch-out Amazon - Secret",
        "punchout-amazon-url": "Integrations - Punch-out Amazon - Punch-out URL",
        "punchout-amazon-order-url": "Integrations - Punch-out Amazon - Order URL",
        "punchout-amazon-email": "Integrations - Punch-out Amazon - E-Mail",
        "punchout-amazon-supplier": "Integrations - Punch-out Amazon - Default Supplier",
        "punchout-mercateo-identity": "Integrations - Punch-out Mercateo - Identity",
        "punchout-mercateo-secret": "Integrations - Punch-out Mercateo - Secret",
        "punchout-mercateo-url": "Integrations - Punch-out Mercateo - Punch-out URL",
        "punchout-mercateo-order-url": "Integrations - Punch-out Mercateo - Order URL",
        "punchout-mercateo-email": "Integrations - Punch-out Mercateo - E-Mail",
        "punchout-mercateo-supplier": "Integrations - Punch-out Mercateo - Default Supplier",
        "punchout-rajapack-identity": "Integrations - Punch-out RAJA - Identity",
        "punchout-rajapack-secret": "Integrations - Punch-out RAJA - Secret",
        "punchout-rajapack-url": "Integrations - Punch-out RAJA - Punch-out URL",
        "punchout-rajapack-order-url": "Integrations - Punch-out RAJA - Order URL",
        "punchout-rajapack-email": "Integrations - Punch-out RAJA - E-Mail",
        "punchout-rajapack-supplier": "Integrations - Punch-out RAJA - Default Supplier",
        "punchout-conrad-identity": "Integrations - Punch-out Conrad - Identity",
        "punchout-conrad-secret": "Integrations - Punch-out Conrad - Secret",
        "punchout-conrad-url": "Integrations - Punch-out Conrad - Punch-out URL",
        "punchout-conrad-order-url": "Integrations - Punch-out Conrad - Order URL",
        "punchout-conrad-email": "Integrations - Punch-out Conrad - E-Mail",
        "punchout-conrad-supplier": "Integrations - Punch-out Conrad - Default Supplier",
        "punchout-thgeyer-identity": "Integrations - Punch-out TH-GEYER - Identity",
        "punchout-thgeyer-secret": "Integrations - Punch-out TH-GEYER - Secret",
        "punchout-thgeyer-url": "Integrations - Punch-out TH-GEYER - Punch-out URL",
        "punchout-thgeyer-supplier": "Integrations - Punch-out TH-GEYER - Default Supplier",
        "punchout-reichelt-identity": "Integrations - Punch-out Reichelt - Identity",
        "punchout-reichelt-secret": "Integrations - Punch-out Reichelt - Secret",
        "punchout-reichelt-url": "Integrations - Punch-out Reichelt - Punch-out URL",
        "punchout-reichelt-supplier": "Integrations - Punch-out Reichelt - Default Supplier",
        "validation": {
            "bic_invalid": "Please enter a valid BIC.",
            "iban_invalid": "Please enter a valid IBAN number."
        },
        "datev": {
            "description": "DATEV",
            "connection": "Connection with DATEV",
            "status_active": "Active",
            "status_inactive": "Inactive",
            "from": "From",
            "until": "Until",
            "load_clients": "Load DATEV Clients",
            "remove_connection": "Remove Connection",
            "deactivate_connection": "Deactivate connection",
            "add_connection": "Set Up Connection",
            "connected_by": "Connection established by {name}.",
            "apps_manager": "Here you can manage the <a href=\"https://apps.datev.de/tokrevui\" target=\"_blank\">applications connected with DATEV</a>.",
            "no_client_found": "No DATEV client found.",
            "msg_no_clients": "No entries found or you do not have the necessary permissions or the connection needs to be set up again.",
            "fiscal_year_start": "Fiscal Year Start",
            "account_length": "Account Length",
            "is_accounts_payable_ledger_available": "Accounts Payable Ledger",
            "is_accounts_receivable_ledger_available": "Accounts Receivable Ledger",
            "client_number": "Client Number",
            "consultant_number": "Consultant Number",
            "client_name": "Client",
            "liftbase_client": "liftbase Company",
            "save_client": "Save Company",
            "change_client": "Change Company"
        },
        "ms365": {
            "description": "D365",
            "connection": "Connect with D365",
            "status_active": "Active",
            "status_inactive": "Inactive",
            "connect_and_load_data": "Establish connection and load data",
            "connect": "Establish connection",
            "load_data": "Load all data",
            "remove_connection": "Remove Connection",
            "add_connection": "Set Up Connection",
            "no_client_found": "No D365 tenants were found.",
            "msg_no_clients": "No entries found, or you do not have the necessary permissions, or the connection needs to be set up again.",
            "client_number": "D365 Company Number",
            "client_name": "D365 Company",
            "liftbase_client": "liftbase Company",
            "save_client": "Save Company",
            "change_client": "Change Company",
            "sync_running": "Data is loading. This may take a few minutes.",
            "last_sync_at": "Last loaded on {last_sync_at}",
            "journal_name": "Journal Name",
            "org_url": "Org URL",
        },
        "datev-csv": {
            "description": "DATEV CSV",
        },
        "template": {
            "module-procurement": {
                "group": "Modules",
                "label": "Purchase Order",
                "tour": [
                    {
                        "target": ".step-modules",
                        "content": "<p>Please select the liftbase modules you want to activate. You can find information on costs at <a href=\"liftbase.de/preise\" target=\"_blank\">liftbase.de/preise</a>.</p>"
                    }
                ]
            },
            "module-invoice": {
                "group": "Modules",
                "label": "Invoice"
            },
            "module-permissions": {
                "group": "Modules",
                "label": "Permission Group"
            },
            "module-multilingualism": {
                "group": "Modules",
                "label": "Multilingual Master Data"
            },
            "insolvency-law-fields": {
                "group": "Modules",
                "label": "Show insolvency law fields"
            },
            "module-invoice-export": {
                "group": "Modules",
                "label": "Invoice export to",
                "options": [
                    {
                        "value": "datev",
                        "content": "DATEV"
                    },
                    {
                        "value": "ms365",
                        "content": "D365 Dynamics"
                    },
                    {
                        "value": "datev-csv",
                        "content": "DATEV CSV"
                    }
                ]
            },
            "procurement-select-product": {
                "group": "Role Defaults",
                "label": "Person \"Product Selection\"",
                "tour": [
                    {
                        "target": ".step-assignment",
                        "content": "<p>Here you can set global role defaults. If no role defaults are defined for the company or the department, the people entered here will be assigned to the respective steps in the workflow of purchase orders and invoices.</p>"
                    }
                ]
            },
            "procurement-send-order": {
                "group": "Role Defaults",
                "label": "Person \"Order Placement\""
            },
            "procurement-clearing": {
                "group": "Role Defaults",
                "label": "Person \"Procurement Clearing\"",
                "hint": "For clearing issues in procurement processes."
            },
            "procurement-consignee": {
                "group": "Role Defaults",
                "label": "Person \"Receipt of Goods\""
            },
            "invoice-manual-check": {
                "group": "Role Defaults",
                "label": "Person \"Manual Check (Invoice)\""
            },
            "invoice-clearing": {
                "group": "Role Defaults",
                "label": "Person \"Accounting Clearing\"",
                "hint": "For resolving issues during invoice processing."
            },
            "procurement-init-seq": {
                "group": "Purchase Orders",
                "label": "Starting Order Numbers",
                "hint": "Enter a number from which order numbers should start.",
                "validation": {
                    "interval": "Must be between 0 and 999999999.",
                    "type": "Should only contain whole numbers."
                },
                "tour": [
                    {
                        "target": ".step-procurement",
                        "content": "Here you will find settings related to purchase orders. Configure the starting number for purchase orders and whether all products in purchase orders must be assigned to a product group."
                    }
                ]
            },
            "procurement-require-product-group": {
                "group": "Purchase Orders",
                "label": "All Products Must Belong to a Product Group."
            },
            "procurement-require-product-selection": {
                "group": "Purchase Orders",
                "label": "Purchase Orders ",
                "options": [
                    {
                        "value": "true",
                        "content": "with product selection<br><ul><li>Create Purchase Order</li><li>Product Selection</li><li>Approval</li><li>Order</li><li>Receipt of goods</li></ul>"
                    },
                    {
                        "value": "false",
                        "content": "without product selection<br><ul><li>Create Purchase Order</li><li>Approval</li><li>Order</li><li>Receipt of goods</li></ul>"
                    }
                ]
            },
            "email-send-order": {
                "group": "Orders",
                "label": "Email Order",
                "hint": "Email message generated during the order placement step, including the order PDF, and sent to the supplier.",
                "default_value": "Dear Sir/Madam,<br>I am sending you our order as a PDF attachment. Please provide us with an order confirmation.",
                "tour": [
                    {
                        "target": ".step-email-order-free-text",
                        "content": "Compose a text here that will be included in the email message generated during the order placement step, including the order PDF, and sent to the supplier."
                    },
                    {
                        "target": ".step-pdf-free-text",
                        "content": "Compose a text here that will be displayed on the generated order PDF during the order placement step, which will be subsequently sent to the supplier."
                    }
                ]
            },
            "procurement-pdf-free-text": {
                "group": "Orders",
                "label": "Purchase Order PDF",
                "hint": "Message displayed on the generated purchase order PDF during the order placement step, which will be subsequently sent to the supplier.",
                "default_value": ""
            },
            "invoice-recognition-lines": {
                "group": "Invoices",
                "label": "Automatically take the positions that document recognition has identified."
            },
            "invoice-procurement-required": {
                "group": "Invoices",
                "label": "Linking of purchase request required."
            },
            "invoice-approval": {
                "group": "Invoices",
                "label": "When should an invoice be approved?",
                "options": [
                    {
                        "value": "always",
                        "content": "<b>Always</b><br>Invoices must always be approved."
                    },
                    {
                        "value": "if-no-order",
                        "content": "<b>Only if no PO is assigned</b><br>Approval is only required if no purchase order (PO) is present."
                    },
                    {
                        "value": "never",
                        "content": "<b>Never</b><br>Invoices do not require separate approval."
                    }
                ]
            },
            "notifications-mail-interval": {
                "group": "Notifications",
                "label": "Email Interval in Minutes",
                "tour": [
                    {
                        "target": ".step-notification",
                        "content": "Set a global frequency for email notifications about new tasks. Email notifications are only sent when a person receives a new task."
                    }
                ]
            },
            "product-default-tax": {
                "group": "Product",
                "label": "Default tax rate (%)"
            },
            "product-default-currency": {
                "group": "Product",
                "label": "Default currency"
            },
            "product-default-unit": {
                "group": "Product",
                "label": "Default unit"
            },
            "punchout-amazon-identity": {
                "group": "Integrations",
                "subgroup": "Punch-out Amazon",
                "label": "Identity",
                "tour": [
                    {
                        "target": ".step-punchout-amazon",
                        "content": "Here you can set up your access to integrated marketplaces like Amazon and Mercateo. Instructions can be found <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">in our manual</a>."
                    }
                ]
            },
            "punchout-amazon-secret": {
                "group": "Integrations",
                "subgroup": "Punch-out Amazon",
                "label": "Secret"
            },
            "punchout-amazon-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Amazon",
                "label": "Punch-out URL"
            },
            "punchout-amazon-order-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Amazon",
                "label": "Order URL"
            },
            "punchout-amazon-email": {
                "group": "Integrations",
                "subgroup": "Punch-out Amazon",
                "label": "Email",
                "hint": "The email address of the main Amazon account. <br>Leave blank to transmit the email address of the logged-in person. <br>May require an Amazon account per person."
            },
            "punchout-amazon-supplier": {
                "group": "Integrations",
                "subgroup": "Punch-out Amazon",
                "label": "Supplier",
                "hint": "To which supplier should orders from this Punch-out provider be assigned (e.g., in reports)?"
            },
            "punchout-mercateo-identity": {
                "group": "Integrations",
                "subgroup": "Punch-out Mercateo",
                "label": "Identity"
            },
            "punchout-mercateo-secret": {
                "group": "Integrations",
                "subgroup": "Punch-out Mercateo",
                "label": "Secret"
            },
            "punchout-mercateo-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Mercateo",
                "label": "Punch-out URL"
            },
            "punchout-mercateo-order-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Mercateo",
                "label": "Order URL"
            },
            "punchout-mercateo-email": {
                "group": "Integrations",
                "subgroup": "Punch-out Mercateo",
                "label": "Email",
                "hint": "The email address of the main Mercateo account. <br>Leave blank to transmit the email address of the logged-in person. <br>May require a Mercateo account per person."
            },
            "punchout-mercateo-supplier": {
                "group": "Integrations",
                "subgroup": "Punch-out Mercateo",
                "label": "Supplier",
                "hint": "To which supplier should orders from this Punch-out provider be assigned (e.g., in reports)?"
            },
            "punchout-rajapack-identity": {
                "group": "Integrations",
                "subgroup": "Punch-out RAJA",
                "label": "Identity"
            },
            "punchout-rajapack-secret": {
                "group": "Integrations",
                "subgroup": "Punch-out RAJA",
                "label": "Secret"
            },
            "punchout-rajapack-url": {
                "group": "Integrations",
                "subgroup": "Punch-out RAJA",
                "label": "Punch-out URL"
            },
            "punchout-rajapack-order-url": {
                "group": "Integrations",
                "subgroup": "Punch-out RAJA",
                "label": "Order URL"
            },
            "punchout-rajapack-email": {
                "group": "Integrations",
                "subgroup": "Punch-out RAJA",
                "label": "Email"
            },
            "punchout-rajapack-supplier": {
                "group": "Integrations",
                "subgroup": "Punch-out RAJA",
                "label": "Supplier",
                "hint": "To which supplier should orders from this Punch-out provider be assigned (e.g., in reports)?"
            },
            "punchout-conrad-identity": {
                "group": "Integrations",
                "subgroup": "Punch-out Conrad",
                "label": "Identity"
            },
            "punchout-conrad-secret": {
                "group": "Integrations",
                "subgroup": "Punch-out Conrad",
                "label": "Secret"
            },
            "punchout-conrad-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Conrad",
                "label": "Punch-out URL"
            },
            "punchout-conrad-order-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Conrad",
                "label": "Order URL"
            },
            "punchout-conrad-email": {
                "group": "Integrations",
                "subgroup": "Punch-out Conrad",
                "label": "Email"
            },
            "punchout-conrad-supplier": {
                "group": "Integrations",
                "subgroup": "Punch-out Conrad",
                "label": "Supplier",
                "hint": "To which supplier should orders from this Punch-out provider be assigned (e.g., in reports)?"
            },
            "punchout-thgeyer-identity": {
                "group": "Integrations",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Identity"
            },
            "punchout-thgeyer-secret": {
                "group": "Integrations",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Secret"
            },
            "punchout-thgeyer-url": {
                "group": "Integrations",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Punch-out URL"
            },
            "punchout-thgeyer-supplier": {
                "group": "Integrations",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Default Supplier Company",
                "hint": "To which supplier should orders from this Punch-out provider be assigned (e.g., in reports)?"
            },
            "punchout-reichelt-identity": {
                "group": "Integrations",
                "subgroup": "Punch-out Reichelt",
                "label": "Identity"
            },
            "punchout-reichelt-secret": {
                "group": "Integrations",
                "subgroup": "Punch-out Reichelt",
                "label": "Secret"
            },
            "punchout-reichelt-url": {
                "group": "Integrations",
                "subgroup": "Punch-out Reichelt",
                "label": "Punch-out URL"
            },
            "punchout-reichelt-supplier": {
                "group": "Integrations",
                "subgroup": "Punch-out Reichelt",
                "label": "Default Supplier Company",
                "hint": "To which supplier should orders from this Punch-out provider be assigned (e.g., in reports)?"
            },
            "tour": [
                {
                    "target": "",
                    "content": ""
                }
            ]

        },
        "tour": {
            "tab_general": "Here, you can provide the name of your company for the liftbase environment.",
            "tab_contact": "Enter your company's contact information here. This data will be used for sending and delivering invoices for liftbase usage.",
            "tab_bank": "Here, you can set up a SEPA direct debit for liftbase billing or revoke it.",
            "tab_datev": "Here, you can set up the integration with DATEV. Log in to DATEV first to load all available clients. Link these clients with the companies registered in liftbase and individually connect each client with DATEV to enable the DATEV export. This connection has a validity of 2 years and extends each time it's used for an export.",
            "tab_marketplaces": "Here you can set up your access to integrated marketplaces such as Amazon and Mercateo. You can find a guide <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">in our manual</a>."
        }
    },
    "error_title": "Error",
    "request_error": "Error executing the request. Please check your internet connection or try again later.",
    "saved_success": "The document was saved successfully.",
    "save_error": "Error saving the document.",
    "help_page": {
        "title": "Help",
        "popup_feedback_msg": "Your browser is set up correctly. You can now close this window.",
        "punchout_error_msg": "An error occurred. Please check the Punch-out settings or contact a person with administration rights.",
        "popup_did_not_open": "If no new window opened, pop-ups are blocked in your browser. Limitations may therefore occur during use.",
        "test_email_msg": "An email program or a list of email programs should have opened. Set up an email program from the list if necessary.",
        "tour_restart": "The tour has been restarted.",

        "manual_title": "Manual",
        "manual_msg": "<p>In the manual, you will find various articles and guides about the usage and setup of liftbase.</p>",
        "manual_open_btn": "Open Manual",

        "function_title": "Test Functions",

        "popup_msg": "<p>Here, you can test if pop-up windows are allowed in your browser, which are required for your product selection on external marketplaces like Amazon and Mercateo.</p>",
        "popup_test_btn": "Test Pop-up Blocker",

        "email_msg": "<p>Here, you can test if you can open emails with an email program. The email function is used, for example, to send orders to suppliers.</p>",
        "email_test_btn": "Test Email Function",

        "tour_title": "Tour",
        "tour_msg": "<p>To get a reintroduction to liftbase, you can restart the tour here.</p>",
        "tour_btn": "Restart Tour"
    },
    "dashboard_page": {
        "title": "Dashboard",
        "no_option": "None",
        "date_picker": {
            "start_placeholder": "Start date",
            "end_placeholder": "Start date",
            "separator": "to"
        },
        "ordered_by": "Created by",
        "count_by_status": "Count by status",
        "diagrams": {
            "title": "Charts",
            "count_by_status": "Count per status",
            "value_by_status": "Values per status (Net in EUR)",
            "count_by_department": "Count per department",
            "value_by_department": "Values per department (Net in EUR)",
            "count_by_cost_center": "Count per cost center",
            "value_by_cost_center": "Values per cost center (Net in EUR)",
            "count_by_ledger_account": "Number by ledger account",
            "value_by_ledger_account": "Amounts by ledger account (Net in EUR)"
        },
        "budgets": {
            "title": "Budgets and Expenses",
            "approved_status": "Approved",
            "in_approval_status": "In Approval",
            "open_status": "Open",
            "no_budget": "Without Budget",
            "from": "From",
            "no_name": "No name"
        },
        "products": {
            "title": "Products",
            "approved_status": "Approved",
            "in_approval_status": "In Approval"
        },
        "no_data": "No purchase orders found.",
        "no_permission": "You do not have permission for the dashboard.",
        "from_budget": "from budget",
        "data": "Expenses",
        "shortcuts": {
            "this_week": "This week",
            "last_week": "Last week",
            "this_month": "This month",
            "last_month": "Last month",
            "last_3_months": "Last 3 months",
            "last_6_months": "Last 6 months",
            "this_year": "This year",
            "last_year": "Last year"
        },
        "tour": {
            "title": "The dashboard offers you statistics and evaluation features for your purchase orders.",
            "filters_title": "Filter",
            "filters": "First select a desired period and then filter the purchase orders by company, ordered by, cost center or department.",
            "count_by_status_title": "Count by status",
            "count_by_status": "This shows how many of the pre-filtered purchase orders are currently in the respective statuses.",
            "graphic_title": "Charts",
            "graphic": "This is a graphical representation of the number or amounts of purchase orders, grouped by status, departments or cost centers.",
            "budget_title": "Budgets and expenses",
            "budget": "This area gives you an overview of the budgets and expenses of your cost centers. The colored bars show the usage of the budgets and how many of the expenses are still in approval.",
            "progress_bar_cost_center_title": "Progress bars by cost center",
            "progress_bar_cost_center": "Here you can unfold a more detailed overview of the different budgets of a cost center. The orders placed can also be listed for the individual budgets. Expenses without a budget are listed under a separate bar."
        }
    },
    "overview_page": {
        "title": "Overview",
        "procurement_abbr": "PO",
        "invoice_abbr": "IN",
        "draft_abbr": "DR",
        "delivery_note_abbr": "DN",
        "all_docs": "All documents",
        "my_tasks": "My tasks",
        "procurements": "Purchase orders",
        "invoices": "Invoices",
        "drafts": "Drafts",
        "no_selection": "No document selected.",
        "comment_box_placeholder": "Your comment...",
        "comment_box_btn": "Comment",
        "edit_filename_title": "Edit filename",
        "edit_filename_msg": "Please enter the new filename. <br> Old name: {filename}",
        "duplicate_invoice": {
            "message": "Duplicate - At least one invoice with the same invoice number was found:",
            "invoice_number": "Invoice Number",
            "invoice_date": "Date"
        },
        "task_list": {
            "name": "Name",
            "status": "Status",
            "date": "Date",
            "draft": "Draft"
        },
        "searchbar": {
            "search": "Search",
            "filter_by": "Filter by",
            "created_at": "Creation date",
            "timeline": "Timeline",
            "last_used": "Last used",
            "favorites": "Favorites",
            "no_search": "None",
            "timeline_props": {
                "editor_name": "Editor",
                "entry_type": "Timeline Step",
                "current_editor_name": "Current Editor",
                "current_entry_type": "Current Step"
            },
            "procurement_props": {
                "order_number_formatted": "Order number",
                "name": "Name",
                "creator_name": "Created by",
                "client_name": "Company",
                "department_name": "Department",
                "cost_center_name": "Cost center name",
                "cost_center_number": "Cost center number",
                "consignee_name": "Receipt of goods",
                "status": "Status",
                "product_names": "Product",
                "product_numbers": "Product number",
                "supplier_names": "Supplier"
            },
            "invoice_props": {
                "invoice_number": "Invoice number",
                "invoice_number_formatted": "Internal LB Number",
                "procurement_number": "Order number",
                "creator_name": "Uploaded by",
                "client_name": "Company",
                "cost_center_name": "Cost center name",
                "cost_center_number": "Cost center number",
                "supplier_name": "Supplier",
                "ledger_account_name": "Expense account",
                "status": "Status",
                "product_names": "Product",
                "product_numbers": "Product number",
                "due_date": "Due Date"
            },
            "time_ranges": {
                "today": "today",
                "yesterday": "yesterday",
                "past_7_days": "last 7 days",
                "past_30_days": "last 30 days",
                "this_week": "this week",
                "last_week": "last week",
                "this_month": "this month",
                "last_month": "last month",
                "this_year": "this year",
                "last_year": "last year"
            }
        },
        "export": {
            "all_information": "All Information",
            "export_procurement": "Export Purchase Orders",
            "export_documents": "Export Documents",
            "registers": "entries",
            "export": "Export",
            "to_export": "Export",
            "export_with_products": "Export with products",
            "custom_export": "Configured export",
            "select_fields": "Select the fields that should be exported.",
            "select_all": "Select all",
            "deselect_all": "Remove all",
            "general": "General",
            "products": "Products",
            "include_product": "Incl. products",
            "filter": " filter",
            "no_filter": "no filter",
            "labels": {
                "procurement": {
                    "order_number_formatted": "LB-Number",
                    "name": "Name",
                    "creator_name": "Created by",
                    "status": "Status",
                    "total_price_brutto": "Total-Gross",
                    "total_price_netto": "Total-Net",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Creation date",
                    "client_number": "Company number",
                    "client_name": "Company name",
                    "department_name": "Department",
                    "cost_center_number": "Cost center number",
                    "cost_center_name": "Cost center name",
                    "consignee_name": "Receipt of goods",
                    "shipping_date": "Delivery date",
                    "shipping_name": "Delivery address name",
                    "shipping_consignee": "Delivery address name of receiving person",
                    "shipping_dropoff_location": "Delivery address name of drop-off location",
                    "shipping_address": "Delivery address street and no.",
                    "shipping_complement": "Delivery address street 2",
                    "shipping_country": "Delivery address country",
                    "shipping_zipcode": "Delivery address postal code",
                    "shipping_city": "Delivery address city",
                    "shipping_phone_country_code": "Delivery address phone country",
                    "shipping_phone_number": "Delivery address phone number",
                    "billing_name": "Invoice address name",
                    "billing_consignee": "Invoice address name receiving person",
                    "billing_dropoff_location": "Invoice address name of the drop-off location",
                    "billing_address": "Invoice address street and no.",
                    "billing_complement": "Invoice address street 2",
                    "billing_country": "Invoice address country",
                    "billing_zipcode": "Invoice address postal code",
                    "billing_city": "Invoice address city",
                    "billing_phone_country_code": "Invoice address phone country",
                    "billing_phone_number": "Invoice address phone number"
                },
                "product": {
                    "product_name": "Product name",
                    "product_number": "Item number",
                    "product_supplier_name": "Supplier name",
                    "product_supplier_email": "Supplier email",
                    "product_cost_center_name": "Cost center name (product)",
                    "product_cost_center_number": "Cost center number (product)",
                    "product_tax": "Tax",
                    "product_quantity": "Quantity (product)",
                    "product_total_netto": "Total-net (product)",
                    "product_total_brutto": "Total-gross (product)",
                    "product_netto": "Single price net",
                    "product_brutto": "Single price gross",
                    "product_unit": "Unit",
                    "product_img_url": "Image URL",
                    "product_shop_url": "Shop URL",
                    "product_shipping_name": "Delivery address name (product)",
                    "product_shipping_consignee": "Delivery address name receiving person (product)",
                    "product_shipping_dropoff_location": "Delivery address name of the drop-off location (pProduct)",
                    "product_shipping_address": "Delivery address street and no. (product)",
                    "product_shipping_complement": "Delivery address street 2 (product)",
                    "product_shipping_country": "Delivery address country (product)",
                    "product_shipping_zipcode": "Delivery address postal code (product)",
                    "product_shipping_city": "Delivery address city (product)",
                    "product_shipping_phone_country_code": "Delivery address phone country (product)",
                    "product_shipping_phone_number": "Delivery address phone number (product)",
                    "product_received_at": "Last goods receipt (date)",
                    "product_received_by": "Last goods receipt (person)"
                },
                "invoice": {
                    "invoice_number_formatted": "Internal invoice number",
                    "number": "Invoice number",
                    "creator_name": "Uploaded by",
                    "status": "Status",
                    "procurement_name": "Purchase order",
                    "procurement_number": "Order number",
                    "cost_center_name": "Cost center name",
                    "cost_center_number": "Cost center number",
                    "total_price_netto": "Total net",
                    "total_price_brutto": "Total gross",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Uploaded on",
                    "client_name": "Company",
                    "client_number": "Company number",
                    "invoice_date": "Invoice date",
                    "due_date": "Due date",
                    "delivery_date": "Delivery date",
                    "currency": "Currency",
                    "ledger_account_name": "Ledger account name",
                    "ledger_account_number": "Ledger account number",
                    "posting_key_number": "Posting key number",
                    "posting_key_tax": "Posting key tax",
                    "supplier_name": "Supplier"
                }
            }
        },
        "actions": {
            "tour": {
                "procurement_reassignment_title": "Assign/Clearing Purchase Order",
                "procurement_reassignment_msg": "This task is currently with another person. Use this button to send the task to clearing or to assign this task to a third person or yourself. In clearing, purchase orders can also be cancelled.",
                "invoice_reassignment_title": "Assign/Clearing invoice",
                "invoice_reassignment_msg": "This task is currently with another person. Use this button to send the task to clearing or to assign this task to a third person or yourself. In clearing, invoices can also be cleared."
            },
            "skip_confirm_dialog_title": "Cancel document recognition",
            "skip_confirm_dialog_msg": "To check if a document recognition result already exists, refresh the page in your browser. <br>Are you sure you want to cancel the invoice data recognition and enter the invoice data manually?",
            "button_hint": {
                "to_approval": "To approval",
                "to_send_order": "To order",
                "to_accounting": "To Accounting",
                "approve": "Approve",
                "self_assign": "Accept",
                "finish_order": "Complete order",
                "finish_delivery": "Finish",
                "skip_recognition": "Cancel document recognition",
                "to_export": "To Export",
                "default": "Continue",
                "cancel_or_assign_procurement": "Assign/Cancel",
                "cancel_or_assign_invoice": "Assign/Cancel",
                "clearing_or_assign": "Assign/Clearing"
            },
            "assignment_tooltip": "Clearing or Assignment",
            "assignment_dialog": {
                "title_procurement_assign_or_cancel": "Assignment or Cancellation",
                "title_invoice_assign_or_cancel": "Assignment or Cancellation",
                "title_assign": "Assignment",
                "title_clearing_or_assign": "Clearing or Assignment",
                "assign_title": "Assign Current Task to Another Person",
                "assign_msg": "The currently assigned person is not available or not responsible for this task, so another person should take over this task.",
                "clearing_title": "Assign for clearing",
                "clearing_msg": "The currently assigned person is available and responsible for this task, but the process cannot be continued for other reasons. Therefore, you want to initiate a clearing process.",
                "cancel_procurement_title": "Cancellation",
                "cancel_procurement_msg": "The order is irrevocably cancelled and cannot be processed further. Cancelled orders can be found in the overview under the status 'Declined'.",
                "cancel_invoice_title": "Canceling",
                "cancel_invoice_msg": "The invoice is irrevocably cleared and cannot be processed further. Cleared invoices can be found in the overview of all documents under the status 'Cleared'.",
                "assign_to": "Assign to",
                "reason": "Reason",
                "clearing_footer_info": "The current task is cancelled. The selected person is getting a new task for clearing.",
                "reassignment_footer_info": "Only the assigned person of the current task is changed.",
                "cancel_invoice_btn": "Cancel Invoice",
                "cancel_procurement_btn": "Cancel Order",
                "assign_btn": "Assign",
                "clearing_dialog": {
                    "title": "Clearing Person",
                    "procurement_message": "The person in the 'clearing' step results from various defaults of responsible persons per department, company, a global role default and the person who created the order. The person who is at the top of the list is preassigned for the task.",
                    "invoice_message": "The person in the 'clearing' step results from various defaults of responsible persons per department, company, a global role default and the person who uploaded the invoice. The person who is at the top of the list is preassigned for the task.",
                    "department_form": "Department in form",
                    "department_creator": "Department of the ordered-by person",
                    "client_form": "Company in form",
                    "client_creator": "Company of the ordered-by person",
                    "no_selection": "not yet selected",
                    "no_action": "not applicable"
                },
                "cancellation_dialog": {
                    "title_procurement": "Cancellation",
                    "btn_procurement": "Cancel Order",
                    "message_procurement": "Do you really want to cancel this order?",
                    "title_invoice": "Canceling",
                    "btn_invoice": "Cancel Invoice",
                    "message_invoice": "Do you really want to cancel this invoice?"
                }
            }
        },
        "tour": {
            "overview": {
                "search_box": "With the search, you can filter the purchase orders under \"My tasks\" and \"All documents\" and search for various information to find specific purchase orders faster.",
                "tab_tasks": "Here you can see the purchase orders for which you are currently responsible. In the right column, you can see the status of your tasks.",
                "tab_all_procurements": "In this tree structure, you can find all purchase orders, sorted by current status.",
                "refresh_btn": "Here you can refresh the overview of the purchase orders.",
                "type_filter": "Here you can filter by the type of task."
            },
            "procurement": {
                "form_title": "Form of the purchase order",
                "form": "Here you can see information about the purchase order.",
                "approve_btn_title": "Forward the purchase order",
                "approve_btn": "When you have completed a task and want to forward the purchase order to the next person responsible or the next step, press the blue tick.",
                "reassign_btn": "If you do not want to complete the task, because, for example, there are still uncertainties or another person should continue to process the task, you can press the red button. You can then either hand over the task or assign it to someone for clearing. During the clearing process, purchase orders can also be cancelled.",
                "add_comment": "You can add a comment to the purchase order via the comment field. This is then displayed in the timeline.",
                "timeline_title": "Timeline",
                "timeline": "The timeline shows the past and future course of the purchase order as well as the current status (blue) and comments on the purchase order. The sort order can be reversed using the gear icon.",
                "timeline_preferences": "Via this button, display settings can be made on the timeline. For example, it can be set whether the events should be sorted in ascending or descending order and whether changes to the purchase order should be hidden or displayed."
            },
            "invoice": {
                "form_title": "Invoice Form",
                "form": "Here you can enter or edit the information for the invoice.",
                "add_line": "Here you can add an invoice line item.",
                "edit_lines": "Here you can adjust information about the invoice line items.",
                "approve_btn_title": "Forward the invoice",
                "approve_btn": "Once you have completed a task and want to forward the invoice to the next person in charge or the next step, then you can press the blue tick.",
                "reassign_btn": "If you don't want to finish the task, for example because there is an issue to be solved or if another person should continue working on the task, you can press the red button. Then you can either assign the task to someone else or assign it to someone for clearing. During the clearing process, invoices can also be adjusted.",
                "add_comment": "You can add a comment to the invoice via the comment field. This will then be displayed in the timeline.",
                "timeline_title": "Timeline",
                "timeline": "The timeline shows the past and future course of the invoice as well as the current status (blue) and comments on the invoice. The sort order can be reversed using the gear icon.",
                "timeline_preferences": "Via this button, display settings can be made on the timeline. Here it can be set whether the events should be sorted in ascending or descending order and whether edits to the invoice should be hidden or displayed."
            },
            "welcome": {
                "logo_title": "Welcome to liftbase!",
                "logo": "<p>To make it easier for you to start, we will explain the most important things with a tour through liftbase.</p> <p>Use the buttons below or your arrow keys to navigate through the tour.</p>",
                "menu": "In the upper right corner is the main menu, through which you can find all important areas of liftbase.",
                "menu_help": "You can finish the individual sections of the tour at any time and restart them at a later time in the help menu.",
                "menu_admin": "Here you can find the next section of the tour."
            }
        }
    },
    "operator_area": {
        "title": "Operator",
        "tenant_menu": "Tenants",
        "procurement_menu": "Purchase Orders",
        "invoice_menu": "Invoices",
        "no_permission": "You are not authorized to see this area.",
        "tenant": {
            "description": "Tenant",
            "new": "New tenant",
            "edit": "Edit",
            "archive": "Archive",
            "delete": "Delete",
            "activate": "Restore",
            "procurement_count": "PO",
            "user_count": "User",
            "automatic_billing": "Automatic Billing",
            "tabs": {
                "general": "General",
                "invoice_config": "Billing"
            },
            "standard_package": "Standard package",
            "invoice": {
                "items": {
                    "label": "Additional Items",
                    "description": "Description",
                    "price": "Net Amount",
                    "start_date": "Start Date",
                    "end_date": "End Date"
                },
                "tiered_price": {
                    "label": "Tiered prices",
                    "unit_price": "Unit price",
                    "base_price": "Base price",
                    "doc_amount": "Quantity"
                }
            },
            "contact_email_tooltip": "The tenant can initially log in with this email.",
            "no_bank_info": "The data is not yet available and can only be created by the tenant.",
            "no_connection_with_easybill": "This tenant is not yet connected with Easybill. Please register the tenant ID in the Easybill system.",
            "sync_with_easybill": "Retrieve all data from Easybill",
            "update_from_easybill_btn": "Retrieve from Easybill",
            "update_from_easybill_tooltip": "Retrieve tenant from Easybill",
            "send_to_easybill_btn": "Create in Easybill",
            "send_to_easybill_tooltip": "Create tenant in Easybill",
            "create_and_send_invoice_tooltip": "Create invoice in Easybill and send to customer",
            "create_and_send_invoice_btn": "Create/send invoice",
            "open_invoices": "View invoices",
            "delete_procurements": "Delete Purchase Order",
            "delete_invoices": "Delete Invoices",
            "confirm_before_delete_msg": "<p>Are you sure you want to permanently delete this tenant and all its data from the database?</p><p> <i>This action cannot be undone.</i></p><p><b>Enter the name of the tenant:</b></p>",
            "confirm_before_delete_error_msg": "The tenant was not deleted because the name was entered incorrectly.",
            "deleted_msg": "The tenant has been permanently deleted.",
            "updated_msg": "It has been updated.",
            "created_msg": "It has been created.",
            "sync_msg_error": "This tenant is not yet connected with Easybill. Please register the tenant ID in the Easybill system.",
            "create_invoice_dialog": {
                "title": "Create Billing",
                "message": "Choose the billing period.",
                "date_placeholder": "Select a month.",
                "create_btn": "Create"
            },
            "validation": {
                "name_required": "Please enter a name.",
                "name_exists": "The name already exists.",
                "email_required": "Please enter a contact email.",
                "email_invalid": "Please enter a valid email."
            }
        },
        "procurement": {
            "title": "Purchase orders from Tenant",
            "delete_title": "Delete purchase order",
            "result_found": "Hits",
            "delete_result_found": "Delete hits",
            "start_date": "Start date",
            "end_date": "End date",
            "range_separator": "to",
            "confirm_delete_msg": "Are you sure you want to permanently delete the selected purchase orders from the database?",
            "confirm_delete_title": "Delete purchase order",
            "confirm_delete_btn": "Delete"
        },
        "invoice": {
            "title": "Invoices from Tenant",
            "delete_title": "Delete invoices",
            "result_found": "Hits",
            "delete_result_found": "Delete hits",
            "start_date": "Start date",
            "end_date": "End date",
            "range_separator": "to",
            "confirm_delete_msg": "Are you sure you want to permanently delete the selected invoices from the database?",
            "confirm_delete_title": "Delete invoice",
            "confirm_delete_btn": "Delete"
        }
    },
    "timeline": {
        "title": "Timeline",
        "preferences": {
            "order_asc": "Oldest first",
            "order_desc": "Newest first"
        },
        "text": {
            "description_assignment": "The task has been accepted.",
            "cancellation": {
                "name": "{name}",
                "description_past_successful_invoice": "The invoice has been removed and archived.",
                "description_past_successful_procurement": "The purchase order has been canceled and archived.",
                "name_procurement": "Cancellation",
                "name_invoice": "Removal"
            },
            "start": {
                "name": "New Purchase Order",
                "description_past_successful": "New purchase order has been created."
            },
            "select-product": {
                "is_searchable": true,
                "simple_name": "Product selection",
                "name": "Product selection (Stage {index})",
                "description_past_unsuccessful": "Product selection has been cancelled.",
                "description_past_successful": "Products for this order have been selected.",
                "description_present": "Please select products for this order.",
                "description_future": "Products for this order need to be selected."
            },
            "approval": {
                "is_searchable": true,
                "simple_name": "Approval",
                "name": "Approval (Stage {index})",
                "description_past_unsuccessful": "Approval of the order was cancelled.",
                "description_past_successful": "Purchase order has been reviewed and approved.",
                "description_present": "Please review and approve the purchase order.",
                "description_future": "Purchase order needs to be reviewed and approved."
            },
            "send-order": {
                "is_searchable": true,
                "name": "Order placement",
                "description_past_unsuccessful": "Order placement was cancelled.",
                "description_past_successful": "Order has been placed with the supplier.",
                "description_present": "Please place the order with the supplier.",
                "description_future": "Order must be placed with supplier."
            },
            "delivery": {
                "is_searchable": true,
                "name": "Delivery Confirmation",
                "description_past_unsuccessful": "Confirmation of receipt of goods has been cancelled.",
                "description_past_successful": "Goods have been delivered and receipt has been confirmed.",
                "description_present": "Please confirm the receipt of goods.",
                "description_future": "Receipt of goods must be confirmed."
            },
            "reassignment": {
                "name": "Assignment",
                "description_past_unsuccessful": "",
                "description_past_successful": "assigned the task to {name}.",
                "description_past_successful_from_group": "accepted the task."
            },
            "clearing": {
                "is_searchable": true,
                "name": "Clearing",
                "description_past_unsuccessful": "Clearing was cancelled.",
                "description_past_successful": "Uncertainties were cleared.",
                "description_present": "Please clear uncertainties concerning the order.",
                "description_finished_unsuccessful": "Order was cancelled and archived."
            },
            "order-sent-internal": {
                "name": "System message",
                "description_past_successful": "The order <b>({first_product_name})</b> with the supplier <b>{supplier}</b> has been placed and confirmed."
            },
            "order-sent-punchout": {
                "name": "System message",
                "description_past_successful": "The order <b>({first_product_name})</b> was sent electronically to the supplier <b>{supplier}</b>."
            },
            "product-received": {
                "name": "System message",
                "description_past_successful": "<b>{quantity} {unit}</b> received:<p>{product}</p><span style=\"color:#9a9898\">{supplier}</span>"
            },
            "invoice-upload": {
                "name": "New Invoice",
                "description_past_successful": "The invoice has been uploaded."
            },
            "invoice-recognition": {
                "is_searchable": true,
                "name": "Invoice Recognition",
                "description_past_unsuccessful": "Recognition of invoice data has been cancelled.",
                "description_past_successful": "Recognition of invoice data has been completed.",
                "description_present": "The invoice data is being read. This process may take up to a minute. Please refresh the page after this time to see the results."
            },
            "invoice-manual-check": {
                "is_searchable": true,
                "name": "Manual Check",
                "description_past_unsuccessful": "The manual check has not been completed.",
                "description_past_successful": "The invoice has been manually checked.",
                "description_present": "Please check the invoice data.",
                "description_future": "The invoice must be manually checked."
            },
            "invoice-financial-approval": {
                "is_searchable": true,
                "simple_name": "Invoice Approval",
                "name": "Invoice Approval (Stage {index})",
                "description_past_unsuccessful": "Invoice approval was canceled.",
                "description_past_successful": "Invoice was reviewed and approved.",
                "description_present": "Please review and approve the invoice.",
                "description_future": "The invoice must be reviewed and approved."
            },
            "invoice-clearing": {
                "is_searchable": true,
                "name": "Accounting clearing",
                "description_past_unsuccessful": "Clearing was cancelled.",
                "description_past_successful": "Invoice uncertainties were cleared.",
                "description_present": "Please clear uncertainties concerning the invoice.",
                "description_finished_unsuccessful": "The invoice was cancelled and archived."
            },
            "invoice-export": {
                "is_searchable": true,
                "name": "Export",
                "description_past_unsuccessful": "The invoice was not exported.",
                "description_past_successful": "The invoice was submitted to {export_name}.",
                "description_present": "The invoice is ready for export.",
                "description_future": "The invoice is ready for export."
            },
            "invoice-accounting": {
                "is_searchable": true,
                "name": "Accounting",
                "description_past_unsuccessful": "Accounting was aborted.",
                "description_past_successful": "Accounting was verified.",
                "description_present": "Please check accounting.",
                "description_future": "Accounting needs to be checked."
            },
            "skip": {
                "name": "Cancellation",
                "description_past_successful": "has cancelled the step."
            }
        }
    },
    "jexcel": {
        "noRecordsFound": "No entries found.",
        "showingPage": "Page {0} of {1}",
        "show": "Show ",
        "entries": " entries",
        "insertANewColumnBefore": "Insert column before",
        "insertANewColumnAfter": "Insert column after",
        "deleteSelectedColumns": "Delete selected columns",
        "renameThisColumn": "Rename this column",
        "orderAscending": "Sort ascending",
        "orderDescending": "Sort descending",
        "insertANewRowBefore": "Insert row before",
        "insertANewRowAfter": "Insert row after",
        "deleteSelectedRows": "Delete selected rows",
        "copy": "Copy",
        "paste": "Paste",
        "saveAs": "Save as",
        "about": "About",
        "areYouSureToDeleteTheSelectedRows": "Are you sure you want to delete the selected rows?",
        "areYouSureToDeleteTheSelectedColumns": "Are you sure you want to delete the selected columns?",
        "thisActionWillDestroyAnyExistingMergedCellsAreYouSure": "This action will destroy any existing merged cells. Are you sure?",
        "thisActionWillClearYourSearchResultsAreYouSure": "This action will clear your search results. Are you sure?",
        "thereIsAConflictWithAnotherMergedCell": "There is a conflict with another merged cell",
        "invalidMergeProperties": "Invalid merged properties",
        "cellAlreadyMerged": "Cell already merged",
        "noCellsSelected": "No cell selected",
        "save_warning": "Copying data between Excel and the web can lead to incorrect sequences and lost ID relationships. <br>Please use only the online editing or add Excel data as a supplement."
    },
    "system_comment": {
        "name": {
            "allocation": "Allocation",
            "datev_export": "DATEV export",
            "export_error": "Export error",
            "export_detail": "Export protocol",
            "repeat_recognition": "Repeat Invoice Recognition"
        },
        "recognition_was_repeated": "{user_name} repeated the document recognition.",
        "attach_new_invoice_to_procurement": "The invoice <b>{link_to_invoice}</b> from <b>{supplier_name}</b> has been allocated to this purchase order by <b>{user_name}</b>.",
        "attach_new_invoice_to_procurement_without_supplier": "The invoice <b>{link_to_invoice}</b> has been allocated to this purchase order by <b>{user_name}</b>.",
        "change_procurement_of_invoice": "The allocation of the invoice <b>{link_to_invoice}</b> from <b>{supplier_name}</b> has been removed. <br>The invoice has been allocated to the purchase order {link_to_procurement}.",
        "change_procurement_of_invoice_without_supplier": "The allocation of the invoice <b>{link_to_invoice}</b> has been removed. <br>The invoice has been allocated to the purchase order {link_to_procurement}.",
        "detach_procurement_of_invoice": "The allocation of the invoice <b>{link_to_invoice}</b> from <b>{supplier_name}</b> has been removed.",
        "detach_procurement_of_invoice_without_supplier": "The allocation of the invoice <b>{link_to_invoice}</b> has been removed.",
        "attach_external_invoice_to_procurement": "The invoice {invoice_number} from <b>{supplier_name}</b> has been allocated to this purchase order by <b>{invoice_by}</b>.",
        "insert_datev_note": "The invoice <b>{link_to_invoice}</b> from <b>{supplier_name}</b> has been sent to DATEV.",
        "insert_datev_note_without_supplier": "The invoice <b>{link_to_invoice}</b> has been sent to DATEV.",
        "on_ms365_export_error_generic": "Error while exporting the invoice to D365. Please contact support.",
        "on_ms365_export_validation_error": "Error while exporting the invoice to D365. Please contact support. Error: {error}",
        "on_export_error": "The invoice could not be sent to DATEV. {error}",
        "on_export_error_generic": "Error while exporting the invoice to DATEV. Please contact support.",
        "on_export_error_validation": "{supplier_external_id_export_validation}{supplier_name_export_validation} Please contact someone with administration rights to check the settings.",
        "on_export_error_datev_access_is_not_active": "The connection with DATEV must be established. Please contact someone with administrator rights.",
        "on_export_error_client_has_no_datev_access": "The company {client_name} has not stored any DATEV access data. Please contact someone with administrator rights.",
        "on_export_error_client_has_no_datev_permission": "An error occurred during transmission to DATEV. The set up DATEV account does not have permission for the company {client_name}. Please contact someone with administrator rights to check the access, the company data or the permissions within DATEV.",
        "on_export_error_datev_has_protocol_entries": "Error exporting the invoice to DATEV. Please check the export log or contact support.",
        "on_export_error_no_fiscal_year": "An error occurred during transmission to DATEV. The invoice date is not within the DATEV fiscal year. Please contact someone with administrator rights to check the fiscal year in DATEV.",
        "on_export_error_invoice_not_active_for_fiscal_year": "An error occurred during transmission to DATEV. The DATEV fiscal year is not released for the transmission of incoming invoices. Please contact someone with administrator rights to check the fiscal year in DATEV.",
        "insert_datev_protocol_note": "<b>Status</b>: {status} <br><br> <b>Protocol Entries</b>: <br> {protocol}",
        "labels": {
            "invoice_number_label": "Invoice number",
            "procurement_number_label": "Order number",
            "invoice_date_label": "Invoice date",
            "invoice_due_date_label": "Due date",
            "supplier_label": "Supplier",
            "client_label": "Company",
            "total_netto_label": "Total net",
            "total_brutto_label": "Total gross",
            "tax_label": "Tax rate",
            "positions_count_label": "Position(s)",
            "no_data_read_label": "No data recognized.",
            "supplier_external_id_export_validation": "The external ID of the supplier must be specified in the administration area. ",
            "supplier_name_export_validation": "The name of the supplier must be specified in the administration area. ",
            "recognition_was_repeated": "{user_name} repeated the document recognition.",
        }
    },
    "login": {
        "email": {
            "title": "Login with Email",
            "instructions": "Please enter your email address. Then click on the login link in the email.",
            "punchin_instructions": "To complete the process, you need to log in and start the order approval process.",
            "tenant": "Tenant",
            "place_holder": "Email Address",
            "last_used_emails": "Recently used email addresses",
            "delete_last_used_emails": "Delete \"Recently used\"",
            "has_no_account": "Don't have an account yet?",
            "login_link_via_email": "Receive login link via email",
            "finish_login_title": "Complete Login",
            "finish_login_instructions": "You will now receive an email with a login link. Please click on the link to complete the login. <br><br>The login email was sent to <br><b>{email}</b><br> and should arrive shortly. Please check your spam folder if necessary.",
            "forgot_passwort": {
                "title": "Forgot password",
                "login_via_email": "Login via email",
                "instructions": "There are no passwords in liftbase. We send you an email with a login link. Or you can use passkeys."
            },
            "no_email": "No email",
            "no_email_validation": "Please enter your email address to login.",
            "check_email_title": "Check Email",
            "check_email_message": "Please check your email inbox now and click on the login link."
        },
        "or": "or",
        "passkey": {
            "title": "Login with Passkey",
            "instructions": "Please click on the button to login via passkey.",
            "login": "Login with passkey",
            "what_is": {
                "title": "What are passkeys?",
                "first": "Login without waiting for email",
                "second": "With your fingerprint, your face or PIN",
                "third": "Set up now in a few steps in your profile"
            },
            "error": "Error logging in with passkey. Please try again."
        }
    },
    "languages": {
        "de": "German",
        "en": "English",
        "it": "Italian",
        "cz": "Czech",
        "sk": "Slovak"
    },
    "translation-component": {
        "repeated": "You cannot select the same language multiple times.",
        "language_empty": "You must select the language.",
        "translation_empty": "You must enter the text for this selected language.",
        "both_empty": "You must enter both the language and the text.",
        "language": "Language",
        "no_translation": "There is no text registered for this language.",
        "at_least_one_required": "You must provide at least one name.",
        "text_without_translation": "Without Translation"
    },
    "mailbox": {
        "description": "Mailbox",
        "description_plural": "Mailboxes",
        "name": "Name",
        "username": "Username",
        "password": "Password",
        "host": "Host",
        "protocol": "Protocol",
        "folder": "Folder",
        "mailbox": "MailBox",
        "period_in_days": "Period in Days",
        "period_in_days_column": "Period (days)",
        "period_in_days_tooltip": "Age of emails in days",
        "remove_mailbox_msg": "Are you sure you want to remove this mailbox?",
        "remove_mailbox_btn": "Remove",
        "import_invoices_automatically": "Invoices import active",
        "import_mode": "Import mode",
        "import_mode_per_attachment": "Create one invoice per email attachment",
        "import_mode_per_email": "Create one invoice per email",
        "check_connection_btn": "Check Connection",
        "connectivity_success_msg": "Connection established successfully.",
        "connectivity_error_msg": "Connection could not be established. Please check your credentials.",
        "save_and_connect_btn": "Save and Connect",
        "disconnect_btn": "Disconnect",
        "disconnect_success_msg": "Connection disconnected successfully."
    },
    "marketplace": {
        "description": "Marketplace",
        "description_plural": "Marketplaces",
        "provider": "Anbieter",
        "country_code": "Land",
        "url": "URL",
        "email": "E-Mail",
        "order_url": "Bestell-URL",
        "identity": "Identity",
        "secret": "Secret",
        "new": "New Marketplace"
    },
    "payment_options": {
        "label": "Payment method of the supplier company",
        "AUTOMATIC_DEBIT": "Via SEPA Direct Debit",
        "CREDIT_CARD": "Via Credit Card",
        "BANK_TRANSFER": "Via Bank Transfer"
    }

}