export default {
    "administration": "Administrácia",
    "admin_page": {
        "no_permission": "Nemáte oprávnenie do oblasti správy.",
        "confirm_before_delete_msg": "Naozaj chcete odstrániť tento záznam? (Urobil som})",
        "confirm_before_delete_from_db_msg": "Naozaj chcete odstrániť tento záznam? (Urobil som})",
        "confirm_before_delete_from_db_title": "Natrvalo odstrániť",
        "confirm_before_delete_from_db_btn": "Odstrániť",
        "block_user_msg": "Naozaj chcete zablokovať používateľa {name}? Osoba sa potom už nemôže prihlásiť.",
        "block_user_title": "Kontrolný blok",
        "block_user_btn": "Uzamknite sa",
        "unblock_user_msg": "Naozaj chcete odblokovať používateľa {name}? Osoba sa potom môže znova prihlásiť.",
        "unblock_user_title": "Odomknúť prihlásenie",
        "unblock_user_btn": "Odomknúť",
        "tour": {
            "menu_title": "oblasti",
            "menu": "Tu vidíte rôzne oblasti správy, v ktorých môžete ukladať a upravovať kmeňové dáta a vykonávať konfigurácie.",
            "client_menu_title": "Riadiť spoločnosti",
            "client_menu": "Tu môžete pridávať, zobrazovať alebo upravovať svoje spoločnosti.",
            "client_list": "Tu si môžete pozrieť zoznam všetkých spoločností.",
            "new_client": "Kliknutím sem vytvoríte spoločnosť.",
            "view_mode": "<p>Tu môžete prepnúť zo zobrazenia zoznamu do zobrazenia tabuľky, aby ste mohli uložiť viacero záznamov kmeňových dát naraz alebo aby ste mali lepší prehľad.</p> Zobrazenie tabuľky je užitočné napríklad aj pri exporte alebo importe dát. . Po úprave nezabudnite tabuľku uložiť."
        }
    },
    "version": "Verzia",
    "yes": "A",
    "no": "Nie",
    "never": "NIE",
    "add": "Pridať",
    "save": "Uložiť",
    "cancel": "Zrušiť",
    "edit": "Upraviť",
    "delete": "Odstrániť",
    "next": "Ďalej",
    "back": "späť",
    "finish": "Skončiť",
    "ok": "Dobre",
    "open": "OTVORENÉ",
    "dashboard": "Dashboard",
    "overview": "Prehľad",
    "operator": "Operátor",
    "profile": "Profil",
    "help": "Pomoc",
    "logout": "Odhlásiť sa",
    "list_view": "Zobrazenie zoznamu",
    "table_view": "Tabuľkový pohľad",
    "archive": "archív",
    "active": "Aktívne",
    "email": "E-mail",
    "name": "názov",
    "tax": "daň (%)",
    "value": "Suma",
    "number": "číslo",
    "currency": "mena",
    "actions": "Akcie",
    "system": "Systém",
    "from": "Von",
    "of": "Von",
    "until": "bis",
    "up_to": "bis",
    "cost_center_id": "Nákladové stredisko",
    "cost_centers": "Nákladové strediská",
    "budget_id": "Rozpočet",
    "budgets": "Rozpočty",
    "branch_id": "Pobočka",
    "branches": "Pobočky",
    "employee_id": "Zamestnanec",
    "employees": "Zamestnanec",
    "d365_vehicle_id": "Motorové vozidlo",
    "vehicles": "Motorové vozidlá",
    "financial_dimension_id": "Finančný rozmer",
    "financial_dimensions": "Finančný rozmer",
    "tax_group_id": "Skupina dane z predaja",
    "tax_groups": "Skupiny dane z predaja",
    "tax_item_group_id": "Skupina položiek dane z predaja",
    "tax_item_groups": "Skupiny položiek dane z predaja",
    "profit_center_id": "Ziskové centrum",
    "profit_centers": "Zisková centrá",
    "client_id": "Prenasledovať",
    "clients": "Prenasledovať",
    "department_id": "oddelenie",
    "departments": "oddelenia",
    "d365_department_id": "D365 oddelenie",
    "d365_departments": "D365 oddelenia",
    "location_id": "Poloha",
    "consignee_id": "Príjem tovaru",
    "address_id": "Adresa dodania",
    "lb_user_id": "Osoba",
    "users": "osôb",
    "task_group_id": "Skupina úloh",
    "task_groups": "Skupiny úloh",
    "user_task_group": "Používateľ/Skupina úloh",
    "user_task_groups": "Používatelia/Skupiny úloh",
    "products": "Katalóg produktov",
    "product_group_id": "Skupina produktov",
    "product_groups": "Skupiny produktov",
    "supplier_id": "Doručovacia spoločnosť",
    "suppliers": "Doručovacia spoločnosť",
    "procurement_id": "žiadosť o objednávku",
    "procurements": "Požiadavky na nákup",
    "user_select_product_id": "Osoba „výber produktu“.",
    "user_send_order_id": "Osoba „úloha“",
    "user_clearing_id": "Osoba „objasňujúca nákup“",
    "user_consignee_id": "Osoba „Príjem tovaru“.",
    "user_invoice_manual_check_id": "Osoba „Manuálna kontrola (faktúra)“",
    "user_invoice_clearing_id": "Osoba „objasnenie účtovníctva“",
    "user_accounting_id": "Používateľ „Účtovníctvo“",
    "ledger_account_id": "Účet hlavnej knihy",
    "ledger_accounts": "Účty hlavnej knihy",
    "posting_key_id": "Daňový kľúč",
    "posting_keys": "Daňový kľúč",
    "permission_id": "Skupina povolení",
    "permissions": "Autorizačné skupiny",
    "invoices": "účty",
    "invoice_id": "Faktúra",
    "configurations": "Konfigurácie",
    "marketplace_id": "Trhovisko",
    "provider_id": "Poskytovateľ trhoviska",
    "notes": "Poznámka",
    "search": "Hľadaj",
    "no_data": "Nenašli sa žiadne záznamy",
    "months": "Sladké",
    "select_valid_option": "Vyberte platný záznam.",
    "procurement_line": {
        "description": "Produkt",
        "item_number": "Číslo položky",
        "is_cost_center_fixed": "Blokovať nákladové stredisko pre túto položku",
        "image_url": "Adresa URL obrázka",
        "webshop_url": "Adresa URL obchodu",
        "offer_number": "Číslo ponuky",
        "offer_date": "Dátum ponuky",
        "include_tax": "vrátane {tax} DPH",
        "offer_msg": "Po ponuke {offer_number}",
        "offer_date_msg": "od {offer_date}",
        "total_price_brutto": "Celková hrubá suma",
        "total_price_netto": "Celková čistá suma",
        "type_total_price_brutto": "currency",
        "type_price_brutto": "currency",
        "type_total_price_netto": "currency",
        "type_price_netto": "currency"
    },
    "product_catalog": {
        "description": "Článok",
        "item_number": "Číslo položky",
        "is_cost_center_fixed": "Blokovať nákladové stredisko pre túto položku",
        "image_url": "Adresa URL obrázka",
        "webshop_url": "Adresa URL obchodu",
        "offer_number": "Číslo ponuky",
        "offer_date": "Dátum ponuky",
        "type_price_netto": "plavák",
        "template_price_netto": "1 €",
        "type_price_brutto": "plavák",
        "template_price_brutto": "1 €",
        "form_sequence": "name,item_number,product_group_id,supplier_id,location_id,cost_center_id,is_cost_center_fixed,tax,price_netto,price_brutto,unit,image_url,webshop_url,offer_number,offer_date",
        "page": {
            "new": "Nový produkt",
            "supplier_tooltip": "Vyberte doručovaciu spoločnosť z kmeňových údajov alebo pridajte novú zadaním nového názvu.",
            "supplier_new_tooltip": "Táto doručovacia spoločnosť sa uloží do vašich kmeňových údajov, keď pridáte produkt.",
            "should_save": "Uložiť do katalógu produktov",
            "validation": {
                "name_required": "Zadajte meno.",
                "supplier_required": "Vyberte doručovaciu spoločnosť alebo zadajte novú.",
                "image_url_invalid": "Prosím vložte platnú URL.",
                "webshop_url_invalid": "Prosím vložte platnú URL.",
                "tax_invalid": "Uveďte platnú daň."
            },
            "tour": {
                "name": "Zadajte zmysluplný názov produktu.",
                "number": "Tu môžete zadať číslo položky produktu.",
                "product_group": "Vyberte, do ktorej skupiny produktov produkt patrí.",
                "supplier": "Vyberte, ktorá doručovacia spoločnosť produkt ponúka alebo u ktorej si ho chcete objednať.",
                "price": "Zadajte sadzbu dane a čistú alebo brutto cenu. Ďalšia cena sa potom vypočíta pomocou sadzby dane.",
                "unit": "Zadajte jednotku, v ktorej sa výrobok meria, napr. „kus“, „kg“ alebo „liter“.",
                "url": "Tu môžete pridať adresu URL obrázka k produktu. Môžete tiež pridať odkaz na stránku položky obchodu, ak je k dispozícii.",
                "offer": "Ak existuje ponuka na produkt, je možné sem zadať číslo a dátum ponuky, aby sa zobrazili v objednávkovom PDF.",
                "btn_save": "Po pridaní všetkých informácií uložte produkt do katalógu produktov."
            }
        }
    },
    "product_group": {
        "form_sequence": "name,description,user_select_product_id",
        "page": {
            "new": "Nová skupina produktov",
            "no_product_group": "Bez skupiny položiek",
            "validation": {
                "name_required": "Zadajte meno."
            },
            "tour": {
                "name": "Zadajte zmysluplný názov pre skupinu produktov.",
                "btn_save": "Po zadaní všetkých informácií pridajte skupinu produktov."
            }
        }
    },
    "procurement": {
        "description": "žiadosť o objednávku",
        "order_description": "objednať",
        "name": "Označenie",
        "number": "Číslo objednávky",
        "order_number_formatted": "Číslo objednávky",
        "creator_id": "Vytvoril(a)",
        "created_at": "Dátum vytvorenia",
        "delivery_date": "dátum doručenia",
        "delivery_address_id": "Adresa dodania",
        "billing_address_text": "fakturačná adresa",
        "status": "Postavenie",
        "total_brutto": "Celková brutto",
        "total_netto": "Celková netto",
        "total_netto_abbr": "-Sieť",
        "payment_in_advance": "Platba vopred",
        "type": "Typ objednávky",
        "type_options": {
            "single_invoice": "eKuez s jednou faktúrou",
            "recurring_invoice": "dKuez s jednou faktúrou",
            "recurring_contract": "dKuez s opakovanou faktúrou"
        },
        "statuses": {
            "open": "Výber produktu",
            "approved": "uvedenie do prevádzky",
            "ordered": "Čakanie na doručenie",
            "declined": "Odmietnuté",
            "completed": "Dokončené",
            "approval": "Uvoľnite",
            "clearing": "Objasnenie nákupu",
            "draft": "Návrh"
        },
        "page": {
            "no_delivery_address": "Žiadna doručovacia adresa",
            "no_budget": "Žiadny rozpočet",
            "new": "Nová požiadavka na nákup",
            "create_btn": "Spustite požiadavku na nákup",
            "copy_btn_tooltip": "Kopírovať žiadosť o objednávku",
            "delivery_date_tooltip": "Dátum dodania je vytlačený na objednávkovom PDF a môže byť následne zaslaný doručovacej spoločnosti.",
            "order_sender": "uvedenie do prevádzky",
            "product_selector": "Výber produktu",
            "product_search": "Vyhľadajte produkty",
            "before_leave_msg": "Vaša požiadavka na objednávku nebola uložená. Naozaj chcete opustiť stránku?",
            "confirm_without_approval_limit_msg": "Žiadna z vybratých osôb nemá potrebný limit uvoľnenia pre hodnotu tejto objednávky. Príslušné vydania môžu byť pridané neskôr. Ste si istý, že chcete pokračovať?",
            "confirm_without_approval_limit_title": "Limit uvoľnenia",
            "confirm_product_selection_without_product_msg": "Nevybrali ste produkt. Ste si istý, že chcete pokračovať?",
            "confirm_product_selection_without_product_title": "Nie je vybraný žiadny produkt",
            "no_approval_limit_msg": "Váš limit zúčtovania nie je dostatočný pre túto objednávku. Pridajte na schválenie ďalšiu osobu, ktorá má dostatočný limit schválenia.",
            "no_approval_limit_title": "Limit uvoľnenia",
            "product_group_missing_msg": "Všetky produkty musia byť zaradené do skupiny produktov. Prejdite na kartu Skupiny produktov nad zoznamom produktov a priraďte každý produkt ku skupine produktov.",
            "product_group_missing_title": "Priradiť skupinu produktov",
            "product_group_missing_btn": "Rozumel",
            "min_approval_one_person": "1 osoba",
            "min_approval_qty": "{qty} rôznych ľudí",
            "min_approval_error_msg": "Vydanie nie je dostatočné pre túto kúpnu požiadavku. Pridajte ďalšiu osobu na zdieľanie. Táto požiadavka na nákup musí byť schválená <b>aspoň {min_approval_msg}</b>.",
            "min_approval_error_title": "Uvoľnenie nie je dostatočné",
            "order_not_completed_msg": "Nezadali ste ani nepotvrdili všetky objednávky. V zozname produktov sa uistite, že ste si objednali všetky produkty a v prípade potreby ich potvrďte pre pokračovanie.",
            "order_not_completed_title": "Najprv zadajte objednávky",
            "order_not_completed_btn": "Rozumel",
            "confirm_delivery_not_completed_msg": "Nepotvrdili ste všetky príjemky tovaru. Naozaj chcete dokončiť požiadavku na nákup? <br> <i>Túto akciu nie je možné vrátiť späť</i>.",
            "confirm_delivery_not_completed_title": "Čakajúce potvrdenky o tovare",
            "confirm_delivery_not_completed_btn": "Dokončiť",
            "default_confirm_btn": "ďalej",
            "budget_exceeded_msg": "Rozpočet bol prekročený",
            "approval_limit_exceeded_msg": "Prekročený limit uvoľnenia",
            "external_order_number_msg": "Zadajte externé číslo objednávky.",
            "external_order_number_title": "Zadajte externé číslo objednávky",
            "no_edit_permission": "Nemáte povolenie na úpravu údajov. Ak chcete vykonať zmeny v údajoch, odošlite žiadosť o objednávku prostredníctvom akcií na objasnenie.",
            "delivery_dialog": {
                "title": "Potvrďte príjem tovaru",
                "confirm_btn": "Potvrďte",
                "message": "Zadajte posledné prijaté množstvo.<br> Chýba <b>{missing_qty} z {quantity}</b> {unit}.",
                "qty_exceeded_msg": "Chýbajúce množstvo tovaru nesmie byť prekročené.",
                "qty_must_be_greater_msg": "Množstvo tovaru musí byť väčšie ako 0.",
                "invalid_format": "Použite platný formát čísla."
            },
            "order_sender_dialog": {
                "title": "Predbežné zadanie úlohy",
                "info": "Osoba v kroku „Objednávka“ je výsledkom rôznych predbežných pridelení zodpovedných ľudí podľa oddelenia, spoločnosti, predbežného pridelenia globálnej role a osoby, ktorá objednávku vytvorila. Osoba v hornej časti zoznamu je vopred priradená k úlohe."
            },
            "consignee_dialog": {
                "title": "Predbežné pridelenie z príjmu tovaru",
                "info": "Osoba v kroku „Prijatie tovaru“ je výsledkom rôznych predbežných pridelení zodpovedných ľudí podľa oddelenia, spoločnosti, predbežného priradenia globálnej role a osoby, ktorá vytvorila objednávku. Osoba v hornej časti zoznamu je vopred priradená k úlohe."
            },
            "select_product_dialog": {
                "title": "Predbežné zadanie výberu produktu",
                "info": "Osoba v kroku „Výber produktu“ je výsledkom rôznych predbežných pridelení zodpovedných ľudí podľa oddelenia, spoločnosti, predbežného pridelenia globálnej role a osoby, ktorá vytvorila objednávku. Osoba v hornej časti zoznamu je vopred priradená k úlohe."
            },
            "marketplace_change_dialog": {
                "title": "Objednávka bude zrušená",
                "info": "Bola zistená zmena spoločnosti. Na trhu {marketplace_name} však existuje objednávka, ktorá nie je spojená s novou spoločnosťou {client_name}. Ak budete pokračovať, táto objednávka bude zrušená.",
                "btn_confirm": "Zrušiť objednávku"
            },
            "dialog_assignment": {
                "department_form": "Oddelenie vo formulári",
                "department_creator": "Oddelenie objednávateľa",
                "client_form": "Spoločnosť vo forme",
                "client_creator": "Spoločnosť osoby, ktorá zadáva objednávku",
                "no_selection": "ešte nevybraté",
                "no_action": "nepoužiteľné",
                "configuration": "Globálne predvolené"
            },
            "draft": {
                "title": "Návrh",
                "save": "uložiť koncept",
                "delete": "Odstrániť návrh",
                "confirm_before_delete_msg": "Naozaj chcete natrvalo odstrániť tento koncept?",
                "confirm_before_delete_title": "Odstrániť návrh",
                "confirm_before_delete_btn": "Odstrániť"
            },
            "punchout": {
                "pending_msg": "Existujú vynikajúce produkty z punču.",
                "error_title": "Chyba pri vyrazení",
                "error_msg": "Došlo k chybe. Skontrolujte konfigurácie vytlačenia alebo kontaktujte niekoho s oprávneniami správcu.",
                "error_start_url_msg": "Nebola poskytnutá žiadna spúšťacia adresa URL, takže Punch-out nie je možné spustiť.",
                "no_config": "Najprv uložte konfiguráciu, aby ste mohli použiť dierovanie.",
                "pending_dialog": {
                    "title": "Čakajúce produkty z punchout",
                    "add_products": "Pridať do objednávky",
                    "add_all_products": "Pridajte všetko do objednávky",
                    "delete_products": "Odstráňte produkty"
                },
                "log_dialog": {
                    "title": "{supplier_name} Žiadosť",
                    "request": "Žiadosť",
                    "response": "Odpoveď",
                    "no_log": "Žiadny záznam",
                    "url": "Url",
                    "header": "Header",
                    "method": "Method",
                    "body": "Body"
                },
                "delete_dialog": {
                    "title": "Odstrániť produkty od {supplier} #{index}",
                    "info1": "Naozaj chcete odstrániť všetky nasledujúce produkty z {supplier} #{index}?",
                    "info2": "Túto akciu nie je možné vrátiť späť.",
                    "delete_btn": "Odstrániť"
                },
                "loading": "Dokončite výber produktu v kontextovom okne, ktoré sa otvorí. Ak sa neotvorí žiadne kontextové okno, musíte ho najskôr povoliť.",
                "can_not_edit": "Vyrazené výrobky nie je možné meniť. Vykonajte dierovanie znova. alebo vymažte celý nákupný košík.",
                "no_provider_found": "Žiaľ, vybraný poskytovateľ dierovania momentálne nie je podporovaný."
            },
            "download_pdf": {
                "title": "Stiahnuť PDF",
                "confirm_btn": "Potvrdiť",
                "title_confirm_save_changes": "Uložiť neuložené údaje",
                "text_confirm_save_changes": "Chcete uložiť neuložené údaje a aplikovať ich na dokument o nákupe?",
                "invalid_form": "Prosím, vyplňte všetky povinné polia vo formulári pred pokračovaním v akcii."
            },
            "lines": {
                "no_line": "Nie sú vybraté žiadne produkty, pridajte produkty.",
                "show_all_info": "rozšírený pohľad",
                "invoice": {
                    "title": "Faktúra",
                    "created_at": "Zaznamenané",
                    "date": "Dátum faktúry",
                    "created_by": "Vytvoril"
                },
                "automatic_order": "Odoslať elektronicky",
                "automatic_ordered": "Zaslané elektronicky",
                "confirm_order": "Potvrďte objednávku",
                "finish_order": "Dokončite zadanie",
                "order_finished": "Komisia dokončená",
                "confirm_order_tooltip": "Upozorňujeme, že objednávku musíte zadať doručovacej spoločnosti <b>e-mailom</b>, <br> objednávka sa <b>neprenesie elektronicky.</b>",
                "ordered_at": "Prenesené",
                "ordered_by": "Von",
                "confirm_receipt": "Potvrďte príjem tovaru",
                "no_cost_center": "Žiadne nákladové stredisko",
                "include_taxes": "vrátane DPH",
                "budget": "Rozpočet",
                "no_budget": "Žiadny rozpočet",
                "no_product_group": "Žiadna skupina produktov",
                "no_ledger_account": "Žiadny účtovný účet",
                "select_product": "Výber produktu",
                "quantity_to_show": "prijaté: <b>{quantity_received} od {quantity}</b>",
                "from": "von",
                "item_number": "Položka č. #",
                "delete_msg": "Naozaj chcete odstrániť tento produkt?",
                "delete_title": "Odstráňte produkt",
                "delete_btn": "Odstrániť",
                "automatic_order_dialog": {
                    "confirm_order": "Odošlite žiadosť",
                    "confirm_order_btn": "Predložiť",
                    "finish_order": "dokončiť objednávku",
                    "finish_order_btn": "Dokončiť",
                    "info1": "Chcete túto objednávku zaslať <span style=\"font-weight: bold\">teraz záväzne elektronicky </span>doručovacej spoločnosti <span style=\"font-weight: bold\">?</span>",
                    "info2": "Túto akciu nie je možné vrátiť späť."
                },
                "order_dialog": {
                    "confirm_order": "Potvrďte objednávku",
                    "confirm_order_btn": "Potvrďte",
                    "finish_order": "Dokončite zadanie",
                    "finish_order_btn": "Dokončiť",
                    "info1": "Poslali ste objednávku doručovacej spoločnosti? (napr. e-mailom alebo faxom)",
                    "info2": "Túto akciu nie je možné vrátiť späť."
                },
                "more_actions": {
                    "delete_products": "Odstráňte produkty",
                    "add_external_id": "Zadajte externé číslo objednávky",
                    "download_pdf": "Objednávka (PDF)",
                    "download_pdf_with_attachments": "Objednávka vrátane prílohy PDF",
                    "write_email": "Písať",
                    "email_with_pdf": "E-Mail vrátane PDF"
                },
                "file": {
                    "open_btn": "Otvorte PDF",
                    "add_btn": "Pridať PDF",
                    "upload_info1": "Presuňte myšou <br>alebo <br> <em>Vyberte súbor</em>.",
                    "upload_info2": "Súbory PDF do veľkosti 10 MB.",
                    "delete_msg": "Naozaj chcete odstrániť tento dokument?",
                    "delete_title": "Odstrániť dokument",
                    "delete_btn": "Odstrániť",
                    "delete_error_msg": "Dokument sa nepodarilo odstrániť."
                }
            },
            "validation": {
                "delivery_date_format": "Zadajte dátum doručenia.",
                "client_id_required": "Vyberte spoločnosť.",
                "department_id_required": "Vyberte oddelenie.",
                "department_id_required_client_missing": "Najprv vyberte firmu.",
                "cost_center_id_required": "Vyberte nákladové stredisko.",
                "cost_center_id_required_filters_missing": "Najprv vyberte firmu a oddelenie.",
                "cost_center_id_required_department_missing": "Najprv vyberte oddelenie.",
                "cost_center_id_required_client_missing": "Najprv vyberte firmu.",
                "ledger_account_id_required": "Vyberte, prosím, účtovný účet.",
                "consignee_id_required": "Vyberte zodpovednú osobu.",
                "delivery_address_id_required": "Vyberte dodaciu adresu.",
                "billing_address_text_required": "V konfigurácii spoločnosti chýba fakturačná adresa."
            },
            "tour": {
                "main": {
                    "description": "V prvých poliach uveďte názov žiadosti o objednávku, svoju spoločnosť a oddelenie, pre ktoré má byť objednávka uskutočnená. Oddelenie sa filtruje podľa spoločnosti a nákladové stredisko podľa oddelenia.",
                    "organization": "Tu zadáte firmu, oddelenie a nákladové stredisko, pre ktoré sa má zadať požiadavka na nákup. Oddelenie je filtrované podľa spoločnosti a nákladové stredisko podľa oddelenia.",
                    "address": "Dodacia adresa, ktorú je potrebné oznámiť doručovacej spoločnosti. Môžete si vybrať z adries spoločnosti, príjemcu tovaru a vašich adries.",
                    "total": "Tu vidíte celkovú hodnotu vašej objednávky, ktorá je súčtom vybraných produktov.",
                    "delivery_date": "Dátum dodania je možné zaznamenať na účely dokumentácie po prevzatí tovaru. Nebude zaslaný doručovacej spoločnosti.",
                    "product_selector": "Tu môžete vidieť, ktorá osoba je zodpovedná za výber produktu v ďalšom kroku. Závisí to od rôznych predzadaní, ktoré si môžete podrobnejšie pozrieť po kliknutí na ikonu info.",
                    "order_sender": "Tu vidíte, ktorá osoba je zodpovedná za zadanie objednávky v predposlednom kroku. Závisí to od rôznych predzadaní, ktoré si môžete podrobnejšie pozrieť po kliknutí na ikonu info.",
                    "consignee": "Tu vyberte osobu, ktorá má tovar prevziať. V poslednom kroku žiadosti o objednávku potom vybraná osoba potvrdí prijatie objednaného tovaru a dokončí požiadavku na objednávku.",
                    "approvers": "Tu môžete vidieť, či bolo pre vybrané nákladové stredisko nakonfigurované vydanie. Môžete tiež pridať viac ľudí na zdieľanie.",
                    "product_search": "Tu môžete vyhľadať produkt z interného katalógu produktov a pridať ho do požiadavky na objednávku.",
                    "punchout": "Tu si môžete vybrať produkty z integrovaných trhovísk, ako sú Amazon alebo Mercateo. Po schválení požiadavky na objednávku je možné takto uskutočnený výber produktov jednoducho elektronicky preniesť na trhovisko a tak objednať.",
                    "new_product": "Ak Vami požadovaný produkt ešte nie je v katalógu produktov, môžete ho pridať do požiadavky objednávky tu.",
                    "save_btn": "Po zhromaždení všetkých informácií môžete spustiť požiadavku na objednávku a presunúť ju na ďalší krok."
                },
                "product": {
                    "pdf": "Kliknutím sem môžete pridať dokumenty k produktu.",
                    "remove": "Produkty môžete z výberu aj odstrániť kliknutím sem.",
                    "more_actions": "Po prijatí tu môžete zadať číslo externej objednávky.",
                    "tabs": "Tu môžete zoskupiť pohľad na vaše produkty podľa doručovacej spoločnosti, nákladového strediska alebo skupiny produktov.",
                    "expand_view": "Tu môžete zobraziť alebo skryť rozšírené nastavenia pre produkty, aby ste im mohli priradiť nákladové stredisko, dodaciu adresu alebo skupinu produktov."
                },
                "new_product": {
                    "name": "Zadajte zmysluplný názov produktu.",
                    "number": "Môžete tiež zadať číslo výrobku pre produkt.",
                    "supplier": "Vyberte, ktorá doručovacia spoločnosť produkt ponúka alebo u ktorej si ho chcete objednať.",
                    "price": "Zadajte sadzbu dane a čistú alebo brutto cenu. Ďalšia cena sa potom vypočíta pomocou sadzby dane.",
                    "unit": "Uveďte jednotku, v ktorej sa výrobok meria, napr. Napríklad „kus“, „kg“ alebo „liter“.",
                    "url": "Tu môžete pridať adresu URL obrázka k produktu. Môžete tiež pridať odkaz na stránku položky obchodu, ak je k dispozícii.",
                    "offer": "Ak existuje ponuka na produkt, je možné sem zadať číslo a dátum ponuky, aby sa zobrazili v objednávkovom PDF.",
                    "should_save": "Tu si môžete vybrať, či sa má nový produkt uložiť do katalógu produktov. To znamená, že konkrétny produkt je možné pridať aj do ďalších objednávok.",
                    "save_btn": "Po zhromaždení všetkých informácií pridajte produkt do požiadavky na nákup."
                },
                "cost_center_view": "Nákladové strediská môžu byť tiež priradené k jednotlivým produktom v požiadavke na nákup. Pre produkt je potom možné zvoliť dodacie adresy uložené v nákladovom stredisku.",
                "product_group_view": "Každému produktu je možné priradiť skupinu produktov. Osoba zodpovedná za skupinu produktov potom dostane na časovej osi úlohu upraviť alebo potvrdiť výber produktov.",
                "send_order": {
                    "send_btn": "V objednávkovom kroku môžete objednávku odoslať elektronicky alebo objednávku zadanú doručovateľskej spoločnosti potvrdiť emailom.",
                    "more_actions": "Vo voľbách pre vybrané doručovacie spoločnosti si môžete vygenerovať objednávkové PDF alebo e-mail s alebo bez objednávkového PDF doručovacej spoločnosti. Týmto spôsobom môžete zadať objednávku spoločnosti alebo napr. B. objasniť dotaz e-mailom."
                },
                "delivery_btn": "Ak ste dostali čiastočnú dodávku alebo celý tovar, môžete to uviesť tu. Potvrdené prevzatie tovaru je následne zaznamenané aj v časovej osi."
            }
        }
    },
    "quantity": "Dav",
    "price_netto": "Internetová cena",
    "price_brutto": "Hrubá cena",
    "unit": "Jednotka",
    "default_unit": "Kus",
    "tenant": {
        "tab_general": "Vo všeobecnosti",
        "description": "Nájomca",
        "main_language": "Jazyk náhrady",
        "monthly_document_limit": "Dokumenty za mesiac",
        "base_price": "Základná cena",
        "price_extra_document": "Cena každého ďalšieho dokumentu",
        "monthly_procurement_limit": "BANF mesačne v základnej cene",
        "monthly_invoice_limit": "Vyúčtovanie mesačne v základnej cene",
        "base_price_procurement": "Základná cena BANF",
        "base_price_invoice": "Fakturačná základná cena",
        "price_extra_procurement": "Cena každej ďalšej objednávky",
        "price_extra_invoice": "Cena každej ďalšej faktúry",
        "invoice_recognition_unit_price": "Cena za rozpoznanie faktúry na dokument",
        "easybill_id": "Easybill ID",
        "bank_account": "Číslo účtu",
        "bank_account_owner": "Osoba, ktorá vedie účet",
        "bank_bic": "BIC",
        "bank_code": "Smerové číslo banky",
        "bank_iban": "IBAN",
        "bank_name": "názov banky",
        "address": "fakturačná adresa",
        "sepa_agreement": "Schéma SEPA inkasa",
        "sepa_agreement_date": "SEPA - dátum prijatia poverenia",
        "sepa_mandate_reference": "Referencia mandátu",
        "automatic_billing": "Automaticky vytvoriť fakturáciu",
        "automatic_billing_mail": "Posielajte fakturáciu automaticky e-mailom",
        "bank": "Bankové údaje",
        "datev": "DATEV",
        "billing_address_name": "Podnikanie",
        "billing_address_consignee_name": "Meno príjemcu",
        "billing_address_street": "Ulica a číslo.",
        "billing_address_zipcode": "PLZ",
        "billing_address_city": "Mesto",
        "billing_address_state": "Federálny štát",
        "billing_address_country": "Pôda",
        "billing_address_phone": "Telefónne číslo",
        "billing_email": "Fakturačný email",
        "contact": "kontaktné údaje",
        "sepa": "SEPA inkaso",
        "add_sepa": "Vložiť SEPA inkaso",
        "remove_sepa": "SEPA inkaso zrušené",
        "import_invoice_email": "Automatické vytváranie faktúr cez email",
        "tab_mailbox": "Poštové schránky",
        "debit_credit_reverse": "Vymeniť účet/protiúčet"
    },
    "datev_access": {
        "description": "Integrácia DATEV",
        "label_log": "Spoločnosť DATEV (datev_client_id)",
        "label_log_fields": "datev_client_id",
        "connected_info": "Pripojenie k DATEV bolo nadviazané.",
        "disconnected_info": "Pripojenie k DATEV bolo odstránené.",
        "connected_client_info": "Pripojenie k spoločnosti DATEV {datev_client_id} bolo nadviazané.",
        "disconnected_client_info": "Pripojenie k spoločnosti DATEV {datev_client_id} bolo deaktivované."
    },
    "ms365_access": {
        "description": "Integrácia D365",
        "connected_info": "Pripojenie k D365 bolo nadviazané.",
        "disconnected_info": "Pripojenie k D365 bolo odstránené.",
        "data_loaded_info": "Údaje z D365 boli manuálne načítané.",
        "journal_created_info": "Denník D365 bol pridaný.<br>Názov denníka: {journal_name} <br>URL organizácie: {org_url}.",
        "journal_edited_info": "Názov denníka D365 bol upravený: {journal_name}."
    },
    "client": {
        "description": "Prenasledovať",
        "min_approval": "Minimálny počet schvaľovateľov",
        "branch_required": "Pobočka je povinná pre formulár faktúry.",
        "department_required": "Oddelenie je potrebné pre formulár faktúry.",
        "cost_center_required": "Nákladové stredisko je povinné pre objednávkový formulár.",
        "client_number": "Číslo",
        "consultant_number": "Číslo poradcu",
        "ledger_account_length": "Dĺžka účtovného účtu",
        "vat_number": "ID č.",
        "address_name": "názov",
        "consignee": "Meno príjemcu – fakturačná adresa",
        "dropoff_location": "Názov miesta doručenia - fakturačná adresa",
        "address": "Ulica a číslo - fakturačná adresa",
        "address_complement": "Ulica 2 – fakturačná adresa",
        "country": "Krajina – fakturačná adresa",
        "zipcode": "PSČ – fakturačná adresa",
        "city": "Mesto – fakturačná adresa",
        "phone_country_code": "Krajina telefónu – fakturačná adresa",
        "phone_number": "Telefónne číslo – fakturačná adresa",
        "can_delete_document_directly": "Dokumenty je možné vymazať bez upresnenia.",
        "default_product_tax": "Štandardná daňová sadzba (%)",
        "default_product_currency": "Predvolená mena",
        "default_product_unit": "Predvolená jednotka",
        "form_sequence": "name,email,client_number,currency,min_approval,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id,user_invoice_manual_check_id,user_invoice_clearing_id",
        "page": {
            "tab_general": "Vo všeobecnosti",
            "tab_billing_address": "fakturačná adresa",
            "tab_shipping_address": "Doručovacie adresy",
            "tab_default_roles": "Predvolená všeobecná rola",
            "tab_permissions": "Autorizačné skupiny",
            "tab_departments": "Oddelenia",
            "tab_cost_centers": "Nákladová miesta",
            "tab_ledger_accounts": "Účty hlavnej knihy",
            "tab_required_fields": "Povinné polia",
            "tab_datev_csv": "DATEV CSV Export",
            "new": "Nová spoločnosť",
            "select_logo": "Vyberte logo",
            "select_logo_tooltip_content": "Pridajte logo, ktoré bude vytlačené na objednávkovom dokumente vašich schválených objednávok. <br/> Najlepšie je nasnímať súbor obrázka s priehľadným pozadím.",
            "select_letterhead": "Vyberte hlavičkový papier",
            "select_letterhead_tooltip_content": "Pridajte hlavičkový papier, ktorý bude vytlačený na objednávkový dokument vašich schválených objednávok.",
            "billing_address": "fakturačná adresa",
            "roles": "Predvolená všeobecná rola",
            "user_clearing_id_tooltip_content": "Na objasnenie objednávkových procesov.",
            "user_invoice_clearing_id_tooltip_content": "Na objasnenie pri podávaní faktúry.",
            "no_selection": "Nie je pridelené konkrétnej spoločnosti, preto patrí všetkým spoločnostiam.",
            "validation": {
                "name_required": "Zadajte meno.",
                "name_max_length": "Môže obsahovať maximálne 50 znakov.",
                "email_invalid": "Prosím zadajte platnú emailovú adresu.",
                "client_number_invalid": "Zadajte platné číslo.",
                "min_approval_invalid": "Zadajte platné číslo.",
                "address_incomplete": "Vyplňte povinné údaje o adrese.",
                "delivery_address_missing": "Pridajte aspoň dodaciu adresu.",
                "error_address_incomplete": "Prosím, doplňte povinné údaje pre dodaciu adresu."
            },
            "tour": {
                "name": "Najprv zadajte názov vašej spoločnosti.",
                "email": "Zadajte e-mailovú adresu vašej spoločnosti, na ktorú má byť zaslaná faktúra napr.",
                "timeline_assignment": "Voliteľne môžete vopred prideliť zodpovedné osoby pre spoločnosť pre rôzne kroky požiadaviek na nákup. Napríklad, ak je vždy jedna osoba, ktorá má zadať objednávku.",
                "billing_address": "Fakturačná adresa je potrebná pre objednávku a môže byť zaslaná doručovacej spoločnosti spolu s objednávkou.",
                "shipping_address": "Tu môžete zadať jednu alebo viac doručovacích adries, z ktorých si môžete vybrať v požiadavke na nákup.",
                "btn_save": "Po zhromaždení všetkých potrebných informácií pridajte spoločnosť."
            }
        }
    },
    "client_form_config": {
        "field": "Pole",
        "form": "Formulár",
        "required": "Povinné"
    },
    "department": {
        "description": "Oddelenie",
        "code": "Číslo",
        "form_sequence": "name,code,client_id,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id",
        "page": {
            "new": "Nové oddelenie",
            "tab_general": "Všeobecné",
            "tab_clients": "Klienti",
            "tab_cost_centers": "Nákladová miesta",
            "tab_approvers": "Schváľovatelia",
            "tab_permissions": "Skupiny oprávnení",
            "tab_employees": "Zamestnanci",
            "tab_assignment": "Priradenia rolí",
            "validation": {
                "name_required": "Zadajte meno.",
                "email_required": "Prosím zadajte platnú emailovú adresu."
            },
            "tour": {
                "name": "Najprv zadajte názov oddelenia.",
                "client": "Ak oddelenie priradíte spoločnosti, oddelenie možno vybrať iba v tejto spoločnosti pri vytváraní požiadavky na nákup.",
                "timeline_assignment": "Môžete určiť osoby v oddelení, ktoré sú zodpovedné za rôzne kroky požiadavky na nákup. Napríklad, ak na oddelení príjmu tovaru je vždy jedna osoba.",
                "cost_centers": "Tu môžete k oddeleniu priradiť už vytvorené nákladové strediská. Ak nepriradíte nákladové strediská, požiadavky na nákup oddelení môžu vybrať iba nákladové strediská, ktoré nie sú priradené žiadnemu oddeleniu.",
                "employees": "Tu môžete pridať rôznych ľudí, ktorí budú priradení k tomuto oddeleniu. Ak ľudia v tomto oddelení vytvoria požiadavku na nákup, oddelenie je predvyplnené.",
                "btn_save": "Po zadaní všetkých informácií pridajte oddelenie."
            }
        }
    },
    "permission": {
        "description": "Skupina povolení",
        "name": "Označenie",
        "page": {
            "new": "Nová skupina povolení",
            "validation": {
                "name_required": "Zadajte meno."
            },
            "tour": {
                "name": "Tu môžete vytvoriť skupinu oprávnení. Túto skupinu potom môžete priradiť k rôznym entitám (oddelenia, produkty z katalógu produktov, skupiny produktov atď.). Ľuďom môžete priradiť aj jednu alebo viac skupín oprávnení. Ak nechcete, aby mala entita obmedzenia povolení, nepriraďujte skupinu."
            },
            "no_selection": "Žiadne obmedzenia skupiny povolení, preto sú viditeľné pre všetkých."
        }
    },
    "task_group": {
        "description": "Skupina úloh",
        "name": "Názov",
        "page": {
            "new": "Nová skupina úloh",
            "validation": {
                "name_required": "Prosím, zadajte názov."
            },
            "tour": {
                "name": "Tu môžete vytvoriť skupinu úloh. Potom môžete priradiť úlohy do tejto skupiny."
            },
            "no_selection": "Žiadna vybraná skupina úloh"
        }
    },
    "budget": {
        "description": "rozpočty",
        "label_log": "value€ (start - end)",
        "label_log_fields": "value,start,end",
        "value": "Suma",
        "template_value": "1 €",
        "type_value": "plavák",
        "start": "Platnosť od",
        "end": "Dátum spotreby",
        "add_foreach_ledger_account": "Vložiť všetky účtovné účty",
        "component": {
            "validation": {
                "end_date_before_start_date": "Dátum ukončenia nemôže byť skorší ako dátum začiatku.",
                "end_date_required": "Dátum ukončenia tu nemôže byť prázdny.",
                "time_overlap": "Rozpočty sa nesmú časovo prekrývať.",
                "field_missing": "Zadajte všetky informácie."
            }
        }
    },
    "address": {
        "description": "Adresa dodania",
        "description_plural": "Doručovacie adresy",
        "address_name": "názov",
        "consignee": "Meno príjemcu",
        "dropoff_location": "Názov miesta odovzdania",
        "address": "Ulica a číslo.",
        "address_complement": "Ulica 2",
        "complement": "Ulica 2",
        "country": "Pôda",
        "zipcode": "PLZ",
        "city": "Mesto",
        "phone_country_code": "Telefónna krajina",
        "phone_number": "Telefónne číslo",
        "component": {
            "new": "Nová adresa doručenia",
            "copy_btn": "Skopírujte fakturačnú adresu",
            "required_field": "Vyplňte povinné údaje o adrese.",
            "phone_required": "Zadajte obe informácie.",
            "no_address": "Žiadna doručovacia adresa"
        }
    },
    "cost_center": {
        "name": "Označenie",
        "number": "číslo",
        "free_budget": "Voľný rozpočet",
        "description": "Nákladové stredisko",
        "label_log": "cost_center_name",
        "budgets": "rozpočty",
        "form_sequence": "name,number",
        "page": {
            "new": "Nové nákladové stredisko",
            "department_tooltip_content": "Ktoré oddelenia môžu používať toto nákladové stredisko.",
            "tab_general": "Všeobecné",
            "tab_budgets": "Rozpočty",
            "tab_shipping_address": "Dodacie adresy",
            "tab_approvers": "Schválitelia",
            "tab_permissions": "Skupiny oprávnení",
            "tab_departments": "Oddelenia",
            "tab_clients": "Klienti",
            "validation": {
                "name_required": "Zadajte meno.",
                "number_required": "Zadajte číslo.",
                "number_length": "Môže obsahovať maximálne 36 číslic a písmená a medzery sa môžu vyskytovať iba v strede.",
                "number_exists": "Číslo už existuje."
            },
            "tour": {
                "name_number": "Zadajte názov a jedinečné číslo pre nákladové stredisko.",
                "budget": "K svojmu nákladovému stredisku môžete priradiť rôzne rozpočty a rozpočty prideliť aj na účet hlavnej knihy.",
                "approver": "Tu môžete voliteľne definovať, ktorí ľudia by mali uvoľniť požiadavky na nákup s týmto nákladovým strediskom. Pri vytváraní požiadaviek na nákup je možné pridať ďalšie vydania.",
                "department": "Tu môžete definovať, v ktorých oddeleniach sa môže nákladové stredisko používať. Ak sem nepridáte žiadne oddelenia, nákladové stredisko je možné vybrať vo všetkých oddeleniach.",
                "btn_save": "Po zadaní všetkých potrebných informácií pridajte nákladové stredisko do databázy."
            }
        }
    },
    "supplier": {
        "address": "Adresa",
        "external_id": "DATEV ID",
        "supplier_number": "Číslo dodávateľa",
        "client_number": "Číslo zákazníka",
        "delivery_in_days": "Dodacia lehota v pracovných dňoch",
        "delivery_terms": "dodacie podmienky",
        "payment_terms": "Platobné podmienky",
        "vat_number": "ID č.",
        "city": "Mesto",
        "iban": "IBAN",
        "bic": "BIC",
        "form_sequence": "name,email,city,address,external_id,delivery_terms,payment_terms,iban,bic,vat_number",
        "payment_method": "Spôsob platby",
        "payment_method_info": "Faktúry od dodávateľských spoločností so spôsobom platby SEPA inkaso alebo kreditná karta sú označené ako priamo zaplatené",
        "page": {
            "new": "Nová doručovacia spoločnosť",
            "department_tooltip_content": "Ktoré oddelenia môžu používať tohto dodávateľa.",
            "delivery_in_days_tooltip_content": "Dodacia lehota sa používa pre e-mail s úlohou prijatia tovaru.",
            "notes_tooltip_content": "Táto poznámka sa zobrazuje iba interne v liftbase a nevytlačí sa na PDF objednávky.",
            "external_id_tooltip_content": "ID doručovacej spoločnosti z vami pripojeného systému tretej strany.",
            "client_number_tooltip_content": "Číslo, ktoré vám tento dodávateľ priradil (voliteľné).",
            "supplier_number_tooltip_content": "Číslo, ktoré ste priradili tomuto dodávateľovi.",
            "delivery_terms_tooltip_content": "Platobné a dodacie podmienky dohodnuté s doručovateľskou spoločnosťou sú uvedené v objednávkovom PDF pri zadávaní objednávky.",
            "payment_terms_tooltip_content": "Platobné a dodacie podmienky dohodnuté s doručovateľskou spoločnosťou sú uvedené v objednávkovom PDF pri zadávaní objednávky.",
            "tab_general": "Všeobecné",
            "tab_delivery_info": "Informácie o dodávke",
            "tab_payment_info": "Informácie o platbe",
            "tab_permissions": "Skupiny oprávnení",
            "tab_departments": "Oddelenia",
            "tab_approvers": "Schváľovatelia",
            "tab_clients": "Firemné špecifické ID",
            "default_clients_ids": "Ak spoločnosť nie je uvedená v tejto tabuľke so špecifickými ID, <br>budú použité nasledujúce predvolené ID: <br>DATEV ID: {external_id} <br>Číslo klienta: {client_number}.",
            "no_default_id": "Nie je k dispozícii žiadne predvolené ID.",
            "validation": {
                "name_required": "Zadajte meno.",
                "name_length": "Môže obsahovať maximálne 50 znakov.",
                "email_invalid": "Prosím zadajte platnú emailovú adresu.",
                "supplier_number_invalid": "Zadajte platné číslo.",
                "vat_number_invalid": "Môže obsahovať čísla, veľké a malé písmená, bodky, medzery a podčiarkovníky. Môže obsahovať maximálne 15 znakov.",
                "iban_invalid": "Môže obsahovať minimálne 15, maximálne 34 znakov a žiadne medzery. Začína dvoma veľkými písmenami, po ktorých nasledujú 2 čísla a 11 až 30 číslic/písmen.",
                "bic_invalid": "Môže obsahovať minimálne 8 a maximálne 11 znakov a iba veľké písmená a čísla. Prvých šesť znakov musí byť písmená.",
                "external_id_length": "ID DATEV musí obsahovať iba celé čísla a nesmie byť menšie ako 10000."
            },
            "tour": {
                "name": "Zadajte názov doručovacej spoločnosti.",
                "email": "E-mailová adresa doručovateľskej spoločnosti slúži napríklad na prípravu e-mailových správ na uskutočnenie objednávky.",
                "address": "Tu môžete zadať adresu doručovacej spoločnosti.",
                "external_id": "Externé ID je číslo spoločnosti, ako je uložené napríklad v externom softvéri. Toto číslo sa používa v rozhraní API.",
                "terms": "Platobné a dodacie podmienky dohodnuté s doručovateľskou spoločnosťou zaznamenajte tu. Tieto informácie sa potom zobrazia v objednávkovom PDF pri zadaní objednávky.",
                "btn_save": "Po zadaní všetkých údajov uložte doručovaciu spoločnosť sem."
            }
        }
    },
    "user": {
        "name": "Meno",
        "client_id": "hlavná spoločnosť",
        "approval_limit": "Limit uvoľnenia",
        "roles": {
            "user-admin": "správca",
            "user-price": "Môže vidieť ceny",
            "user-dashboard": "Je možné vidieť palubnú dosku",
            "user-change-order": "Objednávku je možné dodatočne upraviť",
            "user-see-all-orders": "Môžete vidieť všetky požiadavky na nákup",
            "user-see-all-invoices": "Všetky spoločnosti",
            "user-see-invoices": "Zobraziť faktúry:",
            "user-see-all-invoices-belonging-to-own-company": "Vlastná spoločnosť",
            "user-see-own-invoices-and-involved": "Vlastné a zapojené",
            "user-can-change-invoices": "Upraviť faktúru neskôr",
            "user-see-client-addresses": "Vidí všetky adresy spoločností",
            "user-export-invoice": "Môže posielať faktúry do {export_name}",
            "user-can-delete-document-directly": "Môže zrušiť/zahodiť dokumenty bez upresnenia",
            "user-can-insert-product": "Uložiť v katalógu produktov"
        },
        "blocked": "Kontrolný blok",
        "token_issued_at": "Token API vydaný o",
        "form_sequence": "name,email,client_id,department_id,cost_center_id,location_id,approval_limit,roles,blocked",
        "page": {
            "tab_general": "Vo všeobecnosti",
            "tab_roles": "Povolenia",
            "tab_roles_general": "Všeobecné oprávnenia",
            "tab_roles_procurement": "Požiadavky na nákup",
            "tab_roles_invoice": "Faktúry",
            "tab_roles_block": "Blokácie",
            "tab_permissions": "Autorizačné skupiny",
            "tab_addresses": "Doručovacie adresy",
            "tab_task_groups": "Skupiny úloh",
            "tab_passkeys": "Prístupové kľúče",
            "new": "Nový človek",
            "block_btn": "Zablokovať osobu",
            "unblock_btn": "Odblokovať osobu",
            "login_as_btn": "Prihláste sa ako",
            "roles": "Povolenia",
            "no_permission": "Nie sú vybraté žiadne skupiny povolení.",
            "task_group_permissions": "Oprávnenia z mojich skupín úloh:",
            "approval_limit_tooltip_content": "Tu definujte, ktoré sumy môže táto osoba nakoniec uvoľniť. <br> Vyššie sumy môžu byť uvoľnené, ale musia byť nakoniec uvoľnené aj inou osobou s vyšším limitom uvoľnenia.",
            "approval_limit_placeholder": "Neobmedzené",
            "upload_signature_btn": "Vyberte podpis",
            "upload_signature_tooltip_content": "Pridajte podpis, ktorý bude vytlačený na dokumente uvedenia do prevádzky vašich uvoľnených objednávok. <br/> Najlepšie je nasnímať súbor obrázka s priehľadným pozadím.",
            "api_token_btn": "Generovať tokeny API",
            "api_token_dialog_title": "Generovať tokeny API",
            "api_token_dialog_content": "Tokeny API sú potrebné na integráciu aplikácií tretích strán a umožňujú prístup k údajom v mene príslušnej osoby.",
            "api_token_dialog_date_placeholder": "Dátum vypršania platnosti tokenu",
            "api_token_dialog_generate_btn": "Generovať",
            "api_token_dialog_copy_btn": "Kopírovať",
            "api_token_dialog_copy_info": "Skopírujte a prilepte nasledujúci token rozhrania API do aplikácie tretej strany:",
            "api_token_dialog_copied": "Skopírované!",
            "language": "Jazyk",
            "role_dashboard_tooltip": "Dá sa skontrolovať iba vtedy, ak má osoba povolené vidieť ceny.",
            "passkey": "Prístupový kľúč",
            "passkeys": "Prístupové kľúče",
            "passkey_name": "názov",
            "passkey_last_login": "posledné prihlásenie",
            "passkey_dialog": {
                "title": "Pridať prístupový kľúč",
                "info1": "<b>Čo sú prístupové kľúče?</b><ul><li>Prihláste sa bez čakania na e-mail </li><li>Pomocou odtlačku prsta, vašej tváre alebo kódu PIN </li><li>Bezpečné a pohodlné< /li ></ul>",
                "info2": "<b>Nastavte teraz v 3 krokoch:</b><ol><li>Zadajte názov, napr. „Počítač domácej kancelárie“ alebo „Poznámkový blok“</li><li>Kliknite na „Ďalej“</li><li >Postupujte podľa pokynov vášho prehliadača/operačného systému</li></ol>",
                "info3": "Pokyny krok za krokom nájdete v príručke</a>",
                "name_input_label": "Passkey-Name",
                "name_input_placeholder": "napr. „Počítač pre domácu kanceláriu“ alebo „Notebook“",
                "added_msg": "Bol pridaný prístupový kľúč.",
                "deleted_msg": "Prístupový kľúč bol odstránený.",
                "exist_msg": "V tomto zariadení už máte prístupový kľúč, skúste iné zariadenie."
            },
            "validation": {
                "name_required": "Zadajte meno.",
                "name_exists": "Názov už existuje.",
                "email_required": "Prosím zadajte emailovú adresu.",
                "email_invalid": "Prosím zadajte platnú emailovú adresu.",
                "email_exists": "E-mailová adresa už existuje.",
                "approval_limit_length": "Limit uvoľnenia musí byť buď väčší ako 0, alebo prázdny."
            },
            "tour": {
                "name": "Zadajte meno osoby, najlepšie meno a priezvisko.",
                "email": "Zadajte e-mailovú adresu, ktorú môže osoba použiť na prihlásenie.",
                "organization": "Priraďte osobu k spoločnosti, oddeleniu a nákladovému stredisku. Tieto informácie sa potom vopred vyplnia do formulára, keď sa vytvorí požiadavka na nákup.",
                "approval_limit": "Tu môžete definovať limit uvoľnenia pre osobu. Ak má požiadavka na nákup vyššiu celkovú sumu, musí ju schváliť aj niekto s vyšším limitom schválenia. Ak nie je definovaný žiadny limit, osoba môže konečne uvoľniť všetky sumy.",
                "signature": "Tu je možné pridať podpis osoby. Ak osoba uvoľní požiadavku na objednávku, podpis sa pridá do PDF objednávky.",
                "roles": "Tu je možné nastaviť oprávnenia pre osobu, napríklad či a ktoré procesy alebo informácie môžu byť zobrazené alebo upravené. Existuje tiež možnosť zablokovať osobu, ak napríklad opustila spoločnosť.",
                "roles_price": "Tu nastavte, či osoba vidí ceny produktov alebo dashboard s vyhodnoteniami požiadaviek na objednávku.",
                "role_change_order": "Dôveryhodnej osobe môžete udeliť povolenie na spracovanie požiadaviek na nákup po ich uvoľnení bez toho, aby ste ich museli posielať na objasnenie, a tak sa vyhnúť tomu, aby ste museli byť znova uvoľnení. To môže byť užitočné napríklad pri následnej úprave doručovacích adries alebo cien produktov.",
                "admin": "Vyberte, či má mať osoba prístup do oblasti správy, napríklad na ukladanie ďalších osôb, kmeňových údajov alebo konfigurácií.",
                "block": "Túto možnosť vyberte, ak chcete zabrániť osobe v prihlásení do výťahovej základne, napríklad ak dočasne opustila spoločnosť.",
                "api_token": "Tokeny API sú potrebné na integráciu aplikácií tretích strán a umožňujú prístup k údajom v mene príslušnej osoby.",
                "addresses": "Môžete si uložiť svoje vlastné doručovacie adresy pre danú osobu, napríklad ak osoba pracuje z domu a produkty by mali byť doručené tam.",
                "btn_save": "Uložte osobu a súvisiace informácie.",
                "profile_signature": "Tu môžete nahrať svoj podpis. Potom, keď uvoľníte požiadavky na nákup, váš podpis bude vytlačený na PDF s objednávkou.",
                "profile_addresses": "Môžete zadať svoje vlastné doručovacie adresy, napríklad ak pracujete z domu a produkty by vám tam mali byť doručené.",
                "profile_btn_save": "Uložte súvisiace informácie."
            }
        }
    },
    "lb_user": {
        "description": "Osoba"
    },
    "posting_key": {
        "name": "Označenie",
        "tax": "Sadzba dane v %",
        "number": "kľúč",
        "form_sequence": "name,number,tax",
        "page": {
            "new": "Nový daňový kód",
            "validation": {
                "name_required": "Zadajte meno.",
                "number_required": "Zadajte platné číslo.",
                "number_length": "Môže obsahovať až 9-miestne čísla začínajúce od 1."
            },
            "tour": {
                "name": "Zadajte kľúč a príslušnú sadzbu dane."
            }
        }
    },
    "ledger_account": {
        "name": "Označenie",
        "description": "Popis",
        "form_sequence": "name,number,description",
        "page": {
            "new": "Nový účtovný účet",
            "validation": {
                "name_required": "Zadajte názov.",
                "number_required": "Zadajte platné číslo.",
                "number_length": "Môže obsahovať iba čísla a nemôže začínať 0, ak obsahuje niekoľko číslic.",
                "tax_required": "Zadajte platnú sadzbu dane.",
                "tax_length": "Percentá môžu obsahovať iba dvojciferné čísla s desatinnou čiarkou a najviac na dve desatinné miesta."
            },
            "tour": {
                "name": "Zadajte číslo a názov účtu hlavnej knihy."
            }
        }
    },
    "profit_center": {
        "name": "Názov",
        "form_sequence": "name,number",
        "page": {
            "new": "Nové ziskové centrum",
            "validation": {
                "name_required": "Zadajte názov.",
                "number_required": "Zadajte platné číslo.",
                "number_length": "Môže obsahovať iba čísla a pri viacero číslach nesmie začínať nulou.",
            },
            "tour": {
                "name": "Uveďte číslo a názov ziskového centra."
            }
        }
    },
    "tax_group": {
        "code": "ID",
        "name": "Názov",
        "form_sequence": "name,code,client_id",
        "page": {
            "new": "Nová skupina dane z predaja",
            "validation": {
                "name_required": "Zadajte názov.",
                "code_required": "Zadajte ID.",
                "client_required": "Vyberte prosím klienta.",
            },
            "tour": {
                "name": "Uveďte ID a názov skupiny dane z predaja."
            }
        }
    },
    "financial_dimension": {
        "code": "ID",
        "name": "Názov",
        "form_sequence": "name,code",
        "page": {
            "new": "Nový zamestnanec",
            "validation": {
                "name_required": "Prosím, zadajte meno.",
                "code_required": "Prosím, zadajte ID.",
                "client_required": "Prosím, vyberte firmu."
            },
            "tour": {
                "name": "Zadajte ID a meno zamestnanca."
            }
        }
    },
    "tax_item_group": {
        "code": "ID",
        "tax_code": "Daňové ID",
        "name": "Názov",
        "form_sequence": "name,code,tax_code,client_id",
        "page": {
            "new": "Nová skupina položiek daní",
            "validation": {
                "name_required": "Prosím, zadajte názov.",
                "code_required": "Prosím, zadajte ID.",
                "tax_code_required": "Prosím, zadajte Daňové ID.",
                "client_required": "Prosím, vyberte firmu.",
            },
            "tour": {
                "name": "Zadajte ID a názov skupiny dane na pridané hodnoty."
            }
        }
    },
    "approver": {
        "description": "Uvoľnite",
        "label_log": "lb_user_name",
        "table_log": "lb_user",
        "approver_required": "Vyberte vydanie.",
        "is_required": "Povinný",
        "is_required_info": "Musí tiež schváliť, aj keď predchádzajúci schvaľovatelia už mali potrebný limit schválenia."
    },
    "invoice": {
        "description": "Faktúra",
        "name": "Označenie",
        "creator_id": "nahrané používateľom",
        "created_at": "Nahrané dňa",
        "number": "číslo účtu",
        "invoice_number_formatted": "Interné RE číslo",
        "due_date": "Do dátumu",
        "delivery_date": "Dátum služby",
        "invoice_date": "Dátum faktúry",
        "tax": "Sadzba dane %",
        "total_brutto": "Celková brutto",
        "total_netto": "Celková netto",
        "total_netto_abbr": "-Sieť",
        "fix_recognition": "Nie som spokojný s detekciou",
        "fix_recognition_checkbox": "Nie som spokojný s uznaním faktúry.",
        "paid": "Už zaplatené.",
        "review_status": "Výsledok kontroly",
        "review_status_options": {
            "content_rejection": "Faktúra zamietnutá z vecných dôvodov",
            "insolvency_rejection": "Faktúra zamietnutá z dôvodu insolvenčného práva",
            "further_review_required": "Faktúra vyžaduje ďalšiu kontrolu",
            "approved": "Faktúra schválená"
        },
        "currency": "mena",
        "notes": "poznámky",
        "order_notes": "Poznámky k objednávke",
        "discount_percentage": "Zľava 1 percento",
        "discount_amount": "Hotovostná zľava 1 - suma",
        "discount_days": "Hotovostná zľava 1 - Vezmite",
        "discount_payment_date": "Skonto 1 - Dátum",
        "discount_percentage2": "Zľava 2 percentá",
        "discount_amount2": "Hotovostná zľava 2 - suma",
        "discount_days2": "Zľava 2 - Vezmite",
        "discount_payment_date2": "Skonto 2 - Dátum",
        "type_discount_amount": "currency",
        "type_discount_amount2": "currency",
        "export_individual_items": "Exportovať jednotlivé položky",
        "export_individual_items_tooltip": "Ak je toto políčko začiarknuté, namiesto údajov hlavičky sa prenášajú pozície.",
        "status": "Postavenie",
        "statuses": {
            "open": "Rozpoznávanie faktúr",
            "export": "Pripravené na export",
            "declined": "Kontrolované",
            "completed": "Dokončené",
            "approval": "Manuálna kontrola",
            "financial-approval": "Schválenie",
            "clearing": "Objasnenie účtovníctva",
            "accounting": "Účtovníctvo"
        },
        "page": {
            "upload": {
                "title": "Nahrajte nové faktúry",
                "info": "Presuňte myšou alebo kliknite sem a nahrajte jednu alebo viacero faktúr (*.pdf do 10 MB).",
                "info_attachments": "Presuňte súbory myšou alebo kliknite sem na nahranie jednej alebo viacerých príloh (do 10 MB na súbor).",
                "create_btn": "Spustite rozpoznávanie faktúr",
                "file_invalid": "Odovzdávajte iba súbory PDF or XML do veľkosti 10 MB.",
                "no_preview_available": "Pre tento typ súboru nie je k dispozícii žiadny náhľad. <br>Stiahnite si súbor a otvorte ho na svojom počítači.",
                "tour": {
                    "before_attach": "Tu môžete nahrať jednu alebo viac faktúr presunutím myšou alebo kliknutím do oblasti.",
                    "after_attach_title": "Spustite rozpoznávanie faktúr",
                    "after_attach": "Tu môžete spustiť automatické rozpoznávanie všetkých nahraných faktúr. Následne budete presmerovaný na prehľad prvej faktúry. Ostatné faktúry potom nájdete v zozname úloh."
                }
            },
            "new": "Nový účet",
            "payment_conditions": "Platobné podmienky",
            "discount1": "Zľava 1",
            "discount2": "Zľava 2",
            "discount_percentage": "percent",
            "discount_amount": "Suma",
            "discount_days": "vziať",
            "discount_payment_date": "Dátum",
            "recognition_data_title": "Nespracované údaje rozpoznávania dokumentov",
            "no_recognition_data": "Nezistili sa žiadne údaje.",
            "repeat_recognition_btn": "Zopakovať rozpoznanie dokladu",
            "repeat_recognition_confirm_btn": "Potvrdiť",
            "repeat_recognition_confirm_msg": "Existujúce údaje vo formulári budú prepísané. Naozaj chcete pokračovať?",
            "repeat_recognition_confirm_title": "Zopakovať rozpoznanie dokladu",
            "repeat_recognition_feedback_msg": "Rozpoznávanie dokumentov prebieha na pozadí a môže to trvať niekoľko sekúnd.",
            "select_columns_btn": "Vybrať stĺpce",
            "export_to_datev_btn": "Odoslať na DATEV",
            "export_to_datev_csv_btn": "Exportovať DATEV CSV",
            "export_to_ms365_btn": "Odoslať na D365",
            "export_btn_incomplete_form_tooltip": "Prosím, vyplňte údaje faktúry.",
            "export_btn_send_to_clearing_tooltip": "Ak chcete znova preniesť do D365, odošlite prosím znova na účtovníctvo cez krok 'Riešenie'.",
            "export_error": "<b>Faktúru sa nepodarilo odoslať</b>.<br>",
            "no_export_config_datev_tooltip": "Nebolo nastavené žiadne pripojenie k <b>{export_name}</b>. <br>V konfigurácii nebol pridelený žiadny klient DATEV pre vybranú spoločnosť <b>{client_name}</b>. <br>Ak je to potrebné, kontaktujte osobu s administrátorskými právami <br>na nastavenie pripojenia v administračnej oblasti.",
            "no_export_config_tooltip": "Nebolo vytvorené žiadne pripojenie k {export_name}.<br>V prípade potreby kontaktujte osobu s oprávneniami správcu, aby nastavila pripojenie v oblasti správy.",
            "confirm_before_leave_msg": "Vaša faktúra nebola uložená. Naozaj chcete opustiť stránku?",
            "can_not_edit": "Nemáte povolenie na úpravu údajov. Ak chcete vykonať zmeny v údajoch, odošlite faktúru prostredníctvom akcií na objasnenie.",
            "recognized_client_id": "Spoločnosť bola úspešne identifikovaná na základe <b>DIČ</b> a <b>názvu</b>.<br> <b>Spoločnosť:</b> {client_name}",
            "user_client_id": "Spoločnosť bola priradená na základe <b>užívateľského profilu</b> nahrávateľa.<br><b>Spoločnosť:</b> {client_name}",
            "no_client_recognized": "Nebola identifikovaná žiadna spoločnosť na základe <b>DIČ</b> alebo <b>názvu</b>.<br> Skontrolujte, či údaje spoločnosti v liftbase zodpovedajú údajom na faktúre.",
            "recognized_supplier_id": "Dodávateľská spoločnosť bola úspešne identifikovaná na základe <b>IBAN</b>, <b>DIČ</b> a <b>názvu</b>.<br> <b>Dodávateľ:</b> {supplier_name}",
            "no_supplier_recognized": "Nebola identifikovaná žiadna dodávateľská spoločnosť na základe <b>DIČ</b> alebo <b>názvu</b>.<br> Skontrolujte, či údaje dodávateľa v liftbase zodpovedajú údajom na faktúre.",
            "total_brutto_missing": "Zadajte celkovú hrubú sumu.",
            "total_brutto_zero": "Nesmie obsahovať sumu 0 €.",
            "total_brutto_does_not_match_with_positions": "Celkové sumy sa musia rovnať súčtu položiek.",
            "combination_invalid": "Kombinácia účtu, nákladového miesta a profitového centra nie je povolená v D365.",
            "possible_combinations": "Povolené kombinácie",
            "current_combination": "Aktuálna kombinácia",
            "combination": "Kombinácia",
            "min_approval_one_person": "1 osoba",
            "min_approval_qty": "{qty} rôzne osoby",
            "min_approval_error_msg": "Schválenie tejto faktúry nie je dostatočné. Pridajte prosím ďalšiu osobu na schválenie. Táto faktúra musí byť schválená <b>minimálne {min_approval_msg}</b>.",
            "min_approval_error_title": "Nedostatočné schválenie",
            "no_approval_limit_msg": "Váš schvaľovací limit nie je pre túto faktúru dostatočný. Pridajte prosím ďalšiu osobu na schválenie, ktorá má potrebný schvaľovací limit.",
            "no_approval_limit_title": "Schvaľovací limit",
            "confirm_without_approval_limit_msg": "Žiadna z vybraných osôb nemá potrebný schvaľovací limit pre hodnotu tejto faktúry. Potrebné schválenia je možné pridať neskôr. Naozaj chcete pokračovať?",
            "confirm_without_approval_limit_title": "Schvaľovací limit",
            "lines": {
                "add_tooltip": "Pridať riadok faktúry",
                "get_from_recognition": "Prevziať z rozpoznávania dokladov",
                "legend": {
                    "description": "Legenda",
                    "no_order": "Tovar sa neobjaví v objednávke.",
                    "variance": "Odchýlka v označených stĺpcoch.",
                    "not_invoiced": "Objednaný tovar sa na faktúre nenašiel.",
                    "matched": "Tovar na objednávke a faktúre je rovnaký."
                },
                "form": {
                    "item": "Článok",
                    "new": "Nová položka faktúry",
                    "validation": {
                        "description_required": "Zadajte meno.",
                        "item_number_required": "Zadajte číslo článku.",
                        "tax_percentage_required": "Uveďte platnú daň.",
                        "unit_net_price_required": "Zadajte platné číslo.",
                        "quantity_required": "Zadajte platné číslo."
                    },
                    "tour": {
                        "name": "Zadajte zmysluplný názov produktu.",
                        "number": "Tu môžete zadať číslo položky produktu.",
                        "price": "Zadajte sadzbu dane a čistú cenu. Hrubá cena sa potom vypočíta pomocou sadzby dane."
                    }
                },
                "table": {
                    "source": "Tie",
                    "item_number": "Príroda. Nie",
                    "name": "názov",
                    "quantity": "Anz.",
                    "tax": "daň (%)",
                    "netto_price": "Jedna čistá (€)",
                    "total_netto_price": "Celková čistá (€)",
                    "tax_amount": "Celková daň (€)",
                    "total_brutto_price": "Celková hrubá suma (€)",
                    "cost_center": "Nákladové stredisko",
                    "profit_center": "Profit center",
                    "ledger_account": "Hlavný účet",
                    "tax_group": "Daňová skupina",
                    "tax_item_group": "Daňová skupina položiek",
                    "open_bcase": "ukázať dokument",
                    "no_order_position": "Nenašli sa žiadne položky objednávky. K faktúre priraďte <b>BANF a doručovaciu spoločnosť</b>.",
                    "copy_from_procurement": "Vložte všetky položky do objednávky",
                    "no_order_tooltip": "Zatiaľ nie je prepojená žiadna požiadavka na nákup.",
                    "no_supplier_tooltip": "Zatiaľ nie je pripojená žiadna doručovacia spoločnosť.",
                    "validation_error": "Opravte chyby v tabuľke."
                }
            },
            "export_dialog": {
                "title": {
                    "D365": "Vytvoriť denník",
                    "DATEV": "Odoslať do DATEV",
                    "DATEV CSV": "Export DATEV CSV"
                },
                "description": "Nasledujúce dokumenty budú odoslané na {export_name}.",
                "description_csv": "Nasledujúce doklady budú exportované pre {export_name}.",
                "btn_confirm": "Odoslať",
                "btn_confirm_csv": "Exportovať",
                "btn_finish": "Zatvoriť",
                "export_feedback_csv": "Exportované",
                "export_feedback": "Odoslané",
                "exported_csv": "Exportované",
                "exported": "Odoslané",
                "continue_background": "Pokračovať na pozadí",
                "exported_at": "Naposledy exportované",
                "is_loading": "Prebieha odosielanie",
                "is_loading_csv": "Prebieha exportovanie"
            },
            "select_columns_dialog": {
                "title": "Vybrať stĺpce",
                "select_fields": "Nasledujúce stĺpce sa zobrazia pod <i>Moje úlohy</i> a <i>Všetky dokumenty</i>.",
                "btn_confirm": "Použiť",
                "btn_finish": "Zatvoriť"
            },
            "copy_procurement_dialog": {
                "title": "Skopírujte údaje z požiadavky na nákup",
                "message": "Chcete skopírovať niektoré údaje z objednávky do tejto faktúry?",
                "overwrite_checkbox": "Prepíšte existujúce údaje vo formulári.",
                "select_supplier_msg": "Vyberte, od ktorej doručovacej spoločnosti chcete skopírovať údaje:",
                "select_supplier_placeholder": "Vyberte doručovaciu spoločnosť",
                "confirm_btn": "Potvrďte"
            },
            "validation": {
                "invoice_date_required": "Zadajte dátum faktúry.",
                "due_date_required": "Zadajte dátum splatnosti.",
                "due_date_invalid1": "Dátum splatnosti nemôže byť skorší ako dátum vystavenia faktúry.",
                "delivery_date_required": "Zadajte dátum servisu.",
                "ledger_account_required": "Vyberte účet hlavnej knihy.",
                "branch_required": "Vyberte pobočku.",
                "posting_key_required": "Vyberte daňový kód.",
                "supplier_required": "Vyberte doručovaciu spoločnosť.",
                "cost_center_required": "Vyberte nákladové stredisko.",
                "profit_center_required": "Prosím, vyberte profitné centrum.",
                "employee_required": "Prosím, vyberte zamestnanca.",
                "d365_department_required": "Prosím, vyberte oddelenie D365.",
                "financial_dimension_required": "Vyberte prosím finančný rozmer.",
                "client_required": "Vyberte spoločnosť.",
                "number_required": "Zadajte číslo faktúry.",
                "number_invalid_d365": "Môže obsahovať najviac 20 znakov.",
                "number_invalid": "Môže obsahovať maximálne 36 číslic, veľké a malé písmená, žiadne prehlásky a znaky $ % & * + - /.",
                "notes_length": "Môže obsahovať maximálne 120 znakov.",
                "discount_percentage_invalid1": "Sadzba zľavy musí byť väčšia ako 0.",
                "discount_percentage_invalid2": "Zľava 1 musí byť väčšia ako zľava 2.",
                "discount_amount_invalid1": "Pre kladné fakturované sumy sú povolené len kladné zľavy.",
                "discount_amount_invalid2": "Pre záporné sumy faktúry sú povolené iba záporné zľavy.",
                "discount_amount_invalid3": "Zľava 1 musí byť väčšia ako zľava 2.",
                "discount_amount_invalid4": "Zľava musí byť nižšia ako celková suma.",
                "discount_amount_invalid5": "Súčet čiastok zľavy musí byť menší ako celková čiastka.",
                "discount_date_invalid1": "Zľavy 1 a 2 musia byť medzi dátumom splatnosti dokladu.",
                "discount_date_invalid2": "Zľava 1 – dátum musí byť skorší ako dátum zľavy 2.",
                "missing_discount_field": "Všetky zľavové polia musia byť vyplnené."
            }
        }
    },
    "invoice_line": {
        "item_number": "Číslo položky",
        "quantity": "Dav",
        "unit_net_price": "Internetová cena",
        "tax_percentage": "daň"
    },
    "location": {
        "name": "názov",
        "number": "číslo",
        "address": "Adresa",
        "form_sequence": "name,number,address"
    },
    "spreadsheet": {
        "add_row": "Nový riadok",
        "download": "Stiahnite si tabuľku",
        "save": "Uložiť tabuľku"
    },
    "audit_log": {
        "description": "Úpravy",
        "history": "Úpravy",
        "table": "Tabuľka",
        "lb_user_id": "užívateľ",
        "data_before": "Predtým",
        "data_after": "Potom",
        "created_at": "Dátum",
        "type": {
            "insert": "Pridané",
            "update": "Spracované",
            "delete": "Odstránený"
        },
        "msg": {
            "deleted": "odstránený",
            "inserted": "pridané",
            "was_deleted": "Bol odstránený.",
            "was_inserted": "Bol pridaný."
        },
        "none": "nie)",
        "empty": "Nie sú k dispozícii žiadne úpravy."
    },
    "txt_before_leave_page": "Vaše zmeny sa nemusia uložiť. Ste si istý, že chcete pokračovať?",
    "easybill_invoice": {
        "from_tenant": "von Nájomca",
        "no_invoice": "Zatiaľ nebola vytvorená žiadna faktúra.",
        "number": "číslo",
        "service_date": "Obdobie",
        "from": "Z",
        "until": "bis",
        "total": "Spolu",
        "due_date": "Do dátumu",
        "status": "Postavenie",
        "paid_at": "Zaplatené",
        "payment_missing": "Čaká na spracovanie",
        "sepa": "SEPA",
        "tour": {
            "invoice_table": "Tu si môžete pozrieť podrobný prehľad všetkých faktúr liftbase vrátane stavu. Faktúry je možné stiahnuť pomocou tlačidla."
        }
    },
    "configuration": {
        "description": "konfigurácia",
        "sepa_number": "Identifikačné číslo veriteľa",
        "sepa_mandate_reference": "Referencia mandátu",
        "remove_sepa_msg": "Naozaj chcete odvolať SEPA inkaso?', 'Zrušiť SEPA inkaso",
        "remove_sepa_btn": "Odstúpiť",
        "system-modules-procurement": "Modul - Nákupné požiadavky",
        "system-modules-invoice": "Modul - Faktúry",
        "system-modules-permissions": "Modul - Skupiny oprávnení",
        "system-modules-multilingualism": "Modul - Viacjazyčné hlavné údaje",
        "system-modules-invoice-export": "Modul - Export faktúr do",
        "purchaseorder-defaults-selectproduct": "Prednastavenia rolí - Osoba „Výber produktu“",
        "purchaseorder-defaults-sendorder": "Prednastavenia rolí - Osoba „Odoslanie objednávky“",
        "purchaseorder-defaults-clearing": "Prednastavenia rolí - Osoba „Vyrovnanie nákupu“",
        "purchaseorder-defaults-consignee": "Prednastavenia rolí - Osoba „Príjem tovaru“",
        "invoice-defaults-manual_check": "Prednastavenia rolí - Osoba „Manuálna kontrola (faktúra)“",
        "invoice-defaults-clearing": "Prednastavenia rolí - Osoba „Účtovné vyrovnanie“",
        "purchaseorder-defaults-init_seq": "Nákupné požiadavky - Číslovanie objednávok od",
        "purchaseorder-product-require_product_group": "Nákupné požiadavky - Všetky produkty musia byť priradené do produktovej skupiny.",
        "purchaseorder-product-require_product_selection": "Nákupné požiadavky - Proces nákupnej požiadavky",
        "email-send-order": "Objednávky - E-mailová objednávka",
        "purchaseorder-pdf-free-text": "Objednávky - PDF objednávky",
        "type_email-send-order": "html-i18n",
        "type_purchaseorder-pdf-free-text": "html-i18n",
        "invoice-recognition-lines": "Faktúry - Automatické vytváranie položiek faktúry na základe rozpoznávania dokumentov.",
        "invoice-form-procurement-required": "Faktúry - Vyžaduje sa odkaz na nákupnú požiadavku.",
        "notifications-mail-interval": "Oznámenia - Interval e-mailov v minútach",
        "punchout-amazon-identity": "Integrácie - Punch-out Amazon - Identita",
        "punchout-amazon-secret": "Integrácie - Punch-out Amazon - Tajomstvo",
        "punchout-amazon-url": "Integrácie - Punch-out Amazon - Punch-out URL",
        "punchout-amazon-order-url": "Integrácie - Punch-out Amazon - URL objednávky",
        "punchout-amazon-email": "Integrácie - Punch-out Amazon - E-Mail",
        "punchout-amazon-supplier": "Integrácie - Punch-out Amazon - Predvolený dodávateľ",
        "punchout-mercateo-identity": "Integrácie - Punch-out Mercateo - Identita",
        "punchout-mercateo-secret": "Integrácie - Punch-out Mercateo - Tajomstvo",
        "punchout-mercateo-url": "Integrácie - Punch-out Mercateo - Punch-out URL",
        "punchout-mercateo-order-url": "Integrácie - Punch-out Mercateo - URL objednávky",
        "punchout-mercateo-email": "Integrácie - Punch-out Mercateo - E-Mail",
        "punchout-mercateo-supplier": "Integrácie - Punch-out Mercateo - Predvolený dodávateľ",
        "punchout-rajapack-identity": "Integrácie - Punch-out RAJA - Identita",
        "punchout-rajapack-secret": "Integrácie - Punch-out RAJA - Tajomstvo",
        "punchout-rajapack-url": "Integrácie - Punch-out RAJA - Punch-out URL",
        "punchout-rajapack-order-url": "Integrácie - Punch-out RAJA - URL objednávky",
        "punchout-rajapack-email": "Integrácie - Punch-out RAJA - E-Mail",
        "punchout-rajapack-supplier": "Integrácie - Punch-out RAJA - Predvolený dodávateľ",
        "punchout-conrad-identity": "Integrácie - Punch-out Conrad - Identita",
        "punchout-conrad-secret": "Integrácie - Punch-out Conrad - Tajomstvo",
        "punchout-conrad-url": "Integrácie - Punch-out Conrad - Punch-out URL",
        "punchout-conrad-order-url": "Integrácie - Punch-out Conrad - URL objednávky",
        "punchout-conrad-email": "Integrácie - Punch-out Conrad - E-Mail",
        "punchout-conrad-supplier": "Integrácie - Punch-out Conrad - Predvolený dodávateľ",
        "punchout-thgeyer-identity": "Integrácie - Punch-out TH-GEYER - Identita",
        "punchout-thgeyer-secret": "Integrácie - Punch-out TH-GEYER - Tajomstvo",
        "punchout-thgeyer-url": "Integrácie - Punch-out TH-GEYER - Punch-out URL",
        "punchout-thgeyer-supplier": "Integrácie - Punch-out TH-GEYER - Predvolený dodávateľ",
        "punchout-reichelt-identity": "Integrácie - Punch-out Reichelt - Identita",
        "punchout-reichelt-secret": "Integrácie - Punch-out Reichelt - Tajomstvo",
        "punchout-reichelt-url": "Integrácie - Punch-out Reichelt - Punch-out URL",
        "punchout-reichelt-supplier": "Integrácie - Punch-out Reichelt - Predvolený dodávateľ",
        "validation": {
            "bic_invalid": "Zadajte platný kód BIC.",
            "iban_invalid": "Zadajte platné číslo IBAN."
        },
        "datev": {
            "description": "DATEV",
            "connection": "Spojenie s DATEV",
            "status_active": "aktívny",
            "status_inactive": "neaktívne",
            "from": "von",
            "until": "bis",
            "load_clients": "Načítať klientov DATEV",
            "remove_connection": "Odstráňte pripojenie",
            "deactivate_connection": "Deaktivovať pripojenie",
            "add_connection": "Nastaviť pripojenie",
            "connected_by": "Spojenie nadviazal {name}.",
            "apps_manager": "Tu môžete spravovať <a href=\"https://apps.datev.de/tokrevui\" target=\"_blank\">aplikácie pripojené k DATEV</a>.",
            "msg_no_clients": "Nenašli sa žiadne záznamy alebo nemáte potrebné povolenia alebo je potrebné znova nastaviť pripojenie.",
            "fiscal_year_start": "fiškálny rok",
            "account_length": "Dĺžka účtu",
            "is_accounts_payable_ledger_available": "Príjem faktúry",
            "is_accounts_receivable_ledger_available": "Odchádzajúca faktúra",
            "client_number": "Číslo klienta",
            "consultant_number": "Číslo konzultanta",
            "client_name": "zákazník",
            "liftbase_client": "liftbase spoločnosť",
            "save_client": "Zachráňte spoločnosť",
            "change_client": "Zmeňte spoločnosti"
        },
        "ms365": {
            "description": "D365",
            "connection": "Pripojenie k D365",
            "status_active": "aktívne",
            "status_inactive": "neaktívne",
            "connect_and_load_data": "Nastavte spojenie a načítajte údaje",
            "connect": "Nastavte spojenie",
            "load_data": "Načítavajte všetky dáta",
            "remove_connection": "Odstrániť pripojenie",
            "add_connection": "Nastaviť pripojenie",
            "no_client_found": "Neboli nájdené žiadne klienty D365.",
            "msg_no_clients": "Neboli nájdené žiadne záznamy alebo nemáte potrebné oprávnenia alebo je potrebné pripojenie nastaviť znovu.",
            "client_number": "Číslo firmy D365",
            "client_name": "Firma D365",
            "liftbase_client": "Firma liftbase",
            "save_client": "Uložiť firmu",
            "change_client": "Zmeniť firmu",
            "sync_running": "Dáta sa načítavajú. To môže trvať niekoľko minút.",
            "last_sync_at": "Naposledy načítané dňa {last_sync_at}",
            "journal_name": "Journal Name",
            "org_url": "Org URL",
        },
        "datev-csv": {
            "description": "DATEV CSV",
        },
        "template": {
            "module-procurement": {
                "group": "modul",
                "label": "Požiadavky na nákup",
                "tour": [
                    {
                        "target": ".krokové moduly",
                        "content": "<p>Vyberte moduly výťahovej základne, ktoré chcete aktivovať. Informácie o nákladoch nájdete na <a href=\"liftbase.de/preise\" target=\"_blank\">liftbase.de/preise</a>.</p>"
                    }
                ]
            },
            "module-invoice": {
                "group": "modul",
                "label": "účty"
            },
            "module-permissions": {
                "group": "modul",
                "label": "Autorizačné skupiny"
            },
            "module-multilingualism": {
                "group": "modul",
                "label": "Viacjazyčné základné údaje"
            },
            "insolvency-law-fields": {
                "group": "modul",
                "label": "Zobraziť polia insolvenčného práva"
            },
            "module-invoice-export": {
                "group": "modul",
                "label": "Export faktúry na",
                "options": [
                    {
                        "value": "datev",
                        "content": "DATEV"
                    },
                    {
                        "value": "ms365",
                        "content": "D365"
                    },
                    {
                        "value": "datev-csv",
                        "content": "DATEV CSV"
                    }
                ]
            },
            "procurement-select-product": {
                "group": "Predvolené hodnoty roly",
                "label": "Osoba „výber produktu“.",
                "tour": [
                    {
                        "target": ".krok-priradenie",
                        "content": "<p>Tu možno uložiť predvolené globálne hodnoty rolí. Ak na úrovni spoločnosti alebo oddelenia nie sú definované žiadne predvolené hodnoty rolí, tu zadaní ľudia prevezmú príslušné kroky v procese vyžiadania objednávky a fakturácie.</p>"
                    }
                ]
            },
            "procurement-send-order": {
                "group": "Predvolené hodnoty roly",
                "label": "Osoba „úloha“"
            },
            "procurement-clearing": {
                "group": "Predvolené hodnoty roly",
                "label": "Osoba „objasňujúca nákup“",
                "hint": "Pre upresnenie v procese objednávania."
            },
            "procurement-consignee": {
                "group": "Predvolené hodnoty roly",
                "label": "Osoba „Príjem tovaru“."
            },
            "invoice-manual-check": {
                "group": "Predvolené hodnoty roly",
                "label": "Osoba „Manuálna kontrola (faktúra)“"
            },
            "invoice-clearing": {
                "group": "Predvolené hodnoty roly",
                "label": "Osoba „objasnenie účtovníctva“",
                "hint": "Pre upresnenie pri vystavovaní faktúry."
            },
            "procurement-init-seq": {
                "group": "Požiadavky na nákup",
                "label": "Objednávacie čísla",
                "hint": "Tu zadajte číslo, od ktorého majú začínať čísla objednávok.",
                "validation": {
                    "interval": "Musí byť medzi 0 a 999999999.",
                    "type": "Môže obsahovať iba celé čísla."
                },
                "tour": [
                    {
                        "target": ".krokové obstarávanie",
                        "content": "Tu nájdete nastavenia požiadaviek objednávky. Nastavte napríklad číslo, ktorým sa má začať požiadavka na objednávku a či všetky produkty v požiadavke na objednávku musia byť priradené k skupine produktov."
                    }
                ]
            },
            "procurement-require-product-group": {
                "group": "Požiadavky na nákup",
                "label": "Všetky produkty musia byť zaradené do skupiny produktov."
            },
            "procurement-require-product-selection": {
                "group": "Požiadavky na nákup",
                "label": "Proces vyžiadania nákupu",
                "options": [
                    {
                        "value": "pravda",
                        "content": "s výberom produktu<br><ul><li>Zadajte BANF</li><li>Výber produktu</li><li>Uvoľnenie</li><li>Objednávka </li><li>Potvrdenie o tovare</ li>< /ul>"
                    },
                    {
                        "value": "falošný",
                        "content": "bez výberu produktu<br><ul><li>Zadajte BANF </li><li>Uvoľnenie </li><li>Objednávka </li><li>Príjem tovaru </li></ul>"
                    }
                ]
            },
            "email-send-order": {
                "group": "objednávky",
                "label": "E-mailová objednávka",
                "hint": "E-mailový sprievodný list, ktorý je možné vygenerovať v kroku objednávky vrátane objednávkového PDF a odoslať ho doručovacej spoločnosti.",
                "default_value": "Vážený pán alebo pani, posielam Vám našu objednávku ako PDF. Zašlite nám prosím potvrdenie objednávky.",
                "tour": [
                    {
                        "target": ".step-email-order-free-text",
                        "content": "Sem napíšte text, ktorý bude súčasťou e-mailového sprievodného listu, ktorý je možné vygenerovať v kroku objednávky vrátane objednávkového PDF a zaslať doručovacej spoločnosti."
                    },
                    {
                        "target": ".step-pdf-free-text",
                        "content": "Sem napíšte text, ktorý sa zobrazí na vygenerovanom objednávkovom PDF v kroku objednávky, ktorý je možné následne odoslať doručovacej spoločnosti."
                    }
                ]
            },
            "procurement-pdf-free-text": {
                "group": "objednávky",
                "label": "Objednať PDF",
                "hint": "Sprievodný list, ktorý sa zobrazí na vygenerovanom objednávkovom PDF v kroku objednávky, ktorý je možné následne zaslať doručovacej spoločnosti.",
                "default_value": ""
            },
            "invoice-recognition-lines": {
                "group": "Faktúry",
                "label": "Automaticky prevziať položky, ktoré rozpoznávanie dokladov identifikovalo."
            },
            "invoice-procurement-required": {
                "group": "Faktúry",
                "label": "Vyžaduje sa prepojenie požiadavky na nákup."
            },
            "invoice-approval": {
                "group": "Faktúry",
                "label": "Kedy by mala byť faktúra schválená?",
                "options": [
                    {
                        "value": "always",
                        "content": "<b>Vždy</b><br>Faktúry musia byť vždy schválené."
                    },
                    {
                        "value": "if-no-order",
                        "content": "<b>Len ak nie je priradená objednávka</b><br>Schválenie je potrebné iba v prípade, že neexistuje objednávka (PO)."
                    },
                    {
                        "value": "never",
                        "content": "<b>Nikdy</b><br>Faktúry nevyžadujú samostatné schválenie."
                    }
                ]
            },
            "notifications-mail-interval": {
                "group": "Upozornenia",
                "label": "Interval e-mailu v minútach",
                "tour": [
                    {
                        "target": ".krok-oznámenie",
                        "content": "Tu môžete nastaviť maximálny počet, koľkokrát majú byť ľudia informovaní o nových úlohách e-mailom. E-mailové upozornenia sa odosielajú iba vtedy, keď osoba dostane novú úlohu."
                    }
                ]
            },
            "product-default-tax": {
                "group": "Produkt",
                "label": "Štandardná daňová sadzba (%)"
            },
            "product-default-currency": {
                "group": "Produkt",
                "label": "Predvolená mena"
            },
            "product-default-unit": {
                "group": "Produkt",
                "label": "Predvolená jednotka"
            },
            "punchout-amazon-identity": {
                "group": "Integrácia",
                "subgroup": "Punch-out Amazon",
                "label": "Přihlašovací jméno",
                "tour": [
                    {
                        "target": ".step-punchout-amazon",
                        "content": "Tu si môžete nastaviť prístup k integrovaným trhoviskám, ako sú Amazon a Mercateo. Pokyny nájdete <a href=\"https://liftbase.de/handbook/einrichtung/punch-out\" target=\"_blank\">v našej príručke</a>."
                    }
                ]
            },
            "punchout-amazon-secret": {
                "group": "Integrácia",
                "subgroup": "Punch-out Amazon",
                "label": "Tajomstvo"
            },
            "punchout-amazon-url": {
                "group": "Integrácia",
                "subgroup": "Punch-out Amazon",
                "label": "Punch-out-URL"
            },
            "punchout-amazon-order-url": {
                "group": "Integrácia",
                "subgroup": "Punch-out Amazon",
                "label": "Adresa URL objednávky"
            },
            "punchout-amazon-email": {
                "group": "Integrácia",
                "subgroup": "Punch-out Amazon",
                "label": "E-mail",
                "hint": "E-mailová adresa hlavného účtu Amazon. <br>Ak chcete odoslať e-mailovú adresu prihlásenej osoby, ponechajte pole prázdne. <br>Môže vyžadovať účet Amazon na osobu."
            },
            "punchout-amazon-supplier": {
                "group": "Integrácia",
                "subgroup": "Punch-out Amazon",
                "label": "Štandardná doručovacia spoločnosť",
                "hint": "Ku ktorej doručovacej spoločnosti by mali byť priradené objednávky od tohto poskytovateľa punch-out (napr. pri hodnotení)?"
            },
            "punchout-mercateo-identity": {
                "group": "Integrácia",
                "subgroup": "Punch-out Mercateo",
                "label": "Přihlašovací jméno"
            },
            "punchout-mercateo-secret": {
                "group": "Integrácia",
                "subgroup": "Punch-out Mercateo",
                "label": "Tajomstvo"
            },
            "punchout-mercateo-url": {
                "group": "Integrácia",
                "subgroup": "Punch-out Mercateo",
                "label": "Punch-out-URL"
            },
            "punchout-mercateo-order-url": {
                "group": "Integrácia",
                "subgroup": "Punch-out Mercateo",
                "label": "Adresa URL objednávky"
            },
            "punchout-mercateo-email": {
                "group": "Integrácia",
                "subgroup": "Punch-out Mercateo",
                "label": "E-mail",
                "hint": "E-mailová adresa hlavného účtu Mercateo. <br>Ak chcete odoslať e-mailovú adresu prihlásenej osoby, ponechajte pole prázdne. <br>Môže vyžadovať účet Mercateo na osobu."
            },
            "punchout-mercateo-supplier": {
                "group": "Integrácia",
                "subgroup": "Punch-out Mercateo",
                "label": "Štandardná doručovacia spoločnosť",
                "hint": "Ku ktorej doručovacej spoločnosti by mali byť priradené objednávky od tohto poskytovateľa punch-out (napr. pri hodnotení)?"
            },
            "punchout-rajapack-identity": {
                "group": "Integrácia",
                "subgroup": "Punch-out RAJA",
                "label": "Přihlašovací jméno"
            },
            "punchout-rajapack-secret": {
                "group": "Integrácia",
                "subgroup": "Punch-out RAJA",
                "label": "Tajomstvo"
            },
            "punchout-rajapack-url": {
                "group": "Integrácia",
                "subgroup": "Punch-out RAJA",
                "label": "Punch-out-URL"
            },
            "punchout-rajapack-order-url": {
                "group": "Integrácia",
                "subgroup": "Punch-out RAJA",
                "label": "Adresa URL objednávky"
            },
            "punchout-rajapack-email": {
                "group": "Integrácia",
                "subgroup": "Punch-out RAJA",
                "label": "E-mail"
            },
            "punchout-rajapack-supplier": {
                "group": "Integrácia",
                "subgroup": "Punch-out RAJA",
                "label": "Štandardná doručovacia spoločnosť",
                "hint": "Ku ktorej doručovacej spoločnosti by mali byť priradené objednávky od tohto poskytovateľa punch-out (napr. pri hodnotení)?"
            },
            "punchout-conrad-identity": {
                "group": "Integrácia",
                "subgroup": "Vyrazený Conrad",
                "label": "Přihlašovací jméno"
            },
            "punchout-conrad-secret": {
                "group": "Integrácia",
                "subgroup": "Vyrazený Conrad",
                "label": "Tajomstvo"
            },
            "punchout-conrad-url": {
                "group": "Integrácia",
                "subgroup": "Vyrazený Conrad",
                "label": "Punch-out-URL"
            },
            "punchout-conrad-order-url": {
                "group": "Integrácia",
                "subgroup": "Vyrazený Conrad",
                "label": "Adresa URL objednávky"
            },
            "punchout-conrad-email": {
                "group": "Integrácia",
                "subgroup": "Vyrazený Conrad",
                "label": "E-mail"
            },
            "punchout-conrad-supplier": {
                "group": "Integrácia",
                "subgroup": "Vyrazený Conrad",
                "label": "Štandardná doručovacia spoločnosť",
                "hint": "Ku ktorej doručovacej spoločnosti by mali byť priradené objednávky od tohto poskytovateľa punch-out (napr. pri hodnotení)?"
            },
            "punchout-thgeyer-identity": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka TH.GEYER",
                "label": "Přihlašovací jméno"
            },
            "punchout-thgeyer-secret": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka TH.GEYER",
                "label": "Tajomstvo"
            },
            "punchout-thgeyer-url": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka TH.GEYER",
                "label": "Punch-out-URL"
            },
            "punchout-thgeyer-supplier": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka TH.GEYER",
                "label": "Štandardná doručovacia spoločnosť",
                "hint": "Ku ktorej doručovacej spoločnosti by mali byť priradené objednávky od tohto poskytovateľa punch-out (napr. pri hodnotení)?"
            },

            "punchout-reichelt-identity": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka Reichelt",
                "label": "Přihlašovací jméno"
            },
            "punchout-reichelt-secret": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka Reichelt",
                "label": "Tajomstvo"
            },
            "punchout-reichelt-url": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka Reichelt",
                "label": "Punch-out-URL"
            },
            "punchout-reichelt-supplier": {
                "group": "Integrácia",
                "subgroup": "Vybíjačka Reichelt",
                "label": "Štandardná doručovacia spoločnosť",
                "hint": "Ku ktorej doručovacej spoločnosti by mali byť priradené objednávky od tohto poskytovateľa punch-out (napr. pri hodnotení)?"
            },
            "tour": [
                {
                    "target": "",
                    "content": ""
                }
            ]
        },
        "tour": {
            "tab_general": "Tu môžete zadať názov vašej spoločnosti pre prostredie výťahovej základne.",
            "tab_contact": "Tu zadajte kontaktné údaje vašej spoločnosti. Údaje sú potrebné napríklad na vystavenie a doručenie faktúr za použitie liftbase.",
            "tab_bank": "Tu môžete zadať SEPA inkaso pre fakturáciu z liftbase a v prípade potreby ho odvolať.",
            "tab_datev": "Tu môžete nastaviť integráciu s DATEV. Najprv sa musíte prihlásiť do DATEV, aby ste načítali všetkých dostupných klientov. Týchto klientov potom môžete prepojiť so spoločnosťami zaregistrovanými tu v liftbase. Potom musíte pripojiť každého klienta jednotlivo k DATEV, aby bolo možné preniesť export pre týchto klientov. Toto spojenie má platnosť 2 roky a obnovuje sa pri každom použití na export.",
            "tab_marketplaces": "Tu môžete nastaviť prístup k integrovaným trhom, ako sú Amazon a Mercateo. Príručku nájdete <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">v našom návode</a>."
        }
    },
    "error_title": "Omyl",
    "request_error": "Chyba pri vykonávaní požiadavky. Skontrolujte svoje internetové pripojenie alebo to skúste znova neskôr.",
    "saved_success": "Dokument bol uložený.",
    "save_error": "Pri ukladaní sa vyskytla chyba.",
    "help_page": {
        "title": "Pomoc",
        "popup_feedback_msg": "Váš prehliadač je nastavený správne. Teraz môžete toto okno zavrieť.",
        "punchout_error_msg": "Došlo k chybe. Skontrolujte konfigurácie vytlačenia alebo kontaktujte niekoho s oprávneniami správcu.",
        "popup_did_not_open": "Ak sa práve neotvorilo žiadne nové okno, vyskakovacie okná sú vo vašom prehliadači zablokované. Preto môžu existovať obmedzenia pri používaní.",
        "test_email_msg": "Mal by sa otvoriť e-mailový program alebo zoznam na výber programov. V prípade potreby nastavte e-mailový program, ktorý si môžete vybrať zo zoznamu.",
        "tour_restart": "Prehliadka bola reštartovaná.",
        "manual_title": "Manuálny",
        "manual_msg": "<p>V príručke nájdete rôzne články a pokyny na používanie a nastavenie základne výťahu.</p>",
        "manual_open_btn": "Otvorte manuál",
        "function_title": "Testovacie funkcie",
        "popup_msg": "<p>Tu môžete otestovať, či váš prehliadač povoľuje vyskakovacie okná, ktoré sú potrebné na výber vášho produktu na externých trhoch Amazon a Mercateo.</p>",
        "popup_test_btn": "Otestujte blokovanie automaticky otváraných okien",
        "email_msg": "<p>Tu môžete vyskúšať, či môžete otvárať e-maily pomocou e-mailového programu. Funkciou emailu je napr. Používa sa napríklad na odosielanie objednávok doručovacím spoločnostiam.</p>",
        "email_test_btn": "Otestujte funkciu e-mailu",
        "tour_title": "Prehliadka",
        "tour_msg": "<p>Pre opätovné uvedenie do výťahovej základne môžete prehliadku znova spustiť tu.</p>",
        "tour_btn": "Začať odznova"
    },
    "dashboard_page": {
        "title": "Dashboard",
        "no_option": "Žiadne",
        "date_picker": {
            "start_placeholder": "Dátum začiatku",
            "end_placeholder": "Dátum začiatku",
            "separator": "bis"
        },
        "ordered_by": "Objednané z",
        "count_by_status": "Číslo podľa stavu",
        "diagrams": {
            "title": "Diagram",
            "count_by_status": "Počet podľa stavu",
            "value_by_status": "Čiastky podľa stavu (netto v EUR)",
            "count_by_department": "Počet na oddelenie",
            "value_by_department": "Čiastky na oddelenie (netto v EUR)",
            "count_by_cost_center": "Počet na nákladové stredisko",
            "value_by_cost_center": "Čiastky na nákladové stredisko (netto v EUR)",
            "count_by_ledger_account": "Počet za hlavný účet",
            "value_by_ledger_account": "Súm podľa hlavného účtu (netto v EUR)"
        },
        "budgets": {
            "title": "Rozpočty a výdavky",
            "approved_status": "Schválené",
            "in_approval_status": "Vo vydaní",
            "open_status": "OTVORENÉ",
            "no_budget": "Bez rozpočtu",
            "from": "Ab",
            "no_name": "Bez mena"
        },
        "products": {
            "title": "Produkty",
            "approved_status": "Schválené",
            "in_approval_status": "Vo vydaní"
        },
        "no_data": "Nenašli sa žiadne požiadavky na nákup.",
        "no_permission": "Nemáte povolenie pre dashboard.",
        "from_budget": "z rozpočtu",
        "data": "výdavky",
        "shortcuts": {
            "this_week": "Tento týždeň",
            "last_week": "Minulý týždeň",
            "this_month": "Tento mesiac",
            "last_month": "Minulý mesiac",
            "last_3_months": "Posledné 3 mesiace",
            "last_6_months": "Posledných 6 mesiacov",
            "this_year": "Tento rok",
            "last_year": "Minulý rok"
        },
        "tour": {
            "title": "Dashboard vám ponúka štatistiky a možnosti hodnotenia vašich požiadaviek na objednávku.",
            "filters_title": "Filter",
            "filters": "Najprv vyberte požadované obdobie a vyfiltrujte požiadavky na nákup, ktoré sa majú vyhodnotiť podľa spoločnosti, objednávateľa, nákladového strediska alebo oddelenia.",
            "count_by_status_title": "Číslo podľa stavu",
            "count_by_status": "Tu môžete vidieť, koľko z predfiltrovaných nákupných požiadaviek je v rôznych stavoch.",
            "graphic_title": "Diagram",
            "graphic": "Tu môžete vidieť grafické znázornenie počtu alebo čiastok nákupných požiadaviek, zoskupených podľa stavu, oddelenia alebo nákladového strediska.",
            "budget_title": "Rozpočty a výdavky",
            "budget": "Táto oblasť vám poskytuje prehľad o rozpočtoch a výdavkoch vašich nákladových stredísk. Farebné pruhy zobrazujú, ako sa využívajú rozpočty a koľko výdavkov sa ešte uvoľňuje.",
            "progress_bar_cost_center_title": "Tyče podľa nákladového strediska",
            "progress_bar_cost_center": "Tu si môžete otvoriť podrobnejší prehľad rôznych rozpočtov nákladového strediska. Zadané objednávky môžu byť uvedené aj pre jednotlivé rozpočty. Výdavky bez rozpočtu sú uvedené pod samostatnou lištou."
        }
    },
    "overview_page": {
        "title": "Prehľad",
        "procurement_abbr": "PO",
        "invoice_abbr": "IN",
        "draft_abbr": "DR",
        "delivery_note_abbr": "DN",
        "all_docs": "Všetky účtenky",
        "my_tasks": "Moje úlohy",
        "procurements": "Požiadavky na nákup",
        "invoices": "účty",
        "drafts": "koncepty",
        "no_selection": "Nie je vybratý žiadny dokument.",
        "comment_box_placeholder": "Tvoj komentár...",
        "comment_box_btn": "Zanechať komentár",
        "edit_filename_title": "Upraviť názov súboru",
        "edit_filename_msg": "Zadajte nový názov súboru. <br> Starý názov: {filename}",
        "duplicate_invoice": {
            "message": "Duplicita - Bola nájdená aspoň jedna faktúra s rovnakým číslom faktúry:",
            "invoice_number": "Číslo faktúry",
            "invoice_date": "Dátum"
        },
        "task_list": {
            "name": "Označenie",
            "status": "Postavenie",
            "date": "Dátum",
            "draft": "Návrh"
        },
        "searchbar": {
            "search": "Vyhľadávanie",
            "filter_by": "Triediť podľa",
            "created_at": "Dátum vytvorenia",
            "timeline": "Timeline",
            "last_used": "Naposledy použité",
            "no_search": "Nie",
            "timeline_props": {
                "editor_name": "Editor",
                "entry_type": "Krok časovej osi",
                "current_editor_name": "Aktuálny editor",
                "current_entry_type": "Aktuálny krok"
            },
            "procurement_props": {
                "order_number_formatted": "Číslo objednávky",
                "name": "Označenie",
                "creator_name": "Vytvoril(a)",
                "client_name": "Prenasledovať",
                "department_name": "oddelenie",
                "cost_center_name": "Názov nákladového strediska",
                "cost_center_number": "Číslo nákladového strediska",
                "consignee_name": "Príjem tovaru",
                "status": "Postavenie",
                "product_names": "Produkt",
                "product_numbers": "Číslo položky",
                "supplier_names": "Doručovacia spoločnosť"
            },
            "invoice_props": {
                "invoice_number": "číslo účtu",
                "invoice_number_formatted": "Interné LB číslo",
                "procurement_number": "Číslo objednávky",
                "creator_name": "nahrané používateľom",
                "client_name": "Prenasledovať",
                "cost_center_name": "Názov nákladového strediska",
                "cost_center_number": "Číslo nákladového strediska",
                "supplier_name": "Doručovacia spoločnosť",
                "ledger_account_name": "Účet hlavnej knihy",
                "status": "Postavenie",
                "product_names": "Produkt",
                "product_numbers": "Číslo položky",
                "due_date": "Splatnosť"
            },
            "time_ranges": {
                "today": "dnes",
                "yesterday": "včera",
                "past_7_days": "posledných 7 dní",
                "past_30_days": "posledných 30 dní",
                "this_week": "tento týždeň",
                "last_week": "minulý týždeň",
                "this_month": "tento mesiac",
                "last_month": "minulý mesiac",
                "this_year": "tento rok",
                "last_year": "minulý rok"
            }
        },
        "export": {
            "all_information": "Všetky informácie",
            "export_procurement": "Exportujte požiadavky na nákup",
            "export_documents": "Exportovať dokumenty",
            "registers": "Príspevky",
            "export": "Export",
            "to_export": "Export",
            "export_with_products": "Export s produktmi",
            "custom_export": "Nastavený export",
            "select_fields": "Vyberte polia, ktoré sa majú exportovať.",
            "select_all": "vybrať všetko",
            "deselect_all": "Odobrať všetky",
            "general": "Vo všeobecnosti",
            "products": "Produkty",
            "include_product": "Vrátane produktov",
            "filter": "Filter",
            "no_filter": "žiadny filter",
            "labels": {
                "procurement": {
                    "order_number_formatted": "číslo LB",
                    "name": "Označenie",
                    "status": "Postavenie",
                    "creator_name": "Vytvoril(a)",
                    "total_price_brutto": "Celková brutto",
                    "total_price_netto": "Celková netto",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Dátum vytvorenia",
                    "client_number": "Číslo firmy",
                    "client_name": "Meno spoločnosti",
                    "department_name": "oddelenie",
                    "cost_center_number": "Číslo nákladového strediska",
                    "cost_center_name": "Názov nákladového strediska",
                    "consignee_name": "Príjem tovaru",
                    "shipping_date": "dátum doručenia",
                    "shipping_name": "Názov dodacej adresy",
                    "shipping_consignee": "Dodacia adresa Meno prijímajúcej osoby",
                    "shipping_dropoff_location": "Dodacia adresa Názov skladovacieho miesta",
                    "shipping_address": "Ulica a číslo dodacej adresy.",
                    "shipping_complement": "Ulica dodacej adresy 2",
                    "shipping_country": "Krajina dodacej adresy",
                    "shipping_zipcode": "PSČ dodacej adresy",
                    "shipping_city": "Mesto dodacej adresy",
                    "shipping_phone_country_code": "Dodacia adresa telefón krajina",
                    "shipping_phone_number": "Telefónna adresa dodania",
                    "billing_name": "Názov fakturačnej adresy",
                    "billing_consignee": "Fakturačná adresa Meno príjemcu",
                    "billing_dropoff_location": "Fakturačná adresa Názov miesta uloženia",
                    "billing_address": "Fakturačná adresa, ulica a číslo.",
                    "billing_complement": "Ulica fakturačnej adresy 2",
                    "billing_country": "Krajina fakturačnej adresy",
                    "billing_zipcode": "PSČ fakturačnej adresy",
                    "billing_city": "Mesto fakturačnej adresy",
                    "billing_phone_country_code": "Fakturačná adresa telefónna krajina",
                    "billing_phone_number": "Fakturačná adresa telefónne číslo"
                },
                "product": {
                    "product_name": "Meno Produktu",
                    "product_number": "Číslo položky",
                    "product_supplier_name": "Názov doručovacej spoločnosti",
                    "product_supplier_email": "Email doručovacej spoločnosti",
                    "product_cost_center_name": "Názov nákladového strediska (produkt)",
                    "product_cost_center_number": "Číslo nákladového strediska (produkt)",
                    "product_tax": "daň",
                    "product_quantity": "Množstvo (produkt)",
                    "product_total_netto": "Celková netto (produkt)",
                    "product_total_brutto": "Celková hrubá suma (produkt)",
                    "product_netto": "Jednotková cena netto",
                    "product_brutto": "Jednotková cena brutto",
                    "product_unit": "Jednotka",
                    "product_img_url": "Adresa URL obrázka",
                    "product_shop_url": "Adresa URL obchodu",
                    "product_shipping_name": "Názov dodacej adresy (produktu)",
                    "product_shipping_consignee": "Dodacia adresa Meno prijímajúcej osoby (produktu)",
                    "product_shipping_dropoff_location": "Dodacia adresa Názov miesta skladu (produktu)",
                    "product_shipping_address": "Ulica a číslo dodacej adresy (produkt)",
                    "product_shipping_complement": "Ulica doručenia 2 (produkt)",
                    "product_shipping_country": "Krajina dodacej adresy (produkt)",
                    "product_shipping_zipcode": "PSČ dodacej adresy (produkt)",
                    "product_shipping_city": "Mesto dodania (produkt)",
                    "product_shipping_phone_country_code": "Dodacia adresa Krajina telefónu (produkt)",
                    "product_shipping_phone_number": "Telefónne číslo dodacej adresy (produkt)",
                    "product_received_at": "Posledný príjem tovaru (dátum)",
                    "product_received_by": "Posledný príjem tovaru (osoba)"
                },
                "invoice": {
                    "invoice_number_formatted": "Číslo internej faktúry",
                    "number": "číslo účtu",
                    "creator_name": "nahrané používateľom",
                    "status": "Postavenie",
                    "procurement_name": "žiadosť o objednávku",
                    "procurement_number": "Číslo objednávky",
                    "cost_center_name": "Názov nákladového strediska",
                    "cost_center_number": "Číslo nákladového strediska",
                    "total_price_netto": "Celková netto",
                    "total_price_brutto": "Celková brutto",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Nahrané dňa",
                    "client_name": "Prenasledovať",
                    "client_number": "Číslo firmy",
                    "invoice_date": "Dátum faktúry",
                    "due_date": "Do dátumu",
                    "delivery_date": "Dátum služby",
                    "currency": "mena",
                    "ledger_account_name": "Názov účtovného účtu",
                    "ledger_account_number": "Číslo účtu hlavnej knihy",
                    "posting_key_number": "Daňový kód",
                    "posting_key_tax": "Daňový kľúč Daň",
                    "supplier_name": "Doručovacia spoločnosť"
                }
            }
        },
        "actions": {
            "tour": {
                "procurement_reassignment_title": "Priradiť/objasniť požiadavku na nákup",
                "procurement_reassignment_msg": "Táto úloha momentálne leží na niekom inom. Pomocou tohto tlačidla môžete prideliť úlohu tretej osobe alebo sebe na objasnenie alebo ďalšie spracovanie. Žiadosti o nákup je možné zrušiť aj počas objasňovania.",
                "invoice_reassignment_title": "Priradiť/objasniť faktúru",
                "invoice_reassignment_msg": "Táto úloha momentálne leží na niekom inom. Pomocou tohto tlačidla môžete prideliť úlohu tretej osobe alebo sebe na objasnenie alebo ďalšie spracovanie. Faktúry je možné eliminovať aj pri objasňovaní."
            },
            "skip_confirm_dialog_title": "Zrušte rozpoznávanie dokumentov",
            "skip_confirm_dialog_msg": "Ak chcete skontrolovať, či už existuje výsledok rozpoznávania dokumentu, obnovte stránku vo svojom prehliadači. <br>Ste si istí, že chcete zrušiť rozpoznávanie údajov faktúry a zadať údaje ručne?",
            "button_hint": {
                "to_approval": "Na prepustenie",
                "to_send_order": "Do provízie",
                "to_accounting": "Do účtovníctva",
                "approve": "Uvoľnite",
                "self_assign": "Prijať",
                "finish_order": "Dokončite zadanie",
                "finish_delivery": "Dokončiť",
                "skip_recognition": "Zrušte rozpoznávanie dokumentov",
                "to_export": "Na export",
                "default": "ďalej",
                "cancel_or_assign_procurement": "Priradiť/Zrušiť",
                "cancel_or_assign_invoice": "Priradiť/ovládať",
                "clearing_or_assign": "Priradiť/objasniť"
            },
            "assignment_tooltip": "Objasnenie alebo zadanie",
            "assignment_dialog": {
                "title_procurement_assign_or_cancel": "Pridelenie alebo zrušenie",
                "title_invoice_assign_or_cancel": "Priradenie alebo kontrola",
                "title_assign": "pridelenie",
                "title_clearing_or_assign": "Objasnenie alebo zadanie",
                "assign_title": "Priraďte aktuálnu úlohu niekomu inému",
                "assign_msg": "Aktuálne pridelená osoba nie je k dispozícii alebo nie je zodpovedná za túto úlohu, takže túto úlohu by mal prevziať niekto iný.",
                "clearing_title": "Priraďte na objasnenie",
                "clearing_msg": "Aktuálne pridelená osoba je k dispozícii a zodpovedá za túto úlohu, ale proces nemôže pokračovať v spracovaní z iných dôvodov. Preto chcete začať proces objasňovania.",
                "cancel_procurement_title": "zrušenie",
                "cancel_procurement_msg": "Objednávka bude nenávratne zrušená a nie je možné ju ďalej spracovávať. Zrušené objednávky nájdete v prehľade pod statusom „Odmietnuté“.",
                "cancel_invoice_title": "Kontrola",
                "cancel_invoice_msg": "Faktúra je nenávratne vymazaná a nie je možné ju ďalej spracovávať. Vyplatené faktúry nájdete v prehľade všetkých dokladov pod stavom „Vyplatené“.",
                "assign_to": "Priradiť",
                "reason": "Dôvod",
                "clearing_footer_info": "Aktuálna úloha je zrušená. Pre vybranú osobu sa spustí nová úloha na objasnenie.",
                "reassignment_footer_info": "Zmení sa iba osoba, ktorá vykonáva aktuálnu úlohu.",
                "cancel_invoice_btn": "Diskvalifikovať",
                "cancel_procurement_btn": "Zrušiť",
                "assign_btn": "Priradiť",
                "clearing_dialog": {
                    "title": "Osoba na objasnenie ",
                    "procurement_message": "Osoba v kroku „Objasnenie“ je výsledkom rôznych predbežných pridelení zodpovedných ľudí podľa oddelenia, spoločnosti, predbežného pridelenia globálnej role a osoby, ktorá objednávku vytvorila. Osoba v hornej časti zoznamu je vopred priradená k úlohe.",
                    "invoice_message": "Osoba v kroku „Objasnenie“ je výsledkom rôznych predbežných pridelení zodpovedných ľudí na spoločnosť, predbežného pridelenia globálnej role a osoby, ktorá vytvorila faktúru. Osoba v hornej časti zoznamu je vopred priradená k úlohe.",
                    "department_form": "Oddelenie vo formulári",
                    "department_creator": "Oddelenie objednávateľa",
                    "client_form": "Spoločnosť vo forme",
                    "client_creator": "Spoločnosť osoby, ktorá zadáva objednávku",
                    "no_selection": "ešte nevybraté",
                    "no_action": "nepoužiteľné"
                },
                "cancellation_dialog": {
                    "title_procurement": "zrušenie",
                    "btn_procurement": "Zrušiť",
                    "message_procurement": "Naozaj chcete zrušiť túto objednávku?",
                    "title_invoice": "Kontrola",
                    "btn_invoice": "Diskvalifikovať",
                    "message_invoice": "Naozaj chcete zaplatiť tento účet?"
                }
            }
        },
        "tour": {
            "overview": {
                "search_box": "Vyhľadávanie vám umožňuje filtrovať požiadavky v časti Moje úlohy a Všetky potvrdenky a vyhľadávať rôzne informácie, aby ste rýchlejšie našli konkrétne požiadavky.",
                "tab_tasks": "Tu môžete vidieť požiadavky na nákup, za ktoré momentálne zodpovedáte. V pravom stĺpci vidíte stav svojich úloh.",
                "tab_all_procurements": "V tejto stromovej štruktúre nájdete všetky požiadavky na nákup zoradené podľa aktuálneho stavu.",
                "refresh_btn": "Tu môžete aktualizovať prehľad žiadostí o nákup.",
                "type_filter": "Tu môžete filtrovať podľa typu úlohy."
            },
            "procurement": {
                "form_title": "Formulár žiadosti o nákup",
                "form": "Tu nájdete informácie o žiadosti o nákup.",
                "approve_btn_title": "Dopredná požiadavka na nákup",
                "approve_btn": "Keď dokončíte úlohu a chcete požiadavku postúpiť ďalšej zodpovednej osobe alebo kroku, stlačte modré začiarknutie.",
                "reassign_btn": "Ak nechcete úlohu dokončiť, napríklad preto, že je stále neistota alebo by mal na úlohe pokračovať niekto iný, môžete stlačiť červené tlačidlo. Potom môžete úlohu buď odovzdať, alebo ju prideliť niekomu na objasnenie. Žiadosti o nákup je možné zrušiť aj počas objasňovania.",
                "add_comment": "K požiadavke na objednávku môžete pridať komentár pomocou poľa komentár. To sa potom zobrazí na časovej osi.",
                "timeline_title": "Timeline",
                "timeline": "Časová os zobrazuje minulý a budúci priebeh požiadavky na nákup, ako aj aktuálny stav (modrá) a komentáre k požiadavke na nákup. Radenie je možné obrátiť pomocou tlačidla ozubeného kolieska.",
                "timeline_preferences": "Toto tlačidlo možno použiť na nastavenie zobrazenia na časovej osi. Môžete napríklad nastaviť, či sa majú udalosti triediť vzostupne alebo zostupne a či sa majú úpravy požiadavky na nákup zobraziť alebo skryť."
            },
            "invoice": {
                "form_title": "Fakturačný formulár",
                "form": "Tu môžete zadať alebo upraviť informácie o faktúre.",
                "add_line": "Tu môžete pridať položku faktúry.",
                "edit_lines": "Tu môžete upraviť informácie o položkách faktúry.",
                "approve_btn_title": "Dopredná faktúra",
                "approve_btn": "Keď dokončíte úlohu a chcete postúpiť faktúru ďalšej zodpovednej osobe alebo kroku, stlačte modré začiarknutie.",
                "reassign_btn": "Ak nechcete úlohu dokončiť, napríklad preto, že je stále neistota alebo by mal na úlohe pokračovať niekto iný, môžete stlačiť červené tlačidlo. Potom môžete úlohu buď odovzdať, alebo ju prideliť niekomu na objasnenie. Faktúry je možné odstrániť aj počas objasňovania.",
                "add_comment": "Pomocou poľa komentára môžete urobiť komentár k faktúre. To sa potom zobrazí na časovej osi.",
                "timeline_title": "Timeline",
                "timeline": "Časová os zobrazuje minulý a budúci priebeh faktúry, ako aj aktuálny stav (modrá) a komentáre k faktúre. Radenie je možné obrátiť pomocou tlačidla ozubeného kolieska.",
                "timeline_preferences": "Toto tlačidlo možno použiť na nastavenie zobrazenia na časovej osi. Môžete napríklad nastaviť, či sa majú udalosti triediť vzostupne alebo zostupne a či sa majú úpravy faktúr zobraziť alebo skryť."
            },
            "welcome": {
                "logo_title": "Vitajte na liftbase!",
                "logo": "<p>Aby sme vám uľahčili začiatok, vysvetlíme vám najdôležitejšie veci pomocou prehliadky základne výťahu.</p> <p>Na navigáciu v prehliadke použite tlačidlá nižšie alebo klávesy so šípkami. </p>",
                "menu": "V pravom hornom rohu je hlavná ponuka, kde nájdete všetky dôležité oblasti výťahovej základne.",
                "menu_help": "Jednotlivé časti prehliadky môžete kedykoľvek ukončiť a neskôr v ponuke pomocníka znova spustiť.",
                "menu_admin": "Ďalšiu časť prehliadky nájdete tu."
            }
        }
    },
    "operator_area": {
        "title": "Operátor",
        "tenant_menu": "Nájomníci",
        "procurement_menu": "Požiadavky na nákup",
        "invoice_menu": "účty",
        "no_permission": "Nemáte povolenie na zobrazenie tejto oblasti.",
        "tenant": {
            "description": "Nájomca",
            "new": "Nový nájomca",
            "edit": "Upraviť",
            "archive": "Archív",
            "delete": "Odstrániť",
            "activate": "Obnoviť",
            "procurement_count": "BANF",
            "user_count": "užívateľ",
            "automatic_billing": "Automatické účtovanie",
            "tabs": {
                "general": "Vo všeobecnosti",
                "invoice_config": "Faktúra"
            },
            "standard_package": "Štandardný balík",
            "invoice": {
                "items": {
                    "label": "Dodatočné položky",
                    "description": "Popis",
                    "price": "Čistá suma",
                    "start_date": "Dátum začiatku",
                    "end_date": "Dátum ukončenia"
                },
                "tiered_price": {
                    "label": "Stupňovité ceny",
                    "unit_price": "Jednotková cena",
                    "base_price": "Základná cena",
                    "doc_amount": "Množstvo"
                }
            },
            "contact_email_tooltip": "Nájomca sa môže najskôr prihlásiť pomocou tohto e-mailu.",
            "no_bank_info": "Údaje ešte neexistujú a môže ich vytvoriť iba nájomca.",
            "no_connection_with_easybill": "Tento nájomca ešte nie je pripojený k Easybill. Zadajte ID nájomcu v systéme Easybill.",
            "sync_with_easybill": "Získajte všetky údaje z Easybill",
            "update_from_easybill_btn": "Načítať z Easybill",
            "update_from_easybill_tooltip": "Získajte nájomcu z Easybill",
            "send_to_easybill_btn": "Vytvorte v Easybill",
            "send_to_easybill_tooltip": "Vytvorte nájomníka v Easybill",
            "create_and_send_invoice_tooltip": "Vytvorte faktúru v Easybill a odošlite ju zákazníkom",
            "create_and_send_invoice_btn": "Vytvoriť/odoslať faktúru",
            "open_invoices": "Zobraziť výpisy",
            "delete_procurements": "Odstrániť BANF",
            "delete_invoices": "Vymazať faktúry",
            "confirm_before_delete_msg": "<p>Naozaj chcete vymazať tohto nájomníka a všetky jeho údaje z databázy?</p><p> <i>Táto akcia sa nedá vrátiť späť.</i></p><p><b >Enter Zadajte meno nájomníka:</b></p>",
            "confirm_before_delete_error_msg": "Nájomca nebol odstránený, pretože meno bolo zadané nesprávne.",
            "deleted_msg": "Nájomník bol natrvalo odstránený.",
            "updated_msg": "Bola aktualizovaná.",
            "created_msg": "Bol vytvorený.",
            "sync_msg_error": "Tento nájomca ešte nie je pripojený k Easybill. Zadajte ID nájomcu v systéme Easybill.",
            "create_invoice_dialog": {
                "title": "Vytvoriť fakturáciu",
                "message": "Vyberte obdobie pre faktúru.",
                "date_placeholder": "Vyberte mesiac.",
                "create_btn": "Vytvorte"
            },
            "validation": {
                "name_required": "Zadajte meno.",
                "name_exists": "Názov už existuje.",
                "email_required": "Zadajte kontaktný e-mail.",
                "email_invalid": "Prosím zadajte platný e-mail."
            }
        },
        "procurement": {
            "title": "Požiadavky na kúpu nájomcu",
            "delete_title": "Odstrániť požiadavku na nákup",
            "result_found": "Stretnutie",
            "delete_result_found": "Odstrániť prístupy",
            "start_date": "Dátum začiatku",
            "end_date": "Datované",
            "range_separator": "bis",
            "confirm_delete_msg": "Naozaj chcete natrvalo odstrániť vybraté požiadavky z databázy?",
            "confirm_delete_title": "Odstrániť požiadavku na nákup",
            "confirm_delete_btn": "Odstrániť"
        },
        "invoice": {
            "title": "Faktúry nájomníkov",
            "delete_title": "Vymazať faktúry",
            "result_found": "Stretnutie",
            "delete_result_found": "Odstrániť prístupy",
            "start_date": "Dátum začiatku",
            "end_date": "Datované",
            "range_separator": "bis",
            "confirm_delete_msg": "Naozaj chcete natrvalo odstrániť vybraté faktúry z databázy?",
            "confirm_delete_title": "Vymazať faktúru",
            "confirm_delete_btn": "Odstrániť"
        }
    },
    "timeline": {
        "title": "Timeline",
        "preferences": {
            "order_asc": "Najprv najstarší",
            "order_desc": "Najnovšie ako prvé"
        },
        "text": {
            "description_assignment": "Úloha bola prijatá.",
            "cancellation": {
                "name": "{name}",
                "description_past_successful_invoice": "Faktúra bola vyradená a archivovaná.",
                "description_past_successful_procurement": "Objednávka bola zrušená a archivovaná.",
                "name_procurement": "Zrušenie",
                "name_invoice": "Vyradenie"
            },
            "start": {
                "name": "Nová požiadavka na nákup",
                "description_past_successful": "Bola vytvorená nová požiadavka na nákup."
            },
            "select-product": {
                "is_searchable": true,
                "simple_name": "Výber produktu",
                "name": "Výber produktu (úroveň {index})",
                "description_past_unsuccessful": "Výber produktu bol zrušený.",
                "description_past_successful": "Produkty pre túto objednávku boli vybraté.",
                "description_present": "Vyberte produkty pre túto objednávku.",
                "description_future": "Je potrebné vybrať produkty pre túto objednávku."
            },
            "approval": {
                "is_searchable": true,
                "simple_name": "Uvoľniť požiadavku na nákup",
                "name": "Uvoľniť požiadavku na nákup (úroveň {index})",
                "description_past_unsuccessful": "Uvoľnenie objednávky bolo zrušené.",
                "description_past_successful": "Žiadosť o nákup bola skontrolovaná a uvoľnená.",
                "description_present": "Prosím skontrolujte a schváľte žiadosť o objednávku.",
                "description_future": "Žiadosť o nákup musí byť skontrolovaná a schválená."
            },
            "send-order": {
                "is_searchable": true,
                "name": "Zadanie objednávky",
                "description_past_unsuccessful": "Objednávka s doručovacou spoločnosťou bola zrušená.",
                "description_past_successful": "Objednávka bola odoslaná doručovacej spoločnosti.",
                "description_present": "Objednávajte prosím od doručovacej spoločnosti.",
                "description_future": "Objednávku je potrebné zadať doručovacej spoločnosti."
            },
            "delivery": {
                "is_searchable": true,
                "name": "Potvrdenie o doručení",
                "description_past_unsuccessful": "Potvrdenie o prijatí tovaru bolo zrušené.",
                "description_past_successful": "Tovar bol doručený a prevzatie tovaru bolo potvrdené.",
                "description_present": "Potvrďte prosím prevzatie tovaru.",
                "description_future": "Prevzatie tovaru je potrebné potvrdiť."
            },
            "reassignment": {
                "name": "pridelenie",
                "description_past_unsuccessful": "",
                "description_past_successful": "pridelil úlohu používateľovi {name}.",
                "description_past_successful_from_group": "prijal úlohu."
            },
            "clearing": {
                "is_searchable": true,
                "name": "objasnenie",
                "description_past_unsuccessful": "Objasňovanie bolo prerušené.",
                "description_past_successful": "Prípadné nejasnosti v objednávke boli objasnené.",
                "description_present": "Prosím o upresnenie objednávky.",
                "description_finished_unsuccessful": "Objednávka bola zrušená a archivovaná."
            },
            "order-sent-internal": {
                "name": "Systémová správa",
                "description_past_successful": "Objednávka <b>({first_product_name})</b> od doručovacej spoločnosti <b>{supplier}</b> bola potvrdená."
            },
            "order-sent-punchout": {
                "name": "Systémová správa",
                "description_past_successful": "Objednávka <b>({first_product_name})</b> bola odoslaná elektronicky doručovacej spoločnosti <b>{supplier}</b>."
            },
            "product-received": {
                "name": "Systémová správa",
                "description_past_successful": "<b>{quantity} {unit}</b> eingegangen:<p>{product}</p><span style=\"color:#9a9898\">{supplier}</span>"
            },
            "invoice-upload": {
                "name": "Nový účet",
                "description_past_successful": "Faktúra bola nahraná."
            },
            "invoice-recognition": {
                "is_searchable": true,
                "name": "Rozpoznávanie faktúr",
                "description_past_unsuccessful": "Rozpoznanie údajov faktúry bolo zrušené.",
                "description_past_successful": "Rozpoznanie údajov faktúry bolo dokončené.",
                "description_present": "Údaje o faktúre sa načítavajú. Tento proces môže trvať až jednu minútu. Obnovte stránku po tomto čase, aby ste videli výsledky."
            },
            "invoice-manual-check": {
                "is_searchable": true,
                "name": "Manuálna kontrola",
                "description_past_unsuccessful": "Manuálna kontrola nebola dokončená.",
                "description_past_successful": "Faktúra bola skontrolovaná ručne.",
                "description_present": "Skontrolujte fakturačné údaje.",
                "description_future": "Faktúru je potrebné skontrolovať manuálne."
            },
            "invoice-financial-approval": {
                "is_searchable": true,
                "simple_name": "Schválenie faktúry",
                "name": "Schválenie faktúry (Úroveň {index})",
                "description_past_unsuccessful": "Schválenie faktúry bolo zrušené.",
                "description_past_successful": "Faktúra bola skontrolovaná a schválená.",
                "description_present": "Prosím, skontrolujte a schváľte faktúru.",
                "description_future": "Faktúra musí byť skontrolovaná a schválená."
            },
            "invoice-clearing": {
                "is_searchable": true,
                "name": "Objasnenie účtovníctva",
                "description_past_unsuccessful": "Objasňovanie bolo prerušené.",
                "description_past_successful": "Prípadné nejasnosti v návrhu zákona boli objasnené.",
                "description_present": "Prosím o vysvetlenie faktúry.",
                "description_finished_unsuccessful": "Faktúra bola vyplatená a archivovaná."
            },
            "invoice-export": {
                "is_searchable": true,
                "name": "Export",
                "description_past_unsuccessful": "Faktúra nebola exportovaná.",
                "description_past_successful": "Faktúra bola odoslaná spoločnosti {export_name}.",
                "description_present": "Faktúra je pripravená na export.",
                "description_future": "Faktúra je pripravená na export."
            },
            "invoice-accounting": {
                "is_searchable": true,
                "name": "Účtovníctvo",
                "description_past_unsuccessful": "Účtovníctvo bolo zrušené.",
                "description_past_successful": "Účtovníctvo bolo overené.",
                "description_present": "Prosím skontrolujte účtovníctvo.",
                "description_future": "Účtovníctvo treba overiť."
            },
            "skip": {
                "name": "zrušenie",
                "description_past_successful": "prerušil krok."
            }
        }
    },
    "jexcel": {
        "noRecordsFound": "Nenašli sa žiadne záznamy.",
        "showingPage": "Strana {0} z {1}",
        "show": "Šou",
        "entries": "Príspevky",
        "insertANewColumnBefore": "Vložte stĺpec pred neho",
        "insertANewColumnAfter": "Potom vložte stĺpec",
        "deleteSelectedColumns": "Odstrániť vybraté stĺpce",
        "renameThisColumn": "Premenovať stĺpec",
        "orderAscending": "Zoradiť vzostupne",
        "orderDescending": "Zoradiť zostupne",
        "insertANewRowBefore": "Vložte riadok predtým",
        "insertANewRowAfter": "Potom vložte riadok",
        "deleteSelectedRows": "Odstrániť vybraté riadky",
        "copy": "Kopírovať",
        "paste": "Vložiť",
        "saveAs": "Uložiť ako",
        "about": "Koniec",
        "areYouSureToDeleteTheSelectedRows": "Naozaj chcete odstrániť vybraté riadky?",
        "areYouSureToDeleteTheSelectedColumns": "Naozaj chcete odstrániť vybraté stĺpce?",
        "thisActionWillDestroyAnyExistingMergedCellsAreYouSure": "Táto akcia zničí všetky existujúce zlúčené bunky. Si si istý?",
        "thisActionWillClearYourSearchResultsAreYouSure": "Táto akcia vymaže výsledky vyhľadávania. Si si istý?",
        "thereIsAConflictWithAnotherMergedCell": "Došlo ku konfliktu s inou zlúčenou bunkou",
        "invalidMergeProperties": "Neplatné zlúčené vlastníctva",
        "cellAlreadyMerged": "Bunka je už zlúčená",
        "noCellsSelected": "Nie sú vybraté žiadne bunky",
        "save_warning": "Kopírovanie údajov medzi Excelom a webom môže viesť k nesprávnym poradím a stratám ID vzťahov. <br>Prosím, používajte iba online úpravu alebo pridajte údaje z Excelu ako doplnok."
    },
    "system_comment": {
        "name": {
            "allocation": "Pridelenie",
            "datev_export": "Export DATEV",
            "export_error": "Chyba pri exportovaní",
            "export_detail": "Exportný protokol",
            "repeat_recognition": "Zopakovať rozpoznanie faktúry"
        },
        "recognition_was_repeated": "{user_name} zopakoval/a rozpoznanie dokladu.",
        "attach_new_invoice_to_procurement": "Faktúra <b>{link_to_invoice}</b> od <b>{supplier_name}</b> bola priradená k tejto požiadavke na nákup od <b>{user_name}</b>.",
        "attach_new_invoice_to_procurement_without_supplier": "Faktúru <b>{link_to_invoice}</b> priradil k tejto nákupnej požiadavke používateľ <b>{user_name}</b>.",
        "change_procurement_of_invoice": "Priradenie faktúry <b>{link_to_invoice}</b> od <b>{supplier_name}</b> bolo opäť odstránené. <br>Faktúra bola priradená k požiadavke na nákup {link_to_procurement}.",
        "change_procurement_of_invoice_without_supplier": "Priradenie faktúry <b>{link_to_invoice}</b> bolo opäť odstránené. <br>Faktúra bola priradená k požiadavke na nákup {link_to_procurement}.",
        "detach_procurement_of_invoice": "Priradenie faktúry <b>{link_to_invoice}</b> od <b>{supplier_name}</b> bolo opäť odstránené.",
        "detach_procurement_of_invoice_without_supplier": "Priradenie faktúry <b>{link_to_invoice}</b> bolo opäť odstránené.",
        "attach_external_invoice_to_procurement": "Faktúra {invoice_number} od <b>{supplier_name}</b> bola priradená k tejto nákupnej požiadavke od <b>{invoice_by}</b>.",
        "insert_datev_note": "Faktúra <b>{link_to_invoice}</b> od <b>{supplier_name}</b> bola odoslaná online spoločnostiam DATEV.",
        "insert_datev_note_without_supplier": "Faktúra <b>{link_to_invoice}</b> bola odoslaná online spoločnostiam DATEV.",
        "on_ms365_export_error_generic": "Chyba pri exporte faktúry do D365. Kontaktujte podporu.",
        "on_ms365_export_validation_error": "Chyba pri exporte faktúry do D365. Kontaktujte podporu. Chyba: {error}",
        "on_export_error": "Faktúru nebolo možné odoslať spoločnostiam DATEV online. {error}",
        "on_export_error_generic": "Chyba pri exporte faktúry do DATEV. Kontaktujte podporu.",
        "on_export_error_validation": "{supplier_external_id_export_validation}{supplier_name_export_validation} Ak chcete skontrolovať konfiguráciu, kontaktujte osobu s oprávneniami správcu.",
        "on_export_error_datev_access_is_not_active": "Musí byť vytvorené pripojenie k DATEV. Kontaktujte niekoho s oprávneniami správcu.",
        "on_export_error_client_has_no_datev_access": "Spoločnosť {client_name} neposkytla žiadne prístupové údaje DATEV. Kontaktujte niekoho s oprávneniami správcu.",
        "on_export_error_client_has_no_datev_permission": "Pri prenose do DATEV sa vyskytla chyba. Prístup DATEV, ktorý bol nastavený, nemá autorizáciu pre spoločnosť {client_name}. Ak chcete skontrolovať prístup alebo nakonfigurované spoločnosti alebo oprávnenia v rámci DATEV, kontaktujte osobu s právami správcu.",
        "on_export_error_datev_has_protocol_entries": "Chyba pri exporte faktúry do DATEV. Skontrolujte exportný protokol alebo kontaktujte podporu.",
        "on_export_error_no_fiscal_year": "Pri prenose do DATEV sa vyskytla chyba. Dátum faktúry nie je vo finančnom roku DATEV. Ak chcete skontrolovať fiškálny rok v DATEV, kontaktujte osobu s právami správcu.",
        "on_export_error_invoice_not_active_for_fiscal_year": "Pri prenose do DATEV sa vyskytla chyba. Finančný rok DATEV nie je uvoľnený na prenos došlých faktúr. Ak chcete skontrolovať fiškálny rok v DATEV, kontaktujte osobu s právami správcu.",
        "insert_datev_protocol_note": "<b>Stav</b>: {status} <br><br> <b>Protokolleinträge</b>: <br> {protocol}",
        "labels": {
            "invoice_number_label": "číslo účtu",
            "procurement_number_label": "Číslo objednávky",
            "invoice_date_label": "Dátum faktúry",
            "invoice_due_date_label": "Do dátumu",
            "supplier_label": "Doručovacia spoločnosť",
            "client_label": "Prenasledovať",
            "total_netto_label": "Celková netto",
            "total_brutto_label": "Celková brutto",
            "tax_label": "Sadzba dane",
            "positions_count_label": "pozície",
            "no_data_read_label": "Nezistili sa žiadne údaje.",
            "supplier_external_id_export_validation": "Externé ID doručovacej spoločnosti musí byť vopred nakonfigurované.",
            "supplier_name_export_validation": "Názov doručovacej spoločnosti musí byť vopred nakonfigurovaný.",
            "recognition_was_repeated": "{user_name} zopakoval/a rozpoznanie dokladu."
        }
    },
    "login": {
        "email": {
            "title": "Prihláste sa emailom",
            "instructions": "Prosím, zadajte svoju e-mailovú adresu. Potom kliknite na prihlasovací odkaz v e-maile.",
            "punchin_instructions": "Na dokončenie procesu sa musíte prihlásiť a spustiť proces schvaľovania objednávky.",
            "tenant": "Nájomca",
            "place_holder": "Emailová adresa",
            "last_used_emails": "Naposledy použité e-mailové adresy",
            "delete_last_used_emails": "Vymažte „Nedávno použité“.",
            "has_no_account": "Ešte nemáte prístup?",
            "login_link_via_email": "Prihlasovací odkaz prijatý e-mailom",
            "finish_login_title": "Dokončite prihlásenie",
            "finish_login_instructions": "Teraz dostanete e-mail s odkazom na prihlásenie. Kliknutím na odkaz dokončíte prihlásenie. <br><br>Prihlasovací e-mail bol odoslaný na adresu <br><b>{email}</b><br> a mal by prísť v krátkom čase. V prípade potreby skontrolujte priečinok so spamom.",
            "forgot_passwort": {
                "title": "Zabudol si heslo",
                "login_via_email": "Prihláste sa e-mailom",
                "instructions": "V liftbase nie sú žiadne heslá. Pošleme vám e-mail s odkazom na prihlásenie. Alebo použijete prístupové kľúče."
            },
            "no_email": "Žiadny e-mail",
            "no_email_validation": "Pre prihlásenie zadajte svoju e-mailovú adresu.",
            "check_email_title": "Skontrolujte e-mail",
            "check_email_message": "Skontrolujte si teraz svoju e-mailovú schránku a kliknite na prihlasovací odkaz."
        },
        "or": "alebo",
        "passkey": {
            "title": "Prihláste sa pomocou prístupového kľúča",
            "instructions": "Kliknutím na tlačidlo sa prihláste pomocou prístupového kľúča.",
            "login": "Prihláste sa pomocou prístupového kľúča",
            "what_is": {
                "title": "Čo sú prístupové kľúče?",
                "first": "Prihláste sa bez čakania na e-mail",
                "second": "Pomocou odtlačku prsta, tváre alebo kódu PIN",
                "third": "Nastavte si ho vo svojom profile hneď v niekoľkých krokoch"
            },
            "error": "Chyba pri prihlasovaní pomocou prístupového kľúča. Prosím skúste znova."
        }
    },
    "languages": {
        "de": "Deutsch",
        "en": "Angličtina",
        "it": "taliansky",
        "cz": "český",
        "sk": "slovenský"
    },
    "translation-component": {
        "repeated": "Nemôžete vybrať rovnaký jazyk viackrát.",
        "language_empty": "Musíte vybrať jazyk.",
        "translation_empty": "Musíte zadať text pre tento vybraný jazyk.",
        "both_empty": "Musíte zadať aj jazyk, aj text.",
        "language": "Jazyk",
        "no_translation": "Pre tento jazyk nie je zaregistrovaný žiadny text.",
        "at_least_one_required": "Musíte uviesť aspoň jedno meno.",
        "text_without_translation": "Bez prekladu"
    },
    "mailbox": {
        "description": "P.O. Box",
        "description_plural": "Poštové schránky",
        "name": "Názov poštovej schránky",
        "username": "Emailová adresa",
        "password": "heslo",
        "host": "Hostiteľ",
        "protocol": "Protokol",
        "folder": "Priečinok",
        "mailbox": "Poštová schránka",
        "period_in_days": "Obdobie v dňoch",
        "period_in_days_column": "Obdobie (dni)",
        "period_in_days_tooltip": "Vek e-mailov v dňoch",
        "remove_mailbox_msg": "Naozaj chcete odstrániť poštovú schránku?",
        "remove_mailbox_btn": "Odstrániť",
        "import_invoices_automatically": "Import faktúr je aktívny",
        "import_mode": "Režim importu",
        "import_mode_per_attachment": "Vytvoriť jednu faktúru na každú prílohu e-mailu",
        "import_mode_per_email": "Vytvoriť jednu faktúru na každý e-mail",
        "check_connection_btn": "Otestujte pripojenie",
        "connectivity_success_msg": "Spojenie bolo úspešne nadviazané.",
        "connectivity_error_msg": "Spojenie sa nepodarilo nadviazať. Skontrolujte nastavenia.",
        "save_and_connect_btn": "Uložiť a pripojiť",
        "disconnect_btn": "odpojiť",
        "disconnect_success_msg": "Spojenie bolo úspešne odpojené."
    },
    "marketplace": {
        "description": "Trhovisko",
        "description_plural": "Trhoviská",
        "provider": "Poskytovateľ",
        "country_code": "Krajina",
        "url": "URL",
        "email": "E-mail",
        "order_url": "URL objednávky",
        "identity": "Identita",
        "secret": "Tajomstvo",
        "new": "Nový trh"
    },
    "payment_options": {
        "label": "Spôsob platby dodávateľskej spoločnosti",
        "AUTOMATIC_DEBIT": "Prostredníctvom SEPA inkasa",
        "CREDIT_CARD": "Prostredníctvom kreditnej karty",
        "BANK_TRANSFER": "Prostredníctvom bankového prevodu"
    }

}